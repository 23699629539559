import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ModeloSelectModule } from '../modelo-select/modelo-select.module';

import { ElementosSelectComponent } from './elementos-select.component';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [
    ElementosSelectComponent,
  ],
  imports: [
    CommonModule,
    ModeloSelectModule,
    SharedModule
  ],
  exports: [
    ElementosSelectComponent
  ]
})
export class ElementosSelectModule { }
