<div #fieldInformacion id="fieldInformacionInformacion">
  <fieldset>
    <legend>{{ translate("Informacion") }}</legend>

    <div class="row">
      <span style="width: 20%;">
        <div style="margin-top: 5px; clear: both">
          {{ translate("CODIGO") }}
        </div>
        <jqxInput [value]="elemEdit?.Id" [rtl]="true" [width]="'100%'" disabled="true">
        </jqxInput>
      </span>

      <span style="width: 80%;">
        <div style="margin-top: 5px; clear: both">
          {{ translate("Nombre") }}
        </div>
        <jqxInput [value]="elemEdit?.Nombre" (onChange)="elemEdit.Nombre = $event?.args?.value" [width]="'100%'"
          [disabled]="crearMultiples">
        </jqxInput>
      </span>
    </div>

    <div class="row">
      <span style="width: 100%;">
        <div style="margin-top: 5px; clear: both">
          {{ translate("Equipamiento") }}
        </div>
        <jqxDropDownButton #cbEquip [width]="'100%'" [theme]="environment.tema" [animationType]="'none'"
          (onClose)="onCloseSelectEquipamientos($event)" (onOpen)="onOpenSelectEquipamientos($event)"
          (focusout)="cbEquip.close()">
        </jqxDropDownButton>
        <div *ngIf="verGridEquipamiento" style="position: absolute; z-index: 999;">
          <jqxGrid #gridDropDownEquip [theme]="environment.tema" [width]="455" [height]="220"
            [enablebrowserselection]="true" [source]="dataAdapterEquip" [columns]="columnsEquip"
            [localization]="langGrid" (onRowclick)="onRowClickEquip($event)">
          </jqxGrid>
        </div>
      </span>
    </div>

    <div class="row">
      <span style="width: 50%;">
        <div style="margin-top: 5px; clear: both">
          {{ translate("Tipo_elemento") }}
        </div>
        <jqxInput [value]="elemEdit?.Equipamiento?.Elemento?.Nombre" [width]="'100%'" [disabled]="true">
        </jqxInput>
      </span>

      <span style="width: 20%;">
        <div style="margin-top: 5px; clear: both">
          {{ translate("Matricula") }}
        </div>
        <jqxInput [value]="elemEdit?.Tag?.Matricula" [width]="'100%'" [disabled]="true">
        </jqxInput>
      </span>

      <span style="width: 30%;">
        <div style="margin-top: 5px; clear: both">
          {{ translate("Tag") }}
        </div>
        <jqxInput [value]="elemEdit?.Tag?.Tag"
          (onChange)="elemEdit.Tag != null ? elemEdit.Tag.Tag = $event?.args?.value : null" [width]="'100%'"
          [disabled]="crearMultiples">
        </jqxInput>
      </span>
    </div>

    <div class="row">
      <span style="width: 50%;">
        <div style="margin-top: 5px; clear: both">
          {{ translate("Marca") }}
        </div>
        <jqxInput [value]="elemEdit?.Equipamiento?.Marca?.Nombre" [width]="'100%'" [disabled]="true">
        </jqxInput>
      </span>
      <span style="width: 50%;">
        <div style="margin-top: 5px; clear: both">
          {{ translate("Modelo") }}
        </div>
        <jqxInput [value]="elemEdit?.Equipamiento?.Modelo?.Nombre" [width]="'100%'" [disabled]="true">
        </jqxInput>
      </span>
    </div>

    <div class="row">
      <span style="width: 35%;">
        <div style="margin-top: 5px; clear: both">
          {{ translate("Estado") }}
        </div>
        <jqxInput [value]="elemEdit?.EstadoElemento?.Nombre" [width]="'100%'" [disabled]="true">
        </jqxInput>
      </span>

      <span style="width: 65%;">
        <div style="margin-top: 5px; clear: both">
          {{ translate("Residuo") }}
        </div>
        <jqxInput [value]="elemEdit?.Equipamiento?.Residuo?.Nombre ? elemEdit?.Equipamiento?.Residuo?.Nombre :
          elemEdit?.Equipamiento?.Residuos[0]?.Nombre ? elemEdit?.Equipamiento?.Residuos[0]?.Nombre : ''"
          [width]="'100%'" [disabled]="true">
        </jqxInput>
      </span>
    </div>

    <div style="width: 100%; height: 25%; margin-bottom: 5px;">
      <div style="margin-top: 5px; clear: both">
        {{ translate("Observaciones") }}
      </div>
      <jqxTextArea (onChange)="elemEdit.Observaciones = $event?.owner?.query" [width]="'100%'" [height]="'100%'">
      </jqxTextArea>
    </div>
  </fieldset>
</div>

<div id="fieldInformacionUbicacion">
  <fieldset>
    <legend>{{ translate("Ubicacion") }}</legend>
    <div class="row">
      <span style="width: 20%;">
        <div style="margin-top: 5px; clear: both">
          {{ translate("CODIGO") }}
        </div>
        <jqxInput [value]="elemEdit?.PU?.Id" (onChange)="elemEdit?.PU != null ? elemEdit.PU.Id = $event : null"
          [width]="'100%'" [rtl]="true" disabled="true">
        </jqxInput>
      </span>

      <span style="width: 80%;">
        <div style="margin-top: 5px; clear: both">
          {{ translate("Nombre") }}
        </div>
        <jqxInput [value]="elemEdit?.PU?.Nombre" [width]="'100%'">
        </jqxInput>
      </span>
    </div>

    <div class="row">
      <span style="width: 85%;">
        <div style="margin-top: 5px; clear: both">
          {{ translate("Calle") }}
        </div>
        <jqxInput [value]="elemEdit?.Calle" (onChange)="elemEdit.Calle = $event?.args?.value" [width]="'100%'">
        </jqxInput>
      </span>

      <span style="width: 15%;">
        <div style="margin-top: 5px; clear: both">
          {{ translate("Numero") }}
        </div>
        <jqxInput [value]="elemEdit?.NumeroCalle" (onChange)="elemEdit.NumeroCalle = $event?.args?.value"
          [width]="'100%'" [rtl]="true">
        </jqxInput>
      </span>
    </div>

    <div class="row">
      <span style="width: 20%;">
        <div style="margin-top: 5px; clear: both">
          {{ translate("Codigo_postal") }}
        </div>
        <jqxInput [value]="elemEdit?.CodigoPostal" (onChange)="elemEdit.CodigoPostal = $event?.args?.value"
          [width]="'100%'" [rtl]="true">
        </jqxInput>
      </span>

      <span style="width: 40%;">
        <div style="margin-top: 5px; clear: both">
          {{ translate("Poblacion") }}
        </div>
        <jqxInput [value]="elemEdit?.Poblacion" (onChange)="elemEdit.Poblacion = $event?.args?.value" [width]="'100%'">
        </jqxInput>
      </span>

      <span style="width: 40%;">
        <div style="margin-top: 5px; clear: both">
          {{ translate("Municipio") }}
        </div>
        <jqxInput [value]="elemEdit?.Municipio" (onChange)="elemEdit.Municipio = $event?.args?.value" [width]="'100%'">
        </jqxInput>
      </span>
    </div>

    <div class="row">
      <span style="width: 50%;">
        <div style="margin-top: 5px; clear: both">
          {{ translate("Provincia") }}
        </div>
        <jqxInput [value]="elemEdit?.Provincia" (onChange)="elemEdit.Provincia = $event?.args?.value" [width]="'100%'">
        </jqxInput>
      </span>

      <span style="width: 50%;">
        <div style="margin-top: 5px; clear: both">
          {{ translate("Comunidad_autonoma") }}
        </div>
        <jqxInput [value]="elemEdit?.ComunidadAutonoma" (onChange)="elemEdit.ComunidadAutonoma = $event?.args?.value"
          [width]="'100%'">
        </jqxInput>
      </span>
    </div>

    <div style="height: 40%;">
      <div style="margin-top: 5px; clear: both; width: 100%;">
        {{ translate("Historico") }}
      </div>
      <jqxGrid #gridHistorico [theme]="environment.tema" [width]="'100%'" [height]="'100%'" [appCellClick]
        [source]="dataAdapterHistorico" [columns]="columnsHistorico" [showdefaultloadelement]="false"
        [enablebrowserselection]="true" [columnsheight]="20" [rowsheight]="25" (onCellclick)="cellClick($event)">
      </jqxGrid>
    </div>
  </fieldset>
</div>

<div id="fieldInformacionImagenes">
  <fieldset>
    <legend>{{ translate("Imagenes") }}</legend>

    <app-images id="elementImg" [from]="'elemento'" [imagenes]="elemEdit?.Imagenes" [asociateId]="elemEdit?.Id"
      (onAddImage)="addImageDetalle($event, 2)" (onRmvImage)="rmvImageDetalle($event, 2)">
    </app-images>
  </fieldset>
</div>
