String.prototype.normaliceAccents = function() {
  if (this !== null) {
    return this?.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }
  return null;
};

String.prototype.compareWith = function(value: string) {
  if(this.normaliceAccents().toUpperCase().indexOf(value.normaliceAccents().toUpperCase()) > -1) {
    return true;
  }else {
    return false;
  }
}

String.prototype.equalsTo = function(value: string) {
  if(this.normaliceAccents().toUpperCase() === value.normaliceAccents().toUpperCase()) {
    return true;
  }else {
    return false;
  }
}
