import { Injectable } from '@angular/core';
import { CuadriculaModel } from './models/cuadricula.model';
import { SsoService } from '../sso/sso.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuditoriaService } from '../auditoria/auditoria.service';
import { AuditoriaModel } from '../auditoria/models/auditoria.model';
import { MainComponent } from 'src/app/components/main/main.component';
import { Accion } from '../auditoria/models/accion.model';

@Injectable({
  providedIn: 'root'
})
export class CuadriculaService {
  private usuario = this.ssoService.getTicket().Usuario.Email;
  private auditoria: AuditoriaModel = new AuditoriaModel(this.usuario, 0);

  constructor(private ssoService: SsoService, private http: HttpClient, private auditoriaService: AuditoriaService) { }

  // Obtener Cuadriculas
  async getCuadriculas(): Promise<CuadriculaModel[]> {
    let response = null;
    try {
      response = await this.http.get(this.ssoService.getTicket().UrlApi + '/api/cuadriculas?enterprise=' + this.ssoService.getTicket().Empresa.IdGestion).toPromise();
    } catch (e) {
      console.log(e);
    }
    return response;
  }

  // Añadir Cuadricula
  async addCuadricula(cuadricula: CuadriculaModel): Promise<CuadriculaModel> {
    let response = null;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    try {
      response = await this.http.post(this.ssoService.getTicket().UrlApi + '/api/cuadricula', JSON.stringify(cuadricula), httpOptions).toPromise();
    } catch (e) {
      console.log(e);
    }
    if (cuadricula.id && response) {
      this.auditoria.AccionId = Accion.EDITAR_CUADRICULA;
    } else if (response && !cuadricula.id) {
      this.auditoria.AccionId = Accion.CREAR_CUADRICULA;
    }
    this.msgChangeResponse(response);
    this.auditoriaService.addAuditoria(this.auditoria);
    return response;
  }

  // Borrar Cuadricula
  async delCuadricula(id: number) {
    let result = null;
    try {
      result = await this.http.delete(this.ssoService.getTicket().UrlApi + '/api/cuadricula?id=' + id + '&enterprise=' + this.ssoService.getTicket().Empresa.IdGestion).toPromise();

      this.auditoria.AccionId = Accion.ELIMINAR_CUADRICULA;
      this.auditoria.Info = 'ID cuadricula: ' + id;
      this.auditoriaService.addAuditoria(this.auditoria);
    } catch (e) {
      console.log(e);
    }
    return result;
  }

  msgChangeResponse(response: any): string {
    return this.auditoria.Info = 'ID: ' + response.id + ', ' + MainComponent.getInstance().translate('Nombre') + ': ' + response.nombre;
  }
}
