<jqxWindow
  #windowState
  [position]="getFormPos(windowState)"
  [theme]="environment.tema"
  [width]="212"
  [height]="240"
  [zIndex]="90"
  [autoOpen]="true"
  [showCloseButton]="true"
  [showCollapseButton]="true"
  [resizable]="false"
  (onClose)="onClose()"
>
  <div class="formHeader"></div>
  <div class="form">
    <p class="text-name">{{ translate("Estado") }}</p>
    <jqxDropDownList
      #listEstados
      [theme]="environment.tema"
      [width]="200"
      [height]="25"
      [selectedIndex]="0"
      [displayMember]="'Nombre'"
      [valueMember]="'Id'"
      [autoDropDownHeight]="true"
      [disabled]="isDisable"
      [source]="adapterEstados"
      (onChange)="onChangeList($event)"
      [selectedIndex]="0"
    >
    </jqxDropDownList>
    <div style="float: left; margin-top: 5px">
      <p class="text-name">{{ translate("Observaciones") }}</p>
      <jqxTextArea
        #textArea
        [width]="200"
        [height]="100"
        [minLength]="1"
        [disabled]="isDisable"
        (onChange)="onChange($event)"
      >
      </jqxTextArea>
    </div>
    <div class="row-buttons">
      <jqxButton
        style="float: left; margin-top: 10px"
        [width]="100"
        [height]="26"
        [textImageRelation]="'imageBeforeText'"
        [textPosition]="'left'"
        [imgSrc]="'/assets/images/save.png'"
        [value]="translate('Guardar')"
        (onClick)="onClickGuardar()"
        [disabled]="isDisable"
      >
      </jqxButton>
      <jqxButton
        style="float: left; margin-left: 10px"
        [width]="70"
        [height]="26"
        [textImageRelation]="'imageBeforeText'"
        [textPosition]="'center'"
        (onClick)="closeWindow()"
        [value]="translate('Salir')"
      >
      </jqxButton>
    </div>
  </div>
</jqxWindow>
