import {Component, OnInit, ViewChild} from '@angular/core';
import {AppComponent} from 'src/app/app.component';
import {environment} from 'src/environments/environment';
import {CustomForms} from '../../forms/custom-forms';
import {jqxGridComponent} from 'jqwidgets-ng/jqxgrid';
import {jqxWindowComponent} from 'jqwidgets-ng/jqxwindow';
import {JqWidgets} from 'src/app/utils/jqWidgets';
import {NumberUtils} from 'src/app/utils/number-utils';
import {DateUtils} from 'src/app/utils/date-utils';
import {HistoricoEnviosIdentificadorService} from 'src/app/services/historicoEnviosIdentificador/historico-envios-identificador.service';
import {HistoricoEnvioIdentiiicadorModel} from 'src/app/services/historicoEnviosIdentificador/models/historicoEnviosIdentificador.model';
import {DateIdentificacionModel} from 'src/app/services/cerraduras/models/dateIdentificacion';
import {MainComponent} from '../../main/main.component';
import {ElementoModel} from 'src/app/services/elements/models/elem.model';
import {MapBounds, MapComponent, MapLatLng, MapMarker, MapPolyline} from 'movisat-maps';
import {jqxCheckBoxComponent} from 'jqwidgets-ng/jqxcheckbox';
import {CerraduraModel} from 'src/app/services/cerraduras/models/cerradura.model';
import {CerraduraService} from 'src/app/services/cerraduras/cerradura.service';
import {GeoUtils} from 'src/app/utils/geo-utils';
import {jqxTabsComponent} from 'jqwidgets-ng/jqxtabs';
import {LangService} from 'src/app/services/lang/lang.service';
import * as xlsx from 'xlsx';
import {Utils} from 'src/app/utils/utils';
import {HeaderComponent} from '../../header/header.component';
import {MarcoGeograficoModel} from '../../../services/geographics/marco-geografico.model';
import {AmbitoActividadModel} from '../../../services/geographics/ambito-actividad.model';

@Component({
  selector: 'app-historico-envios-disp-identificador',
  templateUrl: './historico-envios-disp-identificador.component.html',
  styleUrls: ['./historico-envios-disp-identificador.component.css']
})
export class HistoricoEnviosDispIdentificadorComponent extends CustomForms implements OnInit {
  @ViewChild('header') header: HeaderComponent;
  @ViewChild('form') form: jqxWindowComponent;
  @ViewChild('myGrid') myGrid: jqxGridComponent;
  @ViewChild('checkTodas') checkTodas: jqxCheckBoxComponent;
  @ViewChild('myTabs') myTabs: jqxTabsComponent;

  private componentRef = null;
  public theme = environment.tema;
  // map
  public mapProvider = 'Google';
  public cartoType = 'raster';
  public lang = 'es-ES'; // Idioma por defecto
  public searchCountry = 'ES'; // Pais por defecto (para las búsquedas en el mapa)
  public searchBounds = ''; // Marco por defecto (para las búsquedas en el mapa)
  public environment = environment;
  public zoom = 6;
  public center = { // Cieza
    lat: 38.2378331,
    lng: -1.42102,
    weight: 1
  };
  public marcoGeografico: MarcoGeograficoModel;
  public ambitoActividad: AmbitoActividadModel;
  // grid
  dataAdapter: any;
  source: any = null;
  public langGrid = JqWidgets.getLocalization('es');
  editrow: number = -1;
  // variables
  gridIdentificaciones: jqxGridComponent;
  historicosEnviosIdentificador: HistoricoEnvioIdentiiicadorModel[] = [];
  envioIdentificador: HistoricoEnvioIdentiiicadorModel = new HistoricoEnvioIdentiiicadorModel();
  dateIdentificacion: DateIdentificacionModel = new DateIdentificacionModel();
  elemento: ElementoModel = new ElementoModel();
  elementos: ElementoModel[] = [];
  marker: MapMarker;
  markerIdentificador: MapMarker;
  markerElemento: MapMarker;
  markers: MapMarker[] = [];
  private map: MapComponent;
  cambiarNumberValue: boolean = false;
  cerraduras: CerraduraModel[] = [];
  private polyline: MapPolyline = null;
  private polylines: MapPolyline[] = [];
  distanciaTotal: number = 0;
  mapWidth: number;
  mapHeight: number;
  rowVisible: number = -1;
  public static _this: any;
  showRowDetails: any[] = [];
  showLoader: boolean = true;
  public ocultar: boolean = true;
  boundChange: boolean = true;

  cellClass = (row: number, columnfield: any, value: any): string => {
    if (value) {
      return 'cellTooltip';
    }
    return '';
  };

  columns: any = [
    {text: 'Selec', columntype: 'textbox', filtertype: 'textbox', datafield: 'selec', hidden: true},
    {text: 'Id', columntype: 'textbox', filtertype: 'textbox', datafield: 'id', hidden: true},
    {
      text: this.translate('Fecha_envio'),
      columntype: 'datetimeinput',
      filtertype: 'date',
      width: 150,
      datafield: 'fechaEnvio',
      cellsrenderer: this.renderRow,
      aggregates: [{
        'Total': function(aggregatedValue, currentValue: number) {
          return aggregatedValue + 1;
        }
      }],
      aggregatesrenderer: function(aggregates) {
        let renderstring = '';
        if (aggregates['Total'] !== undefined) {
          renderstring = '<div style="text-align: left; margin-left: 4px;">' + AppComponent.translate('Total') + ': ' +
            NumberUtils.format(aggregates['Total'], 0) + '</div>';
        }
        return renderstring;
      }
    },
    {
      text: this.translate('Ns_movisat'),
      width: 160,
      columntype: 'textbox',
      filtertype: 'textbox',
      datafield: 'nsMovisat',
      cellclassname: this.cellClass
    },
    {
      text: this.translate('Num_identificaciones'),
      columntype: 'textbox',
      filtertype: 'textbox',
      datafield: 'cantidad',
      width: 100,
      cellsrenderer: this.numberrenderer,
      cellclassname: this.cellClass
    },
    {
      text: this.translate('Bateria'),
      columntype: 'textbox',
      filtertype: 'textbox',
      width: 60,
      datafield: 'bateriaPorcentaje',
      cellsrenderer: this.numberrenderer,
      cellclassname: this.cellClass
    },
    {
      text: this.translate('Temperatura'),
      columntype: 'textbox',
      filtertype: 'textbox',
      datafield: 'temperatura',
      width: 80,
      cellsrenderer: this.numberrenderer,
      cellclassname: this.cellClass
    },
    {
      text: this.translate('Direccion'),
      columntype: 'textbox',
      filtertype: 'textbox',
      datafield: 'calle',
      width: 100,
      cellclassname: this.cellClass
    },
    {
      text: this.translate('Municipio'),
      columntype: 'textbox',
      filtertype: 'textbox',
      datafield: 'municipio',
      width: 70,
      cellclassname: this.cellClass
    },
    {
      text: this.translate('Poblacion'),
      columntype: 'textbox',
      filtertype: 'textbox',
      datafield: 'poblacion',
      width: 70,
      cellclassname: this.cellClass
    },
    {
      text: this.translate('Provincia'),
      columntype: 'textbox',
      filtertype: 'textbox',
      datafield: 'provincia',
      width: 70,
      cellclassname: this.cellClass
    },
    {
      text: this.translate('Num_calle'),
      columntype: 'textbox',
      filtertype: 'textbox',
      datafield: 'numero',
      cellalign: 'right',
      width: 60,
      cellclassname: this.cellClass,
    },
    {
      text: this.translate('Metros'),
      columntype: 'textbox',
      filtertype: 'textbox',
      datafield: 'metros',
      width: 60,
      cellsrenderer: this.numberrendererDecimales,
      cellclassname: this.cellClass
    },
    {
      text: this.translate('Nombre_elemento'),
      columntype: 'textbox',
      filtertype: 'textbox',
      datafield: 'nombreElemento',
      width: 110,
      cellclassname: this.cellClass
    },
    {
      text: this.translate('Marca'),
      columntype: 'textbox',
      filtertype: 'checkedlist',
      datafield: 'marcaElemento',
      width: 100,
      cellclassname: this.cellClass
    },
    {
      text: this.translate('Modelo'),
      columntype: 'textbox',
      filtertype: 'checkedlist',
      datafield: 'modeloElemento',
      width: 100,
      cellclassname: this.cellClass
    },
    {
      text: this.translate('Matricula'),
      columntype: 'textbox',
      filtertype: 'textbox',
      datafield: 'matriculaElemento',
      width: 75,
      cellclassname: this.cellClass
    },
    {
      text: this.translate('Direccion'),
      columntype: 'textbox',
      filtertype: 'textbox',
      datafield: 'direccionElemento',
      width: 100,
      cellclassname: this.cellClass
    },
    {
      text: this.translate('Municipio'),
      columntype: 'textbox',
      filtertype: 'textbox',
      datafield: 'municipioElemento',
      width: 70,
      cellclassname: this.cellClass
    },
    {
      text: this.translate('Poblacion'),
      columntype: 'textbox',
      filtertype: 'textbox',
      datafield: 'poblacionElemento',
      width: 70,
      cellclassname: this.cellClass
    },
    {
      text: this.translate('Observaciones'),
      columntype: 'textbox',
      filtertype: 'textbox',
      datafield: 'observacionesElemento',
      menu: false,
      sortable: false,
      width: 100,
      cellclassname: this.cellClass
    },
  ];

  constructor(
    private historicEnviosIdentificadorService: HistoricoEnviosIdentificadorService,
    private cerraduraService: CerraduraService,
    public langService: LangService
  ) {
    super();
    HistoricoEnviosDispIdentificadorComponent._this = this;
  }

  ngOnInit() {
    this.mapHeight = document.getElementById('map-container').offsetHeight - 24;
    this.mapWidth = document.getElementById('map-container').offsetWidth;
    this.map = MainComponent.getInstance().getMap();
    this.ocultar = false;
  }

  /**Inicializa el componente
   */
  init(componentRef: any) {
    this.componentRef = componentRef;
  }

  async ngAfterViewInit(): Promise<void> {
    this.addCustomForm(this.form, false);
    this.cerraduras = await this.cerraduraService.getCerraduras();
    this.valuesByDefault();
    this.gethistoricoEnviosIdentificador();
    this.myTabs.setTitleAt(0, this.translate('Lecturas'));
    this.myTabs.setTitleAt(1, this.translate('Cartografia'));
    Utils.renderSizeGrid(this.myGrid);
  }

  onRowclick(event: any): void {
    if (event.target.id !== 'nestedGrid1') {
      this.envioIdentificador = this.historicosEnviosIdentificador.find(x => x.id == event.args.row.bounddata.id);
    } else {
      return;
    }
  }

  valuesByDefault() {
    this.dateIdentificacion.Desde = this.header.periodoSelect.desde;
    this.dateIdentificacion.Hasta = this.header.periodoSelect.hasta;
    this.header.currencyInput.value(50);
    this.header.currencyInput.disabled(true);
  }

  onSort(event: any) {
    this.showRowDetails.forEach(row => {
      this.myGrid.getrows().forEach(elem => {
        if (row === elem.id) {
          this.myGrid.showrowdetails(elem.boundindex);
        }
      });
    });
  }

  onRowClick(event: any) {
    this.envioIdentificador = this.historicosEnviosIdentificador.find(x => x.id == event.args.row.bounddata.id);
    if (this.envioIdentificador.cantidad !== 0) {
      this.myGrid.unselectrow(event.args.rowindex);
    } else {
      this.myGrid.selectrow(event.args.rowindex);
    }
  }

  async gethistoricoEnviosIdentificador() {
    this.columns.forEach(column => {
      column.rendered = (element) => {
        Utils.tooltiprenderer(element);
      };
    });

    this.elementos = MainComponent.getInstance().elementsList;

    this.source = {
      datatype: 'json',
      sort: this.customsortfunc,
      datafields: [
        {name: 'id', type: 'number', map: 'id'},
        {name: 'fechaEnvio', type: 'date', map: 'fechaEnvio'},
        {name: 'nsMovisat', type: 'string', map: 'nsMovisat'},
        {name: 'cantidad', type: 'number', map: 'cantidad'},
        {name: 'bateriaPorcentaje', type: 'number', map: 'bateriaPorcentaje'},
        {name: 'temperatura', type: 'number', map: 'temperatura'},
        {name: 'latElemento', type: 'number', map: 'latElemento'},
        {name: 'lngElemento', type: 'number', map: 'lngElemento'},
        {name: 'idElemento', type: 'number', map: 'idElemento'},
        {name: 'metros', type: 'number', map: 'metros'},
        {name: 'provincia', type: 'string', map: 'provincia'},
        {name: 'municipio', type: 'string', map: 'municipio'},
        {name: 'poblacion', type: 'string', map: 'poblacion'},
        {name: 'calle', type: 'string', map: 'calle'},
        {name: 'numero', type: 'string', map: 'numero'},
        {name: 'codigoPostal', type: 'string', map: 'codigoPostal'},
        {name: 'nombreElemento', type: 'string', map: 'nombreElemento'},
        {name: 'marcaElemento', type: 'string', map: 'marcaElemento'},
        {name: 'modeloElemento', type: 'string', map: 'modeloElemento'},
        {name: 'matriculaElemento', type: 'string', map: 'matriculaElemento'},
        {name: 'direccionElemento', type: 'string', map: 'direccionElemento'},
        {name: 'municipioElemento', type: 'string', map: 'municipioElemento'},
        {name: 'poblacionElemento', type: 'string', map: 'poblacionElemento'},
        {name: 'observacionesElemento', type: 'string', map: 'observacionesElemento'},
        {name: 'selec', map: 'selec'}
      ],
      localdata: this.historicosEnviosIdentificador,
    };
    this.dataAdapter = new jqx.dataAdapter(this.source);

    this.onAceptar();
  }

  updatefilterconditions = (type: string, defaultconditions: any): string[] => {
    return Utils.updatefilterconditions(type, defaultconditions);
  };

  public filter(cellValue?: any, rowData?: any, dataField?: string, filterGroup?: any, defaultFilterResult?: boolean): any {
    let filterColumns = [
      'nombreElemento',
      'marcaElemento',
      'modeloElemento',
      'matriculaElemento',
      'direccionElemento',
      'municipioElemento',
      'poblacionElemento',
      'observacionesElemento'
    ];

    return Utils.filterRow(cellValue, dataField, filterGroup, defaultFilterResult, filterColumns);
  }

  onMapReady(map: MapComponent) {
    if (this.map) {
      this.map = null;
    }

    this.map = map;
    this.marcoGeografico = MainComponent.getInstance().marcoGeografico;
    this.ambitoActividad = MainComponent.getInstance().ambitoActividad;
    if (!this.marcoGeografico.marco.contains(this.map.center)) {
      this.map.onBoundsChange(this.map.getBounds());
    } else {
      this.minZoom = this.map.zoom;
      try {
        setTimeout(() => {
          this.lastBounds = this.map.getBounds();
        }, 500);
      } catch (error) {
      }
    }
  }

  private lastZoom;
  private lastBounds: MapBounds;
  private minZoom;

  onBoundsChange(bounds: MapBounds) {
    if(this.boundChange){
      this.marcoGeografico = MainComponent.getInstance().marcoGeografico;
      this.ambitoActividad = MainComponent.getInstance().ambitoActividad;
      if (this.marcoGeografico) {
        if (!this.lastBounds) {
          this.map.fitTo(this.marcoGeografico.marco);
          this.lastZoom = this.map.zoom;
          this.minZoom = this.map.zoom;
        } else {
          if (!this.lastBounds && (bounds.contains(this.marcoGeografico.marco.swCorner) ||
            bounds.contains(this.marcoGeografico.marco.neCorner))) {
            this.map.fitTo(this.marcoGeografico.marco);
            this.minZoom = this.map.zoom;
          } else {
            if (!this.marcoGeografico.marco.contains(this.map.center)) {
              if (this.lastBounds.contains(this.marcoGeografico.marco.swCorner) &&
                this.lastBounds.contains(this.marcoGeografico.marco.neCorner)) {
                this.map.setZoom(this.lastZoom + 1);
                this.map.fitTo(this.marcoGeografico.marco);
              } else {
                this.map.fitTo(this.lastBounds);
              }
              return;
            } else {
              if (bounds.contains(this.marcoGeografico.marco.swCorner) &&
                bounds.contains(this.marcoGeografico.marco.neCorner) && this.map.zoom < this.lastZoom) {
                this.map.setZoom(Math.max(this.lastZoom, this.minZoom));
                this.map.fitTo(this.marcoGeografico.marco);
                const center = {
                  lat: this.marcoGeografico.marco.swCorner.lat +
                    (this.marcoGeografico.marco.neCorner.lat - this.marcoGeografico.marco.swCorner.lat) / 2,
                  lng: this.marcoGeografico.marco.swCorner.lng +
                    (this.marcoGeografico.marco.neCorner.lng - this.marcoGeografico.marco.swCorner.lng) / 2
                };
                return;
              }
            }
          }
        }
      }
    }
  }

  // asigno las fechas del periodo seleccionado
  async onAceptar() {
    this.showLoader = true;
    this.header.searchInput['nativeElement'].value = '';

    this.dateIdentificacion.Desde = this.header.periodoSelect.getFechaIni();
    this.dateIdentificacion.Hasta = this.header.periodoSelect.getFechaFin();
    this.historicosEnviosIdentificador = await this.historicEnviosIdentificadorService.getHistoricosEnviosIdentificador(this.dateIdentificacion);

    if (this.header.checkMasXmetros.checked() && this.cambiarNumberValue) {

      let valor: number = this.header.currencyInput.val();
      this.source.localdata = this.historicosEnviosIdentificador.filter(x => x.metros >= valor);
      this.myGrid.updatebounddata('data');

    } else {
      if (this.source) {
        this.source.localdata = this.historicosEnviosIdentificador;
        this.myGrid.updatebounddata('data');
      }
    }

    this.myGrid.sortby('fechaEnvio', 'descending');
    this.showLoader = false;
  }

  onClose() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
    // this.deleteMapElements();
    this.distanciaTotal = 0;
    HistoricoEnviosDispIdentificadorComponent._this = null;
  }

  // Para traducir los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  renderRow(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (columnfield == 'fechaEnvio' || columnfield == 'fecha') {
      let date = new Date(value);
      return '<div style="margin-left: 4px; margin-top: 4px">' + DateUtils.formatDateTimeShort(date, true) + '</div>';
    } else if (columnfield == 'tipoPermiso') {

      if (value == 'BLANCA') {
        return '<div style="margin-left: 4px; margin-top: 4px">' + AppComponent.translate('Blanca') + '</div>';
      } else if (value == 'MASTER') {
        return '<div style="margin-left: 4px; margin-top: 4px">' + AppComponent.translate('Master') + '</div>';
      }
    } else {
      return '<div style="margin-left: 4px; margin-top: 4px">' + value + '</div>';
    }
  }

  numberrenderer(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (columnfield == 'bateriaPorcentaje') {
      return '<div style="margin-right: 4px; margin-top: 5px; text-align: right">' +
        NumberUtils.format(value, 0) +
        '%</div>';
    } else if (columnfield === 'temperatura') {
      return (
        '<div style="margin-right: 4px; margin-top: 5px; text-align: right">' +
        NumberUtils.format(value, 0) +
        'º</div>'
      );
    } else {
      return '<div style="margin-right: 4px; margin-top: 5px; text-align: right">' + NumberUtils.format(value, 0) + '</div>';
    }
  }

  numberrendererDecimales(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    return (
      '<div style="margin-right: 4px; margin-top: 5px; text-align: right;">' +
      NumberUtils.format(value, 2) +
      '</div>'
    );
  }

  renderText(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (rowdata.idElemento == 0 || rowdata.idElemento == null) {
      return '';
    } else if (rowdata.nombreElemento == null || rowdata.nsCerradura == null) {
      return '';
    } else {
      return '<div style="margin-right: 4px; margin-top: 5px;">' + rowdata.nombreElemento + '</div>';
    }
  }

  renderIdentificacion(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (value == 0) {
      return '<div  style="margin-left: 4px; margin-top: 4px">NFC</div>';
    } else if (value == 1) {
      return '<div  style="margin-left: 4px; margin-top: 4px">Bluetooth</div>';
    }
  }

  renderCiudadano(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (rowdata.nombreCiudadano == null) {
      return '';
    } else {
      return '<div style="margin-left: 4px; margin-top: 4px;">' + rowdata.nombreCiudadano + '</div>';
    }
  }

  renderNsTarjeta(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (value) {
      return `
        <div style="margin-right: 4px; margin-top: 4px; text-align: right;">
          <span onmouseover="this.style.backgroundColor='gray'; this.style.color='white';" onmouseout="this.style.backgroundColor=''; this.style.color='';">
            ${NumberUtils.format(value, 0)}
          </span>
        </div>
      `;
    }
  }

  // identificaciones grid
  dataAdapterIdentificaciones: any;
  sourceIdentificaciones: any;

  public columnsIdentificacines = [
    {text: 'Id', columntype: 'textbox', filtertype: 'textbox', datafield: 'idCerradura', hidden: true},
    {
      text: this.translate('Fecha'),
      columntype: 'datetimeinput',
      filtertype: 'textbox',
      datafield: 'fecha',
      width: 150,
      cellsrenderer: this.renderRow,
      aggregates: [{
        'Total': function(aggregatedValue, currentValue: number) {
          return aggregatedValue + 1;
        }
      }],
      aggregatesrenderer: function(aggregates) {
        let renderstring = '';
        if (aggregates['Total'] !== undefined) {
          renderstring = '<div style="text-align: left; margin-left: 4px;">' + AppComponent.translate('Total') + ': ' +
            NumberUtils.format(aggregates['Total'], 0) + '</div>';
        }
        return renderstring;
      }
    },
    {
      text: this.translate('Ns_tarjeta'),
      columntype: 'textbox',
      filtertype: 'textbox',
      datafield: 'nsTarjeta',
      width: 100,
      cellsrenderer: this.renderNsTarjeta,
    },
    // { text: this.translate('Ns_movisat'), columntype: 'textbox', filtertype: 'textbox', width: 150, datafield: 'nsMovisat', cellsrenderer: this.renderRow, },
    {
      text: this.translate('Ciudadano'),
      columntype: 'textbox',
      filtertype: 'textbox',
      width: 100,
      datafield: 'nombreCiudadano',
      cellsrenderer: this.renderCiudadano
    },
    {text: this.translate('Aporte_residuo'), columntype: 'checkbox', filtertype: 'bool', width: 110, datafield: 'aporteResiduo'},
    {text: this.translate('Apertura_tapa'), columntype: 'checkbox', filtertype: 'bool', width: 100, datafield: 'aperturaTapa'},
    {text: this.translate('No_cerro_tapa'), columntype: 'checkbox', filtertype: 'bool', width: 90, datafield: 'noCerroTapa'},
    {
      text: this.translate('Tipo_identificacion'),
      columntype: 'textbox',
      width: 130,
      filtertype: 'textbox',
      datafield: 'medio',
      cellsrenderer: this.renderIdentificacion
    },
    {
      text: this.translate('Tipo_permiso'),
      columntype: 'textbox',
      width: 100,
      filtertype: 'textbox',
      datafield: 'tipoPermiso',
      cellsrenderer: this.renderRow,
    },
  ];

  onRowdoubleclick(event: any): void {
    if (event.target.id !== 'nestedGrid1') {
      this.elementos = MainComponent.getInstance().elementsList;
      // Elimino los marcadores del mapa
      //this.deleteMapElements();
      this.distanciaTotal = 0;

      this.myTabs.select(1);
      this.boundChange = false;
      // Encuentro el identificador del envío
      if(!event.args.row.bounddata.idCerradura){
        this.envioIdentificador = this.historicosEnviosIdentificador.find(x => x.id == event.args.row.bounddata.id);
        if (!this.envioIdentificador) {
          return MainComponent.getInstance().showError('ATENCION', 'No_existen_coordenadas', 2000);
        }

        // Encuentro el elemento asociado al identificador del envío
        this.elemento = this.elementos.find(x => x.Id == this.envioIdentificador.idElemento);

        // Llamo a showEnviosIdentificacionesMap con la cerradura correspondiente
        this.showEnviosIdentificacionesMap();
      }else{
        let cerradura: CerraduraModel = this.cerraduras.find(x => x.id == event.args.row.bounddata.idCerradura);

        let iconoIdentificador = 'assets/images/cerradura-gris.png';
        this.markerIdentificador = this.createMarkers(new MapLatLng(cerradura.lat, cerradura.lng), iconoIdentificador);
        this.markerIdentificador.content = '<div style="margin: 10px;">' + this.translate('Cerradura') + '<br>' + cerradura.nsMovisat + '</div>';

        this.encuadrarCerradurasSelec([this.markerIdentificador]);

        if(this.markerIdentificador) {
          this.markerIdentificador.animate(2800)
        }

        setTimeout(() => {
          this.boundChange = true;
        }, 100)
      }
    } else {
      return;
    }
  }

  showEnviosIdentificacionesMap(): void {
    let positionIdentificador = null;
    let positionElemento = null;

    // Intentar ubicar usando la información del envío (Identificador)
    if (this.envioIdentificador.lat != 0 && this.envioIdentificador.lng != 0) {
      positionIdentificador = new MapLatLng(this.envioIdentificador.lat, this.envioIdentificador.lng);
    }

    // Intentar ubicar usando la ubicación del elemento
    if (this.envioIdentificador.latElemento && this.envioIdentificador.lngElemento != 0) {
      positionElemento = new MapLatLng(this.envioIdentificador.latElemento, this.envioIdentificador.lngElemento);
    }

    // Si no se encuentra ninguna posición, mostrar error
    if (!positionIdentificador && !positionElemento) {
      return MainComponent.getInstance().showError('ATENCION', 'No_existen_coordenadas', 2000);
    }

    // Crear marcadores para ambas posiciones, si existen
    if (positionIdentificador) {
      let iconoIdentificador = 'assets/images/cerradura-gris.png';
      this.markerIdentificador = this.createMarkers(positionIdentificador, iconoIdentificador);
      this.markerIdentificador.content = '<div style="margin: 10px;">' + this.translate('Ubicacion_por_envio') + '<br>' + this.envioIdentificador.nsMovisat + '</div>';
    }

    if (positionElemento) {
      let iconoElemento = 'data:image/png;base64,' + this.elemento.Equipamiento.Icono;
      this.markerElemento = this.createMarkers(positionElemento, iconoElemento);
      this.markerElemento.content = '<div style="margin: 10px;">' + this.translate('Ubicacion_por_elemento') + '</div>';
    }

    // Lógica para manejar los marcadores
    if (this.markerIdentificador || this.markerElemento) {
      if (this.markerIdentificador && this.markerElemento) {
        this.showDistancePolyline(positionIdentificador, positionElemento);
        this.encuadrarCerradurasSelec([this.markerIdentificador, this.markerElemento]);

        setTimeout(() => {
          this.boundChange = true;
        }, 100)
      } else if (this.markerIdentificador) {
        this.encuadrarCerradurasSelec([this.markerIdentificador]);

        setTimeout(() => {
          this.boundChange = true;
        }, 100)
      } else if (this.markerElemento) {
        this.encuadrarCerradurasSelec([this.markerElemento]);

        setTimeout(() => {
          this.boundChange = true;
        }, 100)
      }

      if (this.markerIdentificador) {
        this.markerIdentificador.animate(3000);
      }

      if (this.markerElemento) {
        this.markerElemento.animate(3000);
      }
    } else {
      return MainComponent.getInstance().showError('ATENCION', 'Error', 2000);
    }
  }

  encuadrarCerradurasSelec(values: MapMarker[]): void {
    let globalSWPoint = new MapLatLng(180, 90);
    let globalNEPoint = new MapLatLng(-180, -90);

    values.forEach(value => {
      let swPoint = new MapLatLng(value.position.lat, value.position.lng);
      let nePoint = new MapLatLng(value.position.lat, value.position.lng);

      globalSWPoint.lat = Math.min(globalSWPoint.lat, swPoint.lat);
      globalSWPoint.lng = Math.min(globalSWPoint.lng, swPoint.lng);
      globalNEPoint.lat = Math.max(globalNEPoint.lat, nePoint.lat);
      globalNEPoint.lng = Math.max(globalNEPoint.lng, nePoint.lng);
    });

    this.map.fitTo(new MapBounds(globalSWPoint, globalNEPoint));
  }

  showDistancePolyline(position1: MapLatLng, position2: MapLatLng): void {
    let distancia = GeoUtils.getDistance(position1.lat, position1.lng, position2.lat, position2.lng);
    distancia = Number.parseFloat(distancia.toFixed(2));
    this.distanciaTotal = distancia;

    if (!this.polyline) {
      this.polyline = this.addPolyline();
    }

    this.map.addPolylinePoint(this.polyline, {
      dataModel: this.envioIdentificador,
      content: this.distanciaTotal + ' m',
      position: position1
    });

    this.map.addPolylinePoint(this.polyline, {
      dataModel: this.envioIdentificador,
      position: position2,
      content: this.distanciaTotal + ' m',
    });
  }

  addPolylinePoint(): void {
    if (!this.polyline) {
      this.polyline = this.addPolyline();
    }
    // Añadir un punto a la polilínea
    this.map.addPolylinePoint(this.polyline, {
      dataModel: this.envioIdentificador,
      content: this.distanciaTotal + ' m',
      position: new MapLatLng(this.envioIdentificador.latElemento, this.envioIdentificador.lngElemento)
    });

    this.map.addPolylinePoint(this.polyline, {
      dataModel: this.envioIdentificador,
      position: new MapLatLng(this.envioIdentificador.lat, this.envioIdentificador.lng),
      content: this.distanciaTotal + ' m',
    });
  }

  createMarkers(position: MapLatLng, icono: string): MapMarker {
    //this.deleteMapElements();
    let marker = this.map.addMarker({
      dataModel: '',
      title: '',
      content: '',
      position: position,
      icon: icono,
      zIndex: 999,
      drag: false,
      visible: true
    });
    return marker;
  }

  // añado una polylinea al mapa entre dos puntos (inicio y fin)
  addPolyline(): MapPolyline {
    const polyline = this.map.addPolyline({
      color: 'red',
      weight: 7,
      opacity: 0.5,
      clickable: true,
    });
    this.polylines.push(polyline);
    return polyline;
  }

  onTabClick(event: any): void {
    this.deleteMapElements();
  }

  deleteMapElements(): void {
    // Elimino los marcadores del mapa
    if (this.markerElemento) {
      this.map.removeMarker(this.markerElemento);
      this.markerElemento = null;
    }
    if (this.markerIdentificador) {
      this.map.removeMarker(this.markerIdentificador);
      this.markerIdentificador = null;
    }

    // Elimino las polilineas del mapa
    if (this.polylines.length > 0) {
      this.polylines.forEach(element => {
        this.map.removePolyline(element);
      });
      this.polylines = [];
      this.polyline = null;
    }
  }

  async onResetFilter() {
    this.showLoader = true;
    this.header.searchInput['nativeElement'].value = '';
    this.header.periodoSelect.setPeriodo(0);

    this.header.periodoSelect.dateForm.get('desde').setValue(new Date());
    this.header.periodoSelect.dateForm.get('hasta').setValue(new Date());
    this.header.periodoSelect.resetForm();

    this.dateIdentificacion.Desde = new Date();
    this.dateIdentificacion.Desde.setHours(0, 0, 0);
    this.dateIdentificacion.Hasta = new Date();
    this.dateIdentificacion.Hasta.setHours(23, 59, 0, 0);

    this.header.checkMasXmetros.checked(false);
    this.header.currencyInput.disabled(true);
    this.header.currencyInput.val(50);
    this.cambiarNumberValue = false;
    //this.deleteMapElements();
    this.historicosEnviosIdentificador = await this.historicEnviosIdentificadorService.getHistoricosEnviosIdentificador(this.dateIdentificacion);
    this.source.localdata = this.historicosEnviosIdentificador;
    this.myGrid.clearfilters();
    this.myGrid.updatebounddata();
    this.myGrid.sortby('fechaEnvio', 'asc');
    this.showLoader = false;
  }

  changeMasXmetros() {
    if (this.header.checkMasXmetros.checked) {
      this.header.currencyInput.disabled(true);
      this.header.currencyInput.val(50);
      this.header.currencyInput.disabled(false);
      this.cambiarNumberValue = true;
    } else {
      this.header.currencyInput.disabled(true);
      this.header.currencyInput.val(50);
      this.cambiarNumberValue = false;
    }
  }

  valueChangedNumber(event: any) {
    this.cambiarNumberValue = true;
  }

  async onBuscar() {
    const filtro = this.obtenerValorFiltro();
    if (filtro) {
      this.aplicarFiltro(filtro);
    } else {
      this.myGrid.clearfilters();
      return;
    }
    this.actualizarGrid();
  }

  obtenerValorFiltro() {
    let filtervalue = '';
    if (this.header.searchInput['nativeElement'].value.length >= 3) {
      return filtervalue = this.header.searchInput['nativeElement'].value.normaliceAccents().toUpperCase();
    } else {
      return;
    }
  }

  aplicarFiltro(filtro) {
    this.historicosEnviosIdentificador.forEach(historico => {
      const coincide = this.verificarCoincidencia(historico, filtro);
      historico['selec'] = coincide ? 'selec' : '';
    });
  }

  verificarCoincidencia(historico, filtro) {
    const camposParaComparar = [
      'nsMovisat',
      'cantidad',
      'bateriaPorcentaje',
      'temperatura',
      'lat',
      'lng',
      'metros',
      'nombreElemento',
      'marcaElemento',
      'modeloElemento',
      'matriculaElemento',
      'direccionElemento',
      'municipioElemento',
      'poblacionElemento',
      'observacionesElemento'
    ];

    for (const campo of camposParaComparar) {
      if (typeof historico[campo] === 'string' && historico[campo].normaliceAccents().toUpperCase().includes(filtro)) {
        return true;
      }
    }
    return false;
  }

  actualizarGrid() {
    const filters = this.myGrid.getfilterinformation();
    if (filters.find(s => s.datafield === 'selec') === undefined) {
      const filtergroup = new jqx.filter();
      filtergroup.operator = 'and';
      filtergroup.addfilter(0, filtergroup.createfilter('stringfilter', 'selec', 'contains'));
      this.myGrid.addfilter('selec', filtergroup);
    }

    setTimeout(() => {
      try {
        this.myGrid.applyfilters();
      } catch (error) {

      }
      try {
        this.myGrid.updatebounddata('data');
      } catch (error) {

      }
    }, 0);
  }

  onExportar() {
    if (this.myGrid.getrows().length === 0) {
      return MainComponent.getInstance().showWarning('ATENCION', this.translate('No_existen_datos'), 2000);
    } else {
      const json = this.myGrid.exportdata('json');
      let datos = JSON.parse(json);
      datos.forEach((element, index) => {
        element[this.translate('Bateria')] = isNaN(parseFloat(element[this.translate('Bateria')])) ? '' : parseFloat(element[this.translate('Bateria')]).toFixed(0);
        element[this.translate('Temperatura')] = isNaN(parseFloat(element[this.translate('Temperatura')])) ? '' : parseFloat(element[this.translate('Temperatura')]).toFixed(0);
        element[this.translate('Num_identificaciones')] = isNaN(parseFloat(element[this.translate('Num_identificaciones')])) ? '' : parseFloat(element[this.translate('Num_identificaciones')]).toFixed(0);
        element[this.translate('Fecha_envio')] = isNaN(parseFloat(element[this.translate('Fecha_envio')])) ? '' : element[this.translate('Fecha_envio')] = DateUtils.formatDateTime(this.myGrid.getrows()[index].fechaEnvio, false).replace('T', ' ');
      });
      // Utiliza el array modificado en lugar del JSON original
      const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(datos);
      this.generateAutofilterHeader(ws);

      const wb: xlsx.WorkBook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(wb, ws, 'Hoja1');
      xlsx.writeFile(wb, DateUtils.formatDateAMDhms(new Date()) + '_' + this.translate('Historico_envios_dispositivo_identificador') + '.xlsx');
    }
  }

  generateAutofilterHeader(sheet) {
    // Añade filtro a todas las casillas.
    sheet['!autofilter'] = {ref: sheet['!ref']};
  }

  // Boton para imprimir
  onPrint() {
    if (this.myGrid.getrows().length === 0) {
      return MainComponent.getInstance().showWarning('ATENCION', this.translate('No_existen_datos'), 2000);
    } else {
      this.myGrid.hidecolumn('Botones');
      let gridContent = this.myGrid.exportdata('html');
      let newWindow = window.open('', '', 'width=800, height=500'),
        document = newWindow.document.open(),
        pageContent =
          '<!DOCTYPE html>\n' +
          '<html>\n' +
          '<head>\n' +
          '<meta charset="utf-8" />\n' +
          '<title>jQWidgets Grid</title>\n' +
          '</head>\n' +
          '<body>\n' +
          gridContent +
          '\n</body>\n</html>';
      this.myGrid.showcolumn('Botones');
      document.write(pageContent);
      document.close();
      newWindow.onafterprint = function() {
        newWindow.close();
      };
      newWindow.print();
    }
  }

  rowdetailstemplate = (index: any): any => {
    var details = {
      rowdetails: '<div id="nestedGrid" style="margin: 10px;"></div>',
      rowdetailsheight: 180,
      rowdetailshidden: true,
    };
    this.editrow = index;
    //Saca el usuario y adapta el row height al numero de accesos que tenga
    let rowId = this.myGrid.getcellvalue(index, 'id');
    let envio = this.historicosEnviosIdentificador.find(x => x.id == rowId);

    if (envio) {
      if (!envio.Detalle) {
        return details;
      }
    }
    return details;
  };

  initRowDetails = (
    index: any,
    parentElement: any,
    gridElement: any,
    datarecord: any
  ): void => {
    if (parentElement && datarecord) {
      let nestedGridContainer = parentElement.children[0];

      let i = this.historicosEnviosIdentificador.findIndex(
        (envio) => envio.id === datarecord.id
      );

      if (this.envioIdentificador) {
        this.envioIdentificador = new HistoricoEnvioIdentiiicadorModel();
      }
      this.envioIdentificador = this.historicosEnviosIdentificador[i];
      if (this.envioIdentificador) {
        let sourceDetalle = {
          datatype: 'array',
          datafields: [
            {name: 'idCerradura', type: 'number', map: 'idCerradura'},
            {name: 'fecha', type: 'date', map: 'fecha'},
            {name: 'nsTarjeta', type: 'number', map: 'nsTarjeta'},
            {name: 'nsMovisat', type: 'string', map: 'nsCerradura'},
            {name: 'nombreCiudadano', type: 'string', map: 'nombreCiudadano'},
            {name: 'aporteResiduo', type: 'boolean', map: 'aporte'},
            {name: 'aperturaTapa', type: 'boolean', map: 'aperturaTapa'},
            {name: 'noCerroTapa', type: 'boolean', map: 'noCerroTapa'},
            //{ name: 'nsCerradura', type: 'string', map: 'nsCerradura' },
            {name: 'observaciones', type: 'string', map: 'observacionesElemento'},
            {name: 'matricula', type: 'string', map: 'matriculaElemento'},
            {name: 'medio', type: 'string', map: 'medio'},
            {name: 'tipoPermiso', type: 'string', map: 'tipoPermiso'},
            {name: 'marca', type: 'string', map: 'marcaElemento'},
            {name: 'modelo', type: 'string', map: 'modeloElemento'},
            {name: 'nombreElemento', type: 'string', map: 'nombreElemento'},
            {name: 'direccion', type: 'string', map: 'direccionElemento'},
            {name: 'municipio', type: 'string', map: 'municipioElemento'},
            {name: 'poblacion', type: 'string', map: 'poblacionElemento'},
          ],
          localdata: this.envioIdentificador.Detalle,
        };

        let dataDetalleEnvios = new jqx.dataAdapter(sourceDetalle);

        if (nestedGridContainer != null) {
          let setting = {
            width: '97%',
            height: '90%',
            source: dataDetalleEnvios,
            theme: this.theme,
            columns: this.columnsIdentificacines,
            localization: this.langGrid,
            editable: false,
            columnsresize: true
            // me subcribo al evento de doble click en la fila
          };
          this.gridIdentificaciones = jqwidgets.createInstance(
            `#${nestedGridContainer.id}`,
            'jqxGrid',
            setting
          );
        }

        setTimeout(() => {
          this.resizeGridDetails(this.gridIdentificaciones);
        }, 200);
      }
    } else {
      return;
    }
  };

  rowExpand(event: any) {
    let rowId = this.myGrid.getcellvalue(event.args.rowindex, 'id');
    let envio = this.historicosEnviosIdentificador.find(x => x.id == rowId);

    if (!envio || envio.cantidad == 0) {
      this.myGrid.hiderowdetails(event.args.rowindex);
    }else {
      let id = this.myGrid.getrowdata(event.args.rowindex).id;
      if(!(this.showRowDetails.includes(id))){
        this.showRowDetails.push(id);
      }
    }
  }

  rowCollapse(event: any) {
    let id = this.myGrid.getrowdata(event.args.rowindex).id;
    this.showRowDetails.splice(this.showRowDetails.indexOf(id), 1);
  }

  sortedColumn: any;

  customsortfunc = (column: any, direction: string | boolean): void => {
    let sortdata = new Array();
    if (direction == 'ascending') direction = true;
    if (direction == 'descending') direction = false;
    if (direction != null) {
      for (let i = 0; i < this.historicosEnviosIdentificador.length; i++) {
        sortdata.push(this.historicosEnviosIdentificador[i]);
      }
    } else {
      sortdata = this.historicosEnviosIdentificador;
    }
    this.sortedColumn = column;

    let tmpToString = Object.prototype.toString;
    Object.prototype.toString = (typeof column == 'function') ? column : () => { return this[column] };
    if (direction != null) {
      sortdata.sort(this.compare);
      if (!direction) {
        sortdata.reverse();
      }
    }
    this.source.localdata = sortdata;
    this.myGrid.updatebounddata('sort');
    Object.prototype.toString = tmpToString;
  }

  compare = (value1: any, value2: any): any => {
    if(this.sortedColumn === 'fechaEnvio') {
      value1 = new Date(value1[this.sortedColumn]).getTime();
      value2 = new Date(value2[this.sortedColumn]).getTime();
    }else {
      value1 = value1[this.sortedColumn];
      value2 = value2[this.sortedColumn];
    }

    try {
      let tmpvalue1 = parseFloat(value1);
      if (isNaN(tmpvalue1)) {
        if (value1 < value2) { return -1; }
        if (value1 > value2) { return 1; }
      }
      else {
        let tmpvalue2 = parseFloat(value2);
        if (tmpvalue1 < tmpvalue2) { return -1; }
        if (tmpvalue1 > tmpvalue2) { return 1; }
      }
    } catch (error) {
    }
    return 0;
  };

  resizeGridDetails(grid: jqxGridComponent) {
    if (grid) {
      grid.setOptions({
        columnsheight: 20,
        rowsheight: 20,
        statusbarheight: 20,
        columnsresize: true,
      });
      grid.refresh();
      grid.showrowdetails(this.editrow);
    }
  }
}
