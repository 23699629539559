import { BdtEquipamientoModel } from "../../bdt/models/bdt-equipamiento.model";
import { CerraduraModel } from "./cerradura.model";

export class ConfiguracionCerraduraModel {
  id: number = 0;
  empresa: number = 0;
  nombre: string = '';
  host: string = '';
  puerto: number = 1;
  apn: string = '';
  apnUsuario: string = '';
  apnPassw: string = '';
  pin: string = '';
  tecnologia: number = 2;
  intervaloEnvio: number = 30;
  horaEnvio: number = 0;
  alarmas: number = 0;
  bateriaBaja: number = 500;
  bateriaCritica: number = 500;
  temperatura: number = 750;
  tiempoAbrir: number = 6;
  tiempoAporte: number = 10;
  tiempoCerrar: number = 10;
  tipoContenedor: number = 0;
  tipoCerradura: number = 0;
  modeloRadar: number = 0;
  radarAmplitud: number = 10;
  radarVelocidad: number = 150;
  sensorPuerta: boolean = false;
  umbralRssBt: number = 0;
  tiempoMaxApertura: number = 0;
  tiempoMaxAperturaBatBaja: number = 0;
  tiempoMaxCierre: number = 0;
  tiempoMaxCierreBatBaja: number = 0;
  deteccionVaciado: number = 0;
  instalacionInvertida: boolean = false;
  orientacion: number = 0;
  frecuenciaAnunciaBT: number = 0;
  distanciaPared: number = 680;
  siempreAbierto: boolean = false;

  // Configuracion EcoLock
  pendienteEcuacionApertura: number = 0;
  pendienteEcuacionCierre: number = 0;
  ordenOrigenEcuApertura: number = 1400;
  ordenOrigenEcuCierre: number = 1000;
  esperaEcuApertura: number = 10;
  esperaEcuCierre: number = 150;
  tiempoActCortasApertura: number = 5;
  tiempoActCortasCierre: number = 5;
  umbralConsumoBajo: number = 40;
  umbralConsumoAlto: number = 200;

  cerraduras: CerraduraModel[] = [];
  numeroCerraduras: number = 0;
  equipamiento: BdtEquipamientoModel;

  constructor() { }
}
