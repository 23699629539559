import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';

import { AppComponent } from 'src/app/app.component';
import { CustomForms } from '../../forms/custom-forms';
import { MainComponent } from '../../main/main.component';
import { environment } from 'src/environments/environment';

import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';
import { jqxTextAreaComponent } from 'jqwidgets-ng/jqxtextarea';
import { jqxDropDownListComponent } from 'jqwidgets-ng/jqxdropdownlist';

import { IncidencesService } from 'src/app/services/incidences/incidences.service';

import { EstadoModel } from 'src/app/services/incidences/models/estado.model';
import { IncidenciaModel } from 'src/app/services/incidences/models/incidencia.model';
import { CambioEstadoModel } from 'src/app/services/incidences/models/cambioEstado.model';

@Component({
  selector: 'app-edit-state',
  templateUrl: './edit-state.component.html',
  styleUrls: ['./edit-state.component.css']
})
export class EditStateComponent extends CustomForms implements OnInit {
  @ViewChild('windowState') windowState: jqxWindowComponent;
  @ViewChild('listEstados') listEstados: jqxDropDownListComponent;
  @ViewChild('textArea') textArea: jqxTextAreaComponent;
  @Output()
  cerrar = new EventEmitter<boolean>();
  //component
  private componentRef = null;
  private static instance: EditStateComponent;
  public environment = environment;
  //models
  estados: EstadoModel[];
  incidence: IncidenciaModel;
  arrStates: EstadoModel[] = [];
  cambioEstado = new CambioEstadoModel();
  //sources
  sourceEstados: { datatype: string; datafields: { name: string; }[]; id: string; localdata: any[]; };
  adapterEstados: any;
  //variables
  isDisable: boolean = false;
  value = '';

  constructor(private incidenceService: IncidencesService) {
    super();
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.addCustomForm(this.windowState);
    //cambia el título de la ventana
    this.windowState.setTitle(this.translate('Cambiar_estado'));
    //selecciona estado por defecto
    let selected = this.listEstados.getSelectedItem();
    //asigna el id del estado al objeto
    this.cambioEstado.EstadoId = selected.value;
  }

  // Este método es llamado por el creador del componente
  public init(componentRef: any, estados: EstadoModel[], incidence) {
    this.componentRef = componentRef;
    this.estados = estados;
    this.incidence = incidence;
    // this.changeState(this.incidence.Estado.Nombre);
    this.changeState(this.incidence.Estado.Nombre);
    this.initList();

  }

  closeWindow(){
    this.windowState.close();
  }

  public static getInstance(): EditStateComponent {
    return EditStateComponent.instance;
  }

  //botón guardar
  async onClickGuardar() {
    this.cambioEstado.IncidenciaId = this.incidence.Id;
    //guardar estado
    let response = await this.incidenceService.cambiarEstado(this.cambioEstado);
    if (response == null) {
      //avisa si falla la solicitud de cambio de estado
      MainComponent.showError('ATENCION', 'Error_', 2000);
    } else {
      MainComponent.showSuccess('ATENCION', 'Incidencia_guardada', 2000);
    }
    this.windowState.close();

  }

  //cuando se introdue texto en observaciones
  onChange(event) {
    this.value = this.textArea.val();
    this.cambioEstado.Observaciones = this.value;
  }

  //cambios en dropdownlist
  onChangeList(event) {
    this.cambioEstado.EstadoId = event.args.item.originalItem.uid;
  }

  // Cierro el formulario y destruyo el componente
  public onClose() {
    this.cerrar.emit(true);
    // Destruyo el componente
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }
  //comprueba el estado actual de la incidencia y muestra solo los posibles estados a los que puede cambiar
  changeState(estadoActual) {
    this.arrStates = [];
    switch (estadoActual) {
      case 'Abierta':
        this.estados.forEach(estado => {
          if (estado.Nombre != estadoActual) this.arrStates.push(estado);
        })
        break;

      case 'En proceso':
        let state = this.estados.filter(estado => {
          if (estado.Nombre == 'Cerrada' || estado.Nombre == 'Anulada') return estado;
        });
        this.arrStates.push(...state);
        // this.arrStates.push(this.estados.find(estado => estado.Nombre == 'Anulada'));
        break;
      default:
        this.arrStates.push(this.incidence.Estado);
        this.isDisable = true;
        break;
    }

  }

  //iniciar dropdownlist
  initList() {
    this.sourceEstados = {
      datatype: 'json',
      datafields: [
        { name: 'Nombre' },
        { name: 'Id' }
      ],
      id: 'Id',
      localdata: this.arrStates
    };
    this.adapterEstados = new jqx.dataAdapter(this.sourceEstados);
  }
  // Para traducir los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }
}
