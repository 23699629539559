import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ZonesComponent } from './zones.component';
import { EntradaSalidaComponent } from './entrada-salida/entrada-salida.component';
import { HeaderModule } from '../header/header.module';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { SharedModule } from 'src/app/shared/shared.module';
import {MapModule} from 'movisat-maps';
import {NzModalModule} from 'ng-zorro-antd/modal';

@NgModule({
  declarations: [
    ZonesComponent,
    EntradaSalidaComponent
  ],
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        HeaderModule,
        MatAutocompleteModule,
        MapModule,
        NzModalModule,
    ]
})
export class ZonesModule { }
