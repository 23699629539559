import { Directive, HostListener, Input } from '@angular/core';
import { ClipboardService } from '../services/clipboard.service';

@Directive({
  selector: '[appCellClick]'
})
export class CellClickDirective {

  @Input() appCellClick: boolean;
  private lastClickedElement: HTMLElement;

  constructor(private clipboardService: ClipboardService) { }

  @HostListener('click', ['$event.target'])
  onClick(target: HTMLElement) {
    this.lastClickedElement = target;
  }

  @HostListener('document:keydown', ['$event'])
  onKeydown(event: KeyboardEvent) {
    if (event.ctrlKey && event.key === 'c') {
      if (this.lastClickedElement) {
        const textToCopy = this.lastClickedElement.innerText;
        this.clipboardService.copyToClipboard(textToCopy);
        event.preventDefault();
      }
    }
  }
}