import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { jqxGridModule } from 'jqwidgets-ng/jqxgrid';
import { jqxDropDownButtonModule } from 'jqwidgets-ng/jqxdropdownbutton';

import { ModeloSelectComponent } from './modelo-select.component';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [
    ModeloSelectComponent,
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [
    ModeloSelectComponent
  ]
})
export class ModeloSelectModule { }
