<div style="height: 100%;">
  <jqxGrid #gridDirecciones [theme]="environment.tema" [width]="'99%'" [height]="'100%'" [source]="adapterDirecciones"
    [autoshowloadelement]='false' [appCellClick] [columnsresize]="true" [localization]="langGrid" [filterable]="true"
    [showfilterrow]="false" [columngroups]="columnsGroupDirecciones" [columns]="columnsDirecciones"
    [showrowlines]="false" [showcolumnlines]="true" [pageable]="false" [sortable]="true" [altrows]="true"
    [showstatusbar]="true" [statusbarheight]="0" [showaggregates]="false" [rowsheight]="25"
    [enablebrowserselection]="true" [columnsheight]="30" [enabletooltips]="false" [editable]="false" [groupable]="true"
    [ready]="setRowDetails()" [filter]="filter" [updatefilterconditions]="updatefilterconditions">
  </jqxGrid>
</div>

<div #formDireccion></div>
