import {Component, OnInit, ViewChild, ViewContainerRef} from '@angular/core';

import * as xlsx from 'xlsx';
import {Utils} from 'src/app/utils/utils';
import {GeoUtils} from 'src/app/utils/geo-utils';
import {DateUtils} from 'src/app/utils/date-utils';
import {AppComponent} from 'src/app/app.component';
import { NumberUtils } from 'src/app/utils/number-utils';
import {environment} from 'src/environments/environment';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import {CustomForms} from 'src/app/components/forms/custom-forms';
import {MapBounds, MapComponent, MapLatLng, MapMarker} from 'movisat-maps';

import {JqWidgets} from 'src/app/utils/jqWidgets';
import {jqxGridComponent} from 'jqwidgets-ng/jqxgrid';
import {jqxWindowComponent} from 'jqwidgets-ng/jqxwindow';

import {SsoService} from 'src/app/services/sso/sso.service';
import { ElementsService } from 'src/app/services/elements/elements.service';
import {CerraduraService} from 'src/app/services/cerraduras/cerradura.service';

import {ElementoModel} from 'src/app/services/elements/models/elem.model';
import {CerraduraModel} from 'src/app/services/cerraduras/models/cerradura.model';
import {PosicionCerraduraModel} from 'src/app/services/cerraduras/models/posicionCerradura.model';
import {ElementoCerraduraModel} from 'src/app/services/cerraduras/models/elementoCerradura.model';

import {MainComponent} from 'src/app/components/main/main.component';
import {HeaderComponent} from 'src/app/components/header/header.component';

import {ConfiguracionCerradurasEditComponent} from 'src/app/components/configuracion-cerraduras/configuracion-cerraduras-edit/configuracion-cerraduras-edit.component';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-listado-cerradura',
  templateUrl: './listado-cerradura.component.html',
  styleUrls: ['./listado-cerradura.component.css']
})
export class ListadoCerraduraComponent extends CustomForms implements OnInit {
  @ViewChild('form') form: jqxWindowComponent;
  @ViewChild('formDetalle') formDetalle: jqxWindowComponent;
  @ViewChild('formLockConfiguracion', {read: ViewContainerRef}) editConfiguracionComponent;
  @ViewChild('myGrid') grid: jqxGridComponent;
  @ViewChild('header') header: HeaderComponent;

  private componentRef = null;
  public static instance: ListadoCerraduraComponent = null; // Para controlar que sólo se cree una instancia
  public static numInstances = 0; // Para controlar el número de reproductores abiertos
  public theme = environment.tema;
  private subscriptionOnMarkerDragEnd;
  any = null;

  //  Esto es para que los textos en los controles del grid salgan en español
  public langGrid = JqWidgets.getLocalization('es');
  public dataSource: any = null;
  public dataAdapter: any;
  public cerraduras: CerraduraModel[] = [];
  public cerradura: CerraduraModel = new CerraduraModel();
  elemento: ElementoCerraduraModel = new ElementoCerraduraModel();
  private map: MapComponent;
  elementos: ElementoModel[] = [];
  dateFin = new Date();
  dateInicio = new Date();
  checkSelect: CerraduraModel[] = [];
  private empresaId: number = this.ssoService.getTicket().Empresa.IdGestion;
  viewForm: boolean = false;
  clickTimer: any;
  mapWidth: number;
  mapHeight: number;
  formLockConfiguracion: any;
  showLoader: boolean = true;
  verDesdeColumn: boolean = false;
  markers: MapMarker[] = [];
  metrosFilter: number = 0;

  constructor(
    private cerraduraService: CerraduraService,
    private elementService: ElementsService,
    private ssoService: SsoService,
    private modal: NzModalService,
    private toastrService: ToastrService,
  ) {
    super();
    ListadoCerraduraComponent.instance = this;
  }

  rendexTextGeneric(row: number, columnfield: string, value: any,
    defaulthtml: string, columnproperties: any, rowdata: any): string {
    if (typeof value === 'string') {
      return `
        <div style="margin-left: 4px; margin-top: 5px; text-align: left;"
          onmouseover="this.style.backgroundColor='gray'; this.style.color='white'; this.style.position='fixed';"
          onmouseout="this.style.backgroundColor=''; this.style.color=''; this.style.position='';">
            ${value}
        </div>
      `;
    } else if (typeof value === 'number') {
      return `
        <div style="margin-right: 4px; margin-top: 4px; text-align: right;">
          <span onmouseover="this.style.backgroundColor='gray'; this.style.color='white';"
          onmouseout="this.style.backgroundColor=''; this.style.color='';">
            ${value}
          </span>
        </div>
      `;
    }
  }

  numberrendererDecimales(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    return (
      '<div style="margin-right: 4px; margin-top: 5px; text-align: right;">' +
      NumberUtils.format(value, 2) +
      '</div>'
    );
  }

  cellClass = (
    row: number,
    columnfield: any,
    value: any
  ): string => {
    if (value) {
      return 'cellTooltip';
    }
    return '';
  };

  public columns: any = [
    {text: 'Selec', columntype: 'textbox', filtertype: 'textbox', datafield: 'selec', hidden: true},
    {
      width: 112,
      text: this.translate('Acciones'),
      columntype: 'text',
      editable: false,
      datafield: 'Botones',
      groupable: false,
      menu: false,
      sortable: false,
      clickable: false,
      filterable: false,
      pinned: true,
      createwidget: (
        row: any,
        column: any,
        value: string,
        htmlElement: HTMLElement
      ): void => {
        this.initBtnColumn(row, column, value, htmlElement);
      },
      initwidget: (
        row: any,
        column: any,
        value: string,
        htmlElement: HTMLElement
      ) => {
        this.initBtnColumn(row, column, value, htmlElement);
      },
      aggregates: [{
        'Total': function(aggregatedValue, currentValue: number) {
          return aggregatedValue + 1;
        }
      }], //agrego el total al grid
      aggregatesrenderer: function(aggregates) {
        let renderstring = '';
        if (aggregates['Total'] !== undefined) {
          renderstring = '<div style="margin-left: 4px;">' + AppComponent.translate('Total') + ': ' +
            aggregates['Total'] + '</div>';
        }
        return renderstring;
      }
    },
    {
      text: AppComponent.translate('Id'),
      datafield: 'id',
      hidden: true,
      filtertype: 'textbox',
      editable: false,
      cellsrenderer: this.rendexTextGeneric
    },
    {
      text: 'idElemento',
      datafield: 'elementoId',
      hidden: true,
      filtertype: 'textbox',
      width: 150,
      editable: false,
      cellsrenderer: this.rendexTextGeneric
    },
    {
      text: AppComponent.translate('Tipo_producto'),
      datafield: 'tipoProducto',
      filtertype: 'checkedlist',
      width: 80,
      cellsrenderer: this.rendexTextGeneric
    },
    {
      text: AppComponent.translate('Ns_movisat'),
      width: 170,
      filtertype: 'textbox',
      datafield: 'nsMovisat',
      cellsrenderer: this.rendexTextGeneric
    },
    {
      text: AppComponent.translate('Ns_fabricante'),
      width: 170,
      filtertype: 'textbox',
      datafield: 'nsFabricante',
      cellsrenderer: this.rendexTextGeneric
    },
    {
      text: AppComponent.translate('Version'),
      filtertype: 'textbox',
      width: 100,
      datafield: 'version',
      cellsrenderer: this.rendexTextGeneric
    },
    {
      text: AppComponent.translate('Imei'),
      filtertype: 'textbox',
      cellsalign: 'right',
      width: 170,
      datafield: 'imei',
      cellsrenderer: this.rendexTextGeneric
    },
    {text: 'MCC', filtertype: 'textbox', datafield: 'mcc', hidden: true, cellsrenderer: this.rendexTextGeneric},
    {text: 'mnc', filtertype: 'textbox', datafield: 'mnc', hidden: true, cellsrenderer: this.rendexTextGeneric},
    {text: 'ICC', filtertype: 'textbox', datafield: 'icc', hidden: true, cellsrenderer: this.rendexTextGeneric},
    {
      text: AppComponent.translate('Direccion_identificacion'), width: 100, filtertype: 'textbox',
      datafield: 'direccionIdentificador', cellsrenderer: this.rendexTextGeneric
    },
    {
      text: this.translate('Metros'),
      columntype: 'textbox',
      filtertype: 'textbox',
      datafield: 'metros',
      width: 60,
      cellsrenderer: this.numberrendererDecimales,
      cellclassname: this.cellClass
    },
    {text: AppComponent.translate('Direccion'), width: 100, filtertype: 'textbox', datafield: 'calle',  cellsrenderer: this.rendexTextGeneric},
    {text: AppComponent.translate('Municipio'), width: 100, filtertype: 'textbox', datafield: 'municipio',  cellsrenderer: this.rendexTextGeneric},
    {text: AppComponent.translate('Poblacion'), width: 100, filtertype: 'textbox', datafield: 'poblacion',  cellsrenderer: this.rendexTextGeneric},
    {text: AppComponent.translate('Provincia'), width: 100, filtertype: 'textbox', datafield: 'provincia',  cellsrenderer: this.rendexTextGeneric},
    {text: AppComponent.translate('Num_calle'), width: 100, filtertype: 'textbox', datafield: 'numeroCalle',  cellsrenderer: this.rendexTextGeneric},
    {
      text: AppComponent.translate('Nombre_elemento'),
      datafield: 'elemento',
      filtertype: 'textbox',
      width: 150,
      cellsrenderer: this.rendexTextGeneric
    },
    {
      text: AppComponent.translate('Marca'),
      filtertype: 'checkedlist',
      datafield: 'marca',
      width: 110,
      cellsrenderer: this.rendexTextGeneric
    },
    {
      text: AppComponent.translate('Modelo'),
      filtertype: 'checkedlist',
      datafield: 'modelo',
      width: 110,
      cellsrenderer: this.rendexTextGeneric
    },
    {
      text: AppComponent.translate('Matricula'),
      filtertype: 'textbox',
      datafield: 'matricula',
      width: 110,
      cellsrenderer: this.rendexTextGeneric
    },
    {
      text: AppComponent.translate('F_ult_conex'),
      datafield: 'fechaModificacion',
      filtertype: 'date',
      columntype: 'datetimeinput',
      width: 100,
      cellsformat: 'dd/MM/yyyy HH:mm:ss',
      cellclassname: this.cellClass
    },
    {
      text: AppComponent.translate('Observaciones'),
      datafield: 'observaciones',
      menu: false,
      sortable: false,
      width: 110,
      cellsrenderer: this.rendexTextGeneric
    },
  ];

  /**Inicializa el componente
   */
  init(componentRef: any) {
    // solo se puede crear una instancia
    if (ListadoCerraduraComponent.numInstances == 0) {
      ListadoCerraduraComponent.numInstances++;
      this.componentRef = componentRef;
    } else {
      componentRef.destroy();
    }
  }

  ngOnInit(): void {
    this.mapHeight = document.getElementById('map-container').offsetHeight;
    this.mapWidth = document.getElementById('map-container').offsetWidth;
    this.map = MainComponent.getInstance().getMap();
    this.getCerraduras();
  }

  ngAfterViewInit(): void {
    this.addCustomForm(this.form);
    this.addCustomForm(this.formDetalle);
    this.form.setTitle(AppComponent.translate('Cerradura_iot'));
    this.formDetalle.setTitle(AppComponent.translate('Ficha_cerradura_iot'));
    this.onSubscribeMap();
  }

  closeWindow() {
    this.form.close();
  }

  onCancelBtn() {
    this.formDetalle.close();
  }

  // Incializa la columna de iconos
  async initBtnColumn(
    row: any,
    column: any,
    value: string,
    htmlElement: HTMLElement
  ) {
    htmlElement.innerHTML = '';
    // Creo un contenedor para los botones
    const btnContainer = document.createElement('div');
    btnContainer.style.display = 'flex';
    btnContainer.style.gap = '2px';
    btnContainer.style.padding = '2px';

    let rowdata;
    if (isNaN(row)) {
      rowdata = row.bounddata;
    } else {
      rowdata = this.grid.getrowdata(row);
    }

    const btnInfo = document.createElement('div');
    btnInfo.innerHTML =
      '<button id=info style="height: 23px; width: 25px; padding: 0; margin: 0; cursor: pointer !important;">' +
      '<i style="font-size: 18px;" class="fas fa-circle-info"></i></button>';
    btnInfo.id = `buttonInfoMap_jqxButton`;
    btnContainer.appendChild(btnInfo);
    htmlElement.appendChild(btnContainer);

    // lo mismo para el resto de botones
    const newIconInfo = document.getElementById('buttonInfoMap_jqxButton');
    if (newIconInfo) {
      btnInfo.title = this.translate('Ficha_cerradura_iot');
    }

    // Capturo el click de los botones
    btnInfo.addEventListener('click', async (event: any) => {
      this.cerradura = this.cerraduras.find((cerradura) => cerradura.id === rowdata.id);
      this.cerradura.imsi = this.concatImsi(this.cerradura.mcc, this.cerradura.mnc, this.cerradura.icc);
      this.form.collapse();
      this.formDetalle.open(); //Abre el formulario
      this.formDetalle.bringToFront();
    });

    // si rowdata tiene coordenadas o el cmapo elemento tiene id, añado un botón para ver en el mapa
    if (rowdata && (rowdata.elementoId || (rowdata.lat && rowdata.lng))) {
      const btnViewMap = document.createElement('div');
      btnViewMap.innerHTML =
        '<button id="viewMap" style=" height: 23px; width: 25px;  padding: 0; margin: 0; cursor: pointer !important;">' +
        '<i style="font-size: 18px;" class="fa-sharp fa-solid fa-location-dot"></i></button>';
      btnViewMap.id = `buttonViewMap_jqxButton`;
      btnContainer.appendChild(btnViewMap);

      const newIconViewMap = document.getElementById('buttonViewMap_jqxButton');
      if (newIconViewMap) {
        btnViewMap.title = this.translate('Posicionar_en_mapa');
      }

      btnViewMap.addEventListener('click', async (event: any) => {
        this.cerradura = this.cerraduras.find((cerradura) => cerradura.id === rowdata.id);
        this.verDesdeColumn = true;
        this.onCentrar(rowdata);
        this.form.collapsed(true);
      });
    }

    // si rowdata tiene configuracion, añado un botón para ver la configuración
    if (rowdata && rowdata.configuracion !== null) {
      const btnConfig = document.createElement('div');
      btnConfig.innerHTML =
        '<button id="config" style="height: 23px; width: 25px;  padding: 0; margin: 0; cursor: pointer !important;">' +
        '<i style="font-size: 18px;" class="fa-solid fa-cog"></i></button>';
      btnConfig.id = `buttonConfig_jqxButton`;
      btnContainer.appendChild(btnConfig);

      const newIconConfig = document.getElementById('buttonConfig_jqxButton');
      if (newIconConfig) {
        btnConfig.title = this.translate('Configuracion');
      }

      btnConfig.addEventListener('click', async (event: any) => {
        this.cerradura = this.cerraduras.find((cerradura) => cerradura.id === rowdata.id);
        this.formLockConfiguracion = this.editConfiguracionComponent.createComponent(ConfiguracionCerradurasEditComponent);
        this.formLockConfiguracion.instance.init(this.formLockConfiguracion, this.cerradura.configuracion, true, this.cerradura.nsMovisat);
        this.form.collapsed(true);
      });
    }

    if (rowdata && rowdata.elementoId) {
      const btnMover = document.createElement('div');
      btnMover.innerHTML = `
        <button id="config" style="height: 23px; width: 25px;  padding: 0; margin: 0; cursor: pointer !important;">
          <img style="float: left; height: 18px; width: 18px;" src="../assets/images/reubicar.png"/>
        </button>
      `;
      btnMover.id = `buttonMover_jqxButton`;
      btnContainer.appendChild(btnMover);

      const newIconMover = document.getElementById('buttonMover_jqxButton');
      if (newIconMover) {
        btnMover.title = this.translate('Mover');
      }

      btnMover.addEventListener('click', async (event: any) => {
        let modal: NzModalRef = this.modal.create({
          nzTitle: '<i>' + AppComponent.translate('ATENCION') + '</i>',
          nzContent: AppComponent.translate('Que_quiere_mover'),
          nzFooter: [
            {
              label: AppComponent.translate('Elemento'),
              type: 'primary',
              ghost: true,
              onClick () {
                let cerradura = ListadoCerraduraComponent.instance.cerraduras.find(cerr => cerr.id === rowdata.id);
                ListadoCerraduraComponent.instance.elementService.moveElement(cerradura.idElemento, cerradura.id, 'element').then(
                  (result) => {
                    ListadoCerraduraComponent.instance.cerraduraService.moveElementToCerradura(cerradura.id, false).then(
                      (res) => {
                        MainComponent.getInstance().showInfo(
                          'ATENCION',
                          'Registro_almacenado',
                          2000
                        );
                        modal.destroy();
                      }
                    )
                  }
                );
              }
            },
            {
              label: AppComponent.translate('Elemento_y_punto_ubicacion'),
              type: 'primary',
              ghost: true,
              onClick () {
                let cerradura = ListadoCerraduraComponent.instance.cerraduras.find(cerr => cerr.id === rowdata.id);
                ListadoCerraduraComponent.instance.elementService.moveElement(cerradura.idElemento, cerradura.id, 'ambos').then(
                  (result) => {
                    ListadoCerraduraComponent.instance.cerraduraService.moveElementToCerradura(cerradura.id, true).then(
                      (res) => {
                        MainComponent.getInstance().showInfo(
                          'ATENCION',
                          'Registro_almacenado',
                          2000
                        );
                        modal.destroy();
                      }
                    )
                  }
                );
              }
            },
            {
              label: AppComponent.translate('CANCELAR'),
              type: 'primary',
              danger: true,
              onClick: () => modal.destroy()
            },
          ],
          nzWidth: 650,
          nzCentered: true,
          nzZIndex: 999,
        });
      });
    }
  }

  onMoveElement(cerradura: CerraduraModel, toMove: string) {
    this.elementService.moveElement(cerradura.idElemento, cerradura.id, toMove);
  }

  onResetFilter(event: any) {
    this.grid.clearfilters();
  }

  onCloseForm(): void {
    this.cerradura = new CerraduraModel();
    this.form.expand();
    this.viewForm = false;
  }

  onRowdoubleclick(event: any): void {
    clearTimeout(this.clickTimer);  // Limpiamos el temporizador si se detecta un doble clic
    this.viewForm = true;
    let row: any = this.grid.getrowdatabyid(event.args.rowindex);
    this.cerradura = this.cerraduras.find(cerradura => cerradura.id === row.id);
    this.cerradura.imsi = this.concatImsi(this.cerradura.mcc, this.cerradura.mnc, this.cerradura.icc);
    this.form.collapse();
    this.formDetalle.open();
    setTimeout(() => {
      this.formDetalle.bringToFront();
    }, 100);
  }

  onCellClick(event: any) {
    clearTimeout(this.clickTimer);  // Siempre limpiamos el temporizador con cada clic para reiniciar el proceso
    // Configuramos el temporizador
    this.clickTimer = setTimeout(() => {
      if (event.args.datafield !== 'Botones') {
        let row: any = this.grid.getrowdatabyid(event.args.rowindex);
        this.onCentrar(row);
        this.form.collapsed(true);
        this.viewForm = false;
        this.form.expand();
      }
    }, 500);  // 500 milisegundos de espera para determinar si es un clic individual
  }

  concatImsi(mcc: string, mnc: string, icc: string): string {
    // concateno el icc, mcc, msi
    let imsi: string = '';
    // modifico para que salga con guiones y no null
    if (mcc === null) {
      imsi += '-';
      this.cerradura.mcc = '-';
    } else {
      imsi += String(mcc);
    }

    if (mnc === null) {
      imsi += '-';
      this.cerradura.mnc = '-';
    } else {
      imsi += String(mnc);
    }

    if (icc === null) {
      imsi += '-';
      this.cerradura.icc = '-';
    } else {
      imsi += String(icc);
    }
    return imsi;
  }

  // obtiene las cerraduras
  async getCerraduras() {
    this.elementos = MainComponent.getInstance().elementsList;
    this.columns.forEach((column, index) => {
      column.rendered = (element) => {
        Utils.tooltiprenderer(element);
      };
    });

    await this.cerraduraService.getCerraduras().then(
      async (result: CerraduraModel[]) => {
        this.cerraduras = await result;
        this.showLoader = false;
      }, (error: any) => {
        this.cerraduras = [];
      }
    );

    this.dataSource = {
      dataType: 'json',
      dataFields: [
        {name: 'Botones', type: 'string'},
        {name: 'id', type: 'number', map: 'id'},
        {name: 'elementoId', type: 'number', map: 'idElemento'},
        {name: 'tipoProducto', type: 'string', map: 'tipoOperacionStr'},
        {name: 'marca', type: 'string', map: 'elemento>marca'},
        {name: 'modelo', type: 'string', map: 'elemento>modelo'},
        {name: 'matricula', type: 'string', map: 'elemento>tag>matricula'},
        {name: 'elemento', type: 'string', map: 'elemento>nombre'},
        {name: 'configuracion', type: 'string', map: 'configuracion'},
        {name: 'nsMovisat', type: 'string', map: 'nsMovisat'},
        {name: 'nsFabricante', type: 'string', map: 'nsFabricante'},
        {name: 'version', type: 'string', map: 'versoft'},
        {name: 'imei', type: 'number', map: 'imei'},
        {name: 'mcc', type: 'number', map: 'mcc'},
        {name: 'mnc', type: 'number', map: 'mnc'},
        {name: 'icc', type: 'number', map: 'icc'},
        {name: 'elemento', type: 'string', map: 'elemento>nombre'},
        {name: 'direccionIdentificador', type: 'string', map: 'direccionIdentificador'},
        {name: 'metros', type: 'number', map: 'metros'},
        {name: 'provincia', type: 'string', map: 'provincia'},
        {name: 'municipio', type: 'string', map: 'municipio'},
        {name: 'poblacion', type: 'string', map: 'poblacion'},
        {name: 'calle', type: 'string', map: 'calle'},
        {name: 'numeroCalle', type: 'int', map: 'numeroCalle'},
        {name: 'marca', type: 'string', map: 'elemento>marca'},
        {name: 'modelo', type: 'string', map: 'elemento>modelo'},
        {name: 'matricula', type: 'string', map: 'elemento>tag>matricula'},
        {name: 'fechaModificacion', type: 'date', map: 'fechaUltimaConexion'},
        {name: 'observaciones', type: 'string', map: 'elemento>observaciones'},
        {name: 'selec', map: 'selec'}
      ],
      localdata: this.cerraduras
    };
    this.dataAdapter = new jqx.dataAdapter(this.dataSource);

    this.onBuscar();
  }

  updatefilterconditions = (type: string, defaultconditions: any): string[] => {
    return Utils.updatefilterconditions(type, defaultconditions);
  };

  public filter(cellValue?: any, rowData?: any, dataField?: string, filterGroup?: any, defaultFilterResult?: boolean): any {
    let filterColumns = [
      'tipoProducto',
      'marca',
      'modelo',
      'matricula',
      'elemento',
      'direccionIdentificador',
      'provincia',
      'municipio',
      'poblacion',
      'calle',
      'numeroCalle',
      'configuracion',
      'nsMovisat',
      'nsFabricante',
      'version',
      'elemento',
      'marca',
      'modelo',
      'matricula',
      'observaciones'
    ];

    return Utils.filterRow(cellValue, dataField, filterGroup, defaultFilterResult, filterColumns);
  }

  concatImsiRender(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    // concateno el icc, mcc, msi
    // Inicializo imsi como una cadena vacía
    let imsi = '';

    // Concateno los valores como cadenas de texto
    if (rowdata.mcc !== null) {
      imsi += String(rowdata.mcc);
    }

    if (rowdata.mnc !== null) {
      imsi += String(rowdata.mnc);
    }

    if (rowdata.icc !== null) {
      imsi += String(rowdata.icc);
    }

    rowdata.imsi = imsi;

    // Verifico si imsi está vacío
    if (imsi === '00') {
      return '';
    }

    return `
      <div style="margin-right: 4px; margin-top: 4px; text-align: right;">
        <span onmouseover="this.style.backgroundColor='gray'; this.style.color='white';" onmouseout="this.style.backgroundColor=''; this.style.color='';">
          ${imsi}
        </span>
      </div>
    `;
  }

  createMarkers(position: MapLatLng, icono: string): MapMarker {

    let marker = this.map.addMarker({
      dataModel: '',
      title: '',
      content: '',
      position: position,
      icon: icono,
      zIndex: 999,
      drag: false,
      visible: true
    });
    this.markers.push(marker);
    return marker;
  }

  onCentrar(data: CerraduraModel) {
    let zonaArray = [];
    if (this.verDesdeColumn) {
      this.cerradura = this.cerraduras.find((cerradura) => cerradura.id === data.id);
      zonaArray[0] = this.cerradura;
    } else {
      zonaArray.forEach(element => {
        zonaArray.push({
          elemento: {
            lat: element.lat,
            lng: element.lng
          }
        });
      });

      if (!zonaArray.length) {
        return MainComponent.getInstance().showWarning('ATENCION', 'Seleccione_registro', 2000);
      }
    }
    if (zonaArray.some(value => value.elemento == null) || zonaArray.some(value => value.lat == null) || zonaArray.some(value => value.lng == null)) {
      return MainComponent.getInstance().showError('ATENCION', 'No_existen_coordenadas', 2000);
    } else {

      let origenUbicacion = '';

      // primero compruebo si hay coordenadas en el elemento
      if (zonaArray.some(value => value.elemento.lat !== null || value.elemento.lng !== null)) {
        origenUbicacion = 'Elemento';
      } else if (zonaArray.some(value => value.lat !== null || value.lng !== null)) {
        origenUbicacion = 'Cerradura';
      }

      if (zonaArray.some(value => value.elemento.lat !== null && value.elemento.lng !== null) && zonaArray.some(value => value.lat !== null && value.lng !== null)) {
        origenUbicacion = 'Elemento';
      }

      this.encuadrarCerrradurasSelec(zonaArray);
      this.moverMarcadores(zonaArray, origenUbicacion);
    }
  }

  moverMarcadores(cerraduras: CerraduraModel[], origenUbicacion: string) {
    // encuentro el elemento  que esta en zonaArray
    let idElementos = cerraduras.map(value => value.elemento.id);
    let elementos = this.elementos.filter(value => idElementos.includes(value.Id));
    let markers = elementos.map(element => element.Marker);
    if (markers.length) {
      markers.forEach(marker => {
        marker.content = '<div style=margin: 10px;>' + marker.content + 'Origen Ubicación: ' + origenUbicacion + '</div>';
        marker.animate(3000);
      });
    }
  }

  encuadrarCerrradurasSelec(value) {
    this.markers.forEach(mark => {
      this.map.removeMarker(mark);
    });

    this.markers = [];

    let globalSWPoint = new MapLatLng(180, 90);
    let globalNEPoint = new MapLatLng(-180, -90);
    // Procesa cada zona
    value.forEach(cerradura => {
      let swPoint, nePoint;
      if (!MainComponent.getInstance().controlAmbitoActividad(new MapLatLng(cerradura.elemento.lat, cerradura.elemento.lng))) {
        this.toastrService.warning(
          AppComponent.translate('Fuera_ambito') + '<br>' +
          AppComponent.translate('Cerradura') + ':<br>' +
          cerradura.nsMovisat,
          AppComponent.translate('ATENCION'),
          {
            timeOut: 2000,
            positionClass: 'toast-top-center',
            enableHtml: true
          }
        );
      }else{
        swPoint = new MapLatLng(cerradura.elemento.lat, cerradura.elemento.lng);
        nePoint = new MapLatLng(cerradura.elemento.lat, cerradura.elemento.lng);

        swPoint = GeoUtils.getNewLatLng(cerradura.elemento, -50, -50);
        nePoint = GeoUtils.getNewLatLng(cerradura.elemento, 50, 50);

        globalSWPoint.lat = Math.min(globalSWPoint.lat, swPoint.lat);
        globalSWPoint.lng = Math.min(globalSWPoint.lng, swPoint.lng);
        globalNEPoint.lat = Math.max(globalNEPoint.lat, nePoint.lat);
        globalNEPoint.lng = Math.max(globalNEPoint.lng, nePoint.lng);

        let markerCerradura = this.createMarkers(new MapLatLng(cerradura.elemento.lat, cerradura.elemento.lng), 'assets/images/cerradura-gris.png');
        markerCerradura.content = '<div style="margin: 10px;">' + this.translate('Cerradura') + '<br>' + cerradura.nsMovisat + '</div>';
      }
    });
    this.map.fitTo(new MapBounds(globalSWPoint, globalNEPoint));
    this.markers.forEach(marker => {
      marker.animate(2800);
    })
  }

  // Si se seleccionan todos los elementos del grid
  onSelect(event: any) {
    let idRow: number[] = this.grid.getselectedrowindexes();
    let rows: any[] = this.grid.getrows();
    this.checkSelect = [];
    rows.forEach((element, index) => {
      if (idRow.includes(element.boundindex)) {
        let zona = this.cerraduras.find((elem) => elem.id == element.id);
        this.checkSelect.push(zona);
      }
    });
  }

  onUnSelect(event) {
    if(event.args.row) {
      this.checkSelect = this.checkSelect.filter(element => element.id !== event.args.row.id);
    }
  }

  onSubscribeMap() {
    if (!this.subscriptionOnMarkerDragEnd) {
      this.subscriptionOnMarkerDragEnd = this.map.subscribeOnMarkerDragEnd(
        this,
        (_this: any, marker: MapMarker) => {
          if (
            MainComponent.getInstance().controlAmbitoActividad(
              marker.position
            )
          ) {
            this.elemento.lat = marker.position.lat;
            this.elemento.lng = marker.position.lng;
          } else {
            if (this.elemento.lat && this.elemento.lng) {
              MainComponent.getInstance().showError(
                'ATENCION',
                'Fuera_ambito',
                2000
              );
              marker.setPosition(
                new MapLatLng(this.elemento.lat, this.elemento.lng)
              );
            }
          }
        }
      );
    }
  }

  onPosicionar() {
    if (this.subscriptionOnMarkerDragEnd && this.checkSelect.length && this.checkSelect.some(value => value.elemento !== null)) {
      this.checkSelect = this.checkSelect.filter(element => element.elemento !== null);
      this.encuadrarCerrradurasSelec(this.checkSelect);
      this.form.collapse();
    } else if (!this.checkSelect.length) {
      MainComponent.getInstance().showWarning('ATENCION', 'Seleccione_registro', 2000);
    } else {
      MainComponent.getInstance().showWarning('ATENCION', 'No_existen_coordenadas', 2000);
    }
  }

  // botones de acciones
  async onLocalizar() {
    let pedirPosicion: PosicionCerraduraModel = new PosicionCerraduraModel(this.checkSelect.map(value => value.id), this.empresaId);
    if (pedirPosicion && pedirPosicion.idsCerraduras.length !== 0) {
      let reult: any = await this.cerraduraService.pedirPosicion(pedirPosicion);
      if (reult.state == 'ok') {
        MainComponent.getInstance().showSuccess('ATENCION', 'Peticion_localizacion_realizada', 2000);
        this.grid.clearselection();
        this.checkSelect = [];
      }
    } else {
      MainComponent.getInstance().showError('ATENCION', 'Error_enviar_localizacion', 2000);
    }
  }

  changeFilterMetros(event: any) {
    this.metrosFilter = parseInt(event);
    this.onBuscar();
  }

  eventFilter() {
    this.header.searchInput['nativeElement'].value = '';
    this.getCerraduras();
  }

  eventResetFilter() {
    this.header.searchInput['nativeElement'].value = '';
    this.onBuscar();
  }

  onBuscar() {
    let filtervalue = '';

    if (this.header.searchInput['nativeElement'].value.length >= 3) {
      filtervalue = this.header.searchInput['nativeElement'].value.toUpperCase();
    } else {
      filtervalue = '';
    }

    this.cerraduras.forEach(cerr => {
      if (
        ((cerr?.idElemento + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (cerr?.tipoOperacionStr + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (cerr?.nsMovisat + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (cerr?.nsFabricante + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (cerr?.versoft + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (cerr?.imei + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (cerr?.sim + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (this.concatImsi(cerr.mcc, cerr.mnc, cerr.icc) + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (cerr?.elemento?.nombre + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (cerr?.elemento?.marca + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (cerr?.elemento?.modelo + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (cerr?.elemento?.tag?.matricula + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (cerr?.fechaUltimaConexionStr + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (cerr?.elemento?.observaciones + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1) &&
        (this.metrosFilter > 0 && cerr?.metros ? cerr.metros < this.metrosFilter : true)
      ) {
        cerr['selec'] = 'selec';
      } else {
        cerr['selec'] = '';
      }
    });

    // Compruebo si ya he creado el filtro "selec" anteriormente
    const filters = this.grid.getfilterinformation();
    if (filters.find(s => s.datafield === 'selec') === undefined) {
      const filtergroup = new jqx.filter();
      filtergroup.operator = 'and';
      filtergroup.addfilter(0, filtergroup.createfilter('stringfilter', 'selec', 'equal'));
      this.grid.addfilter('selec', filtergroup);
    }
    this.grid.applyfilters();
    this.grid.updatebounddata('data');
  }

  onExportar() {
    if (this.grid.getrows().length === 0) {
      return MainComponent.getInstance().showWarning('ATENCION', this.translate('No_existen_datos'), 2000);
    } else {
      const json = this.grid.exportdata('json');
      let datos = JSON.parse(json);
      // Utiliza el array modificado en lugar del JSON original
      const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(datos);
      this.generateAutofilterHeader(ws);
      const wb: xlsx.WorkBook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(wb, ws, 'Hoja1');
      xlsx.writeFile(wb, DateUtils.formatDateAMDhms(new Date()) + '_' + this.translate('Cerradura_iot') + '.xlsx');
    }
  }

  generateAutofilterHeader(sheet) {
    // Añade filtro a todas las casillas.
    sheet['!autofilter'] = {ref: sheet['!ref']};
  }

  // Boton para imprimir
  onPrint() {
    if (this.grid.getrows().length === 0) {
      return MainComponent.getInstance().showWarning('ATENCION', this.translate('No_existen_datos'), 2000);
    } else {
      this.grid.hidecolumn('Botones');
      let gridContent = this.grid.exportdata('html');
      let newWindow = window.open('', '', 'width=800, height=500'),
        document = newWindow.document.open(),
        pageContent =
          '<!DOCTYPE html>\n' +
          '<html>\n' +
          '<head>\n' +
          '<meta charset="utf-8" />\n' +
          '<title>jQWidgets Grid</title>\n' +
          '</head>\n' +
          '<body>\n' +
          gridContent +
          '\n</body>\n</html>';
      this.grid.showcolumn('Botones');
      document.write(pageContent);
      document.close();
      newWindow.onafterprint = function() {
        newWindow.close();
      };
      newWindow.print();
    }
  }

  onClose() {
    if (this.componentRef) {
      this.componentRef.destroy();
      ListadoCerraduraComponent.numInstances--;
    }

    if (this.subscriptionOnMarkerDragEnd) {
      this.subscriptionOnMarkerDragEnd.unsubscribe();
      this.subscriptionOnMarkerDragEnd = null;
    }

    if (this.formDetalle) {
      this.formDetalle.destroy();
      this.viewForm = false;
    }

    ListadoCerraduraComponent.instance = null;
  }

  // Para traducir los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }
}
