import { AfterViewInit, Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';

import * as xlsx from 'xlsx';
import { Utils } from 'src/app/utils/utils';
import { JqWidgets } from 'src/app/utils/jqWidgets';
import { DateUtils } from 'src/app/utils/date-utils';
import { CustomForms } from '../../forms/custom-forms';
import { environment } from 'src/environments/environment';

import { AppComponent } from 'src/app/app.component';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { HeaderComponent } from '../../header/header.component';

import { SsoService } from 'src/app/services/sso/sso.service';

import { RangoModel } from 'src/app/services/ciudadanos/models/rango.model';
import { RangosService } from 'src/app/services/ciudadanos/tarjetas/rangos.service';
import { ZonaModel } from 'src/app/services/zones/models/zona.model';
import { ZonesService } from 'src/app/services/zones/zones.service';
import { FormRangoComponent } from './form-rango/form-rango.component';
import { TarjetasCiudadanosComponent } from '../tarjetas-ciudadanos.component';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';
import { MapComponent, MapLatLng, MapMarker } from 'movisat-maps';
import { MainComponent } from '../../main/main.component';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-tab-rangos',
  templateUrl: './tab-rangos.component.html',
  styleUrls: ['./tab-rangos.component.css']
})
export class TabRangosComponent extends CustomForms implements OnInit, AfterViewInit {
  @ViewChild('header') header: HeaderComponent;
  @ViewChild('gridRangos') gridRangos: jqxGridComponent;

  showLoader: boolean = false;

  public static _this: TabRangosComponent;

  public environment = environment;
  public langGrid = JqWidgets.getLocalization('es');

  public checkTarjVirtual = AppComponent.translate('Todas');

  public zonaFilter: string = '';
  public tipoTarjetaFilter: string = '';
  public numeroTarjetaFilter: number = 0;

  // Centrar mapa y añadir marca
  public map: MapComponent;
  public marker: MapMarker;

  rangos: RangoModel[];
  public zonas: ZonaModel[] = [];

  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  constructor(
    private modal: NzModalService,
    private ssoService: SsoService,
    private zonesService: ZonesService,
    private rangosService: RangosService,
  ) {
    super();
    TabRangosComponent._this = this;
  }

  ngOnInit(): void {
    this.langGrid = JqWidgets.getLocalization(this.ssoService.getTicket().Usuario.Idioma.Codigo.substring(0, 2));

    this.map = MainComponent.getInstance().getMap();

    this.initGrid();
  }

  async ngAfterViewInit(): Promise<void> {
    this.getRangos();
    this.getZones();
  }

  public columnsRangos;

  public sourceRangos;
  public adapterRangos;

  initGrid() {
    this.columnsRangos = [
      { text: 'Id', columntype: 'textbox', datafield: 'id', hidden: true },
      {
        text: '',
        width: 130,
        columntype: 'text',
        sortable: false,
        editable: false,
        datafield: this.translate('Acciones'),
        groupable: false,
        menu: false,
        filterable: false,
        resizable: false,
        rendered: (columnHeaderElement) => {
          let time = new Date().getTime();
          const buttonContainer = document.createElement('div');

          const options = {
            width: '100%',
            value: '<i class="fa fa-plus-circle fa-lg" aria-hidden="true"></i>',
            textImageRelation: 'imageAboveText',
          };
          buttonContainer.id = `buttonContainerColumn_jqxButton` + time;
          columnHeaderElement[0].appendChild(buttonContainer);
          let myButton;

          setTimeout(() => {
            myButton = jqwidgets.createInstance(
              `#buttonContainerColumn_jqxButton` + time,
              'jqxButton',
              options
            );
            myButton.addEventHandler('click', () => {
              this.onCrearRango();
            });
            let btn = <HTMLElement>buttonContainer.parentElement.children[2];
            btn.title = this.translate('Aniadir');
            btn.classList.add('button');
            btn.style.cursor = 'pointer';
            btn.style.height = '25px';
            btn.style.marginTop = '2px';

            let icon = <HTMLElement>btn.children[1];
            icon.style.position = 'unset';
          }, 50);
          return columnHeaderElement[0];
        },
        createwidget: (
          row: any,
          column: any,
          value: string,
          htmlElement: HTMLElement
        ): void => {
          this.initBtnColumn(row, column, value, htmlElement);
        },
        initwidget: (
          row: any,
          column: any,
          value: string,
          htmlElement: HTMLElement
        ) => {
          this.initBtnColumn(row, column, value, htmlElement);
        }
      },
      { text: this.translate('Descripcion'), columntype: 'textbox', filtertype: 'textbox', datafield: this.translate('Descripcion') },
      { text: this.translate('Numero') + ' ' + this.translate('Cerraduras'), columntype: 'textbox', filtertype: 'numericfilter', datafield: 'Num_cerraduras', cellsalign: 'right' },
      { text: this.translate('Tarjeta_inicial'), columntype: 'textbox', filtertype: 'numericfilter', datafield: 'Tarjeta_inicial', cellsalign: 'right' },
      { text: this.translate('Tarjeta_final'), columntype: 'textbox', filtertype: 'numericfilter', datafield: 'Tarjeta_final', cellsalign: 'right' },
      { text: this.translate('Total'), columntype: 'textbox', filtertype: 'numericfilter', datafield: this.translate('Total'), cellsalign: 'right' },
      { text: this.translate('Blanca'), columntype: 'checkbox', filtertype: 'textbox', datafield: this.translate('Blanca'), cellclassname: this.cellClassCheck },
      { text: this.translate('Master'), columntype: 'checkbox', filtertype: 'textbox', datafield: this.translate('Master'), cellclassname: this.cellClassCheck },
      { text: this.translate('Negra'), columntype: 'checkbox', filtertype: 'textbox', datafield: 'Negra', cellclassname: this.cellClassCheck },
      { text: this.translate('Numero') + ' ' + this.translate('Zonas').toLowerCase(), columntype: 'textbox', filtertype: 'numericfilter', datafield: 'numZonas', cellsalign: 'right' },
      { text: this.translate('Zonas'), columntype: 'textbox', filtertype: 'stringfilter', datafield: this.translate('Zonas'), width: 120, cellsrenderer: this.renderZonas },
      { text: 'Selec', columntype: 'textbox', filtertype: 'textbox', datafield: 'selec', hidden: true }
    ];

    this.sourceRangos = {
      datatype: 'json',
      datafields: [
        { name: 'id', type: 'int', map: 'id' },
        { name: this.translate('Acciones'), type: 'string' },
        { name: this.translate('Descripcion'), type: 'string', map: 'nombre' },
        { name: 'Num_cerraduras', type: 'string', map: 'cerraduras>length' },
        { name: 'Tarjeta_inicial', type: 'string', map: 'idTarjetaInicial' },
        { name: 'Tarjeta_final', type: 'string', map: 'idTarjetaFinal' },
        { name: this.translate('Total'), type: 'string', map: 'cantidadTarjetas' },
        { name: this.translate('Blanca'), type: 'string', map: 'listaBlanca' },
        { name: this.translate('Master'), type: 'string', map: 'listaMaster' },
        { name: 'Negra', type: 'string', map:  'listaNegra' },
        { name: 'numZonas', type: 'string', map: 'zonas>length' },
        { name: this.translate('Zonas'), type: 'object', map: 'zonas' },
        { name: 'selec', map: 'selec', type: 'string' }
      ],
      localdata: this.rangos,
    };
    this.adapterRangos = new jqx.dataAdapter(this.sourceRangos);
  }

  onCellClick(event: any) {
    if (event.args.column.datafield === 'Num_cerraduras') {
      let rowdata = this.gridRangos.getrowdata(event.args.rowindex);

      let rango: RangoModel = this.rangos.find(rang => rang.id === rowdata.id);

      if(rango.cerraduras.length > 0) {
        this.openCerraduras(rango);
      }
    }
  }

  renderZonas = (row: number, columnfield: string, value: any[], defaulthtml: string, columnproperties: any, rowdata: any): string => {
    if (value) {
      let textToShow = '';
      value.forEach(zona => {
        if(zona.id === value[value.length - 1].id){
          textToShow += `${zona.nombre}`
        }else{
          textToShow += `${zona.nombre}, `
        }
      });
      return '<div class="jqx-grid-cell-left-align" style="margin-top: 8px;">' + textToShow + '</div>';
    } else {
      return '<div class="jqx-grid-cell-left-align" style="margin-top: 8px;"></div>';
    }
  };

  cellClassCheck = (row: number, columnfield: any, value: any): string => {
    if (value) {
      return 'checkGray';
    }
    return '';
  }

  async initBtnColumn(
    row: any,
    column: any,
    value: string,
    htmlElement: HTMLElement
  ) {
    let rowdata;
    if (isNaN(row)) {
      rowdata = row.bounddata;
    } else {
      rowdata = this.gridRangos.getrowdata(row);
    }

    let rango: RangoModel = this.rangos.find(rang => rang.id === rowdata.id);

    htmlElement.innerHTML = '';
    const btnContainer = document.createElement('div');
    btnContainer.style.display = 'flex';
    btnContainer.style.gap = '2px';
    btnContainer.style.padding = '2px';
    btnContainer.style.justifyContent = 'center';

    const btnEditar = document.createElement('div');
    btnEditar.innerHTML = `
      <button class="button" style="height: 23px; cursor: pointer;" title="`+ AppComponent.translate('Editar') + `">
        <i class="fa-solid fa-pen-to-square"></i>
      </button>
    `;

    btnEditar.addEventListener('click', (event: any) => {
      this.onEditarRango(rango);
    });
    btnContainer.appendChild(btnEditar);

    if(rango && rango.zonas && rango?.zonas?.length !== 0) {
      let showButton: boolean = false;

      rango?.asociaciones?.forEach(asociacion => {
        if(asociacion.enviar === false) {
          showButton = true;
        }
      });

      if(showButton) {
        const btnSendRange = document.createElement('div');
        btnSendRange.innerHTML = `
          <button class="button" style="height: 23px; cursor: pointer;" title="`+ AppComponent.translate('Enviar_rango') + `">
            <img style="float: left; height: 18px; width: 18px;" src="../assets/images/asignar.png"/>
          </button>
        `;

        btnSendRange.addEventListener('click', (event: any) => {
          TabRangosComponent._this.showLoader = true;

          let zonasToAsociate: any[] = [];
          rango.zonas.forEach(zona => {
            zonasToAsociate.push({
              "idZona": zona.id,
              "enviar": true
            });
          });

          TabRangosComponent._this.rangosService.asociateRangoZona(rango.id, zonasToAsociate).then(
            (result) => {
              TabRangosComponent._this.getRangos();
              TabRangosComponent._this.showLoader = false;
            }, (error) => {
            }
          )
        });

        btnContainer.appendChild(btnSendRange);
      }
    }

    const btnUbicar = document.createElement('div');
    btnUbicar.innerHTML = `
      <button class="button" style="height: 23px; cursor: pointer;" title="`+ AppComponent.translate('Centrar_mapa') + `">
        <i class="fa-solid fa-location-dot"></i>
      </button>
    `;

    btnUbicar.addEventListener('click', (event: any) => {
      this.onCentrar(rango);
    });
    btnContainer.appendChild(btnUbicar);

    const btnDelete = document.createElement('div');
    btnDelete.innerHTML = `
      <button class="button" style="height: 23px; cursor: pointer;" title="`+ AppComponent.translate('Borrar') + `">
        <i class="fa-solid fa-trash"></i>
      </button>
    `;

    btnDelete.addEventListener('click', (event: any) => {
      this.onBorrar(rango);
    });
    btnContainer.appendChild(btnDelete);

    htmlElement.appendChild(btnContainer);
  }

  onFilter(event) {
    for (let filterObj of event.args.filters) {
      let column = filterObj.datafield;
      let filter = filterObj.filter.getfilters();
    }
  }

  updatefilterconditions = (type: string, defaultconditions: any): string[] => {
    return Utils.updatefilterconditions(type, defaultconditions);
  };

  public filter(cellValue?: any, rowData?: any, dataField?: string, filterGroup?: any, defaultFilterResult?: boolean): any {
    let filterColumns = [
      AppComponent.translate('Ns_movisat'),
      AppComponent.translate('Ciudadano'),
      AppComponent.translate('Direccion')
    ]

    return Utils.filterRow(cellValue, dataField, filterGroup, defaultFilterResult, filterColumns);
  }

  async getRangos() {
    this.showLoader = true;
    this.rangos = await this.rangosService.getRangos();

    if(!this.rangos) {
      this.rangos = [];
    }
  }

  cargaGrid() {
    this.rangos.sort((a, b) => a.id > b.id ? 1 : -1);

    this.sourceRangos.localdata = this.rangos;
    this.gridRangos.updatebounddata(this.rangos);
    this.showLoader = false;

    this.onBuscar();
  }

  eventFilter() {
    this.header.searchInput['nativeElement'].value = '';
    this.getRangos();
  }

  eventResetFilter() {
    this.header.searchInput['nativeElement'].value = '';
    this.header.dropTipoTarjeta.selectIndex(0);
    this.header.dropZonas.clearFilter();
    this.header.dropZonas.selectIndex(0);
    this.onBuscar();
  }

  async getZones() {
    this.zonas.push(new ZonaModel());
    await this.zonesService.getZonas().then(
      res => {
        this.zonas = this.zonas.concat(res);
      }
    );
  }

  onChangeZona(event: any) {
    this.zonaFilter = event;

    this.onBuscar();
  }

  onChangeNumeroTarjeta(event: any) {
    this.numeroTarjetaFilter = parseInt(event);

    this.onBuscar();
  }

  onChangetTipoTarjeta(event: any) {
    this.tipoTarjetaFilter = event;

    this.onBuscar();
  }

  onBuscar() {
    let filtervalue = '';

    if (this.header.searchInput['nativeElement'].value.length >= 3) {
      filtervalue = this.header.searchInput['nativeElement'].value;
    } else {
      filtervalue = '';
    }

    this.rangos?.forEach(rang => {
      if(this.numeroTarjetaFilter !== 0){
        console.log((rang.idTarjetaInicial <= this.numeroTarjetaFilter && rang.idTarjetaFinal >= this.numeroTarjetaFilter));
      }

      if(
        rang?.nombre.compareWith(filtervalue) &&
        (
          this.zonaFilter !== '' ?
          rang.zonas !== null && rang.zonas.findIndex(t => t.nombre === this.zonaFilter) !== -1 :
          true
        ) &&
        (
          this.numeroTarjetaFilter !== 0 ?
          (rang.idTarjetaInicial <= this.numeroTarjetaFilter && rang.idTarjetaFinal >= this.numeroTarjetaFilter) :
          true
        ) &&
        (
          this.tipoTarjetaFilter === AppComponent.translate('Todas') ? true :
          this.tipoTarjetaFilter === AppComponent.translate('Blanca') && rang.listaBlanca ? true :
          this.tipoTarjetaFilter === AppComponent.translate('Master') && rang.listaMaster ? true :
          this.tipoTarjetaFilter === AppComponent.translate('Negra') && rang.listaNegra ? true :
          false
        )
      ) {
        rang['selec'] = 'selec';
      }else {
        rang['selec'] = '';
      }
    });

    // Compruebo si ya he creado el filtro "selec" anteriormente
    try {
      const filters = this.gridRangos.getfilterinformation();
      if (filters.find(s => s.datafield === 'selec') === undefined) {
        const filtergroup = new jqx.filter();
        filtergroup.operator = 'and';
        filtergroup.addfilter(0, filtergroup.createfilter('stringfilter', 'selec', 'equal'));
        this.gridRangos.addfilter('selec', filtergroup);
      }
      this.gridRangos.applyfilters();
      this.gridRangos.updatebounddata('data');
    } catch (error) {
    }
  }

  public onCrearRango() {
    TarjetasCiudadanosComponent._this.form.collapse()

    TarjetasCiudadanosComponent._this.formRango = TarjetasCiudadanosComponent._this.editRangoComponent.createComponent(FormRangoComponent);
    TarjetasCiudadanosComponent._this.formRango.instance.init(TarjetasCiudadanosComponent._this.formRango);
  }

  public onEditarRango(rango: RangoModel) {
    TarjetasCiudadanosComponent._this.form.collapse()

    TarjetasCiudadanosComponent._this.formRango = TarjetasCiudadanosComponent._this.editRangoComponent.createComponent(FormRangoComponent);
    TarjetasCiudadanosComponent._this.formRango.instance.init(TarjetasCiudadanosComponent._this.formRango, rango);
  }

  public onCentrar(rango: RangoModel) {
    // Acerca y centra el mapa
    TabRangosComponent._this.map.setZoom(18);
    TabRangosComponent._this.map.setCenter(new MapLatLng(rango.zonas[0].lat, rango.zonas[0].lng));

    // Añade el marcador
    TabRangosComponent._this.marker = TabRangosComponent._this.map.addMarker({
      dataModel: rango.id,
      title: '',
      content: '',
      icon: '/assets/images/elemento.png',
      zIndex: 998,
      visible: true,
      position: new MapLatLng(rango.zonas[0].lat, rango.zonas[0].lng)
    });
    TabRangosComponent._this.marker.animate(2850);
    TarjetasCiudadanosComponent._this.form.collapse();
  }

  public onBorrar(rango: RangoModel) {
    this.modal.confirm({
      nzTitle: '<i>' + AppComponent.translate('ATENCION') + '</i>',
      nzContent: AppComponent.translate('Quiere_borrar_rango') + ': ' + rango.nombre + ' ?',
      nzCentered: true,
      nzCancelText: AppComponent.translate('CANCELAR'),
      nzOkText: AppComponent.translate('SI'),
      nzOnOk: async () => {
        this.rangosService.deleteRango(rango.id).then(
          (response) => {
            MainComponent.getInstance().showSuccess('ATENCION', 'Registro_borrado', 2000);
            this.getRangos();
          }, (error) => {
            MainComponent.getInstance().showError(
              'ATENCION',
              'Ha_ocurrido_un_error',
              2000
            )
          }
        )
      }
    });
  }

  onExportar() {
    const json = JSON.parse(JSON.stringify(this.gridRangos.getrows()));
    json.forEach(element => {
      delete element.uid;
      delete element.uniqueid;
      delete element.visibleindex;
      delete element.boundindex;
    });
    const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(json);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Hoja1');
    xlsx.writeFile(wb, DateUtils.formatDateAMDhms(new Date()) + '_' + AppComponent.translate('Rangos') + '.xlsx');
  }

  /* VENTANA CERRADURAS */
  @ViewChild('winCerraduras') winCerraduras: jqxWindowComponent;

  dataAdapterCerraduras: any;
  columnsCerraduras: any[] = [];

  openCerraduras(rango: RangoModel) {
    this.winCerraduras.open();

    this.winCerraduras.title(rango.nombre);

    this.columnsCerraduras = [
      { text: AppComponent.translate('Ns_movisat'), datafield: 'nsMovisat', width: 300, align: 'center', cellsalign: 'left' },
    ];

    let sourceCerraduras = {
      datatype: 'json',
      datafields: [
        { name: 'nsMovisat', type: 'string' },
      ],
      localdata: rango.cerraduras
    };

    this.dataAdapterCerraduras = new jqx.dataAdapter(sourceCerraduras);
  }

  cerrarCerraduras() {
    this.winCerraduras.close();
  }
}
