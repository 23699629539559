<div id="cabecera">
  <div>
    <jqxButton *ngIf="exportar" class="button" id="btnJqx_export"
      [style]="'float: left; cursor: pointer !important; display: flex; align-items:center'" [width]='25' [height]='25'
      (onClick)="onExportar()" [title]="translate('Exportar')">
      <i class="fa fa-file-excel fa-lg"></i>
    </jqxButton>

    <jqxButton *ngIf="imprimir" class="button"
      style="float: left; margin-left: 5px; cursor: pointer; justify-content: center; align-items: center; display: flex;"
      [width]='25' [height]='25' [title]="translate('Imprimir')" (onClick)="onPrint()">
      <i class="fa fa-print fa-lg"></i>
    </jqxButton>

    <div *ngIf="buscador" style="float: left; margin-left: 15px; display: flex; align-items: center;">
      <div
        style="float: left; background-color: white; background-image: url(../assets/images/search.png);
        background-repeat: no-repeat; background-position: 4px center; background-size: 18px; display: flex; align-items: center; width: 206px;
        margin-top: 2px; padding-top: 2px; padding-left: 28px; height: 25px; border: 1px solid rgba(0, 0, 0, 0.5); border-radius: 3px; overflow: hidden;">
        <input #searchInput type="text" style="border: 0;width: 100%; outline: none;"
          placeholder="{{ translate('Buscar') }}..." autocorrect="off" autocapitalize="off" spellcheck="off"
          (keyup)="onBuscar()">
      </div>
    </div>
  </div>

  <div *ngIf="periodo" style="display: flex; align-items: center;">
    <span style="float:left; margin-right: 5px;margin-left: 10px;">{{translate('Periodo')}}</span>
    <app-periodo-select #periodoSelect [noDia]="noDia" [noSemana]="noSemana" [noMes]="noMes" [noTrimestre]="noTrimestre"
      [noSemestre]="noSemestre" [noAnno]="noAnno" [noLibre]="noLibre" [height]="height"
      [periodoAnterior]="periodoAnterior">
    </app-periodo-select>
  </div>

  <div *ngIf="checkMetros" style="float: left; margin-left: 15px; display: flex; align-items: center;">
    <div style="display: flex;align-items: center;margin-left: 5px;">
      <jqxCheckBox #checkMasXmetros (onChange)="changeMasXmetros($event)" [theme]="theme"
        style="margin-left: 10px; float: left;" [height]="20">
      </jqxCheckBox>
      <span style="margin-left: 4px;">{{translate('Lecturas_alejadas')}}</span>
      <jqxNumberInput #currencyInput [theme]="theme" [width]="50" [height]="25" [spinButtons]="true"
        [inputMode]="'simple'" [decimalDigits]="0" [style]="'margin-left: 4px'">
      </jqxNumberInput>
      <span style="margin-left: 4px;">{{translate('Metros')}}</span>
    </div>

    <div style="display: flex; align-items: center; margin-left: 5px;">
      <jqxCheckBox #checkIdentificaciones (onChange)="changeMasXmetros($event)" [theme]="theme"
        style="margin-left: 10px; float: left;" [width]="20" [height]="20" [hidden]="true">
      </jqxCheckBox> <span hidden="true" style="margin-left: 4px;">Con identificaciones</span>
    </div>
  </div>


  <div *ngIf="seeFilterMetros" style="float: left; margin-left: 15px; display: flex; align-items: center;">
    <span style="margin-left: 4px;">{{translate('Metros')}}</span>
    <jqxNumberInput #metrosInput [theme]="theme" [width]="100" [height]="25" [spinButtons]="true"
      [inputMode]="'simple'" [decimalDigits]="0" [style]="'margin-left: 4px'" (onChange)="changeMetros($event)">
    </jqxNumberInput>
  </div>

  <div *ngIf="seeCheckAlarms" style="float: left; margin-left: 15px; display: flex; align-items: center;">
    <div style="display: flex;align-items: center;margin-left: 5px;">
      <span style="margin-left: 4px;">{{translate('Alarmas_no_configuradas')}}</span>
      <jqxCheckBox #checkAlarms (onChange)="onChangeCheckAlarms($event)" [theme]="theme"
        style="margin-left: 10px; float: left;" [height]="20">
      </jqxCheckBox>
    </div>
  </div>

  <div *ngIf="seeDropModoTarj" style="float: left; margin-left: 15px; display: flex; align-items: center;">
    <div style="display: flex;align-items: center;margin-left: 5px;">
      <div style="float: left; margin-right: 5px;">{{translate('Modo')}}</div>
      <jqxDropDownList #dropModoTarjeta [source]="sourceModoTarjetas" (onBindingComplete)="onLoadModoTarjeta()"
        (onChange)="changeModoTarjeta($event)" style="float: left" [width]="110" [dropDownHeight]="30*sourceModoTarjetas?.length">
      </jqxDropDownList>
    </div>
  </div>

  <div *ngIf="lopdCiudadanos" style="float: left; margin-left: 15px; display: flex; align-items: center;">
    <jqxDropDownList #listRoles [theme]="environment.tema" [width]="200" [height]="25"
      [searchMode]="'containsignorecase'" [filterPlaceHolder]="'Buscar...'" [displayMember]="'Nombre'"
      [filterable]="true" [source]="adapterRoles" [valueMember]="'Id'" [dropDownHeight]="150" [checkboxes]="true"
      [selectionRenderer]="selectionRolRenderer" [disabled]="false" (onCheckChange)="onCheckRol($event)">
    </jqxDropDownList>
  </div>

  <div *ngIf="activeAppFilter" style="float: left; margin-left: 15px; display: flex; align-items: center;">
    <jqxDropDownList #listApps [theme]="environment.tema" [width]="200" [height]="25"
      [searchMode]="'containsignorecase'" [filterPlaceHolder]="'Buscar...'" [displayMember]="'Nombre'"
      [filterable]="true" [source]="adapterApps" [valueMember]="'Id'" [dropDownHeight]="150" [checkboxes]="true"
      [selectionRenderer]="selectionAppRenderer" [disabled]="false" (onCheckChange)="onCheckApps($event)">
    </jqxDropDownList>
  </div>

  <div *ngIf="informesSso" style="float: left; margin-left: 15px; display: flex; align-items: center;">
    <jqxInput #searchInforme [placeHolder]="'Busqueda por Acción'" [height]='25' (onChange)="onSearchInformes($event)">
    </jqxInput>
  </div>

  <div *ngIf="seeCheckTarjVirtual" style="float: left; margin-left: 15px; display: flex; align-items: center;">
    <div style="display: flex;align-items: center;margin-left: 5px;">
      <div style="float: left; margin-right: 5px;">{{translate('Modo')}}</div>
      <jqxDropDownList #dropTipoTarjeta [source]="sourceTipoTarjetas" (onBindingComplete)="onLoadTipoTarjeta()"
        (onChange)="changeTipoTarjeta($event)" style="float: left" [width]="130"
        [dropDownHeight]="30*sourceTipoTarjetas.length">
      </jqxDropDownList>
    </div>
  </div>

  <div *ngIf="seeDropTipoTarj" style="float: left; margin-left: 15px; display: flex; align-items: center;">
    <div style="display: flex;align-items: center;margin-left: 5px;">
      <div style="float: left;">{{translate('Tipo')}}</div>
      <jqxDropDownList #dropTipoTarjeta [source]="sourceTipoTarjetas" (onBindingComplete)="onLoadTipoTarjeta()"
        (onChange)="changeTipoTarjeta($event)" style="float: left" [width]="150" [dropDownHeight]="30 * sourceTipoTarjetas?.length">
      </jqxDropDownList>
    </div>
  </div>

  <div *ngIf="seeZonas" style="float: left; margin-left: 15px; display: flex; align-items: center;">
    <div style="float: left;">{{translate('Zonas')}}</div>
    <jqxDropDownList #dropZonas [source]="sourceZonas" (onChange)="changeZona($event)" style="float: left"
      [displayMember]="'Nombre'" [valueMember]="'Nombre'" [width]="250" [filterable]="true"
      [filterPlaceHolder]="translate('Buscar')" [dropDownHeight]="sourceZonas?.length < 10 ? (30 * sourceZonas?.length) : 300">
    </jqxDropDownList>
  </div>

  <div *ngIf="seeNumTarjeta" style="float: left; margin-left: 15px; display: flex; align-items: center;">
    <div style="display: flex;align-items: center;margin-left: 5px;">
      <span style="margin-left: 4px;">{{translate('Numero')}} {{translate('Tarjeta').toLowerCase()}}</span>
      <jqxNumberInput #numeroTarjeta [theme]="theme" [width]="70" [height]="25" [spinButtons]="true"
        [inputMode]="'simple'" [decimalDigits]="0" [style]="'margin-left: 4px'" (onChange)="onChangeNumeroTarjeta($event)">
      </jqxNumberInput>
    </div>
  </div>

  <div *ngIf="subFlota" style="float: right; display: contents">
    <span style="margin: auto;">{{translate('Subflota')}}</span>
    <div style="width: calc(100% - 80px); margin: auto;">
      <jqxDropDownList #selectSubflotas style="float:left; margin-left: 4px;" [width]="'100%'" [height]="24"
        [selectedIndex]="0" (onSelect)="onChangeSubflotas($event)"
        [dropDownHeight]="33*selectSubflotas?.getItems()?.length">
      </jqxDropDownList>
    </div>
  </div>

  <div *ngIf="volumetricos" style="float: left; margin-left: 15px; display: flex; align-items: center;">
    <div style="float: left;">{{translate('Volumetricos')}}</div>
    <jqxDropDownList #cbVolumetricos style="float:left; margin-left: 4px;" [width]="230" [height]="24"
      [selectedIndex]="0">
    </jqxDropDownList>
  </div>

  <div *ngIf="listadoEstados" style="float: left; margin-left: 15px; display: flex; align-items: center;">
    <span style="float:left; margin-right: 5px">{{translate('Estado')}}</span>
    <jqxDropDownList #listEstados [theme]="environment.tema" [width]="200" [height]="25" [checkboxes]="true"
      [displayMember]="'Nombre'" [source]="adapterEstados" [valueMember]="'Id'" [autoDropDownHeight]="true"
      [disabled]="false" (onBindingComplete)="onLoadList()" (onCheckChange)="onCheckEstados($event)">
    </jqxDropDownList>
  </div>

  <div>
    <jqxButton *ngIf="filtro" class="button"
      style="float:left; margin-left: 5px; cursor: pointer; justify-content: center; align-items: center; display: flex;"
      [width]='25' [height]='25' [title]="translate('Filtrar')" (onClick)="onFilter()">
      <img src="/assets/images/filter.png" style="height: 20px; width: 20px;">
    </jqxButton>

    <jqxButton *ngIf="resetFiltro" class="button"
      style="float:left; margin-left: 5px; cursor: pointer; justify-content: center; align-items: center; display: flex;"
      [width]='25' [height]='25' [title]="translate('Restablecer_filtros')" (onClick)="onResetFilter()">
      <img src="/assets/images/filter_cancel.png" style="height: 20px; width: 20px;">
    </jqxButton>
  </div>
</div>
