<div class="loader-container" *ngIf="showLoader">
  <div class="loader"></div>
</div>

<jqxWindow #form [jqxWindowAutoResize] [width]="mapWidth" [height]="mapHeight - 24" [zIndex]="900" [isModal]="false" [position]="getFormPos(form, 24)"
  [showCloseButton]="true" [showCollapseButton]="true" [resizable]="true" [title]="translate('Edicion_elementos') + ':' + elemEdit?.Nombre"
  (onClose)="onClose()" (onOpen)="onOpen()" (onCollapse)="onCollapse($event)" (onExpand)="onExpand($event)">

  <div class="formHeader" style="overflow-x: hidden">
    {{ translate("Edicion_elementos") }}
  </div>

  <div class="form" style="float: left; overflow-x: hidden;">
    <jqxRibbon #elemRibbon [theme]="environment.tema" [position]="'top'" (onChange)="changeTab($event)">
      <ul style="width: 100%; text-align: left">
        <li>
          {{ translate("Informacion") }}
        </li>
        <li>
          {{ translate("Detalle") }}
        </li>
        <li>
          {{ translate("Perifericos_IOT") }}
        </li>
        <li>
          {{ translate("Instalaciones") }}
        </li>
        <li>
          {{ translate("Gestion") }}
        </li>
        <li>
          {{ translate("Historico_coordenadas") }}
        </li>
      </ul>

      <div style="height: calc(100% - 55px)">
        <div class="row tab"
          style="background-color: transparent; display: flex; padding: 0%; overflow-y: hidden !important;">
          <app-elements-edit-informacion #tabInformacion [form]="form" [elemEdit]="elemEdit" [crearMultiples]="crearMultiples"
            [map]="map" [expanded]="expanded" [closed]="closed"
            (catEquipSelecChange)="catEquipSelec = $event">
          </app-elements-edit-informacion>
        </div>

        <div class="row tab" style="background-color: transparent; padding: 0%; overflow-y: hidden !important;">
          <app-elements-edit-detalle #tabDetalle [elemEdit]="elemEdit" [expanded]="expanded" [closed]="closed">
          </app-elements-edit-detalle>
        </div>

        <div style="overflow: hidden; background-color: transparent;">
          <app-elements-edit-perifericos #tabPeriferico [elemEdit]="elemEdit" [expanded]="expanded" [closed]="closed">
          </app-elements-edit-perifericos>
        </div>

        <div class="row tab" style="overflow: hidden; background-color: transparent">
          <app-elements-edit-instalaciones #tabInstalacion [elemEdit]="elemEdit">
          </app-elements-edit-instalaciones>
        </div>

        <div style="overflow: hidden; background-color: transparent">
          <app-elements-edit-gestion #tabGestion [elemEdit]="elemEdit" [expanded]="expanded" [closed]="closed">
          </app-elements-edit-gestion>
        </div>

        <div style="overflow: hidden; background-color: transparent">
          <app-elements-edit-historico-coordenadas #tabHistoricoCoordenadas [elemEdit]="elemEdit" [form]="form" [map]="map">
          </app-elements-edit-historico-coordenadas>
        </div>
      </div>
    </jqxRibbon>

    <div style="
      position: absolute;
      bottom: 5px;
      left: 0px;
      margin-top: 23px;
      clear: both;
      display: flex;
      gap: 4px">
      <jqxButton *ngIf="!crearMultiples" style="float: left; margin-left: 5px" [width]="100" [height]="26"
        [textImageRelation]="'imageBeforeText'" [textPosition]="'left'" [imgSrc]="'/assets/images/save.png'"
        (onClick)="onGuardar($event)" [value]="translate('Guardar')" [disabled]="!canEdit">
      </jqxButton>

      <jqxButton *ngIf="elemEdit?.Id == 0; else elem_existe_tmpl" style="float: left; margin-left: 5px" [width]="100"
        [height]="26" [textImageRelation]="'imageBeforeText'" [textPosition]="'left'"
        [imgSrc]="'/assets/images/posicion.png'" [value]="translate('Posicionar')" (onClick)="onPosicionar()"
        [hidden]="!canEdit">
      </jqxButton>

      <ng-template #elem_existe_tmpl>
        <jqxButton [width]="100" [height]="26" [textImageRelation]="'imageBeforeText'" [textPosition]="'left'"
          [imgSrc]="'/assets/images/localizar.png'" [value]="translate('Centrar')" (onClick)="onPosicionar()"
          [hidden]="!canEdit">
        </jqxButton>
      </ng-template>

      <jqxButton style="float: left; margin-left: 5px" [width]="100" [height]="26"
        [textImageRelation]="'imageBeforeText'" [textPosition]="'left'" (onClick)="closeWindow()"
        [value]="translate('CANCELAR')">
      </jqxButton>

      <div *ngIf="elemEdit?.Id==0" style="margin-left: 500px; margin-top: 10px">
        <input [(ngModel)]="crearMultiples" type="checkbox" [disabled]="!canEdit"
          (change)="onCreacionMultiple($event)" />
        {{translate('Creacion_multiple')}}
      </div>
    </div>
  </div>
</jqxWindow>

<div style="display: hidden;">
  <button id="btnExpandElemEdit{{elemEdit?.Id}}" (click)="expandForm()"></button>
</div>
