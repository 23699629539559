<jqxWindow #form [theme]="environment.tema" [width]="382" [showCloseButton]="true" [height]="400" [resizable]="false"
    [zIndex]="90" (onClose)="onClose()">
    <div class="formHeader" style="overflow-x: hidden;">
        {{translate('Configuracion')}}
    </div>
    <div style="float:left; overflow-x: hidden;" class="form">
        <jqxLoader #loader [imagePosition]="'top'" [width]="100" [height]="60" [text]="translate('Procesando')">
        </jqxLoader>
        <jqxRibbon style="overflow: hidden;" [theme]="environment.tema" [width]="'100%'" [height]="'324px'"
            [position]="'top'">
            <ul style="width: 100%; text-align: left;">
                <li id="tabSensores" style="height: 26px; vertical-align: middle;" (click)="onClickTabSensores()">
                    <span style="float: left;"><img width="20" height="20" src="assets/images/sensor.png">
                        {{translate('Sensores')}}
                    </span>
                </li>
                <li id="tabParadas" style="height: 26px; vertical-align: middle;" (click)="onClickTabParadas()">
                    <span style="float: left;"><img width="20" height="20" src="assets/images/reproductor/parada.png">
                        {{translate('Paradas')}}
                    </span>
                </li>
                <li id="tabColores" style="height: 26px; vertical-align: middle;" (click)="onClickTabColores()">
                    <span style="float: left;"><img width="20" height="20" src="assets/images/colores.png">
                        {{translate('Colores')}}
                    </span>
                </li>
            </ul>
            <div>
                <div style="overflow: hidden;" class="form">
                    <jqxGrid #grid style="margin-top: 5px;" [theme]="environment.tema" [width]="'100%'" [height]="'99%'"
                        [appCellClick] [source]="dataAdapter" [columnsresize]="true" [filterable]="true"
                        [showfilterrow]="false" [filtermode]="'excel'" [selectionmode]="'checkbox'" [columns]="columns"
                        [sortable]="true" [altrows]="true" [showrowlines]="false" [filterrowheight]="30"
                        [rowsheight]="20" [columnsheight]="20" [enabletooltips]="true" [showaggregates]="true"
                        [enablebrowserselection]="true" [showstatusbar]="true" [statusbarheight]="20"
                        [localization]="langGrid" [filter]="filter" [updatefilterconditions]="updatefilterconditions"
                        (onBindingcomplete)="onBindingComplete()">
                    </jqxGrid>
                </div>
                <div class="form">
                    <div style="float:left; margin-left: 4px; margin-top: 10px; clear:both;">
                        {{translate('Tiempo_parada_superior')}}
                        <jqxDateTimeInput #dtParada [(ngModel)]="tiempoParada" [width]="95" [height]="22"
                            [dropDownVerticalAlignment]="'top'" [formatString]="'HH:mm:ss'" [showTimeButton]="true"
                            [showCalendarButton]="false" [disabled]="!canEdit">
                        </jqxDateTimeInput>
                    </div>
                </div>
                <div class="form">
                    <div style="float:left; margin-left: 4px; margin-top: 10px; clear:both;">
                        Criterio<br>
                        <jqxDropDownList #cbCriterio style="float: left; clear: both;" [width]='234' [height]='20'
                            (onSelect)="onSelecCriterio($event)">
                        </jqxDropDownList>
                        <jqxDropDownButton #cbColorFijo style="float: left; margin-left: 16px;" [width]='100'
                            [height]='20' [hidden]="config.criterio!=='COLOR_FIJO'">
                            <jqxColorPicker (onColorchange)="colorFijoChange($event)" [width]="220" [height]="220">
                            </jqxColorPicker>
                        </jqxDropDownButton>
                        <div style="float: left; clear: both; margin-top: 10px;"
                            [hidden]="config.criterio!=='X_VELOCIDAD'">
                            <jqxDropDownButton #cbColorVel1 style="float: left;" [width]='114' [height]='20'
                                (onOpen)="closeCbColor($event, 1)">
                                <div style="float: left; width: 33%;">
                                    <jqxColorPicker (onColorchange)="colorVel1Change($event)" [width]="120"
                                        [height]="220">
                                    </jqxColorPicker>
                                </div>
                            </jqxDropDownButton>
                            <jqxDropDownButton #cbColorVel2 style="float: left; margin-left: 5px;" [width]='114'
                                [height]='20' (onOpen)="closeCbColor($event, 2)">
                                <div style="float: left; width: 33%;">
                                    <jqxColorPicker (onColorchange)="colorVel2Change($event)" [width]="120"
                                        [height]="220">
                                    </jqxColorPicker>
                                </div>
                            </jqxDropDownButton>
                            <jqxDropDownButton #cbColorVel3 style="float: left; margin-left: 5px;" [width]='114'
                                [height]='20' (onOpen)="closeCbColor($event, 3)">
                                <div style="float: left; width: 33%;">
                                    <jqxColorPicker (onColorchange)="colorVel3Change($event)" [width]="120"
                                        [height]="220">
                                    </jqxColorPicker>
                                </div>
                            </jqxDropDownButton>
                        </div>
                        <div style="float: left; clear: both; margin-top: 10px; width: 100%;"
                            [hidden]="config.criterio!=='X_DIAS_SEMANA'">
                            <div style="float: left;">
                                <jqxDropDownButton #cbColorLu style="float: left;" [width]='114' [height]='20'>
                                    <div style="float: left; width: 33%;">
                                        <jqxColorPicker (onColorchange)="colorLuChange($event)" [width]="120"
                                            [height]="220">
                                        </jqxColorPicker>
                                    </div>
                                </jqxDropDownButton>
                                <jqxDropDownButton #cbColorMa style="float: left; margin-left: 5px;" [width]='114'
                                    [height]='20'>
                                    <div style="float: left; width: 33%;">
                                        <jqxColorPicker (onColorchange)="colorMaChange($event)" [width]="120"
                                            [height]="220">
                                        </jqxColorPicker>
                                    </div>
                                </jqxDropDownButton>
                                <jqxDropDownButton #cbColorMi style="float: left; margin-left: 5px;" [width]='114'
                                    [height]='20'>
                                    <div style="float: left; width: 33%;">
                                        <jqxColorPicker (onColorchange)="colorMiChange($event)" [width]="120"
                                            [height]="220">
                                        </jqxColorPicker>
                                    </div>
                                </jqxDropDownButton>
                            </div>
                            <div style="float: left; margin-top: 10px; clear: both; width: 100%;">
                                <jqxDropDownButton #cbColorJu style="float: left;" [width]='114' [height]='20'>
                                    <div style="float: left; width: 33%;">
                                        <jqxColorPicker (onColorchange)="colorJuChange($event)" [width]="120"
                                            [height]="220">
                                        </jqxColorPicker>
                                    </div>
                                </jqxDropDownButton>
                                <jqxDropDownButton #cbColorVi style="float: left; margin-left: 5px;" [width]='114'
                                    [height]='20'>
                                    <div style="float: left; width: 33%;">
                                        <jqxColorPicker (onColorchange)="colorViChange($event)" [width]="120"
                                            [height]="220">
                                        </jqxColorPicker>
                                    </div>
                                </jqxDropDownButton>
                                <jqxDropDownButton #cbColorSa style="float: left; margin-left: 5px;" [width]='114'
                                    [height]='20'>
                                    <div style="float: left; width: 33%;">
                                        <jqxColorPicker (onColorchange)="colorSaChange($event)" [width]="120"
                                            [height]="220">
                                        </jqxColorPicker>
                                    </div>
                                </jqxDropDownButton>
                            </div>
                            <div style="float: left; margin-top: 10px; clear: both; width: 100%;">
                                <jqxDropDownButton #cbColorDo style="float: left;" [width]='114' [height]='20'>
                                    <div style="float: left; width: 33%;">
                                        <jqxColorPicker (onColorchange)="colorDoChange($event)" [width]="120"
                                            [height]="220">
                                        </jqxColorPicker>
                                    </div>
                                </jqxDropDownButton>
                            </div>
                        </div>
                        <div style="float: left; clear: both; margin-top: 10px; width: 100%;"
                            [hidden]="config.criterio!=='X_SENSOR'">
                            <jqxDropDownList #cbSensor style="float: left; clear: both;" [width]="360" [height]='20'
                                (onSelect)="onSelecSensor($event)">
                            </jqxDropDownList>
                            <jqxDropDownButton #cbColorSensor1 style="float: left; margin-top: 10px;" [width]='114'
                                [height]='20'>
                                <div style="float: left; width: 33%;">
                                    <jqxColorPicker (onColorchange)="colorSensor1Change($event)" [width]="120"
                                        [height]="220">
                                    </jqxColorPicker>
                                </div>
                            </jqxDropDownButton>
                            <jqxDropDownButton #cbColorSensor0 style="float: left; margin-left: 5px; margin-top: 10px;"
                                [width]='114' [height]='20'>
                                <div style="float: left; width: 33%;">
                                    <jqxColorPicker (onColorchange)="colorSensor0Change($event)" [width]="120"
                                        [height]="220">
                                    </jqxColorPicker>
                                </div>
                            </jqxDropDownButton>
                            <jqxDropDownButton #cbColorSensorX style="float: left; margin-left: 5px; margin-top: 10px;"
                                [width]='122' [height]='20'>
                                <div style="float: left; width: 33%;">
                                    <jqxColorPicker (onColorchange)="colorSensorXChange($event)" [width]="120"
                                        [height]="220">
                                    </jqxColorPicker>
                                </div>
                            </jqxDropDownButton>
                        </div>
                    </div>
                </div>
            </div>
        </jqxRibbon>
        <div style="position:absolute; bottom:5px; left:5px; margin-top: 15px; clear: both;">
            <jqxButton #btnGuardar style="float:left;" [width]='100' [height]='26'
                [textImageRelation]="'imageBeforeText'" [textPosition]="'center'" [imgSrc]="'/assets/images/save.png'"
                (onClick)="onGuardarClick()" [value]="translate('Guardar')" [disabled]="!canEdit">
            </jqxButton>
        </div>
    </div>
</jqxWindow>
