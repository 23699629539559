import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CiudadanosComponent } from './ciudadanos.component';
import { CiudadanosEditComponent } from './ciudadanos-edit/ciudadanos-edit.component';
import { CiudadanosTarjetasComponent } from './ciudadanos-tarjetas/ciudadanos-tarjetas.component';
import { DireccionListadoComponent } from './direccion/direccion-listado/direccion-listado.component';
import { CiudadanosDireccionEditComponent } from './ciudadanos-direccion-edit/ciudadanos-direccion-edit.component';
import { CiudadanosListadoTarjetasComponent } from './ciudadanos-listado-tarjetas/ciudadanos-listado-tarjetas.component';
import { SelectorCatastroComponent } from './direccion/selector-catastro/selector-catastro.component';
import { MapModule } from 'movisat-maps';
import { DireccionDetallesComponent } from './direccion/direccion-listado/direccion-detalles/direccion-detalles.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { LopdCiudadanosComponent } from './lopd-ciudadanos/lopd-ciudadanos.component';

@NgModule({
  declarations: [
    CiudadanosComponent,
    CiudadanosEditComponent,
    DireccionListadoComponent,
    CiudadanosTarjetasComponent,
    CiudadanosDireccionEditComponent,
    CiudadanosListadoTarjetasComponent,
    SelectorCatastroComponent,
    DireccionDetallesComponent,
    LopdCiudadanosComponent,
  ],
  imports: [
    MapModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    SharedModule
  ],
  exports: [
    CiudadanosComponent
  ]
})
export class CiudadanosModule { }
