import {
  AfterViewInit, Component, EventEmitter, OnDestroy, OnInit,
  Output,
  ViewChild, ViewContainerRef
} from '@angular/core';

import { Subscription } from 'rxjs';
import { DateUtils } from 'src/app/utils/date-utils';
import { AppComponent } from 'src/app/app.component';
import { MainComponent } from '../../main/main.component';
import { environment } from 'src/environments/environment';

import { MapComponent, MapMarker } from 'movisat-maps';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';

import { SsoService } from 'src/app/services/sso/sso.service';
import { ConfigService } from 'src/app/services/config/config.service';
import { PositionService } from 'src/app/services/positions/position.service';
import { ResourcesService } from 'src/app/services/resources/resources.service';

import { MovilModel } from 'src/app/services/resources/models/movil.model';
import { PosicionModel } from 'src/app/services/positions/models/posicion.model';
import { MovilesInterface } from 'src/app/services/resources/interfaces/moviles.interface';
import { PosicionesInterface } from 'src/app/services/positions/interfaces/posiciones.interface';

import { TracksComponent } from '../../tracks/tracks.component';
import { SubflotasComponent } from '../../flota/subflotas/subflotas.component';
import { ItinerariComponent } from '../../maps-tools/itinerari/itinerari.component';
import { MovilesCercaComponent } from '../../flota/moviles-cerca/moviles-cerca.component';
import * as xlsx from 'xlsx';
import { Utils } from 'src/app/utils/utils';

@Component({
  selector: 'app-moviles',
  templateUrl: './moviles.component.html',
  styleUrls: ['./moviles.component.css']
})

export class MovilesComponent implements OnInit, AfterViewInit, OnDestroy, MovilesInterface, PosicionesInterface {
  @ViewChild('movilesContainer', { read: ViewContainerRef }) movilesContainer;
  @ViewChild('gridMovil') gridMovil: jqxGridComponent;

  // Disparadores para notificar eventos
  @Output() navegarMovilEmiter: EventEmitter<MovilModel> = new EventEmitter();

  private static instance: MovilesComponent;
  public environment = environment;
  private movilesIdx = new Map<number, number>();
  public movilesList: MovilModel[] = [];
  private movilSelec: MovilModel = null;
  private searchText = '';
  private timerSearch = null;
  private timerInfoGeo = null;
  private orderBy = 'conectado,desc';
  private map: MapComponent;
  private subcriptionNewMovil: Subscription = null;
  private subcriptionChangeMovil: Subscription = null;
  private subcriptionPosicionReady: Subscription = null;
  private subcriptionNewPosicion: Subscription = null;
  private subcriptionChangePosicion: Subscription = null;
  private subcriptionChangeFilter: Subscription = null;
  private initialFilter: any = null;
  private distCerca = 0;
  private oldFilter: any = null;
  private dataSource = {};
  public dataAdapter = new jqx.dataAdapter(this.dataSource);
  oldSortColumn: any = null;
  oldSortDirection: any = null;
  initialSort: any = null;
  isSorting: boolean = false;

  // Pongo por defecto los textos en los controles del grid en español
  public langGrid = JqWidgets.getLocalization('es');
  columns: any[] = [];
  constructor(
    private ssoService: SsoService,
    private resourcesService: ResourcesService,
    private positionService: PositionService,
    private configService: ConfigService
  ) {
    MovilesComponent.instance = this;
  }

  async ngOnInit(): Promise<void> {
    // Cargo el idioma para los componentes jqwidgets
    this.langGrid = JqWidgets.getLocalization(this.ssoService.getTicket().Usuario.Idioma.Codigo.substring(0, 2));
    // Me subscribo para saber cuando se han cargado las posiciones de todos los móviles
    this.subcriptionPosicionReady = this.positionService.subscribeOnPositionReady(this);
    // Recupero el filtro almacenado si lo hay

    this.initGrid();
    await this.getInitFilter();
    await this.getInitSort();
  }

  ngAfterViewInit(): void {
    this.initSearchControl();
    this.initToolbarButtons();
    // Espera a que el mapa esté listo
    const interval = setInterval(async () => {
      this.map = MainComponent.getInstance().getMap();
      if (this.map) {
        clearInterval(interval);
        // Me subscribo a eventos del mapa
        this.map.subscribeOnMarkerClick(this, this.onMarkerClick);
      }
    }, 1000);
  }

  public static getInstance(): MovilesComponent {
    return MovilesComponent.instance;
  }

  initGrid() {
    this.columns = [
      {
        text: 'Id', columntype: 'textbox', filtertype: 'textbox', datafield: 'id', width: 1, hidden: true
      },
      {
        text: 'CoCheck', columntype: 'checkbox', filtertype: 'textbox', datafield: 'conectado', width: 40, hidden: true,
        // aggregates: [{
        //   'Conectados': function (aggregatedValue, currentValue: number) {
        //     return currentValue ? aggregatedValue + 1 : aggregatedValue;
        //   }
        // }],
        // aggregatesrenderer: function (aggregates) {
        //   let renderstring = '';
        //   if (aggregates["Conectados"] !== undefined) {
        //     renderstring = '<div style="text-align: center;">' + aggregates["Conectados"] + '</div>';
        //   }
        //   return renderstring;
        // }
      },
      {
        text: 'IgCheck', columntype: 'checkbox', filtertype: 'textbox', datafield: 'ignicion', width: 40, hidden: true
        // aggregates: [{
        //   'ConIgnicion': function (aggregatedValue, currentValue: number) {
        //     return currentValue ? aggregatedValue + 1 : aggregatedValue;
        //   }
        // }],
        // aggregatesrenderer: function (aggregates) {
        //   let renderstring = '';
        //   if (aggregates["ConIgnicion"] !== undefined) {
        //     renderstring += '<div style="text-align: center;">' + aggregates["ConIgnicion"];
        //   }
        //   return renderstring;
        // }
      },
      {
        text: 'Co', columntype: 'image', datafield: 'imagenCon', width: 25, cellsrenderer: this.imageRendererConnectado,
        aggregates: [{
          'Conectados': function (aggregatedValue: any, currentValue: any, column: number, record: any) {
            return record.conectado > 0 ? aggregatedValue + 1 : aggregatedValue;
          }
        }],
        aggregatesrenderer: function (aggregates) {
          let renderstring = '';
          if (aggregates["Conectados"] !== undefined) {
            renderstring = '<div style="text-align: center;">' + aggregates["Conectados"] + '</div>';
          }
          return renderstring;
        }
      },
      {
        text: 'Ig', columntype: 'image', datafield: 'imagenIgni', width: 25, cellsrenderer: this.imageRendererIgnicion,
        aggregates: [{
          'ConIgnicion': function (aggregatedValue: any, currentValue: any, column: number, record: any) {
            return record.ignicion > 0 ? aggregatedValue + 1 : aggregatedValue;
          }
        }],
        aggregatesrenderer: function (aggregates) {
          let renderstring = '';
          if (aggregates["ConIgnicion"] !== undefined) {
            renderstring += '<div style="text-align: center;">' + aggregates["ConIgnicion"];
          }
          return renderstring;
        }
      },
      { text: 'Cod', columntype: 'textbox', filtertype: 'textbox', datafield: 'codigo', width: 45, align: 'center', cellsalign: 'center', hidden: true },
      {
        text: AppComponent.translate('Descripcion'), columntype: 'textbox', filtertype: 'textbox', datafield: 'nombre', width: 120,
        aggregates: [{
          'Total': function (aggregatedValue: any, currentValue: number) {
            return currentValue ? aggregatedValue + 1 : aggregatedValue;
          }
        }],
        aggregatesrenderer: function (aggregates) {
          let renderstring = '';
          if (aggregates["Total"] !== undefined) {
            renderstring = '<div style="text-align: center;">' + aggregates["Total"] + '</div>';
          }
          return renderstring;
        }
      },
      { text: AppComponent.translate('Subflota'), columntype: 'textbox', filtertype: 'checkedlist', datafield: 'subflota', width: 80 },
      { text: AppComponent.translate('Tipo'), columntype: 'textbox', filtertype: 'checkedlist', datafield: 'tipo', width: 35 },
      { text: AppComponent.translate('Recurso'), columntype: 'textbox', filtertype: 'checkedlist', datafield: 'recurso', width: 80 },
      { text: AppComponent.translate('Clase'), columntype: 'textbox', filtertype: 'textbox', datafield: 'clase', width: 80 },
      { text: AppComponent.translate('Vel'), columntype: 'textbox', filtertype: 'textbox', datafield: 'velocidad', width: 40, align: 'center', cellsalign: 'center' },
      { text: AppComponent.translate('Desde'), columntype: 'datetimeinput', filtertype: 'date', datafield: 'fecha', width: 140, cellsformat: 'dd/MM/yyyy HH:mm:ss' },
      { text: AppComponent.translate('Informacion_geografica'), columntype: 'textbox', filtertype: 'textbox', datafield: 'infoGeo', width: 300 },
      {
        text: AppComponent.translate('Distancia'), columntype: 'textbox', filtertype: 'textbox', datafield: 'distancia', width: 100,
        hidden: true, align: 'center', cellsalign: 'center',
        aggregates: [{
          'Cerca': function (aggregatedValue: any, currentValue: number, column: number, record: any) {
            let val = undefined;
            if (record['distancia']) {
              const v = record['distancia'].replace(',', '.').replace('km', '').replace('m', '').replace(' ', '');
              val = record['distancia'].indexOf('km') > -1 ? Number.parseFloat(v) * 1000 : Number.parseFloat(v);
            }
            return val <= MovilesComponent.getInstance().distCerca ? aggregatedValue + 1 : aggregatedValue;
          }
        },
        {
          'Lejos': function (aggregatedValue: any, currentValue: number, column: number, record: any) {
            let val = undefined;
            if (record['distancia']) {
              const v = record['distancia'].replace(',', '.').replace('km', '').replace('m', '').replace(' ', '');
              val = record['distancia'].indexOf('km') > -1 ? Number.parseFloat(v) * 1000 : Number.parseFloat(v);
            }
            return val > MovilesComponent.getInstance().distCerca ? aggregatedValue + 1 : aggregatedValue;
          }
        }],
        aggregatesrenderer: function (aggregates) {
          let renderstring = '';
          if (aggregates["Cerca"] !== undefined) {
            renderstring += '<div style="text-align: center;">' + aggregates["Cerca"] + '/' + aggregates["Lejos"] + '</div>';
          }
          return renderstring;
        },
        cellsrenderer: (row: number, columnfield: string, value: any, defaulthtml: string, columnproperties: any, rowdata: any) => {
          if (value) {
            const v = value.replace(',', '.').replace('km', '').replace('m', '').replace(' ', '');
            const val = value.indexOf('km') > -1 ? Number.parseFloat(v) * 1000 : Number.parseFloat(v);
            if (val <= this.distCerca) {
              return '<div style="text-align: center; margin-top: 4px; background-color: #93fc93;">' + value + '</div>';
            }
          }
          return '<div style="text-align: center; margin-top: 4px; background-color: #F96a6a;">' + value + '</div>';
        },
        rendergridrows: (params => {
          const data = generatedata(params.startindex, params.endindex);
          return "";
        }),
      },
      {
        text: AppComponent.translate('Navegar'), datafield: 'navegar', columntype: 'button', width: 70, hidden: true,
        cellsrenderer: (row: number, columnfield: string, value: any, defaulthtml: string, columnproperties: any, rowdata: any): string => {
          return '>';
        },
        buttonclick: (row: number): void => {
          const dataRecord: any = this.gridMovil.getrowdata(row);
          const movil = this.movilesList[this.movilesIdx.get(dataRecord.codigo)];
          // Notifico que quiero navegar desde este móvil a algún otro sitio
          this.navegarMovilEmiter.emit(movil);
        }
      },
      { text: 'Selec', columntype: 'textbox', filtertype: 'textbox', datafield: 'selec', hidden: true, width: 1 }
    ];
  }

  // Para traducir los textos del template
  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  imageRendererConnectado(row: number, columnfield: string, value: any,
    defaulthtml: string, columnproperties: any, rowdata: any): string {
    if (rowdata) {
      return rowdata.conectado > 0 ? '<img style="margin-left: 4px; margin-top: 2px;" height="16" width="16" src="assets/images/conectado.png" />' :
        '<img style="margin-left: 4px; margin-top: 2px;" height="16" width="16" src="assets/images/desconectado.png" />';
    }
  }

  imageRendererIgnicion(row: number, columnfield: string, value: any,
    defaulthtml: string, columnproperties: any, rowdata: any): string {
    if (rowdata) {
      return rowdata.ignicion > 0 ? '<img style="margin-left: 4px; margin-top: 2px;" height="16" width="16" src="assets/images/ignicion1.png" />' :
        '<img style="margin-left: 4px; margin-top: 2px;" height="16" width="16" src="assets/images/ignicion0.png" />';
    }
  }

  onMarkerClick(component: any, marker: MapMarker) {
    // Compruebo que se trata de un móvil
    if (marker.dataModel.hasOwnProperty('Ignicion')) {
      // Busco el elemento en el grid y lo selecciono
      const rows = component.gridMovil.getrows();
      if (rows) {
        rows.forEach((row, i) => {
          if (row.id === marker.dataModel.MovilId) {
            component.gridMovil.ensurerowvisible(i);
            const event = {
              args: {
                rowindex: row.dataindex ? row.dataindex : row.boundindex,
                fromCarto: true
              }
            }
            component.onRowClick(event);
          }
        });
      }
    }
  }

  // Inicializa el control de búsqueda de la cabecera
  initSearchControl(): void {
    const searchControl = document.getElementById('searchControl');
    if (searchControl) {
      searchControl.addEventListener('input', (event: any) => {
        this.searchText = event.target.value.toUpperCase();
      });
      let lastSearch = '';
      // Cada segundo compruebo si se ha filtrado la información
      this.timerSearch = setInterval(() => {
        if (this.searchText !== lastSearch) {
          lastSearch = this.searchText;
          // Marco los registros que cumplen la condición de búsqueda y pongo el campo
          // oculto a "selec"
          this.movilesList.forEach(movil => {
            if (("" + movil.Codigo).indexOf(this.searchText) > -1 ||
              (movil.Nombre && movil.Nombre.toUpperCase().indexOf(this.searchText) > -1) ||
              (movil.Matricula && movil.Matricula.toUpperCase().indexOf(this.searchText) > -1) ||
              (movil.ConjuntoVehiculo && movil.ConjuntoVehiculo.Recurso && movil.ConjuntoVehiculo.Recurso.Nombre &&
                movil.ConjuntoVehiculo.Recurso.Nombre.toUpperCase().indexOf(this.searchText) > -1) ||
              (movil.Clase && movil.Clase.Nombre && movil.Clase.Nombre.toUpperCase().indexOf(this.searchText) > -1) ||
              (movil.Subflota && movil.Subflota.Nombre.toUpperCase().indexOf(this.searchText) > -1) ||
              (movil.ultimaPos && movil.ultimaPos.Velocidad && ('' + movil.ultimaPos.Velocidad).toUpperCase().
                indexOf(this.searchText) > -1) ||
              (movil.ultimaPos && movil.ultimaPos.Fecha && DateUtils.formatDateTimeShort(movil.ultimaPos.Fecha, true).
                toUpperCase().indexOf(this.searchText) > -1) ||
              (movil.ultimaPos && movil.ultimaPos.Localizacion && movil.ultimaPos.Localizacion.Label.toUpperCase().
                indexOf(this.searchText) > -1)) {
              movil.selec = 'selec';
            } else {
              movil.selec = '';
            }
          });
          // Compruebo si ya he creado el filtro "selec" anteriormente
          const filters = this.gridMovil.getfilterinformation();
          if (filters.find(s => s.datafield === 'selec') === undefined) {
            const filtergroup = new jqx.filter();
            filtergroup.addfilter(1, filtergroup.createfilter('stringfilter', 'selec', 'equal'));
            this.gridMovil.addfilter('selec', filtergroup);
          }
          this.gridMovil.applyfilters();
          this.gridMovil.updatebounddata('data');
        }
      }, 1000);
    }
  }

  // Cuando se refrescan los datos del grid
  onBindingComplete() {
    try {
      const orderParameters = this.orderBy.split(',');
      if (this.gridMovil && this.movilesList && this.movilesList.length > 0) {
        this.isSorting = true; // Antes de ordenar, marca que estás en medio de una ordenación

        // Ordenar por 'conectado' primero
        this.gridMovil.sortby(orderParameters[0], orderParameters[1]);

        // Si el usuario ha establecido un campo y una dirección de ordenación
        if (orderParameters.length > 2) {
          this.gridMovil.sortby(orderParameters[2], orderParameters[3]);
        }

        this.isSorting = false; // Una vez que la ordenación se completa, desmarca que estás en medio de una ordenación
      }

      if (this.initialFilter) {
        this.setStoredFilter();
      }
      // Si no está el reproductor de ruta abierto me aseguro que todos los móviles del
      // grid se visualizan en en la cartogafía y quito los que no están visibles
      if (!TracksComponent.instance) {
        const rowsVisibles = this.gridMovil.getrows();
        if (rowsVisibles) {
          const movilesVisibles = new Map<number, number>();
          rowsVisibles.forEach(row => {
            movilesVisibles.set(row.codigo, row.codigo);
          });
          if (this.movilesList) {
            this.movilesList.forEach(movil => {
              if (movil.marker) {
                movil.marker.setVisible(movilesVisibles.get(movil.Codigo) !== undefined);
              }
            });
          }
        }
      }

      Utils.renderSizeGrid(this.gridMovil, 500, ['navegar']);
    } catch (e) { }
  }

  // Cuando se destruye el componente borro las subscripciones y los temporizadores
  ngOnDestroy() {
    if (this.timerSearch === null) {
      clearInterval(this.timerSearch);
    }
    if (this.timerInfoGeo === null) {
      clearInterval(this.timerInfoGeo);
    }
    if (this.subcriptionNewMovil !== null) {
      this.subcriptionNewMovil.unsubscribe();
    }
    if (this.subcriptionChangeMovil !== null) {
      this.subcriptionChangeMovil.unsubscribe();
    }
    if (this.subcriptionPosicionReady !== null) {
      this.subcriptionPosicionReady.unsubscribe();
    }
    if (this.subcriptionNewPosicion !== null) {
      this.subcriptionNewPosicion.unsubscribe();
    }
    if (this.subcriptionChangePosicion !== null) {
      this.subcriptionChangePosicion.unsubscribe();
    }
    if (this.subcriptionChangeFilter !== null) {
      this.subcriptionChangeFilter.unsubscribe();
    }
  }

  // Cuando se selecciona una fila
  onRowClick(event: any) {
    const rowSel = this.gridMovil.getselectedrowindexes();
    if (rowSel && rowSel.length < 2) {
      this.gridMovil.clearselection();
      this.gridMovil.selectrow(event.args.rowindex);
    }
    this.movilSelec = this.movilesList[event.args.rowindex];
    if (this.movilSelec.marker !== undefined) {
      if (!event.args.fromCarto) {
        this.movilSelec.marker.map.setCenter(this.movilSelec.marker.position);
        this.movilSelec.marker.animate(2850);
      }
    }
  }

  // Cuando se crea un móvil nuevo
  onNewMovil(movil: MovilModel) {
    // Añado el móvil a la lista
    this.movilesIdx.set(movil.Codigo, this.movilesList.length);
    this.movilesList.push(movil);
  }

  // Cuando cambia la información de un móvil
  onChangeMovil(movil: MovilModel) {
    // Actualizo los datos del móvil en la lista
    this.movilesList[this.movilesIdx.get(movil.Codigo)] = movil;
  }

  // Cuando se han terminado de recuperar las posiciones de todos los móviles
  async onPosicionesReady() {
    // Cargo la lista de móviles y me subscribo para recibir los cambios
    await this.getMoviles();
    this.subcriptionNewMovil = this.resourcesService.subscribeNewMoviles(this);
    this.subcriptionChangeMovil = this.resourcesService.subscribeChangeMoviles(this);
    this.subcriptionChangeFilter = this.resourcesService.changeFilterEmiter.subscribe(async () => {
      await this.getMoviles();
      this.gridMovil.updatebounddata('data');
      MainComponent.getInstance().createMarkerPos();
    });
    // Me subscribo para recibir los cambios en las posiciones de los móviles
    this.subcriptionNewPosicion = this.positionService.subscribeNewPosition(this);
    this.subcriptionChangePosicion = this.positionService.subscribeChangePosition(this);
    // Actualizo el grid cada x tiempo para que se actualicen la información geográfica
    this.timerInfoGeo = setInterval(() => {
      this.gridMovil.updatebounddata('data');
    }, environment.refresInfoGeoInterval);
  }

  renderDate(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (value) {
      let date = new Date(value);
      return '<div class="jqx-grid-cell-left-align" style="margin-top: 4px; text-align: left">' + DateUtils.formatDateTimeShort(date, true) + '</div>';
    }
  }

  async getMoviles() {
    this.columns.forEach(column => {
      column.rendered = (element) => { Utils.tooltiprenderer(element) };
    });
    let filterIA: any = await this.configService.getEmp('moviles-IA', null);
    if (filterIA) {
      filterIA = JSON.parse(filterIA);
    }
    // Cargo la lista de móviles
    this.movilesList = await this.resourcesService.getMoviles();
    this.movilesList.forEach((movil, index) => {
      this.movilesIdx.set(movil.Codigo, index);
    });
    // Configuro el grid
    this.dataSource = {
      datatype: 'json',
      datafields: [
        { name: 'id', map: 'Codigo' },
        { name: 'conectado', map: 'Conectado' },
        { name: 'ignicion', map: 'Ignicion' },
        { name: 'codigo', map: 'Codigo' },
        { name: 'nombre', map: 'Nombre' },
        { name: 'subflota', map: 'Subflota>Nombre' },
        { name: 'tipo', map: 'TipoMovil>Acronimo' },
        { name: 'recurso', map: 'ConjuntoVehiculo>Recurso>Nombre' },
        { name: 'clase', map: 'Clase>Nombre' },
        { name: 'velocidad', map: 'ultimaPos>Velocidad' },
        { name: 'fecha', type: 'date', map: 'ultimaPos>Fecha' },
        { name: 'infoGeo', map: 'ultimaPos>Localizacion>Label' },
        { name: 'distancia', map: 'distancia' },
        { name: 'selec', map: 'selec' }
      ],
      localdata: this.movilesList,
    };
    this.dataAdapter = new jqx.dataAdapter(this.dataSource);
    this.gridMovil.columnmenuopening(this.columnmenuopening);
  }

  updatefilterconditions = (type: string, defaultconditions: any): string[] => {
    return Utils.updatefilterconditions(type, defaultconditions);
  };

  public filter(cellValue?: any, rowData?: any, dataField?: string, filterGroup?: any, defaultFilterResult?: boolean): any {
    let filterColumns = [
      'nombre',
      'clase',
      'infoGeo',
    ]

    return Utils.filterRow(cellValue, dataField, filterGroup, defaultFilterResult, filterColumns);
  }

  // Ajusto el menu del filtro en funcion de la posicion
  columnmenuopening = (menu: any, datafield: any, height: any): void => {
    if (menu && menu[0]) {
      let menuElement = <HTMLElement>document.getElementById(menu[0].id);
      let areaGestion = <HTMLElement>document.getElementById('gridMoviles').children[0];
      let column = MovilesComponent.instance.gridMovil.getcolumn(datafield)['element'];
      let menuTop = 0;

      /* En caso de que vaya a sobresalir por debajo de la pantalla lo ajusto hacia arriba */
      if (height > areaGestion.offsetHeight) {
        menuTop = height - areaGestion.offsetHeight + 100;

        menuElement.parentElement.style.top = '-' + menuTop + 'px';
      }

      /* Ajuste del menu para que se vea el boton de filtro en el grid */
      setTimeout(() => {
        let columnPos = column.offsetLeft + column.offsetWidth;
        let menuWidth = menuElement.offsetWidth;

        if (columnPos + menuWidth >= areaGestion.offsetWidth) {
          menuElement.parentElement.style.left = '-20px';
        } else {
          menuElement.parentElement.style.left = '20px';
        }
        /*
          Recoge los desplegables del menu del filtro
          y le añade un evento al hacer click
        */
        let dropDownFilterList = document.getElementsByClassName('jqx-dropdownlist');
        let listBox = document.getElementsByClassName('jqx-listbox-container');

        let arrListFilter: any = Array.from(dropDownFilterList);
        let arrListBox: any = Array.from(listBox);

        arrListFilter.forEach((element, idx) => {
          element.addEventListener("click", function() {
            /*
              Si el desplegable va a sobresalir le ajusta el top
              para que aparezca por encima del boton desplegable
            */
            if(document.body.offsetHeight < (arrListBox[idx].offsetTop + arrListBox[idx].offsetHeight)) {
              arrListBox[idx].style.top = (arrListBox[idx].offsetTop - (arrListBox[idx].offsetHeight + 10)) + 'px';
            }
          });
        });

        /*
          Recoge los desplegable de calendario
          y añade un evento al hacer click
        */
        let calendarButton = document.getElementsByClassName('jqx-icon-calendar');
        let listCalendar = document.getElementsByClassName('jqx-calendar-container');

        let arrcalendarButton: any = Array.from(calendarButton);
        let arrlistCalendar: any = Array.from(listCalendar);

        arrcalendarButton.forEach((btn, idx) => {
          btn.parentElement.addEventListener("click", function() {
            /*
              Si el desplegable va a sobresalir le ajusta el top
              para que aparezca por encima del boton desplegable
            */
            if(document.body.offsetHeight < (arrlistCalendar[idx].offsetTop + arrlistCalendar[idx].offsetHeight)) {
              arrlistCalendar[idx].style.top = (arrlistCalendar[idx].offsetTop - (arrlistCalendar[idx].offsetHeight + 40)) + 'px';
            }
          })
        });
      }, 50)
    }
  };

  onNewPosicion(posicion: PosicionModel) {
  }

  onChangePosicion(posicion: PosicionModel) {
  }

  // Crea los componentes de la cabecera
  createToolBar(statusbar: any) {
    if (statusbar[0] !== undefined) {
      // Añado el control de búsqueda a la cabecera
      const toolbarContainer = document.createElement('div');
      toolbarContainer.style.cssText = 'overflow: hidden; position: relative; margin-left: 4px; margin-top: 0px';
      const searchControl: any = document.createElement('div');
      searchControl.id = 'searchControl';
      searchControl.style.cssText = `
        float: left;
        background-color: white;
        background-image: url('../assets/images/search.png');
        background-repeat: no-repeat;
        background-position: 4px center;
        background-size: 18px;
        display: flex;
        align-items: center;
        width: 206px;
        margin-top: 2px;
        padding-top: 2px;
        padding-left: 28px;
        height: 25px;
        border: 1px solid rgba(0, 0, 0, 0.5);
        border-radius: 3px;
        overflow: hidden;
      `;
      searchControl.innerHTML = '<input type="text" style="border: 0;width: 100%; outline: none;" (keydown.enter)="$event.preventDefault()" ' +
        'placeholder="' + AppComponent.translate('Buscar') + '..." autocorrect = "off" autocapitalize = "off" spellcheck = "off">';
      toolbarContainer.appendChild(searchControl);
      // Creo el botón para reproducir rutas
      const btnSubflotas: any = document.createElement('div');
      btnSubflotas.id = 'btnSubflotas';
      btnSubflotas.style.cssText = 'float: left; height: 25px; width: 105px; padding-left: 3px; padding-top: 2px;';
      btnSubflotas.innerHTML = `
        <button type="submit" style="width: 100px; height: 25px; align-items: center; cursor: pointer;">
        <div style="float: right; padding-top: 2px;">`+ AppComponent.translate('Subflotas') + `</div>
        <img style="float: left; height: 18px; width: 18px;" src="../assets/images/subflota.png" /></button>
      `;
      toolbarContainer.appendChild(btnSubflotas);
      // Creo el botón para reproducir rutas
      const btnRutaControl: any = document.createElement('div');
      btnRutaControl.id = 'btnRecorrido';
      btnRutaControl.style.cssText = 'float: left; height: 25px; width: 110px; padding: 2px;';
      btnRutaControl.innerHTML = `
        <button type="submit" style="width: 110px; height: 25px; align-items: center;cursor: pointer;">
        <div style="float: right; padding-top: 2px;">`+ AppComponent.translate('Recorridos') + `</div>
        <img style="float: left; height: 18px; width: 18px;" src="../assets/images/route.png" /></button>
      `;
      toolbarContainer.appendChild(btnRutaControl);

      // Creo el botón para móviles cercanos
      const btnMovilesCerca: any = document.createElement('div');
      btnMovilesCerca.id = 'btnMovilesCerca';
      btnMovilesCerca.style.cssText = 'float: left; height: 25px; width: 75px; margin-left: 4px; padding: 2px;';
      btnMovilesCerca.innerHTML = `
        <button type="submit" style="width: 75px; height: 25px; align-items: center;cursor: pointer;">
        <div style="float: right; padding-top: 2px;">`+ AppComponent.translate('Cerca') + `</div>
        <img style="float: left; height: 18px; width: 18px;" src="../assets/images/radar.png" /></button>
      `;
      toolbarContainer.appendChild(btnMovilesCerca);
      // Creo el botón para itinerarios
      const btnItinerari: any = document.createElement('div');
      btnItinerari.id = 'btnItinerari';
      btnItinerari.style.cssText = 'float: left; height: 25px; width: 110px; margin-left: 4px; padding: 2px;';
      btnItinerari.innerHTML = `
        <button type="submit" style="width: 110px; height: 25px; align-items: center;cursor: pointer;">
        <div style="float: right; padding-top: 2px;">`+ AppComponent.translate('Itinerarios') + `</div>
        <img style="float: left; height: 18px; width: 18px;" src="../assets/images/itinerary.png" /></button>
      `;
      toolbarContainer.appendChild(btnItinerari);
      const btnImprimir: any = document.createElement('div');
      btnImprimir.id = 'btnImprimirMovil';
      btnImprimir.style.cssText = 'float: right; height: 25px;padding: 2px; margin-right: 2px;';
      btnImprimir.innerHTML = `
        <button type="submit" class='button' title='`+ AppComponent.translate('Imprimir') + `' style="width: 35px; height: 25px; align-items: center;cursor: pointer;">
        <div style="float: right; padding-top: 2px;"></div>
        <i class="fa fa-print fa-lg"></i></button>
      `;
      toolbarContainer.appendChild(btnImprimir);
      // añado el boton exportar a excel e imprimir
      const btnExportar: any = document.createElement('div');
      btnExportar.id = 'btnExportarMoviles';
      btnExportar.style.cssText = 'float: right; height: 25px;  padding: 2px; margin-left: 5px;';
      btnExportar.innerHTML = `
        <button type="submit" class='button' title='`+ AppComponent.translate('Exportar') + `' style="width: 35px; height: 25px; align-items: center; cursor: pointer;">
        <div style="float: right; padding-top: 2px;"></div>
        <i class="fa fa-file-excel fa-lg"></i></button>
      `;
      toolbarContainer.appendChild(btnExportar);

      statusbar[0].appendChild(toolbarContainer);
    }
  }

  //devuelve una lista de elementos moviles que es llamado en el componente track.ts
  getListMoviles(): MovilModel[] {
    const movilesSelec: MovilModel[] = [];
    const rowsSelec = this.gridMovil.getselectedrowindexes();
    if (rowsSelec && rowsSelec.length > 0) {
      if (rowsSelec.length < 6) {
        rowsSelec.forEach(rowIndex => {
          movilesSelec.push(this.movilesList[rowIndex]);
        });
      }
      else {
        MainComponent.getInstance().showWarning('ATENCION', 'Cinco_moviles_maximo', 3000);
        return null
      }
    }
    return movilesSelec;
  }

  // Inicializa los botones de la cabecera
  initToolbarButtons(): void {
    const btnRoute = document.getElementById('btnRecorrido');
    if (btnRoute) {
      btnRoute.addEventListener('click', (event: any) => {
        // Sólo puede haber un reproductor abierto
        // if (!TracksComponent.instance) {
        // Recupero los móviles selecionados
        const rowsSelec = this.gridMovil.getselectedrowindexes();
        if (rowsSelec && rowsSelec.length > 0) {
          if (rowsSelec.length < 6) {
            const movilesSelec: MovilModel[] = [];
            rowsSelec.forEach(rowIndex => {
              movilesSelec.push(this.movilesList[rowIndex]);

            });
            // Obtengo las filas filtradas para que el reproductor pueda ocultar y luego volver a poner
            // visibles sólo los móviles filtrados
            const movilesVisibles: MovilModel[] = [];
            let rowsVisibles = this.gridMovil.getrows();
            if (rowsVisibles) {
              rowsVisibles.forEach(row => {
                movilesVisibles.push(this.movilesList[this.movilesIdx.get(row.codigo)]);
              });
            }
            // Creo el componente y le paso los móviles seleccionados, también le paso los filtrados en ese momento
            const component = this.movilesContainer.createComponent(TracksComponent);
            component.instance.init(component, movilesSelec, movilesVisibles);
          } else {
            MainComponent.getInstance().showWarning('ATENCION', 'Cinco_moviles_maximo', 3000);
          }
        } else {
          MainComponent.getInstance().showInfo('ATENCION', 'Seleccione_movil', 3000);
        }
        // } else {
        //   MainComponent.getInstance().showInfo('ATENCION', 'Reproductor_abierto', 3000);
        // }
      });
    }
    const btnSubflota = document.getElementById('btnSubflotas');
    if (btnSubflota) {
      btnSubflota.addEventListener('click', (event: any) => {
        const component = this.movilesContainer.createComponent(SubflotasComponent);
        component.instance.init(component);
      });
    }
    const btnMovilesCerca = document.getElementById('btnMovilesCerca');
    if (btnMovilesCerca) {
      btnMovilesCerca.addEventListener('click', (event: any) => {
        const component = this.movilesContainer.createComponent(MovilesCercaComponent);
        component.instance.init(component, 0);
      });
    }
    const btnItinerari = document.getElementById('btnItinerari');
    if (btnItinerari) {
      btnItinerari.addEventListener('click', (event: any) => {
        const component = this.movilesContainer.createComponent(ItinerariComponent);
        component.instance.init(component, 0);
      });
    }
    //boton exportar
    const btnExportar = document.getElementById('btnExportarMoviles');
    if (btnExportar) {
      btnExportar.addEventListener('click', (event: any) => {
        if (this.gridMovil.getrows().length === 0) {
          return MainComponent.getInstance().showWarning('ATENCION', this.translate('No_existen_datos'), 2000);
        } else {
          this.gridMovil.hidecolumn('imagenCon');
          this.gridMovil.hidecolumn('imagenIgni');
          const json = this.gridMovil.exportdata('json');
          let datos = JSON.parse(json);
          let datosRow = this.gridMovil.getrows();
          datos.forEach((element, index) => {
            element[this.translate('Subflota')] = datosRow.map((item) => item.subflota)[index];
          });
          const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(datos);
          this.generateAutofilterHeader(ws);
          const wb: xlsx.WorkBook = xlsx.utils.book_new();
          xlsx.utils.book_append_sheet(wb, ws, 'Hoja1');
          xlsx.writeFile(wb, DateUtils.formatDateAMDhms(new Date()) + '_' + this.translate('Moviles') + '.xlsx');
          this.gridMovil.showcolumn('imagenCon');
          this.gridMovil.showcolumn('imagenIgni');
        }
      });
    }

    //boton imprimir
    const btnImprimir = document.getElementById('btnImprimirMovil');
    if (btnImprimir) {
      btnImprimir.addEventListener('click', (event: any) => {
        if (this.gridMovil.getrows().length === 0) {
          return MainComponent.getInstance().showWarning('ATENCION', this.translate('No_existen_datos'), 2000);
        } else {
          this.gridMovil.hidecolumn('imagenCon');
          this.gridMovil.hidecolumn('imagenIgni');
          let gridContent = this.gridMovil.exportdata('html');
          let newWindow = window.open('', '', 'width=800, height=500'),
            document = newWindow.document.open(),
            pageContent =
              '<!DOCTYPE html>\n' +
              '<html>\n' +
              '<head>\n' +
              '<meta charset="utf-8" />\n' +
              '<title>jQWidgets Grid</title>\n' +
              '</head>\n' +
              '<body>\n' +
              gridContent +
              '\n</body>\n</html>';
          this.gridMovil.showcolumn('imagenCon');
          this.gridMovil.showcolumn('imagenIgni');
          document.write(pageContent);
          document.close();
          newWindow.onafterprint = function () {
            newWindow.close();
          };
          newWindow.print();
        }
      });
    }
  }

  generateAutofilterHeader(sheet) {
    // Añade filtro a todas las casillas.
    sheet['!autofilter'] = { ref: sheet['!ref'] };
  }

  // Cando se crea un filtro
  async onChangeFilter(event: any) {
    this.initialFilter = null;
    let filter = null;
    // Si el primer filtro es el de la búsqueda busco el siguiente filtro
    let columnFilter = event.args.filters[0] ? event.args.filters[0].datafield : '';
    if (columnFilter === 'selec') {
      if (event.args.filters[1]) {
        // Si hay un segundo filtro es por que se han filtrado por alguna columna
        columnFilter = event.args.filters[1].datafield
        filter = event.args.filters[1].filter.getfilters();
      }
    } else {
      filter = event.args.filters[0] ? event.args.filters[0].filter.getfilters() : null;
    }
    // Si ha cambiado el filtro
    if (this.oldFilter !== filter) {
      this.oldFilter = filter;
      // Si no hay filtro pongo la variable en blanco
      if (!filter) {
        // Guardo la variable de configuración en blanco
        this.configService.setUsuEmpApp('moviles-filters', '');
      } else {
        const config = {
          column: columnFilter,
          filters: filter
        }
        // Guardo la variable de configuración con los datos del filtro
        this.configService.setUsuEmpApp('moviles-filters', JSON.stringify(config));
      }
      // Para que se repinten los cluster
      this.onBindingComplete();
    }
  }

  // '{"column":"subflota","filters":[{"value":"C.Lateral","condition":"EQUAL","operator":1,"type":"stringfilter"}]}'
  setStoredFilter() {
    // Recupero los datos del filtro guardado y los aplico
    const filtergroup = new jqx.filter();
    if (this.initialFilter && this.initialFilter.filters) {
      this.initialFilter.filters.forEach(elem => {
        const filter = filtergroup.createfilter(elem.type, elem.value, elem.condition);
        filtergroup.addfilter(elem.operator, filter);
      });
      this.gridMovil.addfilter(this.initialFilter.column, filtergroup);
      this.gridMovil.applyfilters();
      this.initialFilter = null;
    }
  }

  // Recupero el filtro guardado si lo hay
  async getInitFilter() {
    this.initialFilter = await this.configService.getUsuEmpApp('moviles-filters', null);
    if (this.initialFilter) {
      this.initialFilter = JSON.parse(this.initialFilter);
    }
  }

  async getInitSort() {
    const storedSort: any = await this.configService.getUsuEmpApp('moviles-sort', null);
    if (storedSort) {
      const sortConfig = JSON.parse(storedSort);
      if (sortConfig.column === null) {
        this.setOrderBy('nombre', 'desc');
      } else {
        this.setOrderBy(sortConfig.column, sortConfig.direction);
      }
    }
  }

  onSort(event: any) {
    if (this.isSorting) {
      // Si estamos en medio de una ordenación, no hagas nada
      return;
    }
    // Asume que `event.args.sortinformation.sortcolumn` es el campo de orden y `event.args.sortinformation.sortdirection.ascending` indica si es ascendente
    let userField = event.args.sortinformation.sortcolumn;
    let userDirection = event.args.sortinformation.sortdirection.ascending ? 'asc' : 'desc';

    // si se quita la ordenación, establece el orden predeterminado
    if (event.args.sortinformation.sortdirection.ascending === false && event.args.sortinformation.sortdirection.descending === false) {
      this.orderBy = 'conectado,desc';
      this.onBindingComplete();
      return;
    }

    // Actualizar orderBy para mantener 'conectado' como primer criterio de orden
    this.setOrderBy(userField, userDirection);

    // Crea la configuración de ordenación
    const sortConfig = {
      column: userField,
      direction: userDirection
    }
    // Guarda la configuración de ordenación
    this.configService.setUsuEmpApp('moviles-sort', JSON.stringify(sortConfig));

    // Rehacer el enlace de datos después de cambiar la orden
    this.onBindingComplete();
  }

  setOrderBy(field: string, direction: string) {
    this.orderBy = `conectado,desc,${field},${direction}`;
  }

  // Permite refrescar el grid de móviles
  public async refreshGrid() {
    setTimeout(() => {
      this.gridMovil.updatebounddata('data');
      this.gridMovil.ensurerowvisible(0);
    }, 500);
  }

  // Permite modificar la columna de ordenación y la dirección
  public async orderGridBy(column: string, order: string) {
    setTimeout(() => {
      this.orderBy = column + ',' + order;
      this.gridMovil.updatebounddata('cell');
      this.gridMovil.ensurerowvisible(0);
    }, 500);
  }

  // Permite visualizar u ocultar columnas del grid
  public showColumnGrid(column: string, show: boolean) {
    if (show) {
      this.gridMovil.showcolumn(column);
    } else {
      this.gridMovil.hidecolumn(column);
    }
  }

  // Permite asignar la distancia a la que se buscan móviles cerca
  public setDistanciaCerca(distancia: number) {
    this.distCerca = distancia;
  }
}
