<div class="loader-container" *ngIf="showLoader">
  <div class="loader"></div>
</div>
<jqxWindow #form [jqxWindowAutoResize] [position]="getFormPos(form, 0)" [theme]="theme" [zIndex]="1000" [width]="mapWidth" [height]="mapHeight"
  [autoOpen]="true" [showCloseButton]="false" style="margin-top: 24px">
  <div hidden="true"></div>
  <div class="form">
    <jqxTabs #myTabs [width]="'100%'" [height]="'100%'" [theme]="theme" [scrollable]="false" (onTabclick)="onTabClick($event)"
      style="height: 92% !important; position: absolute;">
      <ul>
        <li>I</li>
        <li>II</li>
      </ul>
      <div style=" width: 100%; height: 100%;">
        <div>
          <app-header #header [exportar]="true" [imprimir]="true" [periodo]="true" [buscador]="true"
            [checkMetros]="true" [filtro]="true" [resetFiltro]="true" [noLibre]="true" (eventExportar)="onExportar()"
            (eventImprimir)="onPrint()" (eventBuscador)="onBuscar()" (eventFilter)="onAceptar()"
            (eventResetFilter)="onResetFilter()" (eventCheckMasMetros)="changeMasXmetros()">
          </app-header>
        </div>
        <div style="width: 99%;height: calc(100% - 40px)">
          <jqxGrid #myGrid [theme]="theme" [width]="'100%'" [height]="'98%'" [source]="dataAdapter" [appCellClick]
            [enablebrowserselection]="true" [columnsresize]="true" [sortable]="true" [showrowlines]="false"
            [showcolumnlines]="true" [columns]="columns" [altrows]="true" [filterable]="true" [showstatusbar]="true"
            [statusbarheight]="20" [rowdetails]="true" [showaggregates]="true" [rowsheight]="20" [columnsheight]="20"
            [localization]="langGrid" [groupable]="true" [rowdetailstemplate]="rowdetailstemplate" [filter]="filter"
            [initrowdetails]="initRowDetails" [updatefilterconditions]="updatefilterconditions" (onRowexpand)="rowExpand($event)"
            (onRowcollapse)="rowCollapse($event)" (onRowdoubleclick)="onRowdoubleclick($event)" (onSort)="onSort($event)">
          </jqxGrid>
        </div>
      </div>
      <div style="height: 460px;">
        <movisat-map #cartoMap [provider]="mapProvider" (mapReady)="onMapReady($event)" [cartoType]="cartoType" [lang]="lang"
          [texts]="langService.getCurrentTexts()" [searchCountry]="searchCountry" [searchBounds]="searchBounds"
          (boundsChange)="onBoundsChange($event)" [noSearch]="true" [zoom]="zoom" [center]="center"></movisat-map>
      </div>
    </jqxTabs>
  </div>
</jqxWindow>
