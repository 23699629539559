<div class="loader-container" *ngIf="showLoader">
  <div class="loader"></div>
</div>

<div>
  <app-header #header [exportar]="true" [buscador]="true" [filtro]="true" [resetFiltro]="true" [seeZonas]="true"
    [seeDropTipoTarj]="true" [sourceZonas]="zonas" [seeNumTarjeta]="true" (eventFilter)="eventFilter()"
    (eventChangeTipoTarjeta)="onChangetTipoTarjeta($event)" (eventChangeNumeroTarjeta)="onChangeNumeroTarjeta($event)"
    (eventResetFilter)="eventResetFilter()" (eventChangeZona)="onChangeZona($event)" (eventExportar)="onExportar()"
    (eventBuscador)="onBuscar()">
  </app-header>
</div>

<div style="float:left; margin-top: 5px; width: 100%; height: calc(100% - 45px);">
  <jqxGrid #gridRangos [theme]="environment.tema" [width]="'100%'" [height]="'100%'" [source]="adapterRangos"
    [appCellClick] [columnsresize]="true" [localization]="langGrid" [filterable]="true"
    [columns]="columnsRangos" [showrowlines]="false" [showcolumnlines]="true" [pageable]="false"
    [sortable]="true" [altrows]="true" [enablebrowserselection]="true" [showstatusbar]="true" [rowsheight]="20"
    [groupable]="true" [columnsheight]="20" [enabletooltips]="false" [editable]="false" [statusbarheight]="0"
    [filter]="filter" [updatefilterconditions]="updatefilterconditions" (onFilter)="onFilter($event)"
    (onCellclick)="onCellClick($event)">
  </jqxGrid>
</div>

<jqxWindow #winCerraduras [width]="310" [height]="250" [zIndex]="101" [isModal]="true" [position]="'center'"
  [showCloseButton]="true" [resizable]="true" [autoOpen]="false" [theme]="environment.tema">
  <div style="background-color: transparent;">
    <div class="row" style="overflow: hidden; background-color: transparent;">
      <jqxGrid #gridCerraduras [theme]="environment.tema" [width]="'100%'" [height]="160" [appCellClick]
        [source]="dataAdapterCerraduras" [columnsresize]="true" [columns]="columnsCerraduras" [sortable]="true"
        [enablebrowserselection]="true" [altrows]="true" [showrowlines]="false" [rowsheight]="25" [columnsheight]="25"
        [localization]="langGrid">
      </jqxGrid>
      <jqxButton style="float: right; margin-right: 2px; margin-top: 5px;" [width]="55" [height]="25"
        [textPosition]="'center'" [value]="translate('Cerrar')" (onClick)="cerrarCerraduras()">
      </jqxButton>
    </div>
  </div>
</jqxWindow>
