import { NgForm } from '@angular/forms';
import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';

import { AppComponent } from 'src/app/app.component';
import { CustomForms } from '../../../forms/custom-forms';
import { environment } from 'src/environments/environment';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';

import { InputNumberComponent } from '../../../input-number/input-number.component';
import { MovilesCatalogComponent } from '../../../resources/moviles-catalog/moviles-catalog.component';
import { ConjuntoVehiculo } from 'src/app/services/conjuntoVehiculo/models/conjunto-vehiculo.model';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { HeaderComponent } from 'src/app/components/header/header.component';
import { MapBounds, MapComponent, MapLatLng } from 'movisat-maps';
import { MainComponent } from 'src/app/components/main/main.component';
import { MarcoGeograficoModel } from 'src/app/services/geographics/marco-geografico.model';
import { Utils } from 'src/app/utils/utils';

@Component({
  selector: 'app-movil-edit',
  templateUrl: './movil-edit.component.html',
  styleUrls: ['./movil-edit.component.css']
})
export class MovilEditComponent extends CustomForms implements OnInit {
  @ViewChildren(InputNumberComponent) inputNumbers: QueryList<InputNumberComponent>;
  @ViewChild('windowForm') windowForm: jqxWindowComponent;
  @ViewChild('gridVehiculos') gridVehiculos: jqxGridComponent;
  @ViewChild('formMovil') formMovil: NgForm;
  @ViewChild('header') header: HeaderComponent;

  static _this: any;
  viewInit = false;
  private componentRef = null;
  public environment = environment;
  public langGrid = JqWidgets.getLocalization('es');

  protected map: MapComponent;

  public movil: ConjuntoVehiculo = new ConjuntoVehiculo();
  clasesMovil: string = "";

  ejecutado: boolean = false;

  mapWidth: number;
  mapHeight: number;

  //Grid vehiculos
  columnsVehiculos;
  sourceVehiculos
  dataAdapterVehiculos;

  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  constructor() {
    super();
    MovilEditComponent._this = this;
  }

  ngOnInit(): void {
    this.mapHeight = document.getElementById('map-container').offsetHeight;
    this.mapWidth = document.getElementById('map-container').offsetWidth;

    setTimeout(() => {
      this.map = MainComponent.getInstance().getMap();
    }, 10)
  }

  public init(componentRef: any, movil: ConjuntoVehiculo){
    this.componentRef = componentRef;

    this.movil = movil ? movil : new ConjuntoVehiculo();
    if(this.movil && this.movil.clases){
      this.movil?.clases?.forEach(clas => {
        this.clasesMovil += this.clasesMovil != "" ? ';' + clas.nombre : clas.nombre;
      });
    }

    if(this.movil && this.movil.imagenes && this.movil.imagenes.length > 0){
      this.selectedImg = this.movil.imagenes[0];
    }

    this.initGridVehiculo();
  }

  ngAfterViewInit(): void {
    this.addCustomForm(this.windowForm);
    this.windowForm.setTitle(this.translate('Edicion_flota'));
    this.viewInit = true;
  }

  onClose(){
    if (this.componentRef) {
      this.componentRef.destroy();
      MovilesCatalogComponent._this.form.expand();
    }
  }

  renderer() {
    if (!this.ejecutado) {
      setTimeout(() => {
        this.inputNumbers.forEach(inputNumber => {
          const value = inputNumber.value;
          inputNumber.rendererDecimal('', '', value);
        });
      }, 100);
      this.ejecutado = true;
    }
  }

  initGridVehiculo() {
    this.movil.vehiculos.forEach(veh => {
      veh['recurso'] = this.movil.recurso.nombre;
      let clase = '';
      if(this.movil && this.movil.clases){
        this.movil.clases.forEach(clas => {
          clase += clase != '' ? ', ' + clas.nombre : clas.nombre;
        });
      }
      veh['clase'] = clase;
    });

    this.columnsVehiculos = [
      { text: 'idLicencia', columntype: 'textbox', filtertype: 'textbox', datafield: 'idLicencia', hidden: true },
      { text: 'Selec', columntype: 'textbox', filtertype: 'textbox', datafield: 'selec', hidden: true },
      {
        text: this.translate('Acciones'),
        width: 50,
        columntype: 'text',
        sortable: false,
        editable: false,
        datafield: 'acciones',
        groupable: false,
        menu: false,
        rendered: (columnHeaderElement) => {
          return columnHeaderElement[0];
        },
        createwidget: (
          row: any,
          column: any,
          value: string,
          htmlElement: HTMLElement
        ): void => {
          this.initBtnColumn(row, column, value, htmlElement);
        },
        initwidget: (
          row: any,
          column: any,
          value: string,
          htmlElement: HTMLElement
        ) => {
          this.initBtnColumn(row, column, value, htmlElement);
        }
      },
      { text: this.translate('Matricula'), columntype: 'textbox', datafield: this.translate('Matricula') },
      { text: this.translate('Descripcion'), columntype: 'textbox', datafield: this.translate('Descripcion') },
      { text: this.translate('Subflota'), columntype: 'textbox', datafield: this.translate('Subflota'), filtertype: 'checkedlist' },
      { text: this.translate('Recurso'), columntype: 'textbox', datafield: this.translate('Recurso'), filtertype: 'checkedlist' },
      { text: this.translate('Clase'), columntype: 'textbox', datafield: this.translate('Clase'), filtertype: 'checkedlist' }
    ];

    this.sourceVehiculos = {
      datatype: 'json',
      datafields: [
        { name: 'idLicencia', type: 'string', map: 'idLicencia' },
        { name: this.translate('Matricula'), type: 'string', map: 'matricula' },
        { name: this.translate('Descripcion'), type: 'string', map: 'descripcionVehiculo' },
        { name: this.translate('Subflota'), type: 'string', map: 'subFlota' },
        { name: this.translate('Recurso'), type: 'string', map: 'recurso' },
        { name: this.translate('Clase'), type: 'string', map: 'clase' },
        { name: 'selec', map: 'selec' }
      ],
      localdata: this.movil.vehiculos,
    };
    this.dataAdapterVehiculos = new jqx.dataAdapter(this.sourceVehiculos);
  }

  updatefilterconditions = (type: string, defaultconditions: any): string[] => {
    return Utils.updatefilterconditions(type, defaultconditions);
  };

  public filter(cellValue?: any, rowData?: any, dataField?: string, filterGroup?: any, defaultFilterResult?: boolean): any {
    let filterColumns = [
      this.translate('Matricula'),
      this.translate('Descripcion')
    ]

    return Utils.filterRow(cellValue, dataField, filterGroup, defaultFilterResult, filterColumns);
  }

  // Incializa la columna de botones
  async initBtnColumn(
    row: any,
    column: any,
    value: string,
    htmlElement: HTMLElement
  ) {
    htmlElement.innerHTML = '';
    // Crea un contenedor para los botones
    const btnContainer = document.createElement('div');
    btnContainer.style.display = 'flex';
    btnContainer.style.justifyContent = 'space-around';
    btnContainer.style.gap = '2px';
    btnContainer.style.padding = '2px';

    let rowdata;
    if (isNaN(row)) {
      rowdata = row.bounddata;
    } else {
      rowdata = this.gridVehiculos.getrowdata(row);
    }

    let conjuntoVehiculo = this.movil.vehiculos.find(veh => veh.idLicencia == rowdata.idLicencia);

    const btnCenter = document.createElement('div');
    btnCenter.innerHTML = `
    <button class="button" style="height: 23px; width: 25px; padding: 0; margin: 0; cursor: pointer !important;" title="`+AppComponent.translate('Centrar_mapa')+`">
      <i class="fa-solid fa-location-dot"></i>
    </button>
      `;
    btnCenter.id = `buttonEdit_jqxButton`;
    btnContainer.appendChild(btnCenter);

    btnCenter.addEventListener('click', async (event: any) => {
      if(conjuntoVehiculo && conjuntoVehiculo.posicion && conjuntoVehiculo.posicion.lat && conjuntoVehiculo.posicion.lng) {
        let neCorner = MainComponent.getInstance().marcoGeografico.marco.neCorner;
        let swCorner = MainComponent.getInstance().marcoGeografico.marco.swCorner;

        if(neCorner.lat > conjuntoVehiculo.posicion.lat && neCorner.lng > conjuntoVehiculo.posicion.lng &&
          swCorner.lat < conjuntoVehiculo.posicion.lat &&  swCorner.lng < conjuntoVehiculo.posicion.lng){
          // Acerca y centra el mapa
          MovilEditComponent._this.map.setZoom(18);
          MovilEditComponent._this.map.setCenter(new MapLatLng(conjuntoVehiculo.posicion.lat, conjuntoVehiculo.posicion.lng));

          MovilEditComponent._this.windowForm.collapse();
        }else {
          MainComponent.showError('ATENCION', 'Fuera_ambito', 3000);
        }
      }else {
        MainComponent.showError('ATENCION', 'No_coordenadas_para_centrar', 3000);
      }
    });

    htmlElement.appendChild(btnContainer);
  }

  onBuscar(){
    let filtervalue = '';

    if (this.header.searchInput['nativeElement'].value.length >= 3) {
      filtervalue = this.header.searchInput['nativeElement'].value.toUpperCase();
    } else {
      filtervalue = '';
    }

    this.movil.vehiculos.forEach(veh => {
      if(
        (veh?.matricula + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (veh?.descripcionVehiculo + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (veh?.subFlota + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (veh['recurso'] + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (veh['clase'] + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1
      ){
        veh['selec'] = 'selec';
      }else{
        veh['selec'] = '';
      }
    });

    // Compruebo si ya he creado el filtro "selec" anteriormente
    const filters = this.gridVehiculos.getfilterinformation();
    if (filters.find(s => s.datafield === 'selec') === undefined) {
      const filtergroup = new jqx.filter();
      filtergroup.operator = 'and';
      filtergroup.addfilter(0, filtergroup.createfilter('stringfilter', 'selec', 'equal'));
      this.gridVehiculos.addfilter('selec', filtergroup);
    }
    this.gridVehiculos.applyfilters();
    this.gridVehiculos.updatebounddata('data');
  }

  onCentrar(){
    let neCorner: MapLatLng = new MapLatLng(0, 0, 1);
    let swCorner: MapLatLng = new MapLatLng(0, 0, 1);
    let markList = this.map.getMarkerList();

    this.movil.vehiculos.forEach(veh => {
      if(veh.posicion && veh.posicion.lat !== null && veh.posicion.lng !== null) {
        if(veh.posicion.lat > neCorner.lat || neCorner.lat === 0){
          neCorner.lat = veh.posicion.lat;
        }

        if(veh.posicion.lng > neCorner.lng || neCorner.lng === 0){
          neCorner.lng = veh.posicion.lng;
        }

        if(veh.posicion.lat < swCorner.lat || swCorner.lat === 0){
          swCorner.lat = veh.posicion.lat;
        }

        if(veh.posicion.lng < swCorner.lng || swCorner.lng === 0){
          swCorner.lng = veh.posicion.lng;
        }

        markList.forEach((val, key) => {
          if((val.position.lat === veh.posicion.lat) && (val.position.lng === veh.posicion.lng)) {
            val.animate(2850);
          }
        });
      }
    });

    if(swCorner.lat || swCorner.lng || neCorner.lat || neCorner.lng){
      let marcoGeografico = new MarcoGeograficoModel(new MapBounds(swCorner, neCorner));

      this.map.fitTo(marcoGeografico.marco);
      MovilEditComponent._this.windowForm.collapse();
    }else{
      MainComponent.showError('ATENCION', 'No_coordenadas_para_centrar', 3000);
    }
  }

  closeWindow(){
    this.windowForm.close();
  }

  /* APARTADO IMAGENES */
  @ViewChild('imgPrincipal') imgPrincipal;
  @ViewChild('uploadImageWind') uploadImageWind: jqxWindowComponent;
  imagenes: [] = []

  inputFile: any = null;

  imgToUpload;
  selectedImg;
  private imgPrincipalRotation = 0;

  /* Establece la imagen principal al
    hacer click en una imagen del slider */
  setImgPrincipal(img) {
    this.selectedImg = img;
    this.rotate('0');
  }

  /* Selecciona, a traves de las flechas,
   la imagen siguiente o anterior */
  changeImage(direction: string) {
    let index;
    switch (direction) {
      case 'previous':
        index = this.movil.imagenes.indexOf(this.selectedImg);
        if (index > 0) {
          this.rotate('0');
          this.selectedImg = this.movil.imagenes[index - 1];
        } else {
          this.rotate('0');
          this.selectedImg = this.movil.imagenes[this.movil.imagenes.length - 1];
        }
        break;
      case 'next':
        index = this.movil.imagenes.indexOf(this.selectedImg);
        if (index < this.movil.imagenes.length - 1) {
          this.rotate('0');
          this.selectedImg = this.movil.imagenes[index + 1];
        } else {
          this.rotate('0');
          this.selectedImg = this.movil.imagenes[0];
        }
        break;
      default:
        break;
    }
  }

  // Rota la imagen principal
  rotate(orientation: string) {
    if(this.imgPrincipal){
      switch (orientation) {
        case 'left':
          this.imgPrincipalRotation = this.imgPrincipalRotation - 90;
          this.imgPrincipal.nativeElement.style.transform = "rotate(" + this.imgPrincipalRotation + "deg)"
          break;
        case 'right':
          this.imgPrincipalRotation = this.imgPrincipalRotation + 90;
          this.imgPrincipal.nativeElement.style.transform = "rotate(" + this.imgPrincipalRotation + "deg)"
          break;
        case '180':
          this.imgPrincipalRotation = this.imgPrincipalRotation + 180;
          this.imgPrincipal.nativeElement.style.transform = "rotate(" + this.imgPrincipalRotation + "deg)"
          break;
        case '0':
          this.imgPrincipalRotation = 0;
          this.imgPrincipal.nativeElement.style.transform = "rotate(" + this.imgPrincipalRotation + "deg)"
          break;
        default:
          break;
      }
    }
  }

  /* // Abre la ventana de subir imagen
  openUploadImageWind() {
  }

  // Cierra la ventana de subir imagen
  closeUploadImageWind() {
  }

  // Cuando se cierra la ventana de subir imagen
  onCloseWindowImage() {
  }

  // Selecciona la imagen a subir
  onSelectImage(event) {
  }

  // Sube la imagen al servidor
  async uploadImage() {
  }

  // Abre la ventana de borrar imagen
  openDeleteImageWind() {
  }

  // Borra la imagen del servidor
  async removeImageElement() {
  } */
  /* FIN APARTADO IMAGENES */
}
