import { Component, OnInit, AfterViewInit, ViewChild, OnDestroy } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { environment } from 'src/environments/environment';
import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';
import { CamposCiudadanosVisibleModel, LopdCiudadanoModel, RolCamposCiudadanoModel } from 'src/app/services/ciudadanos/models/lopdCiudadano.model';
import { CiudadanosService } from 'src/app/services/ciudadanos/ciudadanos.service';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { HeaderComponent } from '../../header/header.component';
import { jqxTabsComponent } from 'jqwidgets-ng/jqxtabs';
import * as xlsx from 'xlsx';
import { DateUtils } from 'src/app/utils/date-utils';
import { MainComponent } from '../../main/main.component';
import { jqxLoaderComponent } from 'jqwidgets-ng/jqxloader';
import { SsoService } from 'src/app/services/sso/sso.service';
import { CustomForms } from '../../forms/custom-forms';
import { RolModel } from 'src/app/services/sso/models/rol.model';
import { jqxDropDownListComponent } from 'jqwidgets-ng/jqxdropdownlist';

@Component({
  selector: 'app-lopd-ciudadanos',
  templateUrl: './lopd-ciudadanos.component.html',
  styleUrls: ['./lopd-ciudadanos.component.css']
})
export class LopdCiudadanosComponent extends CustomForms implements OnInit, AfterViewInit, OnDestroy {

  static _this: any;
  @ViewChild('window') window: jqxWindowComponent;
  @ViewChild('myGrid') grid: jqxGridComponent;
  @ViewChild('myGridConf') gridConf: jqxGridComponent;
  @ViewChild('tabs') tabs: jqxTabsComponent;
  @ViewChild('header') header: HeaderComponent;
  @ViewChild('loader') loader: jqxLoaderComponent;
  @ViewChild('listRoles') listRoles: jqxDropDownListComponent;


  public showLoader = true;
  public tema = environment.tema;
  public langGrid: any;

  lpodsCiudadanos: LopdCiudadanoModel[] = [];
  camposCiudadanosVisible: CamposCiudadanosVisibleModel[] = [];

  mapWidth: number;
  mapHeight: number;
  dataAdapter: any;
  adapterRoles: any;

  idsRolFilter: any[] = [];
  idsRolSelect: any[] = [];
  idsAppSelect: any[] = [];
  roles: RolModel[];
  datosLOPD: RolCamposCiudadanoModel[] = [];
  showCopy: boolean = false;
  esDesactivado: boolean = false;

  createTooltip = (content: string) => {
    return (columnHeaderElement?: any) => {
      const jqElement = columnHeaderElement[0].parentElement;
      const options = {
        content: content,
        theme: environment.tema,
        width: 'auto',
        height: 'auto',
        position: 'mouse',
        autoHide: true,
      };
      jqwidgets.createInstance(jqElement, 'jqxTooltip', options);
    };
  };

  cellhover = (element: any, pageX: any, pageY: any) => {
    // muestro un tooltip con el contenido de la celda
    if (element.innerText !== undefined && element.innerText !== '') {
      const options = {
        content: element.innerText,
        theme: environment.tema,
        position: 'mouse',
        autoHide: true,
      };
      jqwidgets.createInstance(element, 'jqxTooltip', options);
    } else {
      return;
    }
  };


  groupsrenderer(text?: string, group?: any, expanded?: boolean, data?: any): string {
    if (group === undefined) {
      return;
    }

    let num = data?.subItems.length;
    let name = group;
    let header = '';

    if (name === true) {
      header = '(S)';
    } else if (name === false) {
      header = '(N)';
    } else {
      header = name;
    }

    return '<div style="margin-left: 4px;">' + header + ': ' + num + '</div>';
  }


  columns: any[] = [
    {
      text: AppComponent.translate('Campos'), datafield: 'nombre', width: '10%', editable: false, rendered: this.createTooltip(AppComponent.translate('Campos')),
      aggregates: [{
        'Total': function (aggregatedValue, currentValue: number) {
          return aggregatedValue + 1;
        }
      }], //agrego el total al grid
      aggregatesrenderer: function (aggregates) {
        let renderstring = '';
        if (aggregates['Total'] !== undefined) {
          renderstring = '<div style="margin-left: 4px;">' + 'Total: ' +
            aggregates['Total'] + '</div>';
        }
        return renderstring;
      },
    },
    {
      text: AppComponent.translate('Obligatorio'), datafield: 'obligatorio', width: '5%', columntype: 'checkbox', filtertype: 'list', filteritems: ['(S)', '(N)'], editable: true, rendered: this.createTooltip(AppComponent.translate('Obligatorio')),
    },
    {
      text: AppComponent.translate('Dato_personal'), datafield: 'datoPersonal', width: '5%', columntype: 'checkbox', filtertype: 'list', filteritems: ['(S)', '(N)'], editable: true,
      rendered: this.createTooltip(AppComponent.translate('Dato_personal')),
    },
  ];

  dataAdapterConf: any;
  columnsConf: any[] = [
    {
      text: AppComponent.translate('Campos'), datafield: 'nombre', width: '10%', editable: false, rendered: this.createTooltip(AppComponent.translate('Roles_app')),
      aggregates: [{
        'Total': function (aggregatedValue, currentValue: number) {
          return aggregatedValue + 1;
        }
      }], //agrego el total al grid
      aggregatesrenderer: function (aggregates) {
        let renderstring = '';
        if (aggregates['Total'] !== undefined) {
          renderstring = '<div style="margin-left: 4px;">' + 'Total: ' +
            aggregates['Total'] + '</div>';
        }
        return renderstring;
      },
    },
    { text: 'Visible', datafield: 'visible', width: '5%', columntype: 'checkbox', filtertype: 'list', filteritems: ['(S)', '(N)'], editable: true, rendered: this.createTooltip(AppComponent.translate('Campo')) },
    { text: 'Rol', datafield: 'rol', width: '10%', editable: false, rendered: this.createTooltip(AppComponent.translate('Roles_app')), filtertype: 'checkedlist' },
  ];

  componentRef: any;
  constructor(private ciudadanosService: CiudadanosService, private ssoService: SsoService) {
    super();
    LopdCiudadanosComponent._this = this;
  }

  init(componentRef: any) {
    this.componentRef = componentRef;
  }

  ngOnInit() {
    this.mapHeight = document.getElementById('map-container').offsetHeight;
    this.mapWidth = document.getElementById('map-container').offsetWidth;
    this.langGrid = JqWidgets.getLocalization(this.ssoService.getTicket().Usuario.Idioma.Codigo.substring(0, 2));
    this.loadRoles();
  }


  ngAfterViewInit() {
    this.addCustomForm(this.window);
    this.loadWindowTabs();
    this.loadGridGeneral();
  }


  loadWindowTabs() {
    this.window.setTitle(AppComponent.translate('Lopd_ciudadanos'));
    this.tabs.setTitleAt(0, AppComponent.translate('General'));
    this.tabs.setTitleAt(1, AppComponent.translate('Configuracion_por_rol'));
    setTimeout(() => {
      if (this.tabs.selectedItem() === 0) {
        HeaderComponent._this.listRoles.disabled(true);
      } else {
        HeaderComponent._this.listRoles.disabled(false);
      }
    }, 500);
  }

  async loadGridGeneral() {
    this.lpodsCiudadanos = await this.ciudadanosService.getLopdCiudadanos();
    this.camposCiudadanosVisible = await this.ciudadanosService.getCamposVisiblesCiudadanos();
    this.dataAdapter = new jqx.dataAdapter({
      datatype: 'json',
      datafields: [
        { name: 'id', type: 'number' },
        { name: 'nombre', type: 'string' },
        { name: 'obligatorio', type: 'boolean' },
        { name: 'datoPersonal', type: 'boolean' },
      ],
      localdata: this.lpodsCiudadanos,
      sortcolumn: 'nombre',
      sortdirection: 'asc'
    });
    this.grid.source(this.dataAdapter);
    this.showLoader = false;
  }

  loadGridConf() {
    let sourceConf = {
      datatype: 'json',
      datafields: [
        { name: 'id', type: 'number' },
        { name: 'nombre', type: 'string' },
        { name: 'visible', type: 'boolean' },
        { name: 'rol', type: 'string', map: 'rolName' },
      ],
      localdata: this.camposCiudadanosVisible,
      sortcolumn: 'nombre',
      sortdirection: 'asc'
    };
    this.dataAdapterConf = new jqx.dataAdapter(sourceConf);
    this.loader.close();
  }

  async loadRoles() {
    this.roles = await this.ssoService.getRoles();
    if (this.roles) {
      let dataSource = {
        datatype: 'json',
        datafields: [
          { name: 'Id', type: 'string', map: 'Id' },
          { name: 'Nombre', type: 'string', map: 'Nombre' },
        ],
        localdata: this.roles,
      };

      dataSource.localdata.sort((a, b) => (a.Nombre < b.Nombre ? -1 : 1));
      this.adapterRoles = new jqx.dataAdapter(dataSource,
        {
          beforeLoadComplete: (records: any[]): void => {
            records.sort((a, b) => (a.Nombre < b.Nombre ? -1 : 1));
          },
        }
      );
    }
  }

  filter = (cellValue, rowData, dataField, filterGroup, defaultFilterResult): boolean => {
    if (dataField == 'obligatorio' || dataField == 'datoPersonal') {
      let filterValue = filterGroup.getfilters()[0].value;
      switch (filterValue) {
        case '(S)':
          return cellValue;

        case '(N)':
          return !cellValue;

        default:
          return defaultFilterResult;
      }
    }
  };

  filterConf = (cellValue, rowData, dataField, filterGroup, defaultFilterResult): boolean => {
    if (dataField == 'visible') {
      let filterValue = filterGroup.getfilters()[0].value;
      switch (filterValue) {
        case '(S)':
          return cellValue;

        case '(N)':
          return !cellValue;

        default:
          return defaultFilterResult;
      }
    }
  }

  async onGuardar() {
    this.loader.open();
    if (this.tabs.selectedItem() === 0) {
      let data = this.grid.getrows();
      let dataToSend: any = data.map(x => {
        return {
          id: x.id,
          nombre: x.nombre,
          obligatorio: x.obligatorio,
          datoPersonal: x.datoPersonal,
        };
      });
      let responde = await this.ciudadanosService.postLopdCiudadanos(dataToSend);
      if (responde) {
        MainComponent.showSuccess('ATENCION', 'Cambios_guardados', 2000);
        this.loadGridGeneral();
      } else {
        MainComponent.showError('ATENCION', 'Ha_ocurrido_un_error', 2000);
      }
    } else {
      if (this.idsRolSelect !== undefined && this.idsRolSelect.length > 0 && this.gridConf.getrows().length > 0) {
        let data = this.gridConf.getrows();
        let dataToSend: any = data.map(x => {
          return {
            id: x.id,
            visible: x.visible
          };
        });

        let successCount = 0;

        for (let idRol of this.idsRolSelect) {
          try {
            let response = await this.ciudadanosService.postCamposVisiblesCiudadanos(dataToSend, idRol);
            if (response) {
              successCount++;
            }
          } catch (error) {
            console.error(error);
          }
        }

        if (successCount > 0) {
          MainComponent.showSuccess('ATENCION', 'Cambios_guardados', 2000);
        }

      } else {
        MainComponent.showError('ATENCION', 'Debe_seleccionar_un_rol', 2000);
      }
    }
    this.loader.close();
  }

  onTabClick(event: any) {
    if (event.args.item === 1) {
      this.showCopy = true;
      HeaderComponent._this.listApps.disabled(true);
      HeaderComponent._this.listRoles.disabled(false);
      this.loadGridConf();
    } else {
      this.showCopy = false;
      HeaderComponent._this.listRoles.disabled(true);
      HeaderComponent._this.listApps.disabled(false);
    }
  }

  onSelectRol(event: any) {
    this.idsRolFilter = event;
  }

  onCheckApps(event: any) {
    this.idsAppSelect = event;
  }

  onUnChecked(event: any) {
    // quito de la lista de campos visibles el campo que se ha deseleccionado
    let dataLOPD = event.args.row;
    this.datosLOPD = this.datosLOPD.filter(x => x.id !== dataLOPD.id);
  }


  onChecked(event: any) {

    let dataLOPD = event.args.row;
    if (typeof dataLOPD === 'object' && dataLOPD !== null) {
      // cojo los dos campos que me interesan
      let rowSelect = {
        id: dataLOPD.id,
        visible: dataLOPD.visible
      };
      this.datosLOPD.push(rowSelect);
    }
  }

  onCheckRol(event: any) {
    this.idsRolSelect = this.listRoles.getCheckedItems().map(x => x.value);
  }

  async onClickCopyConfRol(event) {

    if (this.datosLOPD.length === 0) {
      return MainComponent.getInstance().showWarning('ATENCION', this.translate('Debe_seleccionar_campos'), 2000);
    }

    if (this.idsRolSelect === undefined || this.idsRolSelect.length === 0) {
      return MainComponent.getInstance().showWarning('ATENCION', this.translate('Debe_seleccionar_un_rol'), 2000);
    }

    this.loader.open();
    let dataToSend: any = this.datosLOPD.map(x => {
      return {
        id: x.id,
        visible: x.visible
      };
    });

    let successCount = 0;

    for (let idRol of this.idsRolSelect) {
      try {
        let response = await this.ciudadanosService.postCamposVisiblesCiudadanos(dataToSend, idRol);
        if (response) {
          successCount++;
        } else {
        }
      } catch (error) {
        console.error(error);
      }
    }

    if (successCount > 0) {
      MainComponent.showSuccess('ATENCION', 'Cambios_guardados', 2000);
    }

    this.loader.close();
  }


  onPrint($event: any) {

    if (this.grid.getrows().length === 0) {
      return MainComponent.getInstance().showWarning('ATENCION', this.translate('No_existen_datos'), 2000);
    } else {
      let gridContent = this.grid.exportdata('html');
      let newWindow = window.open('', '', 'width=800, height=500'),
        document = newWindow.document.open(),
        pageContent =
          '<!DOCTYPE html>\n' +
          '<html>\n' +
          '<head>\n' +
          '<meta charset="utf-8" />\n' +
          '<title>jQWidgets Grid</title>\n' +
          '</head>\n' +
          '<body>\n' +
          gridContent +
          '\n</body>\n</html>';
      document.write(pageContent);
      document.close();
      newWindow.onafterprint = function () {
        newWindow.close();
      };
      newWindow.print();
    }

    if (this.gridConf.getrows().length === 0) {
      return MainComponent.getInstance().showWarning('ATENCION', this.translate('No_existen_datos'), 2000);
    } else {
      let gridContent = this.gridConf.exportdata('html');
      let newWindow = window.open('', '', 'width=800, height=500'),
        document = newWindow.document.open(),
        pageContent =
          '<!DOCTYPE html>\n' +
          '<html>\n' +
          '<head>\n' +
          '<meta charset="utf-8" />\n' +
          '<title>jQWidgets GridConfig</title>\n' +
          '</head>\n' +
          '<body>\n' +
          gridContent +
          '\n</body>\n</html>';
      document.write(pageContent);
      document.close();
      newWindow.onafterprint = function () {
        newWindow.close();
      };
      newWindow.print();
    }
  }

   eventResetFilter() {
    this.loader.open();
    this.header.searchInput['nativeElement'].value = '';
    this.grid.clearfilters();
    this.grid.sortby('nombre', 'asc');
    HeaderComponent._this.listApps.uncheckAll();
    this.esDesactivado = false;

    // quito la columna appName
    if (this.columns.some(x => x.text === 'App')) {
      this.columns = this.columns.filter(x => x.text !== 'App');
    }

    if (this.gridConf && this.gridConf.getrows().length > 0) {
      this.gridConf.clearfilters();
      this.gridConf.sortby('nombre', 'asc');
      HeaderComponent._this.listRoles.uncheckAll();
      this.listRoles.uncheckAll();
    }



    setTimeout(() => {
      this.loadGridGeneral();
      this.loadGridConf();
    }, 50);
  }

  eliminarDuplicados(array: { nombre: string, id: number, obligatorio: boolean, datoPersonal: boolean }[]) {
    const mapa = new Map<string, { nombre: string, id: number, obligatorio: boolean, datoPersonal: boolean }[]>();

    array.forEach(item => {
      const key = item.nombre;
      if (!mapa.has(key)) {
        mapa.set(key, [item]);
      } else {
        const items = mapa.get(key);
        if (items && !items.some(i => i.obligatorio === item.obligatorio && i.datoPersonal === item.datoPersonal)) {
          items.push(item);
        }
      }
    });

    const resultado: { nombre: string, id: number, obligatorio: boolean, datoPersonal: boolean }[] = [];
    mapa.forEach(items => {
      items.forEach(item => resultado.push(item));
    });

    return resultado;
  }


  eliminarDuplicadosPorRol(array: { nombre: string, id: number, visible: boolean }[]) {
    const mapa = new Map<string, { nombre: string, id: number, visible: boolean }[]>();

    array.forEach(item => {
      const key = item.nombre;
      if (!mapa.has(key)) {
        mapa.set(key, [item]);
      } else {
        const items = mapa.get(key);
        if (items && !items.some(i => i.visible === item.visible)) {
          items.push(item);
        }
      }
    });

    const resultado: { nombre: string, id: number, visible: boolean }[] = [];
    mapa.forEach(items => {
      items.forEach(item => resultado.push(item));
    });

    return resultado;
  }

  async onFilter(event: any) {
    this.loader.open();
    if (this.tabs.selectedItem() == 0) {

      this.esDesactivado = this.idsAppSelect.length > 0 && 
      !this.idsAppSelect.some(element => element.value === this.ssoService.getTicket().Aplicacion.Id);

      const promises: any = this.idsAppSelect.map(idApp => this.ciudadanosService.getLopdCiudadanos(idApp.value));


      const responses = await Promise.all(promises);

      // Unir todas las respuestas en un solo array
      let data = responses.reduce((acc, val) => acc.concat(val), []);
      this.eliminarDuplicados(data);

      // añado una columna con el nombre de la app
      data.forEach(element => {
        element.appName = this.idsAppSelect.find(x => x.value === element.idAplicacion).label;
      });

      // añadado la columna con el nombre de la app en la lista this.columns

      // si no existe la columna appName la añado
      if (!this.columns.some(x => x.text === 'App')) {
        this.columns.push({
          text: 'App', datafield: 'appName', width: '10%', editable: false, rendered: this.createTooltip('App'), filtertype: 'checkedlist', filteritems: this.idsAppSelect.map(x => x.label)
        });
      }
   
      let source = {
        datatype: 'json',
        datafields: [
          { name: 'id', type: 'number' },
          { name: 'nombre', type: 'string' },
          { name: 'obligatorio', type: 'boolean' },
          { name: 'datoPersonal', type: 'boolean' },
          { name: 'appName', type: 'string' }
        ],
        localdata: data,
        sortcolumn: 'nombre',
        sortdirection: 'asc'
      };

      this.dataAdapter = new jqx.dataAdapter(source);
      this.grid.source(this.dataAdapter);

      this.loader.close();


      //this.loadGridGeneral();
    } else {

      // Hacer las solicitudes a la API en paralelo
      const promises: any = this.idsRolFilter.map(idRol => this.ciudadanosService.getCamposVisiblesCiudadanos(idRol.value, idRol.label));

      const responses = await Promise.all(promises);


      // Unir todas las respuestas en un solo array
      let data = responses.reduce((acc, val) => acc.concat(val), []);

      // Eliminar duplicados
      data = this.eliminarDuplicadosPorRol(data);

      // Configurar la fuente de datos con los datos únicos
      let sourceConf = {
        datatype: 'json',
        datafields: [
          { name: 'id', type: 'number' },
          { name: 'nombre', type: 'string' },
          { name: 'visible', type: 'boolean' },
          { name: 'rol', type: 'string', map: 'rolName' }
        ],
        localdata: data,
        sortcolumn: 'nombre',
        sortdirection: 'asc'
      };

      this.dataAdapterConf = new jqx.dataAdapter(sourceConf);
      setTimeout(() => {
        this.gridConf.addgroup('rol');
      }, 200);
    }
    this.loader.close();
  }

  onBuscar() {

    let filtervalue = '';

    if (this.header.searchInput['nativeElement'].value.length >= 3) {
      filtervalue = this.header.searchInput['nativeElement'].value.toUpperCase();
    } else {
      filtervalue = '';
    }
    if (filtervalue.length > 2) {
      let filtergroup = new jqx.filter();
      let filter_or_operator = 1;
      let filtervalue1 = filtervalue;
      let filtercondition1 = 'contains';
      let filter1 = filtergroup.createfilter('stringfilter', filtervalue1, filtercondition1);

      filtergroup.addfilter(filter_or_operator, filter1);

      if (this.tabs.selectedItem() === 0) {
        this.grid.addfilter('nombre', filtergroup);
        this.grid.applyfilters();
      } else {
        // filtro por nombre y rol
        this.gridConf.addfilter('nombre', filtergroup);
        this.gridConf.applyfilters();

      }

    } else {
      this.grid.clearfilters();
      this.gridConf.clearfilters();

    }
  }

  onExportar() {
    const wb = xlsx.utils.book_new(); // Crear libro de trabajo

    if (this.grid.getrows().length > 0) {
      const json = JSON.parse(JSON.stringify(this.grid.getrows()));

      let campos = this.translate('Campos');
      let obligatorio = this.translate('Obligatorio');
      let datoPersonal = this.translate('Dato_personal');

      json.forEach(element => {
        delete element.id;
        delete element.uid;
        delete element.uniqueid;
        delete element.visibleindex;
        delete element.boundindex;
        delete element[''];

        element[campos] = element.nombre;
        element[obligatorio] = element.obligatorio;
        element[datoPersonal] = element.datoPersonal;

        delete element.nombre;
        delete element.obligatorio;
        delete element.datoPersonal;

        element[obligatorio] = element[obligatorio] ? 'Si' : 'No';
        element[datoPersonal] = element[datoPersonal] ? 'Si' : 'No';
      });

      const ws1 = xlsx.utils.json_to_sheet(json);
      this.generateAutofilterHeader(ws1);
      xlsx.utils.book_append_sheet(wb, ws1, this.translate('General')); // Añadir hoja al libro
    }

    const jsonConf = JSON.parse(JSON.stringify(this.gridConf.getrows()));

    let roles_app = this.translate('Roles_app');
    let campo = this.translate('Campo');

    jsonConf.forEach(element => {
      delete element.id;
      delete element.uid;
      delete element.uniqueid;
      delete element.visibleindex;
      delete element.boundindex;
      delete element[''];

      element[roles_app] = element.nombre;
      element[campo] = element.obligatorio;

      delete element.nombre;
      delete element.obligatorio;

      element[campo] = element[campo] ? 'Si' : 'No';
    });

    const ws2 = xlsx.utils.json_to_sheet(jsonConf);
    this.generateAutofilterHeader(ws2);
    xlsx.utils.book_append_sheet(wb, ws2, this.translate('Configuracion_por_rol')); // Añadir hoja al libro
    xlsx.writeFile(wb, DateUtils.formatDateAMDhms(new Date()) + '_' + this.translate('Lopd_ciudadanos') + '.xlsx');
  }

  generateAutofilterHeader(sheet) {
    sheet['!autofilter'] = { ref: sheet['!ref'] };
  }

  // Para traducir los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }


  selectionRolRenderer = (): string => {
    if (this.listRoles) {
      let selectedItems = this.listRoles.getCheckedItems();
      if (selectedItems !== undefined && selectedItems.length > 0) {
        let itemValue = selectedItems.map(x => x.label).join(', ');
        return `<div style="position: relative; margin-left: 3px; margin-top: 5px;">${itemValue}</div>`;
      } else {
        return '<div style="position: relative; margin-left: 5px; margin-top: 5px;"> Selecciona Rol  </div>';
      }
    }
  }

  ngOnDestroy(): void {
    this.window.destroy();
    this.componentRef = null;
    LopdCiudadanosComponent._this = null;
  }

  onClose() {
    this.componentRef.destroy();
    LopdCiudadanosComponent._this = null;
  }

}
