import { Component, ElementRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';

import { CustomForms } from '../forms/custom-forms';
import { AppComponent } from 'src/app/app.component';
import { DateUtils } from 'src/app/utils/date-utils';
import { MainComponent } from '../main/main.component';
import { environment } from 'src/environments/environment';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';
import { jqxRibbonComponent } from 'jqwidgets-ng/jqxribbon';

import { NzModalService } from 'ng-zorro-antd/modal';
import { CalendarService } from 'src/app/services/calendar/calendar.service';

import { Appointment } from './appointment.model';
import { CalendarModel } from 'src/app/services/calendar/models/calendar.model';

import { EditCalendarComponent } from './edit-calendar/edit-calendar.component';
import { ViewCalendarComponent } from './view-calendar/view-calendar.component';
import { Utils } from 'src/app/utils/utils';
import { SsoService } from 'src/app/services/sso/sso.service';
import * as xlsx from 'xlsx';
import { HeaderComponent } from '../header/header.component';
import { jqxDateTimeInputComponent } from 'jqwidgets-ng/jqxdatetimeinput';


@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css']
})
export class CalendarComponent extends CustomForms implements OnInit {
  @ViewChild('form') form: jqxWindowComponent;
  @ViewChild('editCalendar', { read: ViewContainerRef }) editCalendarComponent;
  @ViewChild('viewCalendar', { read: ViewContainerRef }) viewCalendarComponent;
  @ViewChild('calendar') verCalendario: jqxWindowComponent;
  @ViewChild('ribbon') ribbon: jqxRibbonComponent;
  @ViewChild('myGrid') myGrid: jqxGridComponent;
  @ViewChild('header') header: HeaderComponent;

  dataAdapter: any;
  dataAdapterScheduler: any;
  dataSource: any;
  source: any;
  //  Esto es para que los textos en los controles del grid salgan en español
  public langGrid = JqWidgets.getLocalization('es');

  private componentRef = null;
  public theme = environment.tema;
  editCalendario: boolean = false;
  editCalendar: any;
  viewCalendar: any;
  calendario: CalendarModel = new CalendarModel();
  public calendarios: CalendarModel[] = [];
  onVer: boolean = false;
  row: number;
  dias: string[] = [];
  date: any = new jqx.date(2020, 11, 23);
  public views = ['dayView', 'weekView', 'monthView'];
  static _this: any;
  appointments = new Array();
  appointment: Appointment = new Appointment();
  mapWidth: number;
  mapHeight: number;
  showLoader: boolean = true;

  constructor(private calendarService: CalendarService, private modal: NzModalService, private ssoService: SsoService) {
    super();
    CalendarComponent._this = this;
  }

  rendexTextGeneric(row: number, columnfield: string, value: any,
    defaulthtml: string, columnproperties: any, rowdata: any): string {

    if (typeof value === 'string') {
      return `<div style="margin-left: 4px; margin-top: 5px;  text-align: left;"" onmouseover="this.style.backgroundColor='gray'; this.style.color='white'; this.style.position='fixed';"onmouseout="this.style.backgroundColor=''; this.style.color=''; this.style.position='';">${value}</div>`;

    } else if (typeof value === 'number') {
      return `<div style="margin-right: 4px; margin-top: 4px;  text-align: right;"><span onmouseover="this.style.backgroundColor='gray'; this.style.color='white';" onmouseout="this.style.backgroundColor=''; this.style.color='';"> ${value}</span></div>`;
    }
  }

  cellClass = (row: number, columnfield: any, value: any): string => {
    if (value) {
      return 'cellTooltip';
    }
    return '';
  }

  cellClassCheck = (row: number, columnfield: any, value: any): string => {
    if (value) {
      return 'checkGray';
    }
    return '';
  }

  public columns: any = [
    { text: '', datafield: 'id', hidden: true },
    { text: 'Selec', columntype: 'textbox', filtertype: 'textbox', datafield: 'selec', hidden: true },
    {
      text: '',
      width: 100,
      columntype: 'text',
      sortable: false,
      editable: false,
      datafield: 'acciones',
      groupable: false,
      menu: false,
      rendered: (columnHeaderElement) => {
        let date = new Date();
        let time = date.getTime().toString();
        const buttonContainer = document.createElement('div');

        const options = {
          width: '100%',
          value: '<i class="fa fa-plus-circle fa-lg" aria-hidden="true"></i>',
          textImageRelation: 'imageAboveText',
        };
        buttonContainer.id = `buttonContainerColumn_jqxButton` + time;
        columnHeaderElement[0].appendChild(buttonContainer);
        let myButton;

        setTimeout(() => {
          try {
            buttonContainer.parentElement.style.width = '100%';

            myButton = jqwidgets.createInstance(
              `#buttonContainerColumn_jqxButton` + time,
              'jqxButton',
              options
            );

            myButton.addEventHandler('click', () => {
              this.onCrear();
            });

            let btnContainer = <HTMLElement>buttonContainer.parentElement.parentElement;
            btnContainer.style.width = '100%';
            btnContainer.style.display = 'flex';
            btnContainer.style.justifyContent = 'center';
            btnContainer.style.alignItems = 'center';

            let btn = <HTMLElement>buttonContainer.parentElement.children[2];
            btn.title = AppComponent.translate('Aniadir');
            btn.classList.add('button');
            btn.style.cursor = 'pointer';
            btn.style.width = '100%';
            btn.style.height = '25px';
            btn.style.display = 'flex';
            btn.style.justifyContent = 'center';
            btn.style.alignItems = 'center';

            let icon = <HTMLElement>btn.children[1];
            icon.style.position = 'unset';
          } catch (e) {

          }
        }, 100);
        return columnHeaderElement[0];
      },
      createwidget: (
        row: any,
        column: any,
        value: string,
        htmlElement: HTMLElement
      ): void => {
        this.initBtnColumn(row, column, value, htmlElement);
      },
      initwidget: (
        row: any,
        column: any,
        value: string,
        htmlElement: HTMLElement
      ) => {
        this.initBtnColumn(row, column, value, htmlElement);
      },
      enabletooltips: false
    },
    {
      text: AppComponent.translate('Nombre'), columntype: 'textbox', filtertype: 'textbox', datafield: 'nombre', cellsrenderer: this.rendexTextGeneric,
      aggregates: [{
        'Total': function (aggregatedValue, currentValue: number) {
          return aggregatedValue + 1;
        }
      }], //agrego el total al grid
      aggregatesrenderer: function (aggregates) {
        let renderstring = '';
        if (aggregates["Total"] !== undefined) {
          renderstring = '<div style="margin-left: 4px;">Total: ' +
            aggregates["Total"] + '</div>';
        }
        return renderstring;
      }
    },
    { text: AppComponent.translate('Observaciones'), columntype: 'textbox', filtertype: 'textbox', datafield: 'observaciones', },
    { text: AppComponent.translate('Desde'), columntype: 'datetimeinput', filtertype: 'date', width: 140, datafield: 'desde', cellsrenderer: this.renderInit },
    { text: AppComponent.translate('Hasta'), columntype: 'datetimeinput', filtertype: 'date', width: 140, datafield: 'hasta', cellsrenderer: this.renderEnd },
    { text: AppComponent.translate('Hora_inicio'), columntype: 'datetimeinput', filtertype: 'date', datafield: 'horainicio', filterable: false },
    { text: AppComponent.translate('Hora_fin'), columntype: 'datetimeinput', filtertype: 'date', datafield: 'horafin', filterable: false },
    { text: AppComponent.translate('Todos_dias'), columntype: 'checkbox', filtertype: 'checkedlist', filteritems: ['Si', 'No'], datafield: 'todosLosDias', cellclassname: this.cellClassCheck },
    { text: AppComponent.translate('Dias_semana'), columntype: 'textbox', filtertype: 'checkedlist', datafield: 'diasSemana', cellsrenderer: this.renderDiasSemana },
  ];

  ngOnInit(): void {
    this.mapHeight = document.getElementById('map-container').offsetHeight;
    this.mapWidth = document.getElementById('map-container').offsetWidth;
    this.langGrid = JqWidgets.getLocalization(this.ssoService.getTicket().Usuario.Idioma.Codigo.substring(0, 2));
  }

  updateGridLocal(calendarios: CalendarModel[]) {
    this.calendarios = calendarios;
    this.getCalendar();
  }

  resizeColumns(grid: jqxGridComponent) {
    Utils.renderSizeGrid(grid, 500, ['acciones']);
  }

  inputDate: jqxDateTimeInputComponent;
  public columnmenuopening(menu?: any, datafield?: any, height?: number | string): boolean | void {
    if (menu.length === 1) {
      const divElement: HTMLElement = menu[0];
      const dateTimeInputs = divElement.querySelectorAll('.jqx-datetimeinput');
      if (dateTimeInputs && dateTimeInputs.length > 0 && datafield !== 'desde' && datafield !== 'hasta') {
        dateTimeInputs.forEach((input: HTMLElement) => {
          const elementRef = new ElementRef(input);
          const jqxDateTimeInput = new jqxDateTimeInputComponent(elementRef);
          this.inputDate = jqwidgets.createInstance('#' + jqxDateTimeInput.elementRef.nativeElement.id, 'jqxDateTimeInput', { width: '100%', height: 25, formatString: 'HH:mm:ss' });
          this.inputDate.setOptions({ showTimeButton: true, showCalendarButton: false });
        });
      }
    }
  }

  async ngAfterViewInit(): Promise<void> {
    this.form.setTitle(AppComponent.translate('Gestion_calendarios'));
    this.addCustomForm(this.form);
    this.getCalendar();
    this.resizeColumns(this.myGrid);
  }

  // Incializa la columna de iconos
  initBtnColumn(
    row: any,
    column: any,
    value: string,
    htmlElement: HTMLElement
  ) {
    let rowdata;
    if (isNaN(row)) {
      rowdata = row.bounddata;
    } else {
      rowdata = this.myGrid.getrowdata(row);
    }
    let calendar = this.calendarios.find(cal => cal.id === rowdata.id);

    htmlElement.innerHTML = '';
    const btnContainer = document.createElement('div');
    btnContainer.style.display = 'flex';
    btnContainer.style.justifyContent = 'flex-start';
    btnContainer.style.gap = '2px';
    btnContainer.style.padding = '2px';

    const btnEdit = document.createElement('div');
    btnEdit.innerHTML = `
      <button class="button" style="height: 23px; cursor: pointer;" title="`+ AppComponent.translate('Editar') + `">
        <i class="fa-solid fa-pen-to-square"></i>
      </button>
    `;

    btnEdit.addEventListener('click', (event: any) => {
      this.row = rowdata.id;
      this.onEditar();
    });
    btnContainer.appendChild(btnEdit);

    const btnVer = document.createElement('div');
    btnVer.innerHTML = `
      <button class="button" style="height: 23px; cursor: pointer;" title="`+ AppComponent.translate('Ver') + `">
        <i class="fa-solid fa-eye"></i>
      </button>
    `;

    btnVer.addEventListener('click', (event: any) => {
      this.row = rowdata.id;
      this.onVerCalendario();
    });
    btnContainer.appendChild(btnVer);

    // Boton de borrar
    if (!calendar?.enUso) {
      const btnDelete = document.createElement('div');
      btnDelete.innerHTML = `
        <button class="button" style="height: 23px; cursor: pointer;" title="`+ AppComponent.translate('Borrar') + `">
          <i class="fa-solid fa-trash"></i>
        </button>
      `;

      btnDelete.addEventListener('click', (event: any) => {
        this.row = rowdata.id;
        this.onBorrar();
      });
      btnContainer.appendChild(btnDelete);
    }

    htmlElement.appendChild(btnContainer);
  }

  renderInit(row: number, columnfield: string, value: any,
    defaulthtml: string, columnproperties: any, rowdata: any): string {
    if (value) {
      let date = new Date(value);
      return '<div style="margin-left: 4px; margin-top: 4px">' + DateUtils.formatDate(date, true) + '</div>';
    }
  }

  renderEnd(row: number, columnfield: string, value: any,
    defaulthtml: string, columnproperties: any, rowdata: any): string {
    if (value) {
      let date = new Date(value);
      return '<div style="margin-left: 4px; margin-top: 4px">' + DateUtils.formatDate(date, true) + '</div>';
    }
  }

  renderHour(row: number, columnfield: string, value: any,
    defaulthtml: string, columnproperties: any, rowdata: any): string {

    return `<div style=" margin-top: 4px;  text-align: center;"><span onmouseover="this.style.backgroundColor='gray'; this.style.color='white';" onmouseout="this.style.backgroundColor=''; this.style.color='';"> ${value}</span></div>`;
  }



  renderDiasSemana(row: number, columnfield: string, value: any,
    defaulthtml: string, columnproperties: any, rowdata: any): string {
    // devolver los dias de la semana del calendario
    if (value) {
      return '<div style="margin-left: 4px; margin-top: 4px">' + value + '</div>';
    }
  }

  /**Inicializa el componente
  */
  init(componentRef: any) {
    this.componentRef = componentRef;
  }

  closeWindow() {
    this.form.close();
  }

  onRowClick(event: any) {
    this.row = event.args.row.bounddata.id;
  }

  onRowdoubleclick() {
    this.onEditar();
  }

  onVerCalendario() {
    this.viewCalendar = this.viewCalendarComponent.createComponent(ViewCalendarComponent);
    this.viewCalendar.instance.init(this.viewCalendar, this.calendarios, this.row);
    this.form.collapse();
  }

  onBorrar() {
    let id = this.row;
    let elem = this.calendarios.find(elem => elem.id == id);
    if (elem) {
      CalendarComponent._this.form.host[0].style['zIndex'] = 998;
      this.modal.confirm({
        nzTitle: '<i>' + AppComponent.translate('ATENCION') + '</i>',
        nzContent: AppComponent.translate('Quiere_borrar_elemento') + ': ' + elem.nombre + ' ?',
        nzCentered: true,
        nzCancelText: AppComponent.translate('CANCELAR'),
        nzOkText: AppComponent.translate('SI'),
        nzZIndex: 999,
        nzOnOk: async () => {
          await this.calendarService.deleteCalendar(elem.id);
          MainComponent.getInstance().showSuccess('ATENCION', 'Registro_borrado', 2000);
          this.calendarios.splice(this.calendarios.indexOf(elem), 1);
          this.myGrid.updatebounddata();
        }
      });
    }
  }

  onEditar() {
    // si no hay entradas no puedo editar
    if (!this.row) {
      MainComponent.getInstance().showWarning('ATENCION', 'Seleccione_registro', 2000);
      return
    }
    if (this.calendarios.length === 0) {
      MainComponent.getInstance().showWarning('ATENCION', 'Seleccione_registro', 2000);
      return
    }
    this.editCalendario = true;
    this.createComponentEditCalendar();
    this.form.collapse();
  }

  async getCalendar() {
    this.columns.forEach(column => {
      if (column.datafield !== 'acciones') {
        column.rendered = (element) => { Utils.tooltiprenderer(element) };
      }
    });

    this.calendarios = await this.calendarService.getCalendars();
    if (this.calendarios) {
      this.calendarios.forEach(cal => {
        cal['horaInicio'] = DateUtils.formatTime(new Date(cal.horaInicio1));
        cal['horaFin'] = DateUtils.formatTime(new Date(cal.horaFin1));
      });

      this.dataSource = {
        dataType: 'json',
        dataFields: [
          { name: 'id', type: 'number', map: 'id' },
          { name: 'selec' },
          { name: 'acciones', type: 'string' },
          { name: 'nombre', type: 'string', map: 'nombre' },
          { name: 'observaciones', type: 'string', map: 'observaciones' },
          { name: 'desde', type: 'date', map: 'fechaInicio' },
          { name: 'hasta', type: 'date', map: 'fechaFin' },
          { name: 'todosLosDias', type: 'checkbox', map: 'todosLosDias' },
          { name: 'diasSemana', type: 'string', map: 'diasSemana' },
          { name: 'horainicio', type: 'string', map: 'horaInicio' },
          { name: 'horafin', type: 'string', map: 'horaFin' },
        ],
        localdata: this.calendarios
      };
      this.dataAdapter = new jqx.dataAdapter(this.dataSource);

      this.resizeColumns(this.myGrid);
    }
    this.showLoader = false;
    return this.appointments;

  }

  onBuscar() {
    let filtervalue = '';

    if (this.header.searchInput['nativeElement'].value.length >= 3) {
      filtervalue = this.header.searchInput['nativeElement'].value.toUpperCase();
    } else {
      filtervalue = '';
    }

    this.calendarios.forEach(cal => {
      if (
        (cal?.nombre + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (cal?.observaciones + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (cal?.fechaInicio + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (cal?.fechaFin + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (cal?.diasSemana + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1
      ) {
        cal['selec'] = 'selec';
      } else {
        cal['selec'] = '';
      }
    });

    // Compruebo si ya he creado el filtro "selec" anteriormente
    const filters = this.myGrid.getfilterinformation();
    if (filters.find(s => s.datafield === 'selec') === undefined) {
      const filtergroup = new jqx.filter();
      filtergroup.operator = 'and';
      filtergroup.addfilter(0, filtergroup.createfilter('stringfilter', 'selec', 'equal'));
      this.myGrid.addfilter('selec', filtergroup);
    }
    this.myGrid.applyfilters();
    this.myGrid.updatebounddata('data');
  }

  onCrear() {
    this.editCalendario = false;
    this.row = null;
    this.createComponentEditCalendar();
  }

  //creo el componente
  createComponentEditCalendar() {
    if (this.editCalendario) {
      this.calendario = this.calendarios.find(cal => cal.id === this.row);
    } else {
      this.calendario = new CalendarModel();
    }
    this.editCalendar = this.editCalendarComponent.createComponent(EditCalendarComponent);
    this.editCalendar.instance.init(this.editCalendar, this.editCalendario, this.row, this.calendario);
  }

  updatefilterconditions = (type: string, defaultconditions: any): string[] => {
    return Utils.updatefilterconditions(type, defaultconditions);
  };

  public filter(cellValue?: any, rowData?: any, dataField?: string, filterGroup?: any, defaultFilterResult?: boolean): any {
    if (dataField === "todosLosDias") {
      var filters = filterGroup.getfilters();
      for (var i = 0; i < filters.length; i++) {
        var filter = filters[i];
        if (filter.value === "Si") {
          return rowData.todosLosDias;
        }
        else if (filter.value === "No") {
          return !rowData.todosLosDias;
        }
      }
    } else {
      let filterColumns = [
        'nombre',
        'observaciones',
        'diasSemana'
      ]

      return Utils.filterRow(cellValue, dataField, filterGroup, defaultFilterResult, filterColumns);
    }
  }

  eventFilter() {
    this.header.searchInput['nativeElement'].value = '';
    this.getCalendar();
  }

  eventResetFilter() {
    this.header.searchInput['nativeElement'].value = '';
    this.onBuscar();
  }

  onExportar() {
    if (this.myGrid.getrows().length === 0) {
      return MainComponent.getInstance().showWarning('ATENCION', this.translate('No_existen_datos'), 2000);
    } else {
      const json = this.myGrid.exportdata('json');
      let datos = JSON.parse(json);
      datos.forEach(element => {
        const todosDiasValue = element[this.translate('Todos_dias')];
        element[this.translate('Todos_dias')] = todosDiasValue ? 'Si' : 'No';
      });

      // Utiliza el array modificado en lugar del JSON original
      const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(datos);
      this.generateAutofilterHeader(ws);

      const wb: xlsx.WorkBook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(wb, ws, 'Hoja1');
      xlsx.writeFile(wb, DateUtils.formatDateAMDhms(new Date()) + '_' + this.translate('Calendarios') + '.xlsx');
    }
  }

  generateAutofilterHeader(sheet) {
    // Añade filtro a todas las casillas.
    sheet['!autofilter'] = { ref: sheet['!ref'] };
  }

  // Boton para imprimir
  onPrint() {
    if (this.myGrid.getrows().length === 0) {
      return MainComponent.getInstance().showWarning('ATENCION', this.translate('No_existen_datos'), 2000);
    } else {
      let gridContent = this.myGrid.exportdata('html');
      let newWindow = window.open('', '', 'width=800, height=500'),
        document = newWindow.document.open(),
        pageContent =
          '<!DOCTYPE html>\n' +
          '<html>\n' +
          '<head>\n' +
          '<meta charset="utf-8" />\n' +
          '<title>jQWidgets Grid</title>\n' +
          '</head>\n' +
          '<body>\n' +
          gridContent +
          '\n</body>\n</html>';
      document.write(pageContent);
      document.close();
      newWindow.onafterprint = function () {
        newWindow.close();
      };
      newWindow.print();
    }
  }

  onClose() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
    CalendarComponent._this = null;
  }

  // Para traducir los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }
}
