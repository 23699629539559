import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { AlarmsgeoComponent } from './alarmsgeo/alarmsgeo.component';
import { HeaderModule } from '../header/header.module';
import { SharedModule } from 'src/app/shared/shared.module';
import {NzModalModule} from 'ng-zorro-antd/modal';

@NgModule({
  declarations: [
    AlarmsgeoComponent,
  ],
    imports: [
        FormsModule,
        HeaderModule,
        CommonModule,
        BrowserModule,
        SharedModule,
        NzModalModule
    ]
})
export class AlarmsModule { }
