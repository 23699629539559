import {
  Input,
  OnInit,
  ViewChild,
  Component,
  SimpleChanges,
  ViewContainerRef
} from '@angular/core';

import { CustomForms } from '../forms/custom-forms';
import { AppComponent } from 'src/app/app.component';
import { MainComponent } from '../main/main.component';
import { environment } from 'src/environments/environment';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';

import { NzModalService } from 'ng-zorro-antd/modal';
import { CiudadanosService } from 'src/app/services/ciudadanos/ciudadanos.service';

import { CiudadanoModel } from 'src/app/services/ciudadanos/models/ciudadano.model';

import { CiudadanosEditComponent } from './ciudadanos-edit/ciudadanos-edit.component';
import { CiudadanosListadoTarjetasComponent } from './ciudadanos-listado-tarjetas/ciudadanos-listado-tarjetas.component';
import { SsoService } from 'src/app/services/sso/sso.service';
import { TicketModel } from 'src/app/services/sso/models/ticket.model';
import { Utils } from 'src/app/utils/utils';
import { DateUtils } from 'src/app/utils/date-utils';
import * as xlsx from 'xlsx';
import { MapsService } from 'src/app/services/maps/maps.service';
import { MapComponent, MapLatLng } from 'movisat-maps';
import { ConfigService } from 'src/app/services/config/config.service';

@Component({
  selector: 'app-ciudadanos',
  templateUrl: './ciudadanos.component.html',
  styleUrls: ['./ciudadanos.component.css']
})
export class CiudadanosComponent extends CustomForms implements OnInit {
  // Componentes del HTML
  @ViewChild('gridCiudadanos', { static: false }) gridCiudadanos: jqxGridComponent;
  @ViewChild('formCiudadano', { read: ViewContainerRef }) editCiudadanoComponent;
  @ViewChild('tarjetasCiudadano', { read: ViewContainerRef }) tarjetasCiudadanoComponent;
  formCiudadano: any;
  tarjetasCiudadano: any;

  // Entradas
  @Input() show = false;

  // Funcionalidad
  static _this: any;
  public showLoader = true;
  protected map: MapComponent;
  public environment = environment;
  public langGrid = JqWidgets.getLocalization('es');

  // Grid ciudadanos
  filterValue: string = '';
  private selectedRow: any[] = [];
  public ciudadanos: CiudadanoModel[] = [];

  // Comprobaciones
  firstLoad = true;
  private toolbarOk = false;
  public ecoCompostaje = false;
  public ecoIdentificadores = false;
  btnValidar;
  btnRechazar;

  // Para traducir los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  constructor(
    private ciudadanosService: CiudadanosService,
    private modal: NzModalService,
    private configService: ConfigService,
    private ssoService: SsoService,
    private mapsService: MapsService,
  ) {
    super();
    CiudadanosComponent._this = this;
  }

  public static getInstance(): CiudadanosComponent {
    return CiudadanosComponent._this;
  }

  ngOnInit(): void {
    this.langGrid = JqWidgets.getLocalization(this.ssoService.getTicket().Usuario.Idioma.Codigo.substring(0, 2));

    setTimeout(() => {
      this.map = MainComponent.getInstance().getMap();
    }, 300)

    let ticketSso: TicketModel = JSON.parse(sessionStorage.getItem('ticketSSO'));

    // Comprueba si tiene el modulo ecoidentificadores
    if (ticketSso && ticketSso.Modulos) {
      ticketSso.Modulos.forEach(ticket => {
        if (ticket.Id === 4) {
          this.ecoIdentificadores = true;
        }
      });

      // Comprueba si esta en la aplicacion de ecoCompostaje
      if (ticketSso.Aplicacion.Id == 4) {
        this.ecoCompostaje = true;
      }
    }

    this.initGrid();
  }

  // Detecta cambios en variables de entrada
  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'show':
            this.show = changes[propName].currentValue;
            if (this.show != false) {
              setTimeout(() => {
                this.getCiudadanos();
              }, 400);
            }
            break;
          default:
            break;
        }
      }
    }
  }

  countCiudadanos(): string {
    let nCiudadanos = '0 (0)';

    if (this.gridCiudadanos) {
      nCiudadanos = this.gridCiudadanos.getrows().length + ' (' + this.ciudadanos.length + ')';
    }

    return nCiudadanos;
  }

  /* GRID de ciudadanos */

  // Obtener ciudadanos
  async getCiudadanos() {
    this.showLoader = true;
    this.ciudadanos = await this.ciudadanosService.getCiudadanos();
    if (!this.ciudadanos) {
      this.ciudadanos = [];
    } else {
      this.ciudadanos.forEach(element => {
        if (element && element.catastro) {
          element.direccionCatastral = element?.catastro?.direccion != '' ? element?.catastro?.direccion : '';
          element.direccionCatastral += element?.catastro?.numero != '' ? (element?.catastro['direccionCatastral'] !== '' ? ', ' + element?.catastro?.numero : element?.catastro?.numero) : '';
        }

        if (element.estadoValidacion === 0 || element.estadoValidacion === 14) {
          element.validadoTexto = this.translate('Pendiente');
        } else if (element.estadoValidacion === 1 || element.estadoValidacion === 15) {
          element.validadoTexto = this.translate('Validado');
        } else if (element.estadoValidacion === 2 || element.estadoValidacion === 16) {
          element.validadoTexto = this.translate('Rechazado');
        }
      });

      if (this.columnsCiudadanos && this.sourceCiudadanos) {
        this.ciudadanos.sort((a, b) => (a.id > b.id ? -1 : 1));
        this.sourceCiudadanos.localdata = this.ciudadanos;

        this.gridCiudadanos.updatebounddata();

        setTimeout(() => {
          try {
            this.resizeColumns();
            this.checkSelect();
          } catch (error) {

          }
        }, 700);
      }
    }
  }

  // Renderizado de numeros en el grid
  numberrenderer(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (value) {
      return (
        '<div class="jqx-grid-cell-right-align" style="height: 100%; display: flex;justify-content: flex-end; align-items: center;">'
        + value.toString() +
        '</div>'
      );
    } else if (value === 0) {
      return (
        '<div class="jqx-grid-cell-right-align" style="height: 100%; display: flex;justify-content: flex-end; align-items: center;">'
        + value.toString() +
        '</div>'
      );
    }
  }

  // Renderizado de numero de tarjetas
  renderTarjetas(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (value) {
      return (
        '<div class="jqx-grid-cell-right-align" style="height: 100%; display: flex;justify-content: flex-end; align-items: center;">'
        + value.toString() +
        '</div>'
      );
    } else if (value === 0 || !value) {
      return (
        '<div class="jqx-grid-cell-right-align"></div>'
      );
    }
  }

  // Creacion de los botones de accion de las columnas
  async initBtnColumn(
    row: any,
    column: any,
    value: string,
    htmlElement: HTMLElement
  ) {
    htmlElement.innerHTML = '';
    const btnContainer = document.createElement('div');
    btnContainer.style.display = 'flex';
    btnContainer.style.gap = '2px';
    btnContainer.style.padding = '2px';
    btnContainer.style.height = '100%';
    btnContainer.style.justifyContent = 'start';
    btnContainer.style.alignItems = 'center';

    const btnEdit = document.createElement('div');
    btnEdit.innerHTML = `
      <button class="button" style="height: 23px; cursor: pointer;" title="`+ AppComponent.translate('Editar') + `">
        <i class="fa-solid fa-pen-to-square"></i>
      </button>
    `;

    btnEdit.addEventListener('click', (event: any) => {
      let data = this.gridCiudadanos.getrowdata(row);
      this.onEditarCiudadano(data);
    });
    btnContainer.appendChild(btnEdit);

    let rowdata;
    if (isNaN(row)) {
      rowdata = row.bounddata;
    } else {
      rowdata = this.gridCiudadanos.getrowdata(row);
    }

    let ciudadano: CiudadanoModel = this.ciudadanos.find(ciud => ciud.id === rowdata.id);

    // Muestra el boton de validar y rechazar unicamente si esta pendiente
    if (rowdata && rowdata[this.translate('Estado')] === AppComponent.translate('Pendiente')) {
      // Boton validar
      const btnValidate = document.createElement('div');
      btnValidate.innerHTML = `
        <button class="button" style="height: 23px; cursor: pointer;" title="`+ AppComponent.translate('Validar') + `">
          <i class="fa-solid fa-circle-check"></i>
        </button>
      `;

      btnValidate.addEventListener('click', async (event: any) => {
        await this.ciudadanosService.validateCiudadano(rowdata.id, 15);
        this.getCiudadanos();
      });
      btnContainer.appendChild(btnValidate);

      // Boton rechazar
      const btnDecline = document.createElement('div');
      btnDecline.innerHTML = `
        <button class="button" style="height: 23px; cursor: pointer;" title="`+ AppComponent.translate('Rechazar') + `">
          <i class="fa-solid fa-ban"></i>
        </button>
      `;

      btnDecline.addEventListener('click', async (event: any) => {
        await this.ciudadanosService.validateCiudadano(rowdata.id, 16);
        this.getCiudadanos();
      });
      btnContainer.appendChild(btnDecline);
    }

    //Boton para centrar el mapa
    if ((ciudadano?.direccion !== null && ciudadano?.direccion !== '') || (ciudadano?.catastro !== null)) {
      const btnPosicionar = document.createElement('div');
      btnPosicionar.innerHTML = `
        <button class="button" style="height: 23px; cursor: pointer;" title="`+ AppComponent.translate('Posicionar') + `">
          <i class="fa-solid fa-location-dot"></i>
        </button>
      `;

      btnPosicionar.addEventListener('click', (event: any) => {
        this.onPosicionar(ciudadano);
      });
      btnContainer.appendChild(btnPosicionar);
    }

    // Boton ver tarjetas, solo si tiene app ecoIdentificadores
    if (this.ecoIdentificadores) {
      const btnSeeCards = document.createElement('div');
      btnSeeCards.innerHTML = `
        <button class="button" style="height: 23px; cursor: pointer;" title="`+ AppComponent.translate('Ver_tarjetas') + `">
          <i class="fa-solid fa-address-card"></i>
        </button>
      `;

      btnSeeCards.addEventListener('click', (event: any) => {
        let data = this.gridCiudadanos.getrowdata(row);
        this.onSeeTarjetas(data);
      });
      btnContainer.appendChild(btnSeeCards);
    }

    // Boton de borrar
    if (rowdata && (rowdata[this.translate('Num_tarjetas')] === 0 || rowdata[this.translate('Num_tarjetas')] === null)) {
      const btnDelete = document.createElement('div');
      btnDelete.innerHTML = `
        <button class="button" style="height: 23px; cursor: pointer;" title="`+ AppComponent.translate('Borrar') + `">
          <i class="fa-solid fa-trash"></i>
        </button>
      `;

      btnDelete.addEventListener('click', (event: any) => {
        let data = this.gridCiudadanos.getrowdata(row);
        this.onBorrarCiudadano(data);
      });
      btnContainer.appendChild(btnDelete);
    }

    htmlElement.appendChild(btnContainer);
  }

  // Grid ciudadanos
  public columnsGroupCiudadanos;
  public columnsCiudadanos: any = [];
  public sourceCiudadanos;
  public adapterCiudadanos;

  // Inicializacion grid
  async initGrid() {

    let camposVisiblesPorRol: any = await this.ciudadanosService.getCamposVisiblesCiudadanos();
    camposVisiblesPorRol = camposVisiblesPorRol.filter(campo => campo.visible);

    this.columnsGroupCiudadanos = [
      { text: AppComponent.translate('Direccion_principal'), align: 'center', name: 'accionesGroup' },
    ];

    this.columnsCiudadanos = JSON.parse(await this.configService.getUsuEmpApp('columnCiudadanos-config', null));

    if (!this.columnsCiudadanos) {
      this.columnsCiudadanos = [
        {
          text: this.translate('Estado'), columntype: 'textbox', datafield: this.translate('Estado'), width: 'auto', sortable: false,
          filtertype: 'checkedlist',
          filteritems: [
            this.translate('Pendiente'),
            this.translate('Validado'),
            this.translate('Rechazado')
          ],
        },
        { text: this.translate('Num_tarjetas'), columntype: 'textbox', datafield: this.translate('Num_tarjetas'), cellsrenderer: this.renderTarjetas, width: 'auto', hidden: !this.ecoIdentificadores },
        { text: this.translate('Num_abonado'), columntype: 'textbox', datafield: this.translate('Num_abonado'), width: 'auto', hidden: true },
        { text: this.translate('Nombre'), columntype: 'textbox', datafield: this.translate('Nombre'), width: 'auto', hidden: true },
        { text: this.translate('Apellidos'), columntype: 'textbox', datafield: this.translate('Apellidos'), width: 'auto', hidden: true },
        { text: this.translate('Dni'), columntype: 'textbox', datafield: this.translate('Dni'), width: 'auto', hidden: true },
        { text: this.translate('Telefono'), columntype: 'textbox', datafield: this.translate('Telefono'), cellsrenderer: this.numberrenderer, width: 'auto', hidden: true },
        { text: this.translate('Correo_electronico'), columntype: 'textbox', datafield: this.translate('Correo_electronico'), width: 'auto', hidden: true },

        { text: this.translate('Tipo'), columntype: 'textbox', datafield: this.translate('Tipo'), width: 'auto', hidden: !this.ecoCompostaje },

        { text: this.translate('Numero_catastro'), columntype: 'textbox', datafield: this.translate('Numero_catastro'), width: 'auto', hidden: true },
        { text: this.translate('Direccion_catastro'), columntype: 'textbox', datafield: this.translate('Direccion_catastro'), width: 'auto', hidden: true },
        { text: this.translate('Titular_catastro'), columntype: 'textbox', datafield: this.translate('Titular_catastro'), width: 'auto', hidden: true },

        { text: this.translate('Direccion'), columntype: 'textbox', datafield: this.translate('Direccion'), width: 'auto', hidden: true },
        { text: this.translate('Numero'), columntype: 'textbox', datafield: this.translate('Numero'), cellsrenderer: this.numberrenderer, width: 'auto', hidden: true },
        { text: this.translate('Codigo_postal'), columntype: 'textbox', datafield: this.translate('Codigo_postal'), cellsrenderer: this.numberrenderer, width: 'auto', hidden: true },
        { text: this.translate('Poblacion'), columntype: 'textbox', datafield: this.translate('Poblacion'), width: 'auto', hidden: true },
        { text: this.translate('Municipio'), columntype: 'textbox', datafield: this.translate('Municipio'), width: 'auto', hidden: true },
        { text: this.translate('Provincia'), columntype: 'textbox', datafield: this.translate('Provincia'), width: 'auto' },
        { text: this.translate('Comunidad_autonoma'), columntype: 'textbox', datafield: this.translate('Comunidad_autonoma'), width: 'auto', hidden: true },
        { text: this.translate('Puerta'), columntype: 'textbox', datafield: this.translate('Puerta'), cellsrenderer: this.numberrenderer, width: 'auto', hidden: true },
        { text: this.translate('Planta'), columntype: 'textbox', datafield: this.translate('Planta'), cellsrenderer: this.numberrenderer, width: 'auto', hidden: true },
        { text: 'Selec', columntype: 'textbox', filtertype: 'textbox', datafield: 'selec', hidden: true, width: 1 }
        // TODO: Descomentar columna en la version 2
        //{ text: this.translate('Zona'), columntype: 'textbox', datafield: 'zona', width: 'auto', hidden: !this.ecoCompostaje }
      ];

      await this.configService.setUsuEmpApp('columnCiudadanos-config', JSON.stringify(this.columnsCiudadanos));

    }

    if (this.columnsCiudadanos && Array.isArray(this.columnsCiudadanos)) {
      const traduccionCampos: { [key: string]: string } = {
        'Nombre': 'Nombre',
        'Apellidos': 'Apellidos',
        'Correo electrónico': 'Correo electronico',
        'Código postal': 'Codigo postal',
        'Municipio': 'Municipio',
        'Comunidad autónoma': 'Comunidad autónoma',
        'Puerta': 'Puerta',
        'Planta': 'Planta',
        'Teléfono': 'Telefono',
        'Número': 'Numero',
        'Población': 'Población',
        'Provincia': 'Provincia',
        'Dirección': 'Direccion',
        'Nº Abonado': 'Numero abonado',
        'Número catastro': 'Numero castastro',
        'DNI': 'Nif',
      };

      // Creo las columnas con los campos visibles
      this.columnsCiudadanos = this.columnsCiudadanos.filter(column => {
        let nombreAPI = traduccionCampos[column.text];
        if (nombreAPI) {
          let campo = camposVisiblesPorRol.find(campo => campo.nombre === nombreAPI);
          if (campo) {
            return { ...column, text: AppComponent.translate(column.text), datafield: AppComponent.translate(column.text)};
          }
        }
      });

      this.columnsCiudadanos.forEach(column => {
        // Añadir cellrenderers
        if (['Teléfono', 'Nº Abonado', 'Número', 'Código postal'].includes(column.text)) {
          column.cellsrenderer = this.numberrenderer;
        }

        // Añadir hidden
        if (column.text === 'Número catastro') {
          column.hidden = !this.ecoIdentificadores;
        } else if (column.text === 'Tipo') {
          column.hidden = !this.ecoCompostaje;
        } else {
          column.hidden = false;
        }
      });

    }

    // añado estos dos campos al principio
    [
      { text: 'Id', columntype: 'textbox', datafield: 'id', hidden: true },
      {
        width: '195px',
        text: this.translate('Acciones'),
        columntype: 'text',
        editable: false,
        datafield: this.translate('Acciones'),
        groupable: false,
        menu: false,
        sortable: false,
        clickable: false,
        filterable: false,
        resizable: false,
        pinned: true,
        createwidget: (
          row: any,
          column: any,
          value: string,
          htmlElement: HTMLElement
        ): void => {
          this.initBtnColumn(row, column, value, htmlElement);
        },
        initwidget: (
          row: any,
          column: any,
          value: string,
          htmlElement: HTMLElement
        ) => {
          this.initBtnColumn(row, column, value, htmlElement);
        },
      },
    ].forEach(column => {
      this.columnsCiudadanos.unshift(column);
    }
    );





    this.sourceCiudadanos = {
      datatype: 'json',
      datafields: [
        { name: 'id', type: 'int', map: 'id' },
        { name: this.translate('Acciones'), type: 'string' },
        { name: this.translate('Estado'), type: 'string', map: 'validadoTexto' },
        { name: this.translate('Num_tarjetas'), type: 'int', map: 'tarjetas>length' },
        { name: this.translate('Num_abonado'), type: 'int', map: 'numeroAbonado' },

        { name: this.translate('Nombre'), type: 'string', map: 'nombre' },
        { name: this.translate('Apellidos'), type: 'string', map: 'apellidos' },
        { name: this.translate('Dni'), type: 'string', map: 'nif' },
        { name: this.translate('Telefono'), type: 'int', map: 'telefono' },
        { name: this.translate('Correo_electronico'), type: 'string', map: 'correoElectronico' },

        { name: this.translate('Tipo'), type: 'string', map: 'tipo' },

        { name: this.translate('Numero_catastro'), type: 'string', map: 'numeroCatastro' },
        { name: this.translate('Direccion_catastro'), type: 'string', map: 'direccionCatastral' },
        { name: this.translate('Titular_catastro'), type: 'string', map: 'catastro>titular' },

        { name: this.translate('Direccion'), type: 'string', map: 'direccion' },
        { name: this.translate('Numero'), type: 'int', map: 'numero' },
        { name: this.translate('Codigo_postal'), type: 'int', map: 'codigoPostal' },
        { name: this.translate('Poblacion'), type: 'string', map: 'poblacion' },
        { name: this.translate('Municipio'), type: 'string', map: 'municipio' },
        { name: this.translate('Provincia'), type: 'string', map: 'provincia' },
        { name: this.translate('Comunidad_autonoma'), type: 'string', map: 'comunidadAutonoma' },
        { name: this.translate('Puerta'), type: 'string', map: 'puerta' },
        { name: this.translate('Planta'), type: 'string', map: 'planta' },
        // TODO: Descomentar columna en la version 2
        //{ name: 'zona', type: 'int', map: 'idZona'},
        { name: 'selec', map: 'selec' }
      ],
      localdata: this.ciudadanos,
    };

    this.adapterCiudadanos = new jqx.dataAdapter(this.sourceCiudadanos);
  }

  updatefilterconditions = (type: string, defaultconditions: any): string[] => {
    return Utils.updatefilterconditions(type, defaultconditions);
  };

  public filter(cellValue?: any, rowData?: any, dataField?: string, filterGroup?: any, defaultFilterResult?: boolean): any {
    let filterColumns = [
      AppComponent.translate('Nombre'),
      AppComponent.translate('Apellidos'),
      AppComponent.translate('Correo_electronico'),
      AppComponent.translate('Direccion_catastro'),
      AppComponent.translate('Titular_catastro'),
      AppComponent.translate('Direccion'),
      AppComponent.translate('Poblacion'),
      AppComponent.translate('Municipio'),
      AppComponent.translate('Provincia'),
      AppComponent.translate('Comunidad_autonoma'),
    ]

    return Utils.filterRow(cellValue, dataField, filterGroup, defaultFilterResult, filterColumns);
  }

  // Crea los componentes de la cabecera
  createToolBar(statusbar: any) {
    if (statusbar[0] !== undefined && !CiudadanosComponent.getInstance().toolbarOk) {
      statusbar[0].style.visibility = 'inherit';
      CiudadanosComponent.getInstance().toolbarOk = true;
      // Añado el control de búsqueda a la cabecera
      const toolbarContainer = document.createElement('div');
      toolbarContainer.style.cssText = 'overflow: hidden; position: relative; margin-left: 4px; margin-top: 0px';
      const searchControl: any = document.createElement('div');
      searchControl.id = 'searchControlCiudadano';
      searchControl.style.cssText = `
        float: left;
        background-color: white;
        background-image: url('../assets/images/search.png');
        background-repeat: no-repeat;
        background-position: 4px center;
        background-size: 18px;
        display: flex;
        align-items: center;
        width: 206px;
        margin-top: 2px;
        padding-top: 2px;
        padding-left: 28px;
        height: 25px;
        border: 1px solid rgba(0, 0, 0, 0.5);
        border-radius: 3px;
        overflow: hidden;
      `;

      searchControl.innerHTML = `
        <input type="text" style="border: 0;width: 100%; outline: none;"
        placeholder="` + AppComponent.translate('Buscar') + `..."
        autocorrect = "off" autocapitalize = "off" spellcheck = "off">`;

      toolbarContainer.appendChild(searchControl);

      // Crea el botón para crear
      const btnCrear: any = document.createElement('div');
      btnCrear.id = 'btnCrearCiudadano';
      btnCrear.style.cssText = 'float: left; height: 25px; width: 85px; padding-left: 3px; padding-top: 2px;';
      btnCrear.innerHTML = `
        <button type="submit" style="width: 80px; height: 25px; align-items: center;cursor: pointer;" (onClick)="onCrearCiudadano()">
          <div style="float: right; padding-top: 2px;">`+ AppComponent.translate('Crear') + `</div>
          <img style="float: left; height: 18px; width: 18px;" src="../assets/images/mas.png" />
        </button>
      `;
      toolbarContainer.appendChild(btnCrear);

      // Crea el botón para validar
      CiudadanosComponent._this.btnValidar = document.createElement('div');
      CiudadanosComponent._this.btnValidar.id = 'btnValidarCiudadano';
      CiudadanosComponent._this.btnValidar.style.cssText = 'float: left; height: 25px; width: 85px; padding-left: 3px; padding-top: 2px;';
      CiudadanosComponent._this.btnValidar.innerHTML = `
        <button type="submit" style="width: 80px; height: 25px; align-items: center;cursor: pointer;" (onClick)="onValidateCiudadano()">
          <div style="float: right; padding-top: 2px;">`+ AppComponent.translate('Validar') + `</div>
          <img style="float: left; height: 18px; width: 18px;" src="../assets/images/ok.png" />
        </button>
      `;
      toolbarContainer.appendChild(CiudadanosComponent._this.btnValidar);

      // Crea el botón para rechazar
      CiudadanosComponent._this.btnRechazar = document.createElement('div');
      CiudadanosComponent._this.btnRechazar.id = 'btnRechazarCiudadano';
      CiudadanosComponent._this.btnRechazar.style.cssText = 'float: left; height: 25px; width: 110px; padding-left: 3px; padding-top: 2px;';
      CiudadanosComponent._this.btnRechazar.innerHTML = `
        <button type="submit" style="width: 105px; height: 25px; align-items: center;cursor: pointer;" (onClick)="onDeclineCiudadano()">
          <div style="float: right; padding-top: 2px;">`+ AppComponent.translate('Rechazar') + `</div>
          <img style="float: left; height: 18px; width: 18px;" src="../assets/images/cancel.png" />
        </button>
      `;
      toolbarContainer.appendChild(CiudadanosComponent._this.btnRechazar);

      // Añade el boton exportar a excel e imprimir
      const btnImprimir: any = document.createElement('div');
      btnImprimir.id = 'btnImprimirCiudadano';
      btnImprimir.style.cssText = 'float: right; height: 25px;padding: 2px; margin-right: 2px;';
      btnImprimir.innerHTML = `
        <button type="submit" class='button' title='`+ AppComponent.translate('Imprimir') + `' style="width: 35px; height: 25px; align-items: center;cursor: pointer;">
        <div style="float: right; padding-top: 2px;"></div>
        <i class="fa fa-print fa-lg"></i></button>
      `;
      toolbarContainer.appendChild(btnImprimir);

      const btnExportar: any = document.createElement('div');
      btnExportar.id = 'btnExportarCiudadano';
      btnExportar.style.cssText = 'float: right; height: 25px;  padding: 2px; margin-left: 5px;';
      btnExportar.innerHTML = `
        <button type="submit" class='button' title='`+ AppComponent.translate('Exportar') + `' style="width: 35px; height: 25px; align-items: center; cursor: pointer;">
        <div style="float: right; padding-top: 2px;"></div>
        <i class="fa fa-file-excel fa-lg"></i></button>
      `;
      toolbarContainer.appendChild(btnExportar);

      statusbar[0].appendChild(toolbarContainer);

      // Creo los botones y el componente de búsqueda
      CiudadanosComponent.getInstance().onBuscar();
      CiudadanosComponent.getInstance().initToolbarButtons();
    }
  }

  // Inicializa el control de búsqueda de la cabecera
  onBuscar(): void {
    const searchControl = document.getElementById('searchControlCiudadano');
    if (searchControl) {
      searchControl.addEventListener('keyup', (event: any) => {
        this.filterValue = '';

        if (event.target.value.length >= 3) {
          this.filterValue = event.target.value;
        }

        this.checkSelect();
      })
    }
  }

  checkSelect() {
    if (this.ciudadanos) {
      this.ciudadanos.forEach(ciud => {
        if (
          ciud.numeroAbonado?.compareWith(this.filterValue) ||
          ciud.nombre?.compareWith(this.filterValue) ||
          ciud.apellidos?.compareWith(this.filterValue) ||
          ciud.nif?.compareWith(this.filterValue) ||
          ciud.correoElectronico?.compareWith(this.filterValue) ||
          ciud.numeroCatastro?.compareWith(this.filterValue) ||
          ciud.direccionCatastral?.compareWith(this.filterValue) ||
          ciud.catastro?.titular?.compareWith(this.filterValue) ||
          ciud.direccion?.compareWith(this.filterValue) ||
          ciud.poblacion?.compareWith(this.filterValue) ||
          ciud.municipio?.compareWith(this.filterValue) ||
          ciud.provincia?.compareWith(this.filterValue) ||
          ciud.comunidadAutonoma?.compareWith(this.filterValue)
        ) {
          ciud['selec'] = 'selec';
        } else {
          ciud['selec'] = '';
        }
      });
    }

    // Compruebo si ya he creado el filtro "selec" anteriormente
    const filters = this.gridCiudadanos.getfilterinformation();
    if (filters.find(s => s.datafield === 'selec') === undefined) {
      const filtergroup = new jqx.filter();
      filtergroup.operator = 'and';
      filtergroup.addfilter(0, filtergroup.createfilter('stringfilter', 'selec', 'equal'));
      this.gridCiudadanos.addfilter('selec', filtergroup);
    }
    this.gridCiudadanos.applyfilters();
    this.gridCiudadanos.updatebounddata('data');
  }

  generateAutofilterHeader(sheet) {
    // Añade filtro a todas las casillas.
    sheet['!autofilter'] = { ref: sheet['!ref'] };
  }

  // Inicializa los botones de la cabecera
  initToolbarButtons(): void {
    const btnCrearCiudadano = document.getElementById('btnCrearCiudadano');
    if (btnCrearCiudadano) {
      btnCrearCiudadano.addEventListener('click', (event: any) => {
        CiudadanosComponent._this.onCrearCiudadano();
      });
    }

    const btnValidarCiudadano = document.getElementById('btnValidarCiudadano');
    if (btnValidarCiudadano) {
      btnValidarCiudadano.addEventListener('click', (event: any) => {
        CiudadanosComponent._this.onValidateCiudadano();
      });
    }

    const btnRechazarCiudadano = document.getElementById('btnRechazarCiudadano');
    if (btnRechazarCiudadano) {
      btnRechazarCiudadano.addEventListener('click', (event: any) => {
        CiudadanosComponent._this.onDeclineCiudadano();
      });
    }

    //boton exportar
    const btnExportar = document.getElementById('btnExportarCiudadano');
    if (btnExportar) {
      btnExportar.addEventListener('click', (event: any) => {
        const json = JSON.parse(JSON.stringify(this.gridCiudadanos.getdisplayrows()));
        /*
          Elimino los campos que no se quieren mostrar en el excel
        */
        json.forEach(element => {
          delete element.dataindex;
          delete element.id;
          delete element.uid;
          delete element.uniqueid;
          delete element.visibleindex;
          delete element.boundindex;
          delete element[this.translate('Acciones')];
          delete element.selec;

          if (element[this.translate('Estado')] === 0 || element[this.translate('Estado')] === 14) {
            element[this.translate('Estado')] = this.translate('Pendiente');
          } else if (element[this.translate('Estado')] === 1 || element[this.translate('Estado')] === 15) {
            element[this.translate('Estado')] = this.translate('Validado');
          } else if (element[this.translate('Estado')] === 2 || element[this.translate('Estado')] === 16) {
            element[this.translate('Estado')] = this.translate('Rechazado');
          }
        });

        const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(json);
        this.generateAutofilterHeader(ws);
        const wb: xlsx.WorkBook = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(wb, ws, 'Hoja1');
        xlsx.writeFile(wb, DateUtils.formatDateAMDhms(new Date()) + '_' + this.translate('Ciudadanos') + '.xlsx');
      });
    }

    //boton imprimir
    const btnImprimir = document.getElementById('btnImprimirCiudadano');
    if (btnImprimir) {
      btnImprimir.addEventListener('click', (event: any) => {
        if (this.gridCiudadanos.getrows().length === 0) {
          return MainComponent.getInstance().showWarning('ATENCION', this.translate('No_existen_datos'), 2000);
        } else {
          let gridContent = this.gridCiudadanos.exportdata('html');
          let newWindow = window.open('', '', 'width=800, height=500'),
            document = newWindow.document.open(),
            pageContent =
              '<!DOCTYPE html>\n' +
              '<html>\n' +
              '<head>\n' +
              '<meta charset="utf-8" />\n' +
              '<title>jQWidgets Grid</title>\n' +
              '</head>\n' +
              '<body>\n' +
              gridContent +
              '\n</body>\n</html>';
          document.write(pageContent);
          document.close();
          newWindow.onafterprint = function () {
            newWindow.close();
          };
          newWindow.print();
        }
      });
    }
  }

  // Ajusto el menu del filtro en funcion de la posicion
  columnmenuopening = (menu: any, datafield: any, height: any): void => {
    if (menu && menu[0]) {
      let menuElement = <HTMLElement>document.getElementById(menu[0].id);
      let areaGestion = <HTMLElement>document.getElementById('ciudadanoGrid').children[0];
      let column = this.gridCiudadanos.getcolumn(datafield)['element'];
      let menuTop = 0;

      /* En caso de que vaya a sobresalir por debajo de la pantalla lo ajusto hacia arriba */
      if (height > areaGestion.offsetHeight) {
        menuTop = height - areaGestion.offsetHeight + 100;

        menuElement.parentElement.style.top = '-' + menuTop + 'px';
      }

      /* Ajuste del menu para que se vea el boton de filtro en el grid */
      setTimeout(() => {
        let columnPos = column.offsetLeft + column.offsetWidth;
        let menuWidth = menuElement.offsetWidth;

        if (columnPos + menuWidth >= areaGestion.offsetWidth) {
          menuElement.parentElement.style.left = '-20px';
        } else {
          menuElement.parentElement.style.left = '20px';
        }
        /*
          Recoge los desplegables del menu del filtro
          y le añade un evento al hacer click
        */
        let dropDownFilterList = document.getElementsByClassName('jqx-dropdownlist');
        let listBox = document.getElementsByClassName('jqx-listbox-container');
        let arrListFilter: any = Array.from(dropDownFilterList);
        let arrListBox: any = Array.from(listBox);

        arrListFilter.forEach((element, idx) => {
          element.addEventListener("click", function () {
            /*
              Si el desplegable va a sobresalir le ajusta el top
              para que aparezca por encima del boton desplegable
            */
            if (document.body.offsetHeight < (arrListBox[idx].offsetTop + arrListBox[idx].offsetHeight)) {
              arrListBox[idx].style.top = (arrListBox[idx].offsetTop - (arrListBox[idx].offsetHeight + 10)) + 'px';
            }
          });
        });
      }, 0)
    }
  };

  // Ajusta el ancho de las columnas de forma automatica
  resizeColumns() {
    Utils.renderSizeGrid(this.gridCiudadanos, 200, [this.translate('Acciones')]);
    this.showLoader = false;
  }

  // Se selecciona una fila
  rowSelect(event: any) {
    this.selectedRow.push(event.args.row);

    this.selectedRow.forEach(row => {
      if (row.Estado !== AppComponent.translate('Pendiente')) {
        CiudadanosComponent._this.btnValidar.children[0].disabled = true;
        CiudadanosComponent._this.btnRechazar.children[0].disabled = true;
      }
    });
  }

  rowUnselect(event: any) {
    let index = this.selectedRow.findIndex(row => row.id === event.args.row.id);
    this.selectedRow.splice(index, 1);

    CiudadanosComponent._this.btnValidar.children[0].disabled = false;
    CiudadanosComponent._this.btnRechazar.children[0].disabled = false;

    this.selectedRow.forEach(row => {
      if (row.Estado !== AppComponent.translate('Pendiente')) {
        CiudadanosComponent._this.btnValidar.children[0].disabled = true;
        CiudadanosComponent._this.btnRechazar.children[0].disabled = true;
      }
    });
  }

  // Doble click sobre una fila
  rowDoubleClick(event: any) {
    this.onEditarCiudadano(event.args.row.bounddata)
  }
  /* FIN GRID */

  /* ACCIONES DE LOS BOTONES */
  public onCrearCiudadano() {
    this.formCiudadano = this.editCiudadanoComponent.createComponent(CiudadanosEditComponent);
    this.formCiudadano.instance.init(this.formCiudadano);
  }

  public onEditarCiudadano(event: any) {
    let ciudadano = this.ciudadanos.find(x => x?.id === event?.id);

    setTimeout(() => {
      let btnExpand = <HTMLElement>document.getElementById(`btnExpandCiudadanoEdit${ciudadano?.id}`);

      if (btnExpand) {
        btnExpand.click();
      } else {
        this.formCiudadano = this.editCiudadanoComponent.createComponent(CiudadanosEditComponent);
        this.formCiudadano.instance.init(this.formCiudadano, ciudadano);
      }
    }, 0)
  }

  public onSeeTarjetas(event: any) {
    let ciudadano = this.ciudadanos.find(x => x.id === event.id);

    this.tarjetasCiudadano = this.tarjetasCiudadanoComponent.createComponent(CiudadanosListadoTarjetasComponent);
    this.tarjetasCiudadano.instance.init(this.tarjetasCiudadano, ciudadano);
  }

  async onValidateCiudadano() {
    let noValidados: string[] = [];

    let promise = new Promise((resolve, reject) => {
      this.gridCiudadanos.getselectedrowindexes().forEach(async (element, index, array) => {
        let data = this.gridCiudadanos.getrowdata(element);
        let ciudadano = this.ciudadanos.find(ciud => ciud.id === data.id);

        if (ciudadano.estadoValidacion === 14) {
          let validado = await this.ciudadanosService.validateCiudadano(data.id, 15);

          if (!validado) {
            noValidados.push(data.nombre + ' ' + data.apellidos);
          }
        }

        if ((array.length - 1) === index) {
          setTimeout(() => {
            resolve('');
          }, 300);
        }
      });
    });

    if (noValidados.length != 0) {
      let info = '';
      noValidados.forEach(elem => {
        info += '<br>' + elem;
      });
      MainComponent.getInstance().showInfo(
        'ATENCION',
        'Error 3',
        5000,
        info
      );
    }

    promise.then(() => {
      this.getCiudadanos();
    });
  }

  async onDeclineCiudadano() {
    let promise = new Promise((resolve, reject) => {
      this.gridCiudadanos.getselectedrowindexes().forEach(async (element, index, array) => {
        let data = this.gridCiudadanos.getrowdata(element);
        let ciudadano = this.ciudadanos.find(ciud => ciud.id === data.id);

        if (ciudadano.estadoValidacion === 14) {
          await this.ciudadanosService.validateCiudadano(data.id, 16)
        }

        if ((array.length - 1) === index) {
          setTimeout(() => {
            resolve('');
          }, 300);
        }
      });
    });

    promise.then(() => {
      this.getCiudadanos();
    });
  }

  public async onPosicionar(event: any) {
    if (CiudadanosComponent._this.marker) {
      CiudadanosComponent._this.map.removeMarker(CiudadanosComponent._this.marker);
    }
    //Obtiene las coordenadas
    let coordenadas;
    if (event.direccion) {
      coordenadas = await this.mapsService.getCoordinatesByDirection(
        event.number,
        event.direccion,
        event.municipio,
        event.provincia,
        event.comunidadAutonoma,
        event.codigoPostal,
        event.poblacion
      );
    } else {
      coordenadas = await this.mapsService.getCoordinatesByDirection(
        event.catastro.number,
        event.catastro.direccion,
        event.catastro.municipio,
        event.catastro.provincia,
        event.catastro.comunidadAutonoma,
        event.catastro.codigoPostal,
        event.catastro.poblacion
      );
    }

    // Acerca y centra el mapa
    if (CiudadanosComponent._this.map) {
      CiudadanosComponent._this.map.setZoom(18);
      CiudadanosComponent._this.map.setCenter(new MapLatLng(coordenadas.lat, coordenadas.lng));
    } else {
      this.map = MainComponent.getInstance().getMap();

      CiudadanosComponent._this.map.setZoom(18);
      CiudadanosComponent._this.map.setCenter(new MapLatLng(coordenadas.lat, coordenadas.lng));
    }

    CiudadanosComponent._this.marker = CiudadanosComponent._this.map.addMarker({
      dataModel: event.id,
      title: 'Direccion',
      content: '',
      icon: '/assets/images/elemento.png',
      zIndex: 998,
      visible: true,
      position: new MapLatLng(coordenadas.lat, coordenadas.lng)
    });

    CiudadanosComponent._this.marker.animate(2850);
  }

  /* Muestra la confirmación del borrado */
  public onBorrarCiudadano(event: any) {
    let ciudadano = this.ciudadanos.find(x => x.id === event.id);

    this.modal.confirm({
      nzTitle: '<i>' + this.translate('ATENCION') + '</i>',
      nzContent: this.translate('Borrar_ciudadano') + ' "' + ciudadano.nombre + ' ' + ciudadano.apellidos + '"?',
      nzCentered: true,
      nzCancelText: this.translate('CANCELAR'),
      nzOkText: this.translate('SI'),
      nzOnOk: async () => {
        this.removeCiudadano(ciudadano);
        this.modal.closeAll();
      }
    });
  }

  /* Metodo que se llama al confirmar el borrado */
  public async removeCiudadano(ciudadano: CiudadanoModel) {
    if (await this.ciudadanosService.deleteCiudadano(ciudadano.id)) {
      this.ciudadanos.splice(this.ciudadanos.indexOf(ciudadano), 1);
      this.checkSelect();

      if (CiudadanosComponent._this.marker && CiudadanosComponent._this.marker.dataModel === ciudadano.id) {
        CiudadanosComponent._this.map.removeMarker(CiudadanosComponent._this.marker);
      }

      MainComponent.getInstance().showInfo(
        'ATENCION',
        'Registro_borrado',
        2000
      );
    } else {
      MainComponent.getInstance().showWarning(
        'Error',
        'Error_api',
        2000
      );
    }
  }
  /* FIN ACCIONES BOTONES */
}
