<div class="loader-container" *ngIf="showLoader">
  <div class="loader"></div>
</div>
<jqxWindow #form [jqxWindowAutoResize] [theme]="theme" [position]="getFormPos(form, 24)" [width]="mapWidth"
  [height]="mapHeight - 24" [zIndex]="900" [autoOpen]="true" [showCloseButton]="true" [showCollapseButton]="true"
  [resizable]="true" (onClose)="onClose()">
  <div class="formHeader" style="overflow-x: hidden;">
    {{translate('Calendarios')}}
  </div>
  <div class="form" style="float:left; overflow: hidden;">
    <div>
      <app-header #header [exportar]="true" [imprimir]="true" [buscador]="true" [filtro]="true" [resetFiltro]="true"
        (eventExportar)="onExportar()" (eventImprimir)="onPrint()" (eventBuscador)="onBuscar()"
        (eventFilter)="eventFilter()" (eventResetFilter)="eventResetFilter()">
      </app-header>
    </div>

    <jqxGrid #myGrid style="margin-top: 5px;" [theme]="theme" [width]="'100%'" [height]="'calc(100% - 100px)'"
      [source]="dataAdapter" [columnsresize]="true" [localization]="langGrid" [filterable]="true" [appCellClick]
      [showfilterrow]="false" [enablebrowserselection]="true" [columns]="columns" [showrowlines]="false"
      [columnmenuopening]='columnmenuopening' [showcolumnlines]="true" [pageable]="false" [sortable]="true"
      [altrows]="true" [showstatusbar]="true" [statusbarheight]="0" [showaggregates]="false" [rowsheight]="25"
      [columnsheight]="30" [enabletooltips]="false" [editable]="false" [groupable]="false"
      (onRowclick)="onRowClick($event)" (onRowdoubleclick)="onRowdoubleclick()"
      [updatefilterconditions]="updatefilterconditions" [filter]="filter">
    </jqxGrid>

    <div style="margin-left: 10px; display: flex; align-items: center;">
      <span style="margin-left: 15px;">{{translate('Total')}}: {{calendarios.length}}</span>
    </div>

    <div style="margin-left: 10px; display: flex; align-items: center; margin-top: 10px;">
      <jqxButton [width]="100" [height]="26" [textImageRelation]="'imageBeforeText'" [textPosition]="'left'"
        (onClick)="closeWindow()" [value]="translate('Salir')">
      </jqxButton>
    </div>
  </div>
</jqxWindow>

<div #editCalendar></div>
<div #viewCalendar></div>