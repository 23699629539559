import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SsoService } from '../sso/sso.service';
import { CiudadanoModel } from './models/ciudadano.model';
import { AuditoriaModel } from '../auditoria/models/auditoria.model';
import { Accion } from '../auditoria/models/accion.model';
import { AuditoriaService } from '../auditoria/auditoria.service';
import { MainComponent } from 'src/app/components/main/main.component';
import { CamposCiudadanosVisibleModel, LopdCiudadanoModel, RolCamposCiudadanoModel } from './models/lopdCiudadano.model';

@Injectable({
  providedIn: 'root'
})
export class CiudadanosService {
  private urlApi = this.ssoService.getTicket().UrlApi;
  private usuario = this.ssoService.getTicket().Usuario.Email;
  private auditoria: AuditoriaModel = new AuditoriaModel(this.usuario, 0);
  private empresaId: number = this.ssoService.getTicket().Empresa.IdGestion;

  constructor(private ssoService: SsoService, private http: HttpClient, private auditoriaService: AuditoriaService) { }

  msgChangeResponse(response: any): string {
    return this.auditoria.Info = 'ID: ' + response.id + ', ' + MainComponent.getInstance().translate('Dni') + ': ' + response.nif;
  }

  async saveCiudadano(ciudadanoOriginal: CiudadanoModel): Promise<CiudadanoModel> {
    let response: CiudadanoModel = null;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    const ciudadano = { ...ciudadanoOriginal };
    ciudadano.empresa = this.empresaId;
    ciudadano.creador = this.usuario;

    delete ciudadano.fechaCreacion;

    try {
      response = await this.http.post<CiudadanoModel>(
        this.urlApi + '/api/ciudadano',
        JSON.stringify(ciudadano),
        httpOptions
      ).toPromise();
      if (ciudadano.id && response) {
        this.auditoria.AccionId = Accion.EDITAR_CIUDADANO;
      } else if (response && !ciudadano.id) {
        this.auditoria.AccionId = Accion.CREAR_CIUDADANO;
      }
      this.msgChangeResponse(response);
      this.auditoriaService.addAuditoria(this.auditoria);
    } catch (e) {
      if (e.error.message === 'El ciudadano ya existe') {
        MainComponent.getInstance().showError(
          'ATENCION',
          'Ciudadano_existe',
          2000
        );
      } else {
        MainComponent.getInstance().showError(
          'ATENCION',
          'Fallo_almacenar_info',
          2000
        );
      }
      return e;
    }

    return response;
  }

  async getCiudadanos(): Promise<CiudadanoModel[]> {
    let response: CiudadanoModel[] = [];
    try {
      response = await this.http.get<CiudadanoModel[]>(this.urlApi + '/api/ciudadanos?include=all').toPromise();
    } catch (e) {
      response = [];
    }
    return response;
  }

  async getCiudadano(id: number): Promise<CiudadanoModel> {
    let ciudadano: CiudadanoModel = null;
    try {
      ciudadano = await this.http.get<CiudadanoModel>(this.urlApi + '/api/ciudadano?id=' + id + '&include=all').toPromise();
      if (ciudadano && !ciudadano.tarjetas) {
        ciudadano.tarjetas = [];
      }
    } catch (e) {
    }
    return ciudadano;
  }

  async checkCiudadanoExist(email: string): Promise<boolean> {
    let response = false;

    try {
      await this.http.get(
        this.urlApi + '/api/ciudadano/check?email=' + email
      ).toPromise().then(
        res => {
          response = true;
        }, error => {
          response = false;
        }
      );
    } catch (e) {
      response = false;
    }

    return response;
  }

  async deleteCiudadano(id: number): Promise<boolean> {
    let response = false;

    try {
      await this.http.delete(
        this.urlApi + '/api/ciudadano?id=' + id
      ).toPromise().then(
        res => {
          response = true;

          this.auditoria.AccionId = Accion.ELIMINAR_CIUDADANO;
          this.auditoria.Info = 'ID:' + id;
          this.auditoriaService.addAuditoria(this.auditoria)
        }, error => {
          response = false;
        }
      );
    } catch (e) {
      response = false;
    }

    return response;
  }

  async validateCiudadano(idCiudadano: number, validateState: number): Promise<boolean> {
    let response = false;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    let data =
    {
      'id': idCiudadano,
      'estadoValidacion': validateState
    }

    try {
      await this.http.post<CiudadanoModel>(
        this.urlApi + '/api/ciudadano/validar',
        JSON.stringify(data),
        httpOptions
      ).toPromise();

      this.auditoria.AccionId = Accion.ASOCIAR_CERRADURA_ELEMENTO;
      this.auditoria.Info = 'ID ciudadano: ' + idCiudadano + ', Estado validacion: ' + validateState;
      this.auditoriaService.addAuditoria(this.auditoria);
    } catch (e) {
      response = false;
    }

    return response;
  }

  async getLopdCiudadanos(idApp?:number): Promise<LopdCiudadanoModel[]> {
    let result = [];
    idApp = idApp ? idApp : this.ssoService.getTicket().Aplicacion.Id;
    try {
      result = await this.http.get<LopdCiudadanoModel[]>(this.urlApi + '/api/lopd/ciudadanos/list/?enterprise=' + this.ssoService.getTicket().Empresa.IdGestion + '&appId=' + idApp).toPromise();
      if (result) {
        return result;
      } else { return [] }
    } catch (error) {
      console.error('Error fetching data', error);
      return [];
    }
  }

  // Metodo para guardar una lista de datos LOPD
  async postLopdCiudadanos(data: LopdCiudadanoModel[]): Promise<LopdCiudadanoModel> {
    data.forEach(element => {
      element.empresa = this.ssoService.getTicket().Empresa.IdGestion;
      element.idAplicacion = this.ssoService.getTicket().Aplicacion.Id;
    });
    let response: LopdCiudadanoModel;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    try {
      response = await this.http.post(this.urlApi + '/api/lopd/ciudadanos/list', JSON.stringify(data), httpOptions).toPromise().then();
    } catch (e) {
      response = null;
    }
    return response;
  }

  async getCamposVisiblesCiudadanos(rolId?: number, rolName?: string): Promise<CamposCiudadanosVisibleModel[]> {
    let result: any[] = [];
    rolId = rolId ? rolId : this.ssoService.getTicket().Rol.Id;
    rolName = rolName ? rolName : this.ssoService.getTicket().Rol.Nombre;
    try {
      result = await this.http.get<CamposCiudadanosVisibleModel[]>(this.urlApi + '/api/lopd/ciudadanos/visible/list/?enterprise=' + this.ssoService.getTicket().Empresa.IdGestion + '&appId=' + this.ssoService.getTicket().Aplicacion.Id + '&rolId=' + rolId).toPromise();
      if (result) {
        // añado el nombre del rol
        result.forEach(element => {
          element.rolName = rolName;
        });
        return result;
      } else {
        return [];
      }
    } catch (error) {
      console.error('Error fetching data', error);
      return [];
    }
  }

  async postCamposVisiblesCiudadanos(data: RolCamposCiudadanoModel[], idRol: number): Promise<CamposCiudadanosVisibleModel> {
    let response: CamposCiudadanosVisibleModel = new CamposCiudadanosVisibleModel();
    response.datosLOPD = data;
    response.empresa = this.ssoService.getTicket().Empresa.IdGestion;
    response.idAplicacion = this.ssoService.getTicket().Aplicacion.Id;
    response.idRol = idRol;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    try {
      response = await this.http.post(this.urlApi + '/api/lopd/ciudadanos/visible/', JSON.stringify(response), httpOptions).toPromise().then();
    } catch (e) {
      response = null;
    }
    return response;
  }


}
