import {
  OnInit,
  Component,
  ViewChild,
  AfterViewInit,
  ViewContainerRef,
} from '@angular/core';

import {
  MapBounds,
  MapComponent,
  MapLatLng
} from 'movisat-maps';

import * as xlsx from 'xlsx';
import { GeoUtils } from 'src/app/utils/geo-utils';
import { DateUtils } from 'src/app/utils/date-utils';
import { CustomForms } from '../../forms/custom-forms';
import { NumberUtils } from 'src/app/utils/number-utils';
import { environment } from 'src/environments/environment';

import { AppComponent } from 'src/app/app.component';
import { MainComponent } from '../../main/main.component';
import { HeaderComponent } from '../../header/header.component';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';

import { SsoService } from 'src/app/services/sso/sso.service';
import { ZonesService } from 'src/app/services/zones/zones.service';

import { ZonaModel } from 'src/app/services/zones/models/zona.model';
import { ConfigService } from 'src/app/services/config/config.service';

@Component({
  selector: 'app-edificios-instalaciones-list',
  templateUrl: './edificios-instalaciones-list.component.html',
  styleUrls: ['./edificios-instalaciones-list.component.css']
})
export class EdificiosInstalacionesListComponent extends CustomForms implements OnInit, AfterViewInit {
  @ViewChild('header') header: HeaderComponent;
  @ViewChild('gridEdificios') gridEdificios: jqxGridComponent;
  @ViewChild('windowEdificios') windowEdificios: jqxWindowComponent;
  @ViewChild('formCiudadano', { read: ViewContainerRef }) editCiudadanoComponent;

  public static _this: EdificiosInstalacionesListComponent;

  protected showLoader = false;
  protected mapWidth: number = 1000;
  protected mapHeight: number = 450;

  private map: MapComponent;
  private componentRef = null;
  protected environment = environment;
  protected langGrid = JqWidgets.getLocalization('es');

  protected sourceGridEdificios;
  protected columnsGridEdificios: any = [];
  protected sourceGridEdificiosAdapter;

  private edificios: ZonaModel[] = [];
  private checkSelect: ZonaModel[] = [];

  // Define los tipos de edificio para que se renderice el texto
  private tipoEdificio = {
    60: 'Base',
    61: 'Cuartel',
    62: 'Vertedero',
    63: 'Planta_de_reciclaje',
    64: 'Planta_de_transferencia',
    65: 'Taller_externo',
    66: 'Punto_de_combustible',
    67: 'Punto_de_agua',
    192: 'Punto_limpio',
  }

  constructor(
    private ssoService: SsoService,
    private zonesService: ZonesService,
    private configService: ConfigService,
  ) {
    super();
    EdificiosInstalacionesListComponent._this = this;
  }

  protected translate(text: string): string {
    return AppComponent.translate(text);
  }

  ngOnInit(): void {
    this.map = MainComponent.getInstance().getActiveMap();

    this.mapWidth = document.getElementById('map-container').offsetWidth;
    this.mapHeight = document.getElementById('map-container').offsetHeight;

    this.langGrid = JqWidgets.getLocalization(this.ssoService.getTicket().Usuario.Idioma.Codigo.substring(0, 2));
  }

  ngAfterViewInit(): void {
    this.addCustomForm(this.windowEdificios);
  }

  onClose() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
    EdificiosInstalacionesListComponent._this = null;
  }

  init() {
    this.getEdificiosInstalaciones();
  }

  async getEdificiosInstalaciones() {
    this.showLoader = true;
    try {
      /* Obtengo las edificios que son tipo edificio instalacion */
      this.zonesService.getEdificiosInstalaciones().then(
        async (response: ZonaModel[]) =>{
          this.edificios = response;
          this.initGridEdificios();
        }
      );
    } catch (error) {
      this.showLoader = false;
    }
  }

  initGridEdificios() {
    this.columnsGridEdificios = [
      { text: '', columntype: 'textbox', filtertype: 'textbox', datafield: 'selec', hidden: true },
      {
        text: '',
        width: 100,
        columntype: 'text',
        sortable: false,
        editable: false,
        groupable: false,
        menu: false,
        clickable: false,
        filterable: false,
        resizable: false,
        rendered: (columnHeaderElement) => {
          const buttonContainer = document.createElement('div');
          buttonContainer.style.width = '100%';
          buttonContainer.style.display = 'flex';
          buttonContainer.style.justifyContent = 'center';

          buttonContainer.id = `buttonContainerColumn_jqxButton`;
          columnHeaderElement[0].appendChild(buttonContainer);
          const btnCrear = document.createElement('div');
          btnCrear.innerHTML =
            `<button class="button" style="height: 20px; width: 100%; display: flex; justify-content: center;"
              title="${AppComponent.translate('Crear')}">
              <i class="fa-solid fa-plus"></i>
            </button>`;
          btnCrear.id = `buttonCrear_jqxButton`;
          buttonContainer.appendChild(btnCrear);
          btnCrear.style.width = '100%';
          btnCrear.style.height = '100%';
          btnCrear.addEventListener('click', async (event: any) => {
          });
          return columnHeaderElement[0];
        },
        aggregates: [{
          'Total': function (aggregatedValue, currentValue: number) {
            return aggregatedValue + 1;
          }
        }],
        aggregatesrenderer: function (aggregates) {
          let renderstring = '';
          if (aggregates["Total"] !== undefined) {
            renderstring = '<div style="text-align: left; margin-left: 4px;">' + AppComponent.translate('Total') + ': ' +
              NumberUtils.format(aggregates["Total"], 0) + '</div>';
          }
          return renderstring;
        },
        createwidget: (
          row: any,
          column: any,
          value: string,
          htmlElement: HTMLElement
        ): void => {
          this.initBtnColumn(row, column, value, htmlElement);
        },
        initwidget: (
          row: any,
          column: any,
          value: string,
          htmlElement: HTMLElement
        ) => {
          this.initBtnColumn(row, column, value, htmlElement);
        },
      },
      {
        text: AppComponent.translate('Texto'),
        columntype: 'checkbox',
        filtertype: 'list',
        datafield: 'textoVisible',
        width: 52,
        filteritems: ['Si', 'No'],
        cellvaluechanging: (row, column, columntype, oldvalue, newvalue) => {
          let fila;
          if (row.boundindex) {
            fila = row.boundindex;
          } else {
            fila = row;
          }
          EdificiosInstalacionesListComponent._this.gridEdificios.selectrow(row);
          //busco en el array de columnas el que coincida con el id
          let data = this.gridEdificios.getrowdata(fila);
          let found = this.edificios.find((edificio) => edificio.Id == data.id);

          switch (found.TipoGeo) {
            case 0:
              // modifico el circulo en el mapa
              this.map['circleList'].forEach(circle => {
                if (circle.dataModel.Id == found.Id) {
                  circle.setContent(newvalue ? found.Nombre : '');
                }
              });
              break;
            case 1:
              this.map['polygonList'].forEach(polygon => {
                if (polygon['dataModel']) {
                  const objeto = polygon['dataModel'];
                  Object.keys(objeto).forEach(key => {
                    let id = objeto[key].Id;
                    if (id === found.Id) {
                      polygon.setContent(newvalue ? found.Nombre : '');
                    }
                  });
                }
              })
              break;
          }
          return newvalue;
        }
      },
      { text: AppComponent.translate('CODIGO'), columntype: 'textbox', datafield: 'id', editable: false, cellsrenderer: this.renderNumero },
      { text: AppComponent.translate('Nombre'), columntype: 'textbox', datafield: 'nombre', editable: false },
      { text: AppComponent.translate('Tipo'), columntype: 'textbox', datafield: 'tipo', editable: false, cellsrenderer: this.renderTipo },
      { text: AppComponent.translate('Domicilio'), columntype: 'textbox', datafield: 'domicilio', editable: false },
      { text: AppComponent.translate('Poblacion'), columntype: 'textbox', datafield: 'poblacion', editable: false },
      { text: AppComponent.translate('Provincia'), columntype: 'textbox', datafield: 'provincia', editable: false },
      { text: AppComponent.translate('Geometria'), columntype: 'textbox', datafield: 'geometria', editable: false, cellsrenderer: this.renderGeometria },
      { text: AppComponent.translate('Radio'), columntype: 'textbox', datafield: 'radio', editable: false, cellsrenderer: this.renderNumero },
      { text: AppComponent.translate('Superficie'), columntype: 'textbox', datafield: 'superficie', editable: false, cellsrenderer: this.renderNumero },
      { text: AppComponent.translate('Observaciones'), columntype: 'textbox', datafield: 'observaciones', editable: false },
    ];

    this.sourceGridEdificios = {
      datatype: 'json',
      datafields: [
        { name: 'selec', type: 'string', map: 'selec' },
        { name: 'textoVisible', type: 'bool', map: 'TextoVisible' },
        { name: 'id', type: 'number', map: 'Id' },
        { name: 'nombre', type: 'string', map: 'Nombre' },
        { name: 'tipo', type: 'number', map: 'TipoBDT' },
        { name: 'domicilio', type: 'string', map: 'Domicilio' },
        { name: 'poblacion', type: 'string', map: 'Poblacion' },
        { name: 'provincia', type: 'string', map: 'Provincia' },
        { name: 'geometria', type: 'number', map: 'TipoGeo' },
        { name: 'radio', type: 'number', map: 'Radio' },
        { name: 'superficie', type: 'number', map: 'Area' },
        { name: 'observaciones', type: 'string', map: 'Observaciones' },
      ],
      localdata: this.edificios
    };

    this.sourceGridEdificiosAdapter = new jqx.dataAdapter(this.sourceGridEdificios);
    this.showLoader = false;
  }

  public async saveFilter() {
    let edificios: any[] = [];

    let rowsSelec: any = this.gridEdificios.getselectedrowindexes();
    // obtengo las filas del grid que tienen la propiedad textoVisible a true
    setTimeout(async () => {
      let rowsConTexto = await this.getCheckConTexto();
      if (rowsSelec.length !== 0) {
        rowsSelec.forEach(i => {
          edificios.push({id: this.edificios[i].Id});
        });

        rowsConTexto.forEach(row => {
          if (edificios.find(edificio => edificio.id == row.Id) !== undefined) {
            return edificios.find(edificio => edificio.id == row.Id).TextoVisible = row.TextoVisible;
          }
        });
      }
      // Guardo el filtro de edificios visibles
      await this.configService.setUsuEmpApp('zones-filter', JSON.stringify(edificios));
      // Notifico que se ha cambiado el filtro de edificios visibles
      this.zonesService.loadZones();
      this.zonesService.setFilterVisible();
    }, 200);
  }

  encuadrarEdificioSelec(zonas: ZonaModel[], map?: MapComponent) {
    let globalSWPoint = new MapLatLng(180, 90);
    let globalNEPoint = new MapLatLng(-180, -90);
    // Procesa cada zona
    zonas.forEach(zona => {
      let swPoint, nePoint;
      if (zona.TipoGeo === 0) { // Si es un círculo
        let center = new MapLatLng(zona.Lat, zona.Lng);
        swPoint = GeoUtils.getNewLatLng(center, -zona.Radio, -zona.Radio);
        nePoint = GeoUtils.getNewLatLng(center, zona.Radio, zona.Radio);
      } else { // Si es un polígono
        let bounds = this.calculatePolygonBounds(zona.Geometria); // Devuelve un objeto con swPoint y nePoint
        swPoint = bounds.swPoint;
        nePoint = bounds.nePoint;
      }
      globalSWPoint.lat = Math.min(globalSWPoint.lat, swPoint.lat);
      globalSWPoint.lng = Math.min(globalSWPoint.lng, swPoint.lng);
      globalNEPoint.lat = Math.max(globalNEPoint.lat, nePoint.lat);
      globalNEPoint.lng = Math.max(globalNEPoint.lng, nePoint.lng);
    });
    map.fitTo(new MapBounds(globalSWPoint, globalNEPoint));
  }

  calculatePolygonBounds(geoJson) {
    let arrLat = [];
    let arrLng = [];
    let arrayCoordenadas = geoJson.geometry.coordinates;
    arrayCoordenadas.forEach(polygon => {
      polygon[0].forEach(coord => {
        arrLat.push(coord[1]);
        arrLng.push(coord[0]);
      });
    });
    let swPoint = new MapLatLng(Math.min(...arrLat), Math.min(...arrLng));
    let nePoint = new MapLatLng(Math.max(...arrLat), Math.max(...arrLng));
    return {swPoint, nePoint};
  }

  async getCheckConTexto() {
    if (this.gridEdificios) {
      let rows: any = this.gridEdificios.getrows();
      let rowsConTexto: any = [];
      if (rows) {
        rows.forEach(row => {
          if (row.textoVisible) {
            rowsConTexto.push({Id: row.id, TextoVisible: row.textoVisible});
          }
        });
      }
      return rowsConTexto;
    }
  }

  /* RENDERIZADOS COLUMNA ACCIONES Y TEXTOS GRID */
  async initBtnColumn(
    row: any,
    column: any,
    value: string,
    htmlElement: HTMLElement
  ) {
    htmlElement.innerHTML = '';

    let rowdata;
    if (isNaN(row)) {
      rowdata = row.bounddata;
    } else {
      rowdata = this.gridEdificios.getrowdata(row);
    }

    const btnContainer = document.createElement('div');
    btnContainer.style.display = 'flex';
    btnContainer.style.justifyContent = 'center';
    btnContainer.style.gap = '2px';
    btnContainer.style.padding = '2px';

    const btnEditar = document.createElement('div');
    btnEditar.innerHTML = `
      <button class="button" style="height: 23px; width: 25px; padding: 0; margin: 0; cursor: pointer !important;" title="`+AppComponent.translate('Editar')+`">
        <i class="fa-solid fa-pen-to-square"></i>
      </button>
    `;
    btnEditar.id = `buttonEdite_jqxButton`;
    btnContainer.appendChild(btnEditar);

    btnEditar.addEventListener('click', async (event: any) => {

    });

    const btnCentrar = document.createElement('div');
    btnCentrar.innerHTML = `
      <button class="button" style="height: 23px; width: 25px; padding: 0; margin: 0; cursor: pointer !important;" title="`+AppComponent.translate('Centrar_mapa')+`">
        <i class="fa-solid fa-location-dot"></i>
      </button>
    `;
    btnCentrar.id = `buttonCenter_jqxButton`;
    btnContainer.appendChild(btnCentrar);

    btnCentrar.addEventListener('click', async (event: any) => {
      let edificio = this.edificios.find(edificio => edificio.Id == rowdata.id);
      this.onVer(true, edificio);
    });

    const btnDelete = document.createElement('div');
    btnDelete.innerHTML = `
      <button class="button" style="height: 23px; width: 25px; padding: 0; margin: 0; cursor: pointer !important;" title="`+AppComponent.translate('Borrar')+`">
        <i class="fa-solid fa-trash"></i>
      </button>
    `;
    btnDelete.id = `buttonDelete_jqxButton`;
    btnContainer.appendChild(btnDelete);

    btnDelete.addEventListener('click', async (event: any) => {

    });

    htmlElement.appendChild(btnContainer);
  }

  renderTipo(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if(value in EdificiosInstalacionesListComponent._this.tipoEdificio){
      return (
        `<div class="jqx-grid-cell-left-align" style="margin-top: 4px">
          ${AppComponent.translate(EdificiosInstalacionesListComponent._this.tipoEdificio[value])}
        </div>`
      );
    }else {
      return (
        `<div class="jqx-grid-cell-left-align" style="margin-top: 4px">
        </div>`
      );
    }
  }

  renderGeometria(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    switch(value) {
      case 0:
        return (
          `<div class="jqx-grid-cell-left-align" style="margin-top: 4px">
            ${AppComponent.translate('Circulo')}
          </div>`
        );
      break;
      case 1:
        return (
          `<div class="jqx-grid-cell-left-align" style="margin-top: 4px">
            ${AppComponent.translate('Poligono')}
          </div>`
        );
      break;
      default:
        return (
          `<div class="jqx-grid-cell-left-align" style="margin-top: 4px">
          </div>`
        );
    }
  }

  renderNumero(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if ((value !== null || value !== undefined) && value !== 0) {
      return (
        `<div class="jqx-grid-cell-right-align" style="margin-top: 4px">
          ${NumberUtils.format(value, 0)}
        </div>`
      );
    } else {
      return (
        `<div class="jqx-grid-cell-right-align" style="margin-top: 4px">
        </div>`
      );
    }
  }

  /* FUNCIONALIDAD BOTONES INFERIORES */
  closeWindow(){
    this.windowEdificios.close();
  }

  async onVer(isColumn: boolean, edificio = null) {
    if (isColumn) {
      let edificios = [];
      this.encuadrarEdificioSelec(edificios, this.map);

      let rowsConTexto = await this.getCheckConTexto();
      if (edificio) {
        edificios.push({id: edificio.Id});
      }

      rowsConTexto.forEach(row => {
        if (edificios.find(edificio => edificio.id == row.Id) !== undefined) {
          return edificios.find(edificio => edificio.id == row.Id).TextoVisible = row.TextoVisible;
        }
      });

      await this.configService.setUsuEmpApp('zones-filter', JSON.stringify(edificios));
      this.zonesService.loadZones();
      this.zonesService.setFilterVisible();

      this.windowEdificios.collapse();
    } else {
      this.encuadrarEdificioSelec(this.checkSelect, this.map);
      this.saveFilter();
    }
  }

  /* FUNCIONALIDAD GRID EDIFICIOS */
  onEditarEdificio() {

  }

  onChecked(event: any) {
    let idRow: number[] = this.gridEdificios.getselectedrowindexes();
    let rows: any[] = this.gridEdificios.getrows();
    this.checkSelect = [];
    rows.forEach((element, index) => {
      if (idRow.includes(element.boundindex)) {
        let edificio = this.edificios.find((elem) => elem.Id == element.id);
        this.checkSelect.push(edificio);
      }
    });
    this.onVer(false);
  }

  onUnselect(event: any) {
    if (event.args.row) {
      this.gridEdificios.getrowdatabyid(event.args.rowindex).textoVisible = false;
      this.onVer(false);
    }
  }

  /* FUNCIONALIDAD CABECERA */
  async eventFilter() {
    this.header.searchInput['nativeElement'].value = '';
    this.getEdificiosInstalaciones();
  }

  async eventResetFilter() {
    this.header.searchInput['nativeElement'].value = '';
    this.onBuscar();
  }

  onBuscar() {
    let filtervalue = '';

    if (this.header.searchInput['nativeElement'].value.length >= 3) {
      filtervalue = this.header.searchInput['nativeElement'].value.toUpperCase();
    } else {
      filtervalue = '';
    }

    this.edificios.forEach(zon => {
      if (
        zon?.Nombre?.toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        zon?.TipoNombre?.toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        zon?.TipoGeoNombre?.toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        zon?.Observaciones?.toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (zon?.Radio + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (zon?.Area + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1
      ) {
        zon['selec'] = 'selec';
      } else {
        zon['selec'] = '';
      }
    });

    // Compruebo si ya he creado el filtro "selec" anteriormente
    const filters = this.gridEdificios.getfilterinformation();
    if (filters.find(s => s.datafield === 'selec') === undefined) {
      const filtergroup = new jqx.filter();
      filtergroup.operator = 'and';
      filtergroup.addfilter(0, filtergroup.createfilter('stringfilter', 'selec', 'equal'));
      this.gridEdificios.addfilter('selec', filtergroup);
    }
    this.gridEdificios.applyfilters();
    this.gridEdificios.updatebounddata('data');
  }

  onExportar() {
    if (this.gridEdificios.getrows().length === 0) {
      return MainComponent.getInstance().showWarning('ATENCION', this.translate('No_existen_datos'), 2000);
    } else {
      const json = this.gridEdificios.exportdata('json');
      let datos = JSON.parse(json);
      const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(datos);

      ws['!autofilter'] = {ref: ws['!ref']};
      const wb: xlsx.WorkBook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(wb, ws, 'Hoja1');
      xlsx.writeFile(wb, DateUtils.formatDateAMDhms(new Date()) + '_' + this.translate('edificios') + '.xlsx');
    }
  }

  onPrint(event: any) {
    if (this.gridEdificios.getrows().length === 0) {
      return MainComponent.getInstance().showWarning('ATENCION', AppComponent.translate('No_existen_datos'), 2000);
    } else {
      this.gridEdificios.hidecolumn('Botones');
      let gridContent = this.gridEdificios.exportdata('html');
      let newWindow = window.open('', '', 'width=800, height=500'),
        document = newWindow.document.open(),
        pageContent =
          '<!DOCTYPE html>\n' +
          '<html>\n' +
          '<head>\n' +
          '<meta charset="utf-8" />\n' +
          '<title>jQWidgets Grid</title>\n' +
          '</head>\n' +
          '<body>\n' +
          gridContent +
          '\n</body>\n</html>';
      this.gridEdificios.showcolumn('Botones');
      document.write(pageContent);
      document.close();
      newWindow.onafterprint = function() {
        newWindow.close();
      };
      newWindow.print();
    }
  }
}
