import { Component, OnInit, ViewChild } from '@angular/core';

import { AppComponent } from 'src/app/app.component';
import { CustomForms } from '../../forms/custom-forms';
import { environment } from 'src/environments/environment';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';

import { NzModalService } from 'ng-zorro-antd/modal';
import { AuditoriaService } from 'src/app/services/auditoria/auditoria.service';

import { AccionModel } from 'src/app/services/auditoria/models/accion.model';
import { Utils } from 'src/app/utils/utils';
import { SsoService } from 'src/app/services/sso/sso.service';
import { HeaderComponent } from '../../header/header.component';
import * as xlsx from 'xlsx';
import { DateUtils } from 'src/app/utils/date-utils';
import { MainComponent } from '../../main/main.component';
import { AplicacionModel } from 'src/app/services/sso/models/aplicacion.model';

@Component({
  selector: 'app-acciones',
  templateUrl: './acciones.component.html',
  styleUrls: ['./acciones.component.css']
})
export class AccionesComponent extends CustomForms implements OnInit {
  public static _this: AccionesComponent;

  //  Esto es para que los textos en los controles del grid salgan en español
  public langGrid = JqWidgets.getLocalization('es');
  public accion: AccionModel = new AccionModel();
  private componentRef = null;
  public theme = environment.tema;
  row: number;
  public menus: any[] = [];
  apps: AplicacionModel[] = [];

  @ViewChild('myGrid') myGrid: jqxGridComponent;
  @ViewChild('form') form: jqxWindowComponent;
  @ViewChild('header') header: HeaderComponent;

  acciones: AccionModel[] = [];
  viewForm: boolean = false;
  mapHeight: number;
  mapWidth: number;

  constructor(private auditoriaService: AuditoriaService, private modal: NzModalService, private ssoService: SsoService) {
    super();
    AccionesComponent._this = this;
  }

  async ngOnInit(): Promise<void> {
    this.mapHeight = document.getElementById('map-container').offsetHeight;
    this.mapWidth = document.getElementById('map-container').offsetWidth;
    this.langGrid = JqWidgets.getLocalization(this.ssoService.getTicket().Usuario.Idioma.Codigo.substring(0, 2));
    this.apps = await this.auditoriaService.getApps();
  }

  /**Inicializa el componente
  */
  ngAfterViewInit() {
    this.form.setTitle(AppComponent.translate('Acciones'));
    this.addCustomForm(this.form);
  }

  init(componentRef: any) {
    this.componentRef = componentRef;
  }

  rowdetailstemplateApp = (index: any): any => {
    var details = {
      rowdetails: '<div id="nestedGridApp" style="margin: 10px;"></div>',
      rowdetailsheight: 200,
      rowdetailshidden: true,
    };
    return details;
  };

  sortedColumn: any;

  customsortfunc = (column: any, direction: string | boolean): void => {
    let sortdata = new Array();
    if (direction == 'ascending') {
      direction = true;
    }
    if (direction == 'descending') {
      direction = false;
    }
    if (direction != null) {
      for (let i = 0; i < this.acciones.length; i++) {
        sortdata.push(this.acciones[i]);
      }
    } else {
      sortdata = this.acciones;
    }
    this.sortedColumn = column;

    if (direction != null) {
      sortdata.sort(this.compare);
      if (!direction) {
        sortdata.reverse();
      }
    }

    this.dataSource.localdata = sortdata;
    this.myGrid.updatebounddata('sort');

  };

  compare = (value1: any, value2: any): any => {
    if (this.sortedColumn == 'Nombre') {
      value1 = value1.Nombre;
      value2 = value2.Nombre;
    } else if (this.sortedColumn == 'Nombre') {
      value1 = value1 = '' ? null : value1[this.sortedColumn];
      value2 = value2 = '' ? null : value2[this.sortedColumn];
    } else {
      value1 = value1[this.sortedColumn];
      value2 = value2[this.sortedColumn];
    }

    try {
      let tmpvalue1 = parseFloat(value1);
      if (isNaN(tmpvalue1)) {
        let compare = value1.localeCompare(value2);
        return compare;
      } else {
        let tmpvalue2 = parseFloat(value2);
        if (tmpvalue1 < tmpvalue2) {
          return -1;
        }
        if (tmpvalue1 > tmpvalue2) {
          return 1;
        }
      }
    } catch (error) {
      let er = error;
    }
    return 0;
  };


  nestedGrids: any[] = [];
  initrowdetailsApp = (index: any, parentElement: any, gridElement: any, record: any): void => {

    if (parentElement) {
      let nestedGridContainer = parentElement.children[0];
      this.nestedGrids[index] = nestedGridContainer;

      let data = record.Aplicaciones;

      this.apps.forEach((app, index) => {

        let appData = data.find((d) => d.AplicacionId === app.Id);

        if (appData !== undefined) {
          data.push({
            Id: appData.Id,
            AplicacionId: appData.AplicacionId,
            NombreApp: app.Nombre,
          });
        }
      });

      data = data.filter((app, index, self) =>
        index === self.findIndex((t) => (
          t.AplicacionId === app.AplicacionId && t.NombreApp
        ))
      );

      let dataSourceApp = {
        sort: this.customsortfunc,
        datatype: 'json',
        datafields: [
          { name: 'id', type: 'string', map: 'Id' },
          { name: 'accionId', type: 'string', map: 'AccionId' },
          { name: 'AplicacionId', type: 'string', map: 'AplicacionId' },
          { name: 'nombreApp', type: 'string', map: 'NombreApp' },
        ],
        localdata: data,
        sortcolumn: 'accionId',
        sortdirection: 'asc'
      };
      let dataAdapterApp = new jqx.dataAdapter(dataSourceApp);

      let columnsApp = [
        { text: 'Id', datafield: 'id', width: 50, hidden: true },
        {
          text: this.translate('Aplicacion_id'), datafield: 'AplicacionId', width: 60, rendered: this.createTooltip('AplicacionId'),
          aggregates: [{
            'Total': function (aggregatedValue, currentValue: number) {
              return aggregatedValue + 1;
            }
          }], //agrego el total al grid
          aggregatesrenderer: function (aggregates) {
            let renderstring = '';
            if (aggregates['Total'] !== undefined) {
              renderstring = '<div style="margin-left: 4px;">' + 'Total: ' +
                aggregates['Total'] + '</div>';
            }
            return renderstring;
          },
        },
        { text: this.translate('Aplicacion'), datafield: 'nombreApp', width: 150, type: 'string', rendered: this.createTooltip('Aplicación') },
      ];

      let nestedGridApp = jqwidgets.createInstance(nestedGridContainer, 'jqxGrid', {
        width: '95%',
        height: '95%',
        source: dataAdapterApp,
        columns: columnsApp,
        theme: environment.tema,
        filterable: true,
        localization: this.langGrid,
        cellhover: this.cellhover,
        statusbarheight: 20,
        sortable: true,
        columnsheight: 25,
        rowsheight: 20,
        altrows: true,
        groupsheaderheight: 25,
        showaggregates: true,
        showstatusbar: true,
        columnsresize: true,
        groupable: true,
      });
    }
  }


  accionMenurennderer = (row: number, column: any, value: any, rowData: any): string => {
    if (value) {
      value = value[0];
      if (this.menus.length) {
        for (const menu of this.menus) {
          if (menu.Opciones && menu.Opciones.length > 0) {
            for (const opcion of menu?.Opciones) {
              if (value?.MenuOpcionId === opcion.Id) {
                return `<div style="margin: 4px; margin-top: 8px; float: left;">${opcion.TextoId}</div>`;
              }
            }
          }
        }
      }
    } else {
      return '';
    }
  };


  createTooltip = (content: string) => {
    return (columnHeaderElement?: any) => {
      const jqElement = columnHeaderElement[0].parentElement;
      const options = {
        content: content,
        theme: environment.tema,
        width: 'auto',
        height: 'auto',
        position: 'mouse',
        autoHide: true,
      };
      jqwidgets.createInstance(jqElement, 'jqxTooltip', options);
    };
  };

  cellhover = (element: any, pageX: any, pageY: any) => {
    // muestro un tooltip con el contenido de la celda
    if (element.innerText !== undefined && element.innerText !== '') {
      const options = {
        content: element.innerText,
        theme: environment.tema,
        position: 'mouse',
        autoHide: true,
      };
      jqwidgets.createInstance(element, 'jqxTooltip', options);
    } else {
      return;
    }
  };


  filter = (cellValue, rowData, dataField, filterGroup, defaultFilterResult): boolean => {
    if (dataField == 'Especifico') {
      let filterValue = filterGroup.getfilters()[0].value;

      switch (filterValue) {
        case 'Si':
          return cellValue;

        case 'No':
          return !cellValue;

        default:
          return defaultFilterResult;

      }
    }
  };

  //Configuración del grid
  columns = [
    {
      text: this.translate('Nombre'), datafield: 'Nombre', width: 150, type: 'string', rendered: this.createTooltip('Nombre'),
      aggregates: [{
        'Total': function (aggregatedValue, currentValue: number) {
          return aggregatedValue + 1;
        }
      }], //agrego el total al grid
      aggregatesrenderer: function (aggregates) {
        let renderstring = '';
        if (aggregates['Total'] !== undefined) {
          renderstring = '<div style="margin-left: 4px;">' + 'Total: ' +
            aggregates['Total'] + '</div>';
        }
        return renderstring;
      },
    },
    { text: this.translate('Descripcion'), datafield: 'Observaciones', width: 200, type: 'string', rendered: this.createTooltip('Descripción') },
    { text: this.translate('Accion_menu'), datafield: 'AccionMenu', width: 200, columntype: 'string', cellsrenderer: this.accionMenurennderer, rendered: this.createTooltip('Acción Menú') },
    { text: this.translate('Aplicacion'), datafield: 'Especifico', columntype: 'checkbox', filtertype: 'checkedlist', width: 50, filteritems: ['Si', 'No'], rendered: this.createTooltip('Aplicación') },
  ];

  dataSource: any = {
    datatype: 'json',
    datafields: [
      { name: 'Id', type: 'string', map: 'Id' },
      { name: 'Nombre', type: 'string', map: 'Nombre' },
      { name: 'Observaciones', type: 'string', map: 'Observaciones' },
      { name: 'AccionMenu', type: 'object', map: 'Apartados' },
      { name: 'Especifico', type: 'bool', map: 'Especifico' },
      { name: 'Aplicaciones', type: 'array', map: 'Aplicaciones' },
    ],
    localdata: [],
    sortcolumn: 'nombre',
    sortdirection: 'asc'
  };
  dataAdapter: any = new jqx.dataAdapter(this.dataSource);

  updatefilterconditions = (type: string, defaultconditions: any): string[] => {
    return Utils.updatefilterconditions(type, defaultconditions);
  };

  // public filter(cellValue?: any, rowData?: any, dataField?: string, filterGroup?: any, defaultFilterResult?: boolean): any {
  //   let filterColumns = [
  //     'Nombre',
  //     'Observaciones'
  //   ]

  //   return Utils.filterRow(cellValue, dataField, filterGroup, defaultFilterResult, filterColumns);
  // }

  onFilter($event: any) {
    this.auditoriaService.getAcciones().then((data: any) => {
      this.acciones = data;
      this.dataSource.localdata = this.acciones;
      this.myGrid.updatebounddata();
      this.myGrid.sortby('Nombre', 'asc');
    });
  }

  onPrint($event: any) {
    if (this.myGrid.getrows().length === 0) {
      return MainComponent.getInstance().showWarning('ATENCION', this.translate('No_existen_datos'), 2000);
    } else {
      let gridContent = this.myGrid.exportdata('html');
      let newWindow = window.open('', '', 'width=800, height=500'),
        document = newWindow.document.open(),
        pageContent =
          '<!DOCTYPE html>\n' +
          '<html>\n' +
          '<head>\n' +
          '<meta charset="utf-8" />\n' +
          '<title>jQWidgets Grid</title>\n' +
          '</head>\n' +
          '<body>\n' +
          gridContent +
          '\n</body>\n</html>';
      document.write(pageContent);
      document.close();
      newWindow.onafterprint = function () {
        newWindow.close();
      };
      newWindow.print();
    }
  }


  onExportar() {

    if (this.myGrid.getrows().length === 0) {
      return MainComponent.getInstance().showWarning('ATENCION', this.translate('No_existen_datos'), 2000);
    } else {

      const wb = xlsx.utils.book_new();
      const json = this.myGrid.getrows().map(row => ({
        Nombre: row.Nombre,
        Descripcion: row.Observaciones,
        Accion_menu: row.Accion_menu,
        Aplicacion: row.Especifico ? 'Si' : 'No'
      }));

      // Traducir encabezados
      const headers = [
        { key: 'Nombre', title: this.translate('Nombre') },
        { key: 'Descripcion', title: this.translate('Descripcion') },
        { key: 'Accion_menu', title: this.translate('Accion_menu') },
        { key: 'Aplicacion', title: this.translate('Aplicacion') }
      ];

      const headerMap = headers.reduce((acc, header) => {
        if (!acc[header.title]) {
          acc[header.title] = header.key;
        }
        return acc;
      }, {});

      const uniqueHeaders = Object.keys(headerMap);
      const wsData = [uniqueHeaders];
      json.forEach(row => {
        const rowData = uniqueHeaders.map(header => row[headerMap[header]]);
        wsData.push(rowData);
      });

      const ws = xlsx.utils.aoa_to_sheet(wsData);

      // Añadir filtros a las columnas
      ws['!autofilter'] = { ref: `A1:${String.fromCharCode(65 + uniqueHeaders.length - 1)}1` };

      // Agregar la hoja al libro
      xlsx.utils.book_append_sheet(wb, ws, 'Acciones');

      // Escribir el archivo
      const fileName = DateUtils.formatDateAMDhms(new Date()) + '_' + this.translate('Acciones') + `.xlsx`;
      xlsx.writeFile(wb, fileName);

    }
  }


  onBuscar() {

    let filtervalue = '';
    if (this.header.searchInput['nativeElement'].value.length >= 3) {
      filtervalue = this.header.searchInput['nativeElement'].value.toUpperCase();
    } else {
      filtervalue = '';
    }

    if (filtervalue.length >= 3) {

      this.myGrid.clearfilters();
      let filtergroup = new jqx.filter();
      let filter_or_operator = 1;
      let filtervalue1 = filtervalue;
      let filtercondition1 = 'contains';
      let filter1 = filtergroup.createfilter('stringfilter', filtervalue1, filtercondition1);
      filtergroup.addfilter(filter_or_operator, filter1);

      this.myGrid.addfilter('Nombre', filtergroup);
      this.myGrid.applyfilters();

    } else {
      this.myGrid.clearfilters();
    }
  }

  eventResetFilter() {

    this.myGrid.clearfilters();
    this.header.searchInput['nativeElement'].value = '';
    if (this.dataSource) {
      this.dataSource.localdata = [];
      this.myGrid.updatebounddata();
    }
  }


  ngOnDestroy(): void {
    this.form.destroy();
    this.componentRef = null;
    AccionesComponent._this = null;
  }

  onClose() {
    this.componentRef.destroy();
    AccionesComponent._this = null;
  }

  // Para traducir los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }
}
