import {
  Input,
  OnInit,
  ViewChild,
  Component,
  SimpleChanges,
} from '@angular/core';

import {
  MapMarker,
  MapLatLng,
  MapComponent,
} from 'movisat-maps';

import { AppComponent } from 'src/app/app.component';
import { DateUtils } from 'src/app/utils/date-utils';
import { environment } from 'src/environments/environment';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';

import { ElementoModel } from 'src/app/services/elements/models/elem.model';

@Component({
  selector: 'app-elements-edit-historico-coordenadas',
  templateUrl: './elements-edit-historico-coordenadas.component.html',
  styleUrls: ['./elements-edit-historico-coordenadas.component.css', '../elements-edit.component.css']
})
export class ElementsEditHistoricoCoordenadasComponent implements OnInit {
  @ViewChild('gridHistCoordenadas') gridHistCoordenadas: jqxGridComponent;

  @Input() elemEdit: ElementoModel;
  @Input() form: jqxWindowComponent;
  @Input() map: MapComponent;

  public static _this: ElementsEditHistoricoCoordenadasComponent;

  public environment = environment;
  public coordinateMarker: MapMarker;
  public langGrid = JqWidgets.getLocalization('es');

  translate(text: string): string {
    return AppComponent.translate(text);
  }

  constructor() {
    ElementsEditHistoricoCoordenadasComponent._this = this;
  }

  ngOnInit(): void {
    this.initGridHistCoordenadas();
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'elemEdit': {
            this.elemEdit = changes[propName].currentValue;

            if (this.elemEdit && this.sourceHistCoordenadas) {
              this.elemEdit.Coordenadas.sort((a,b) => a.Fecha < b.Fecha ? 1 : -1);
              this.sourceHistCoordenadas.localdata = this.elemEdit.Coordenadas;
              this.gridHistCoordenadas.updatebounddata('data');
            }
            break;
          }
          default: {
            break;
          }
        }
      }
    }
  }

  // Columnas para el grid historico de coordenadas
  columHistCoordenadas;
  sourceHistCoordenadas;
  dataAdapterHistCoordenadas: any;

  initGridHistCoordenadas() {
    this.columHistCoordenadas = [
      {
        text: AppComponent.translate('Ver'), width: 40, columnType: 'button',
        cellsrenderer: this.renderButtonVer,
      },
      { text: this.translate('Fecha'), columntype: 'textbox', filtertype: 'textbox', datafield: 'fecha', autowidth: 'true', width: 150,
        cellsrenderer: this.renderDate
      },
      { text: this.translate('Calle'), columntype: 'textbox', filtertype: 'textbox', datafield: 'calle', autowidth: 'true', width: 260 },
      { text: this.translate('Poblacion'), columntype: 'textbox', filtertype: 'textbox', datafield: 'poblacion', autowidth: 'true', width: 150 },
    ];

    this.sourceHistCoordenadas =
    {
      datatype: 'json',
      datafields: [
        { name: 'fecha', type: 'date', map: 'Fecha' },
        { name: 'calle', type: 'number', map: 'Calle' },
        { name: 'poblacion', type: 'number', map: 'Poblacion' }
      ],
      localdata: this.elemEdit?.Coordenadas ? this.elemEdit.Coordenadas : [],
    };

    this.dataAdapterHistCoordenadas = new jqx.dataAdapter(this.sourceHistCoordenadas);
  }

  renderButtonVer(row, columnfield, value, defaulthtml, columnproperties) {
    return (
    `
      <div class="jqx-grid-cell-center-align" style="margin-top: -2px; margin-left: 4.5px">
        <i class="fa-solid fa-eye" style></i>
      </div>
    `);
  }

  cellClick(e) {
    if(e.args.column.text === AppComponent.translate('Ver')) {
      this.centerCoordenada(e.args.rowindex)
    }
  }

  centerCoordenada(e) {
    try {
      setTimeout(() => {
        const obj = this.elemEdit.Coordenadas[e];

        if(this.coordinateMarker) {
          this.coordinateMarker.setVisible(true);
          this.coordinateMarker.setPosition(new MapLatLng(obj.Lat, obj.Lng));
        }else {
          this.coordinateMarker = this.map.addMarker({
            dataModel: this.elemEdit.Marker.dataModel,
            title: this.elemEdit.Marker.title,
            content: this.elemEdit.Marker.content,
            position: new MapLatLng(obj.Lat, obj.Lng),
            icon: this.elemEdit.Marker.icon,
            zIndex: 0,
            drag: false,
            visible: true
          });
        }

        this.map.setCenter(new MapLatLng(obj.Lat, obj.Lng));
        this.map.setZoom(18);
        try {
          ElementsEditHistoricoCoordenadasComponent._this.coordinateMarker.animate(2850);
        } catch (error) {
        }

        this.form.collapse();
      }, 0);
    }catch (e) {
    }
  }

  //Renderizamos las fechas
  renderDate(row: number, columnfield: string, value: any,
    defaulthtml: string, columnproperties: any, rowdata: any): string {
    if (value) {
      /*
        Cuando se hace new Date le esta añadiendo a la fecha GMT +1
        por tanto se debe de corregir seteando los valores a UTC
      */
      let dateUTC = new Date(value);
      let date = new Date(value);
      dateUTC.setFullYear(date.getUTCFullYear());
      dateUTC.setMonth(date.getUTCMonth());
      dateUTC.setDate(date.getUTCDate());
      dateUTC.setHours(date.getUTCHours());
      return '<div style="margin-left: 3px; margin-top: 6.5px">' + DateUtils.formatDateTimeShort(dateUTC, true) + '</div>';
    }
  }
}
