import {
  OnInit,
  Component,
  ViewChild,
  AfterViewInit,
} from '@angular/core';

import { Utils } from 'src/app/utils/utils';

import { AppComponent } from 'src/app/app.component';
import { CustomForms } from '../../forms/custom-forms';
import { NumberUtils } from 'src/app/utils/number-utils';
import { MainComponent } from '../../main/main.component';
import { environment } from 'src/environments/environment';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxInputComponent } from 'jqwidgets-ng/jqxinput';
import { jqxLoaderComponent } from 'jqwidgets-ng/jqxloader';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';

import { NzModalService } from 'ng-zorro-antd/modal';
import { SsoService } from 'src/app/services/sso/sso.service';
import { FlotaService } from 'src/app/services/flota/flota.service';
import { ResourcesService } from 'src/app/services/resources/resources.service';

import { MovilModel } from 'src/app/services/resources/models/movil.model';
import { SubflotaModel } from 'src/app/services/flota/models/subflota.model';

import { HeaderComponent } from '../../header/header.component';

@Component({
  selector: 'app-subflotas',
  templateUrl: './subflotas.component.html',
  styleUrls: ['./subflotas.component.css']
})
export class SubflotasComponent extends CustomForms implements OnInit, AfterViewInit {
  @ViewChild('grid') grid: jqxGridComponent;
  @ViewChild('form') form: jqxWindowComponent;
  @ViewChild('header') header: HeaderComponent;
  @ViewChild('loader') loader: jqxLoaderComponent;
  @ViewChild('ebNombre') ebNombre: jqxInputComponent;
  @ViewChild('formEdit') formEdit: jqxWindowComponent;
  @ViewChild('gridMoviles1') gridMoviles1: jqxGridComponent;
  @ViewChild('gridMoviles2') gridMoviles2: jqxGridComponent;

  public static _this: SubflotasComponent;

  private componentRef = null;
  public environment = environment;
  public subflotas: SubflotaModel[] = [];
  public subflotaSelec: SubflotaModel = null;
  public subflotaEdit: SubflotaModel = null;
  public subflotaResto: SubflotaModel = null;
  public mostrarEditForm = false;
  public canEdit = true;
  private showRowDetails: number[] = [];

  // Variables para el grid de subflotas
  public sourceSubflotas: any = [];
  public dataAdapterSubflotas = new jqx.dataAdapter(this.sourceSubflotas);

  public columnsMoviles = [
    { text: AppComponent.translate('Icono'), datafield: 'icono', cellsrenderer: this.rendeCell },
    { text: AppComponent.translate('CODIGO'), datafield: 'codigo', cellsrenderer: this.rendeCell,
      aggregates: [{
        'Total': function (aggregatedValue, currentValue: number) {
          return aggregatedValue + 1;
        }
      }],
      aggregatesrenderer: function (aggregates) {
        let renderstring = '';
        if (aggregates["Total"] !== undefined) {
          renderstring = '<div style="text-align: left; margin-left: 4px;">' + AppComponent.translate('Total') + ': ' +
            aggregates["Total"] + ' </div>';
        }
        return renderstring;
      }
    },
    { text: AppComponent.translate('Matricula'), datafield: 'matricula', cellsrenderer: this.rendeCell },
    { text: AppComponent.translate('Nombre'), datafield: 'nombre', cellsrenderer: this.rendeCell },
    { text: AppComponent.translate('Recurso'), datafield: 'recurso', cellsrenderer: this.rendeCell },
    { text: AppComponent.translate('Clase'), datafield: 'clase', cellsrenderer: this.rendeCell },
    { text: AppComponent.translate('Base'), datafield: 'base', cellsrenderer: this.rendeCell },
  ];
  // Variables para el grid de resto de flota
  public movilesResto: MovilModel[] = [];
  public sourceMoviles1: any = [];
  public dataAdapterMoviles1;

  numberrenderer(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (value) {
      return (
        '<div class="jqx-grid-cell-right-align" style="height: 100%; display: flex;justify-content: flex-end; align-items: center;">' +
        NumberUtils.format(value, 0) +
        '</div>'
      );
    } else if (value === 0) {
      return (
        '<div class="jqx-grid-cell-right-align" style="height: 100%; display: flex;justify-content: flex-end; align-items: center;">' +
        NumberUtils.format(value, 0) +
        '</div>'
      );
    }
  }

  // Variables para el grid de moviles de la subflota
  public movilesSubflota: MovilModel[] = [];
  public sourceMoviles2: any = [];
  public dataAdapterMoviles2;

  // Pongo por defecto los textos en los controles del grid en español
  public langGrid = JqWidgets.getLocalization('es');

  constructor(
    private flotaService: FlotaService,
    private resourcesService: ResourcesService,
    private ssoService: SsoService,
    private modal: NzModalService,
  ) {
    super();
    SubflotasComponent._this = this;
  }

  ngOnInit(): void {
    this.canEdit = true; // TODO: por hacer...
    this.langGrid = JqWidgets.getLocalization(this.ssoService.getTicket().Usuario.Idioma.Codigo.substring(0, 2));
  }

  ngAfterViewInit(): void {
    this.addCustomForm(this.form);
    // Creo una subflota vacia para rellenar
    this.subflotaEdit = this.getEmptySubflota();
    // Recupero las subflotas
    this.getSubflotas();
  }

  // Este método es llamado por el creador del componente
  public init(componentref: any) {
    this.componentRef = componentref;
  }

  closeWindow(){
    this.form.close();
  }

  closeWindowEdit(){
    this.formEdit.close();
  }

  // Para traducir los textos del template
  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  public columnsSubflotas = [
    { text: '', datafield: 'id', hidden: true },
    {
      text: '',
      width: 60,
      columntype: 'text',
      sortable: false,
      editable: false,
      resizable: false,
      filterable: false,
      datafield: 'acciones',
      groupable: false,
      menu: false,
      rendered: (columnHeaderElement) => {
        let date = new Date();
        let time = date.getTime().toString();
        const buttonContainer = document.createElement('div');

        const options = {
          width: '100%',
          value: '<i class="fa fa-plus-circle fa-lg" aria-hidden="true"></i>',
          textImageRelation: 'imageAboveText',
        };

        buttonContainer.id = `buttonContainerColumn_jqxButton`;
        columnHeaderElement[0].appendChild(buttonContainer);
        let myButton;

        setTimeout(() => {
          myButton = jqwidgets.createInstance(
            `#buttonContainerColumn_jqxButton`,
            'jqxButton',
            options
          );

          myButton.addEventHandler('click', () => {
            this.onCrearSubflotaClick()
          });

          try {
            let btn = <HTMLElement>buttonContainer.parentElement.children[2];
            btn.title = this.translate('Aniadir');
            btn.classList.add('button');
            btn.style.cursor = 'pointer';
            btn.style.width = '100%';
            btn.style.height = '25px';
            btn.style.display = 'flex';
            btn.style.justifyContent = 'center';
            btn.style.alignItems = 'center';

            let icon = <HTMLElement>btn.children[1];
            icon.style.position = 'unset';
          } catch (error) {
          }
        }, 100);
        return columnHeaderElement[0];
      },
      createwidget: (
        row: any,
        column: any,
        value: string,
        htmlElement: HTMLElement
      ): void => {
        this.initBtnColumn(row, column, value, htmlElement);
      },
      initwidget: (
        row: any,
        column: any,
        value: string,
        htmlElement: HTMLElement
      ) => {
        this.initBtnColumn(row, column, value, htmlElement);
      }
    },
    {
      text: AppComponent.translate('Nombre'), columntype: 'textbox', filtertype: 'textbox', datafield: 'nombre', width: 200,
      aggregates: [{
        'Total': function (aggregatedValue, currentValue: number) {
          return aggregatedValue + 1;
        }
      }],
      aggregatesrenderer: function (aggregates) {
        let renderstring = '';
        if (aggregates["Total"] !== undefined) {
          renderstring = '<div style="text-align: left; margin-left: 4px;">' + AppComponent.translate('Total') + ': ' +
            aggregates["Total"] + ' </div>';
        }
        return renderstring;
      }
    },
    {
      text: AppComponent.translate('Moviles'), columntype: 'textbox', filtertype: 'textbox', datafield: 'totMoviles', width: 70, align: 'center',
      cellsalign: 'right', cellsrenderer: this.numberrenderer,
      aggregates: [{
        'Total': function (aggregatedValue, currentValue: number) {
          return aggregatedValue + currentValue;
        }
      }],
      aggregatesrenderer: function (aggregates) {
        let renderstring = '';
        if (aggregates["Total"] !== undefined) {
          renderstring = '<div style="text-align: center;">' + aggregates["Total"] + '</div>';
        }
        return renderstring;
      }
    },
    {
      text: AppComponent.translate('Observaciones'), columntype: 'textbox', filtertype: 'textbox', datafield: 'observ', width: 300
    },
    { text: 'Selec', columntype: 'textbox', filtertype: 'textbox', datafield: 'selec', hidden: true }
  ];

  // Incializa la columna de botones
  async initBtnColumn(
    row: any,
    column: any,
    value: string,
    htmlElement: HTMLElement
  ) {
    htmlElement.innerHTML = '';
    // Crea un contenedor para los botones
    const btnContainer = document.createElement('div');
    btnContainer.style.display = 'flex';
    btnContainer.style.justifyContent = 'space-around';
    btnContainer.style.gap = '2px';
    btnContainer.style.padding = '2px';

    let rowdata: any;
    if (isNaN(row)) {
      rowdata = row.bounddata;
    } else {
      rowdata = this.grid.getrowdata(row);
    }

    const btnEdit = document.createElement('div');
    btnEdit.innerHTML = `
        <button class="button" style="height: 23px; cursor: pointer;" title="`+AppComponent.translate('Editar')+`">
          <i class="fa-solid fa-pen-to-square"></i>
        </button>
      `;
    btnEdit.id = `buttonEdit_jqxButton`;
    btnContainer.appendChild(btnEdit);

    btnEdit.addEventListener('click', async (event: any) => {
      this.onEditarSubflotaClick()
    });
    htmlElement.appendChild(btnContainer);

    if(!(rowdata?.totMoviles) || rowdata?.totMoviles === 0){
      const btnDelete = document.createElement('div');
      btnDelete.innerHTML = `
        <button class="button" style="height: 23px; width: 25px; padding: 0; margin: 0; cursor: pointer !important;" title="`+AppComponent.translate('Borrar')+`">
          <i class="fa-solid fa-trash"></i>
        </button>
      `;
      btnDelete.id = `buttonDelete_jqxButton`;
      btnContainer.appendChild(btnDelete);

      btnDelete.addEventListener('click', async (event: any) => {
        this.onBorrarSubflotaClick();
      });
    }
  }

  rowdetailstemplate = (index: any): any => {
    let date = new Date();
    let time = date.getTime().toString();
    var details = {
      rowdetails: `<div id="detailsSubflota${time}" style="margin: 10px;"></div>`,
      rowdetailshidden: true,
    };
    return details;
  };

  initRowDetails = async (index: any,
    parentElement: any,
    gridElement: any,
    datarecord: any): Promise<void> => {
    if (parentElement && datarecord) {
      let nestedGridContainer = parentElement.children[0];
      const moviles = this.subflotas.find(subflota => subflota.Id === datarecord.id).Moviles;
      if (moviles) {
        moviles.forEach(movil => {
          let clasesString = '';

          if(movil && movil.ConjuntoVehiculo && movil.ConjuntoVehiculo.Clases) {
            movil.ConjuntoVehiculo.Clases.forEach(clase => {
              if(clase) {
                clasesString += clasesString == '' ? clase.Nombre : ', ' + clase.Nombre;
              }
            });
          }

          movil['clasesText'] = clasesString;
        });

        let columnasMoviles = [
          { text: AppComponent.translate('Icono'), datafield: 'icono', cellsrenderer: this.rendeCell },
          { text: AppComponent.translate('CODIGO'), datafield: 'codigo', cellsrenderer: this.rendeCell },
          { text: AppComponent.translate('Matricula'), datafield: 'matricula', cellsrenderer: this.rendeCell },
          { text: AppComponent.translate('Nombre'), datafield: 'nombre', cellsrenderer: this.rendeCell },
          { text: AppComponent.translate('Recurso'), datafield: 'recurso', cellsrenderer: this.rendeCell },
          { text: AppComponent.translate('Clase'), datafield: 'clase', cellsrenderer: this.rendeCell },
          { text: AppComponent.translate('Base'), datafield: 'base', cellsrenderer: this.rendeCell },
        ];

        let sourceMoviles = {
          datatype: 'json',
          datafields: [
            { name: 'icono', map: 'ConjuntoVehiculo>Icono' },
            { name: 'codigo', map: 'Codigo' },
            { name: 'matricula', map: 'Matricula' },
            { name: 'nombre', map: 'Nombre' },
            { name: 'recurso', map: 'TipoMovil>Nombre' },
            { name: 'clase', map: 'clasesText' },
            { name: 'base', map: '' },
          ],
          localdata: moviles,
        };

        let dataAdapterMoviles = new jqx.dataAdapter(sourceMoviles);

        if (nestedGridContainer != null) {
          let setting = {
            width: '97%',
            height: 180,
            showstatusbar: true,
            showaggregates: true,
            source: dataAdapterMoviles,
            theme: this.environment.tema,
            columns: columnasMoviles,
            localization: this.langGrid,
            editable: false,
          };

          let gridDetail = jqwidgets.createInstance(
            `#${nestedGridContainer.id}`,
            'jqxGrid',
            setting
          );

          setTimeout(() => {
            this.resizeGridDetails(gridDetail);
          }, 100);
        }
      }
    }
  }

  rendeCell(row: number, columnfield: string, value: any,
  defaulthtml: string, columnproperties: any, rowdata: any): string {
    if(columnfield === 'icono') {
      return `
        <img style="margin-left: 4px; margin-top: 6px;" height="16" width="16" src="data:image/jpg;base64,${value}"/>
      `;
    }
    return `
      <div style="margin-left: 4px; margin-top: 5px;  text-align: left;""
      onmouseover="this.style.backgroundColor='gray'; this.style.color='white'; this.style.position='fixed'"
      onmouseout="this.style.backgroundColor=''; this.style.color=''; this.style.position=''">
        ${value}
      </div>
    `;
  }

  resizeGridDetails(grid: jqxGridComponent) {
    if (grid) {
      grid.setOptions({
        columnsheight: 20,
        statusbarheight: 20,
        rowsheight: 22,
        columnsresize: true,
      });
    }
  }

  async getSubflotas() {
    try {
      this.columnsSubflotas.forEach(column => {
        if (column.datafield !== 'acciones') {
          column.rendered = (element) => { Utils.tooltiprenderer(element) };
        }
      });

      this.subflotaSelec = null;
      this.subflotas = await this.flotaService.getSubflotas();
      if (this.subflotas) {
        this.sourceSubflotas = {
          datatype: 'json',
          sort: this.customsortfunc,
          datafields: [
            { name: 'id', type: 'string', map: 'Id' },
            { name: 'nombre', type: 'string', map: 'Nombre' },
            { name: 'totMoviles', type: 'number', map: 'MovilesId>length' },
            { name: 'observ', type: 'string', map: 'Observaciones' },
            { name: 'selec', map: 'selec' }
          ],
          localdata: this.subflotas,
        };
        this.dataAdapterSubflotas = new jqx.dataAdapter(this.sourceSubflotas);
      }
    } catch (e) {
    }
  }

  onSort(event: any) {
    this.showRowDetails.forEach(row => {
      this.grid.getrows().forEach(elem => {
        if (row === elem.id) {
          this.grid.showrowdetails(elem.boundindex)
        }
      });
    });
  }

  rowexpand(event: any) {
    let id = this.grid.getrowdata(event.args.rowindex).id;
    if(!(this.showRowDetails.includes(id))){
      this.showRowDetails.push(id);
    }
  }

  rowcollapse(event: any) {
    let id = this.grid.getrowdata(event.args.rowindex).id;
    this.showRowDetails.splice(this.showRowDetails.indexOf(id), 1);
  }

  sortedColumn: any;

  customsortfunc = (column: any, direction: string | boolean): void => {
    let sortdata = new Array();
    if (direction == 'ascending') direction = true;
    if (direction == 'descending') direction = false;
    if (direction != null) {
      for (let i = 0; i < this.subflotas.length; i++) {
        sortdata.push(this.subflotas[i]);
      }
    } else {
      sortdata = this.subflotas;
    }
    this.sortedColumn = column;

    let tmpToString = Object.prototype.toString;
    Object.prototype.toString = (typeof column == 'function') ? column : () => { return this[column] };
    if (direction != null) {
      sortdata.sort(this.compare);
      if (!direction) {
        sortdata.reverse();
      }
    }
    this.sourceSubflotas.localdata = sortdata;
    this.grid.updatebounddata('sort');
    Object.prototype.toString = tmpToString;
  }

  compare = (value1: any, value2: any): any => {
    if(this.sortedColumn === 'nombre'){
      value1 = value1.Nombre;
      value2 = value2.Nombre;
    } else if(this.sortedColumn === 'totMoviles') {
      value1 = value1.Moviles.length;
      value2 = value2.Moviles.length;
    } else if(this.sortedColumn === 'observ') {
      value1 = value1.Observaciones;
      value2 = value2.Observaciones
    }

    try {
      let tmpvalue1 = parseFloat(value1);
      if (isNaN(tmpvalue1)) {
        if (value1 < value2) { return -1; }
        if (value1 > value2) { return 1; }
      }
      else {
        let tmpvalue2 = parseFloat(value2);
        if (tmpvalue1 < tmpvalue2) { return -1; }
        if (tmpvalue1 > tmpvalue2) { return 1; }
      }
    }
    catch (error) {
      let er = error;
    }
    return 0;
  };

  eventFilter() {
    this.header.searchInput['nativeElement'].value = '';
    this.getSubflotas();
  }

  eventResetFilter() {
    this.header.searchInput['nativeElement'].value = '';
    this.onBuscar();
  }

  onBuscar() {
    let filtervalue = '';

    if (this.header.searchInput['nativeElement'].value.length >= 3) {
      filtervalue = this.header.searchInput['nativeElement'].value.toUpperCase();
    } else {
      filtervalue = '';
    }

    this.subflotas.forEach(cal => {
      if (
        (cal?.Nombre + '').compareWith(filtervalue) ||
        (cal?.Observaciones + '').compareWith(filtervalue)
      ) {
        cal['selec'] = 'selec';
      } else {
        cal['selec'] = '';
      }
    });

    // Compruebo si ya he creado el filtro "selec" anteriormente
    const filters = this.grid.getfilterinformation();
    if (filters.find(s => s.datafield === 'selec') === undefined) {
      const filtergroup = new jqx.filter();
      filtergroup.operator = 'and';
      filtergroup.addfilter(0, filtergroup.createfilter('stringfilter', 'selec', 'equal'));
      this.grid.addfilter('selec', filtergroup);
    }
    try {
      this.grid.applyfilters();
    } catch (error) {
    }
    try {
      this.grid.updatebounddata('data');
    } catch (error) {
    }
  }

  updatefilterconditions = (type: string, defaultconditions: any): string[] => {
    return Utils.updatefilterconditions(type, defaultconditions);
  };

  public filter(cellValue?: any, rowData?: any, dataField?: string, filterGroup?: any, defaultFilterResult?: boolean): any {
    let filterColumns = [
      'nombre',
      'observ',
    ]

    return Utils.filterRow(cellValue, dataField, filterGroup, defaultFilterResult, filterColumns);
  }

  // Actualiza los móviles de la frota resto después de asignar móviles
  // a una subflota o si se borra una subflota
  async updateSubflotaResto(): Promise<void> {
    // Recupero todos los móviles
    const moviles = await this.resourcesService.getMoviles();
    // Recupero todos los móviles asignados a alguna subflota
    const movilesAsig = new Map<number, number>();
    if (this.subflotas) {
      this.subflotas.forEach(subflota => {
        if (subflota.MovilesId && subflota.Id > 0) { // Los moviles de la flota resto no cuentan
          subflota.MovilesId.forEach(movil => {
            movilesAsig.set(movil, movil);
          });
        }
      });
    }
    // Me quedo con los móviles que no están asignados
    this.subflotaResto.MovilesId = [];
    if (moviles) {
      moviles.forEach(movil => {
        if (!movilesAsig.get(movil.Codigo)) {
          this.subflotaResto.MovilesId.push(movil.Codigo);
        }
      });
    }
  }

  // Cierro el formulario y destruyo el componente
  public onClose() {
    if (this.componentRef) {
      if(this.formEdit) {
        this.formEdit.destroy();
      }

      this.form.destroy();
      this.componentRef.destroy();
    }

    SubflotasComponent._this = null;
  }

  // Crea una subflota sin datos
  getEmptySubflota(): SubflotaModel {
    const subflota = new SubflotaModel();
    subflota.Id = 0;
    subflota.EmpresaId = this.ssoService.getTicket().Empresa.IdGestion;
    subflota.Nombre = '';
    subflota.Observaciones = '';
    subflota.MovilesId = [];
    return subflota;
  }

  onRowSubflotaSelect(event: any) {
    this.subflotaSelec = this.subflotas[event.args.rowindex];
  }

  onRowdoubleclick(event: any) {
    this.subflotaSelec = this.subflotas[event.args.rowindex];
    this.onEditarSubflotaClick();
  }

  // Crear una nueva subflota
  onCrearSubflotaClick() {
    if (!this.canEdit) {
      return;
    }
    // Creo una subflota vacía para rellenar
    this.subflotaEdit = this.getEmptySubflota();
    this.mostrarEditForm = true;
    this.form.collapse();
    this.form.disable();
  }

  // Editar la subflota seleccionada
  onEditarSubflotaClick() {
    if (this.subflotaSelec) {
      this.subflotaEdit = { ...this.subflotaSelec };
      if (this.form !== undefined) {
        this.form.collapse();
        this.form.disable();
      }
      this.mostrarEditForm = true;
    } else {
      MainComponent.getInstance().showError('ATENCION', 'Seleccione_registro', 2000);
    }
  }

  // Borrar la subflota seleccionada
  onBorrarSubflotaClick() {
    if (!this.canEdit || this.subflotaSelec.Id === 0) {
      return;
    }
    if (this.subflotaSelec !== null) {
      this.deleteSubflota();
    } else {
      MainComponent.getInstance().showError('ATENCION', 'Seleccione_registro', 2000);
    }
  }

  onGuardarClick(event: any) {
    if (!this.canEdit) {
      return;
    }
    if (this.subflotaEdit.Nombre === null || this.subflotaEdit.Nombre.length < 1) {
      MainComponent.getInstance().showError('ATENCION', 'Introduzca_nombre', 2000);
      return;
    }
    // Recupero los móviles asignados a la subflota
    this.subflotaEdit.MovilesId = [];
    if (this.movilesSubflota.length > 0) {
      this.movilesSubflota.forEach(movil => {
        this.subflotaEdit.MovilesId.push(movil.Codigo);
      });
    }
    this.saveSubflota();
  }

  // Guardo la subflota
  async saveSubflota() {
    // Recupero los móviles no asignados a ninguna subflota
    if (this.movilesResto.length > 0) {
      const sfAux = this.getEmptySubflota();
      sfAux.Id = 0; // Para que se desasignen los móviles
      sfAux.MovilesId = [];
      this, this.movilesResto.forEach(movil => {
        sfAux.MovilesId.push(movil.Codigo);
      });
      // Desasigno los móviles que no están en ninguna subflota
      await this.flotaService.setSubflotaMoviles(sfAux);
    }

    // Almaceno la subflota con todos los móviles asignados
    const result = await this.flotaService.saveSubflota(this.subflotaEdit);

    if (result) {
      if (this.subflotaEdit.Id === 0) {
        this.subflotas.push(result);
      } else {
        for (let i = 0; i < this.subflotas.length; i++) {
          if (this.subflotas[i].Id === this.subflotaEdit.Id) {
            this.subflotas[i] = { ...result };
            break;
          }
        }
      }
      this.subflotaEdit = { ...result };
      this.subflotaSelec = { ...this.subflotaEdit };
      await this.updateSubflotaResto();
      this.grid.updatebounddata('data');
      MainComponent.getInstance().showSuccess('ATENCION', 'Registro_almacenado', 2000);
    } else {
      MainComponent.getInstance().showError('ATENCION', 'Fallo_almacenar_info', 2000);
    }
    this.formEdit.close();
  }

  deleteSubflota() {
    this.modal.confirm({
      nzTitle: '<i>' + AppComponent.translate('ATENCION') + '</i>',
      nzContent: AppComponent.translate('Borrar_subflota') + ': ' + this.subflotaSelec.Nombre + ' ?',
      nzCentered: true,
      nzCancelText: AppComponent.translate('CANCELAR'),
      nzOkText: AppComponent.translate('SI'),
      nzOnOk: async () => {
        const result = await this.flotaService.deleteSubflota(this.subflotaSelec.Id);
        if (result) {
          for (let i = 0; i < this.subflotas.length; i++) {
            if (this.subflotas[i].Id === this.subflotaSelec.Id) {
              this.subflotas.splice(i, 1);
              break;
            }
          }
          this.subflotaSelec = null;
          await this.updateSubflotaResto();
          this.grid.updatebounddata('data');
          MainComponent.getInstance().showSuccess('ATENCION', 'Registro_borrado', 2000);
        }
      }
    });
  }

  onOpenEditSubflota() {
    const t = setTimeout(() => {
      clearTimeout(t);
      this.ebNombre.focus();
      this.updateListaDisponibles();
      this.addCustomForm(this.formEdit);
    }, 0);
  }

  onCloseEdit() {
    this.formEdit.destroy();
    this.mostrarEditForm = false;
    if (this.form !== undefined) {
      this.form.enable();
      this.form.expand();
    }
  }

  async updateListaDisponibles() {
    try {
      // Recupero todos los móviles asignados a alguna subflota
      const otrosMovilesAsig = new Map<number, number>();
      const movilesAsig = new Map<number, number>();
      this.subflotas.forEach(subflota => {
        if (subflota.MovilesId) {
          subflota.MovilesId.forEach(movil => {
            if (subflota.Id !== 0) { // Los móviles de la flota resto no cuentan
              if (subflota.Id !== this.subflotaEdit.Id) {
                otrosMovilesAsig.set(movil, movil);
              } else {
                movilesAsig.set(movil, movil);
              }
            }
          });
        }
      });
      // Recupero todos los móviles
      const result = await this.resourcesService.getMoviles()
      if (result) {
        this.movilesResto = [];
        this.movilesSubflota = [];
        result.forEach(movil => {
          if (!movilesAsig.get(movil.Codigo)) {
            if (!otrosMovilesAsig.get(movil.Codigo)) {
              this.movilesResto.push(movil);
            }else {
              movil.Codigo;
            }
          } else {
            this.movilesSubflota.push(movil);
          }
        });
        // Actualizo la lista de móviles sin asignar
        this.sourceMoviles1 = {
          datatype: 'json',
          datafields: [
            { name: 'icono', map: 'ConjuntoVehiculo>Icono' },
            { name: 'codigo', map: 'Codigo' },
            { name: 'matricula', map: 'Matricula' },
            { name: 'nombre', map: 'Nombre' },
            { name: 'recurso', map: 'TipoMovil>Nombre' },
            { name: 'clase', map: 'clasesText' },
            { name: 'base', map: '' },
          ],
          localdata: this.movilesResto,
          sortcolumn: 'codigo',
          sortdirection: 'asc'
        };
        this.dataAdapterMoviles1 = new jqx.dataAdapter(this.sourceMoviles1);

        // Actualizo la lista de móviles asignados
        this.sourceMoviles2 = {
          datatype: 'json',
          datafields: [
            { name: 'icono', map: 'ConjuntoVehiculo>Icono' },
            { name: 'codigo', map: 'Codigo' },
            { name: 'matricula', map: 'Matricula' },
            { name: 'nombre', map: 'Nombre' },
            { name: 'recurso', map: 'TipoMovil>Nombre' },
            { name: 'clase', map: 'clasesText' },
            { name: 'base', map: '' },
          ],
          localdata: this.movilesSubflota,
          sortcolumn: 'codigo',
          sortdirection: 'asc'
        };
        this.dataAdapterMoviles2 = new jqx.dataAdapter(this.sourceMoviles2);
      }
    } finally {

    }
  }

  onMasClick(event: any) {
    if (!this.canEdit) {
      return;
    }
    const moviles = new Map<number, number>();
    const rowsSelec = this.gridMoviles1.getselectedrowindexes();
    if (rowsSelec.length > 0) {
      rowsSelec.forEach(rowIndex => {
        this.movilesSubflota.push(this.movilesResto[rowIndex]);
        moviles.set(this.movilesResto[rowIndex].Codigo, this.movilesResto[rowIndex].Codigo);
      });
      for (let i = this.movilesResto.length - 1; i >= 0; i--) {
        if (moviles.get(this.movilesResto[i].Codigo)) {
          this.movilesResto.splice(i, 1);
        }
      };
      this.gridMoviles1.clearselection();
      this.gridMoviles1.updatebounddata('data');
      this.gridMoviles2.updatebounddata('data');
    }
  }

  onMenosClick(event: any) {
    if (!this.canEdit) {
      return;
    }
    const moviles = new Map<number, number>();
    const rowsSelec = this.gridMoviles2.getselectedrowindexes();
    if (rowsSelec.length > 0) {
      rowsSelec.forEach(rowIndex => {
        this.movilesResto.push(this.movilesSubflota[rowIndex]);
        moviles.set(this.movilesSubflota[rowIndex].Codigo, this.movilesSubflota[rowIndex].Codigo);
      });
      for (let i = this.movilesSubflota.length - 1; i >= 0; i--) {
        if (moviles.get(this.movilesSubflota[i].Codigo)) {
          this.movilesSubflota.splice(i, 1);
        }
      };
      this.gridMoviles2.clearselection();
      this.gridMoviles1.updatebounddata('data');
      this.gridMoviles2.updatebounddata('data');
    }
  }
}
