import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HeaderModule } from '../header/header.module';
import { BrowserModule } from '@angular/platform-browser';
import { SharedModule } from 'src/app/shared/shared.module';
import { ModeloSelectModule } from '../modelo-select/modelo-select.module';

import { TarjetasCiudadanosComponent } from './tarjetas-ciudadanos.component';
import { TabRangosComponent } from './tab-rangos/tab-rangos.component';
import { FormRangoComponent } from './tab-rangos/form-rango/form-rango.component';
import { AsociarTarjetasComponent } from './asociar-tarjetas/asociar-tarjetas.component';

@NgModule({
  declarations: [
    TabRangosComponent,
    FormRangoComponent,
    TarjetasCiudadanosComponent,
    AsociarTarjetasComponent,
  ],
  imports: [
    FormsModule,
    SharedModule,
    CommonModule,
    HeaderModule,
    BrowserModule,
    ModeloSelectModule,
  ]
})
export class TarjetasCiudadanosModule { }
