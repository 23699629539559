export class EntradaModel {
    Id: number;
    ZonaId: number;
    Nombre: String;
    Tipo: number;               //1= entrada, 2= salida, 3= entrada y salida
    Observaciones: String;
    Direccion: String;
    FechaCreacion: Date;
    FechaModificacion: Date;
    FechaBaja: Date;
    Lat: number;
    Lng: number;

    //agregados
    Poblacion: String;
    Municipio: String;
    Provincia: String;
}
