<div class="loader-container" *ngIf="showLoader">
  <div class="loader"></div>
</div>
<jqxWindow #windowEdificios [jqxWindowAutoResize] [theme]="environment.tema" [width]="mapWidth" [height]="mapHeight - 24"
  [position]="getFormPos(windowEdificios, 24)" [zIndex]="900" [autoOpen]="true" [showCloseButton]="true"
  [showCollapseButton]="true" [resizable]="true" [title]="translate('Edificios_e_instalaciones')" (onClose)="onClose()">

  <div class="formHeader">
  </div>

  <div class="form" style="float:left; overflow: hidden; height: 100%; width: 100%;">
    <app-header #header [exportar]="true" [imprimir]="true" [buscador]="true" [filtro]="true" [resetFiltro]="true"
      (eventImprimir)="onPrint($event)" (eventExportar)="onExportar()" (eventBuscador)="onBuscar()"
      (eventFilter)="eventFilter()" (eventResetFilter)="eventResetFilter()">
    </app-header>

    <div style="float: left; width: 100%; height: 100%;">
      <jqxGrid #gridEdificios [theme]="environment.tema" [width]="'100%'" [height]="'calc(100% - 80px)'"
        [appCellClick] [source]="sourceGridEdificiosAdapter" [localization]="langGrid" [filterable]="true"
        [columns]="columnsGridEdificios" [showrowlines]="false" [columnsresize]="true" [sortable]="true"
        [enablebrowserselection]="true" [altrows]="true" [showstatusbar]="true" [statusbarheight]="20"
        [showaggregates]="true" [rowsheight]="25" [columnsheight]="20" [groupable]="true" [editable]="true"
        [selectionmode]="'checkbox'" (onRowselect)="onChecked($event)" (onRowunselect)="onUnselect($event)"
        (onRowdoubleclick)="onEditarEdificio()">
      </jqxGrid>

      <div style="float: left; margin-top: 10px">
        <jqxButton style="float: left; margin-left: 5px" [width]="100" [height]="26"
          [textImageRelation]="'imageBeforeText'" [textPosition]="'left'"
          [value]="translate('Salir')" (onClick)="closeWindow()">
        </jqxButton>
      </div>
    </div>
  </div>
</jqxWindow>

<div #formEdificiosInstalaciones></div>
