<div style="float:left; width: 100%; height: 380px;">
    <jqxGrid #grid [theme]="environment.tema" [width]="'98%'" [height]="'100%'" [source]="dataAdapter" [appCellClick]
        [columnsresize]="true" [filterable]="true" [columns]="columns" [sortable]="true" [altrows]="true"
        [enablebrowserselection]="true" [showrowlines]="false" [filterrowheight]="30" [rowsheight]="25"
        [columnsheight]="25" [showaggregates]="true" [showstatusbar]="true" [statusbarheight]="20"
        [localization]="langGrid" [filter]="filter" [updatefilterconditions]="updatefilterconditions"
        (onBindingcomplete)="onBindingComplete()">
    </jqxGrid>
</div>

<div #elementsContainer></div>
