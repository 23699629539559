<jqxWindow #form [theme]="theme" [position]="getFormPos(form, 24)" [zIndex]="90" [width]="mapWidth"
    [height]="mapHeight - 24" [autoOpen]="true" [showCloseButton]="true" [showCollapseButton]="true" [resizable]="true"
    (onClose)="onClose()">
    <div class="formHeader" style="overflow-x: hidden;">
        {{translate('Acciones')}}
    </div>
    <div class="form" style="float:left; overflow-x: hidden; ">

        <app-header #header [exportar]="true" [imprimir]="true" [buscador]="true" (eventFilter)="onFilter($event)"
            [filtro]="true" [resetFiltro]="true" (eventImprimir)="onPrint($event)" (eventExportar)="onExportar()"
            (eventBuscador)="onBuscar()" (eventResetFilter)="eventResetFilter()">
        </app-header>

        <div
            style="float: left; margin-top: 4px;width: 100%;height:calc(100% - 42px);display: flex;flex-direction: column;justify-content: space-between;">
            <div style="height: 100%;">
                <jqxGrid #myGrid [theme]="theme" [width]="'100%'" [height]="'99%'" [source]="dataAdapter" [appCellClick]
                    [columnsresize]="true" [localization]="langGrid" [filterable]="true" [selectionmode]="'singlecell'"
                    [columns]="columns" [showrowlines]="false" [showcolumnlines]="true" [sortable]="true"
                    [rowdetails]="true" [rowdetailstemplate]="rowdetailstemplateApp"
                    [initrowdetails]="initrowdetailsApp" [cellhover]="cellhover" [editable]="false" [altrows]="true"
                    [showstatusbar]="true" [statusbarheight]="20" [showaggregates]="true" [rowsheight]="20"
                    [columnsheight]="20" [editable]="false" [enablebrowserselection]="true" [filter]="filter"
                    [updatefilterconditions]="updatefilterconditions">
                </jqxGrid>
            </div>
            <div>
                <jqxButton style="float: left; margin-left: 1px;" [width]="100" [height]="26" [imgPosition]="'left'"
                    [textImageRelation]="'imageBeforeText'" [textPosition]="'center'" [value]="translate('Salir')"
                    (onClick)="form.close()">
                </jqxButton>
            </div>
        </div>
    </div>
</jqxWindow>