export class JqWidgets {
  static getLocalization(lang: string): any {
    switch (lang.substring(0, 2)) {
      case 'eu':
      case 'eu-ES':
        return {
          // separator of parts of a date (e.g. '/' in 11/05/1955)
          '/': '/',
          // separator of parts of a time (e.g. ':' in 05:44 PM)
          ':': ':',
          // the first day of the week (0 = Sunday, 1 = Monday, etc)
          percentsymbol: '%',
          currencysymbol: '€',
          currencysymbolposition: 'after',
          decimalseparator: ',',
          thousandsseparator: '.',
          pagergotopagestring: 'Orrialdera joan:',
          pagershowrowsstring: 'Errenkadak erakutsi:',
          pagerrangestring: ' de ',
          pagerpreviousbuttonstring: 'ohia',
          pagernextbuttonstring: 'jarraian',
          groupsheaderstring: 'Arrastatu zutabe bat taldera',
          sortascendingstring: 'ordenatu Gor',
          sortdescendingstring: 'Ordenatu Beh',
          sortremovestring: 'Ordena kendu',
          groupbystring: 'Zutabe honen arabera taldekatu',
          groupremovestring: 'Taldeetatik kendu',
          filterclearstring: 'Garbitu',
          filterstring: 'Iragazkia',
          filtershowrowstring: 'Errenkadak non erakutsi:',
          filtershowrowdatestring: 'Erakutsi errenkadak non data:',
          filterorconditionstring: 'O',
          filterandconditionstring: 'Y',
          filterselectallstring: '(Hautatu guztiak)',
          filterchoosestring: 'Aukeratu mesedez:',
          filterstringcomparisonoperators: ['hutsik', 'ez hutsik', 'eduki',
            'ez dauka', 'hasten da', 'honekin bukatzen da', 'berdinak'],
          filternumericcomparisonoperators: ['Berdin'/* = */, 'Ez da berdina'/* != */, 'Baino txikiagoa'/* < */, 'Baino txikiagoa edo berdina'/* <= */,
            'Baino handiagoa' /* > */, 'Handiagoa edo berdina' /* >= */, 'Nulua' /* null */, 'Ez nulua' /* not null */],
          filterdatecomparisonoperators: ['Berdin'/* = */, 'Ez da berdina'/* != */, 'Baino txikiagoa'/* < */, 'Baino txikiagoa edo berdina'/* <= */,
            'Baino handiagoa' /* > */, 'Handiagoa edo berdina' /* >= */, 'Nulua' /* null */, 'Ez nulua' /* not null */],
          filterbooleancomparisonoperators: ['Berdin'/* = */, 'Ez da berdina'/* != */],
          validationstring: 'Balio baliogabea',
          emptydatastring: 'Ez dago erregistrorik erakusteko',
          filterselectstring: 'Hautatu iragazki bat',
          loadtext: 'Kargatzen...',
          clearstring: 'Garbitu',
          todaystring: 'gaur',
          dayViewString: 'Eguna',
          weekViewString: 'Astea',
          monthViewString: 'Hilabetea',
          firstDay: 1,
          days: {
            names: ['Igandea', 'Astelehena', 'Asteartea', 'Asteazkena', 'Osteguna',
              'Ostirala', 'Larunbata'],
            namesAbbr: ['Ig', 'Al', 'As', 'Az', 'Og', 'Or', 'Lr'],
            namesShort: ['Ig', 'Al', 'As', 'Az', 'Og', 'Or', 'Lr']
          },
          months: {
            names: ['Urtarril', 'Otsail', 'Martxo', 'Apiril', 'Maiatz', 'Ekain', 'Uztail',
              'Abuztu', 'Irail', 'Urri', 'Azaro', 'Abendu', ''],
            namesAbbr: ['Urt', 'Ots', 'Mar', 'Api', 'Mai', 'Eka', 'Uzt', 'Abu',
              'Ira', 'Urr', 'Aza', 'Abe', '']
          },
          AM: ['AM', 'am', 'AM'],
          PM: ['PM', 'pm', 'PM'],
          eras: [
            { name: 'A.D.', start: null, offset: 0 }
          ],
          twoDigitYearMax: 2090,
          patterns:
          {
            d: 'dd/MM/yyyy',
            D: 'dddd, d. MMMM yyyy',
            t: 'HH:mm',
            T: 'HH:mm:ss',
            f: 'dddd, d. MMMM yyyy HH:mm',
            F: 'dddd, d. MMMM yyyy HH:mm:ss',
            M: 'dd MMMM',
            Y: 'MMMM yyyy'
          },
          // Para los jqxedit
          bold: "Letra lodia",
          italic: "Etzana",
          underline: "Azpimarratua",
          format: "Formatua",
          font: "Iturria",
          size: "Tamaina",
          color: "Testuaren kolorea",
          background: "Atzeko kolorea",
          left: "Ezkerra justifikatuta",
          center: "Zentroa",
          right: "Eskubidea justifikatuta",
          outdent: "Tabularra <",
          indent: "Tabularra >",
          ul: "Txertatu ordenatu gabeko zerrenda",
          ol: "Txertatu zerrenda ordenatua",
          image: "Irudia",
          link: "Hiperesteka",
          html: "Ikusi Html",
          clean: "Formatu argia"
        }
      case 'ca':
      case 'ca-ES':
        return {
          // separator of parts of a date (e.g. '/' in 11/05/1955)
          '/': '/',
          // separator of parts of a time (e.g. ':' in 05:44 PM)
          ':': ':',
          // the first day of the week (0 = Sunday, 1 = Monday, etc)
          percentsymbol: '%',
          currencysymbol: '€',
          currencysymbolposition: 'after',
          decimalseparator: ',',
          thousandsseparator: '.',
          pagergotopagestring: 'Anar a pag:',
          pagershowrowsstring: 'Mostra files:',
          pagerrangestring: ' de ',
          pagerpreviousbuttonstring: 'anterior',
          pagernextbuttonstring: 'següent',
          groupsheaderstring: 'Arrossegueu una columna per agrupar',
          sortascendingstring: 'Ordenar Acs',
          sortdescendingstring: 'Ordenar Des',
          sortremovestring: 'Treure ordre',
          groupbystring: 'Agrupar per aquesta columna',
          groupremovestring: 'Treure de grups',
          filterclearstring: 'Netejar',
          filterstring: 'Filtre',
          filtershowrowstring: 'Mostra files on:',
          filtershowrowdatestring: 'Mostra files on data:',
          filterorconditionstring: 'O',
          filterandconditionstring: 'Y',
          filterselectallstring: '(Seleccionar Tot)',
          filterchoosestring: 'Si us plau seleccioneu:',
          filterstringcomparisonoperators: ['buit', 'no buit', 'contingui',
            'no contingui', 'inicia amb', 'acaba amb', 'igual'],
          filternumericcomparisonoperators: ['Igual'/* = */, 'No igual a'/* != */, 'Menor que'/* < */, 'Menor o igual que'/* <= */,
            'Major que' /* > */, 'Major o igual que' /* >= */, 'Nul' /* null */, 'No nul' /* not null */],
          filterdatecomparisonoperators: ['Igual'/* = */, 'No igual a'/* != */, 'Menor que'/* < */, 'Menor o igual que'/* <= */,
            'Major que' /* > */, 'Major o igual que' /* >= */, 'Nul' /* null */, 'No nul' /* not null */],
          filterbooleancomparisonoperators: ['Igual'/* = */, 'No igual a'/* != */],
          validationstring: 'Valor no vàlid',
          emptydatastring: 'No hi ha registres per mostrar',
          filterselectstring: 'Seleccioneu un Filtre',
          loadtext: 'Carregant...',
          clearstring: 'Netejar',
          todaystring: 'avui',
          dayViewString: 'Dia',
          weekViewString: 'Setmana',
          monthViewString: 'Mes',
          firstDay: 1,
          days: {
            names: ['Diumenge', 'Dilluns', 'Dimarts', 'Dimecres', 'Dijous',
              'Divendres', 'Dissabte'],
            namesAbbr: ['Dg', 'Dl', 'Dt', 'Dc', 'Dj', 'Dv', 'Ds'],
            namesShort: ['Dg', 'Dl', 'Dt', 'Dc', 'Dj', 'Dv', 'Ds']
          },
          months: {
            names: ['Gener', 'Febrer', 'Març', 'Abril', 'Maig', 'Juny', 'Juliol',
              'Agost', 'Setembre', 'Octubre', 'Novembre', 'Desembre', ''],
            namesAbbr: ['Gen', 'Febr', 'Març', 'Abr', 'Maig', 'Juny', 'Jul', 'Ag',
              'Set', 'Oct', 'Nov', 'Des', '']
          },
          AM: ['AM', 'am', 'AM'],
          PM: ['PM', 'pm', 'PM'],
          eras: [
            { name: 'A.D.', start: null, offset: 0 }
          ],
          twoDigitYearMax: 2090,
          patterns:
          {
            d: 'dd/MM/yyyy',
            D: 'dddd, d. MMMM yyyy',
            t: 'HH:mm',
            T: 'HH:mm:ss',
            f: 'dddd, d. MMMM yyyy HH:mm',
            F: 'dddd, d. MMMM yyyy HH:mm:ss',
            M: 'dd MMMM',
            Y: 'MMMM yyyy'
          },
          // Para los jqxedit
          bold: "Negreta",
          italic: "Itàlica",
          underline: "Subratllat",
          format: "Format",
          font: "Font",
          size: "Grandària",
          color: "Color del text",
          background: "Color del fons",
          left: "Justif. esquerra",
          center: "Centrar",
          right: "Justif. dreta",
          outdent: "Tabular <",
          indent: "Tabular >",
          ul: "Inserir llista desordenada",
          ol: "Inserir llista ordenada",
          image: "Imatge",
          link: "Hipervincle",
          html: "Vista Html",
          clean: "Esborrar format"
        }
      case 'es':
      case 'es-ES':
        return {
          // separator of parts of a date (e.g. '/' in 11/05/1955)
          '/': '/',
          // separator of parts of a time (e.g. ':' in 05:44 PM)
          ':': ':',
          // the first day of the week (0 = Sunday, 1 = Monday, etc)
          percentSymbol: '%',
          currencySymbol: '€',
          currencySymbolPosition: 'after',
          decimalSeparator: ',',
          thousandsSeparator: '.',
          pagerGotoPageString: 'Ir a pag:',
          pagerShowRowsString: 'Mostrar filas:',
          pagerRangeString: ' de ',
          pagerPreviousButtonString: 'anterior',
          pagerNextButtonString: 'siguiente',
          groupsHeaderString: 'Arrastre una columna para agrupar',
          sortAscendingString: 'Ordenar Acs',
          sortDescendingString: 'Ordenar Des',
          sortRemoveString: 'Quitar orden',
          groupByString: 'Agrupar por esta columna',
          groupRemoveString: 'Quitar de grupos',
          filterClearString: 'Limpiar',
          filterString: 'Filtro',
          filterShowrowString: 'Mostrar filas donde:',
          filterShowRowDateString: 'Mostrar filas donde fecha:',
          filterOrConditionString: 'O',
          filterAndConditionString: 'Y',
          filterSelectallString: '(Seleccionar Todo)',
          filterChooseString: 'Por favor seleccione:',
          filterStringComparisonOperators: ['Vacio', 'No vacio', 'Contiene',
            'No contiene', 'Comienza por', 'Finaliza por', 'Igual'],
          filterNumericComparisonOperators: ['Igual'/* = */, 'No igual '/* != */, 'Menor que'/* < */, 'Menor que o igual'/* <= */,
            'Mayor que' /* > */, 'Mayor que o igual' /* >= */, 'Nulo' /* null */, 'No nulo' /* not null */],
          filterDateComparisonOperators: ['Igual'/* = */, 'No igual '/* != */, 'Menor que'/* < */, 'Menor que o igual'/* <= */,
            'Mayor que' /* > */, 'Mayor que o igual' /* >= */, 'Nulo' /* null */, 'No nulo' /* not null */],
          filterBooleanComparisonOperators: ['Igual'/* = */, 'No igual '/* != */],
          validationString: 'Valor no valido',
          emptyDataString: 'No hay registros que mostrar',
          filterSelectString: 'Seleccione un Filtro',
          loadText: 'Cargando...',
          clearString: 'Limpiar',
          todayString: 'Hoy',
          dayViewString: 'Día',
          weekViewString: 'Semana',
          monthViewString: 'Mes',
          firstDay: 1,
          days: {
            names: ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves',
              'Viernes', 'Sabado'],
            namesAbbr: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
            namesShort: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa']
          },
          months: {
            names: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio',
              'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre', ''],
            namesAbbr: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago',
              'Sep', 'Oct', 'Nov', 'Dic', '']
          },
          AM: ['AM', 'am', 'AM'],
          PM: ['PM', 'pm', 'PM'],
          eras: [
            { name: 'A.D.', start: null, offset: 0 }
          ],
          twoDigitYearMax: 2090,
          patterns:
          {
            d: 'dd/MM/yyyy',
            D: 'dddd, d. MMMM yyyy',
            t: 'HH:mm',
            T: 'HH:mm:ss',
            f: 'dddd, d. MMMM yyyy HH:mm',
            F: 'dddd, d. MMMM yyyy HH:mm:ss',
            M: 'dd MMMM',
            Y: 'MMMM yyyy'
          },
          // Para los jqxedit
          bold: "Negrita",
          italic: "Itálica",
          underline: "Subrayado",
          format: "Formato",
          font: "Fuente",
          size: "Tamaño",
          color: "Color del texto",
          background: "Color del fondo",
          left: "Justif. izquierda",
          center: "Centrar",
          right: "Justif. derecha",
          outdent: "Tabular <",
          indent: "Tabular >",
          ul: "Insertar lista desordenada",
          ol: "Insertar lista ordenada",
          image: "Imagen",
          link: "Hipervínculo",
          html: "Vista Html",
          clean: "Borrar formato"
        }
      case 'de':
      case 'de-DE':
        return {
          // separator of parts of a date (e.g. '/' in 11/05/1955)
          '/': '/',
          // separator of parts of a time (e.g. ':' in 05:44 PM)
          ':': ':',
          // the first day of the week (0 = Sunday, 1 = Monday, etc)
          firstDay: 1,
          days: {
            // full day names
            names: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
            // abbreviated day names
            namesAbbr: ['Sonn', 'Mon', 'Dien', 'Mitt', 'Donn', 'Fre', 'Sams'],
            // shortest day names
            namesShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa']
          },
          months: {
            // full month names (13 months for lunar calendards -- 13th month should be '' if not lunar)
            names: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember', ''],
            // abbreviated month names
            namesAbbr: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dez', '']
          },
          // AM and PM designators in one of these forms:
          // The usual view, and the upper and lower case versions
          //      [standard,lowercase,uppercase]
          // The culture does not use AM or PM (likely all standard date formats use 24 hour time)
          //      null
          AM: ['AM', 'am', 'AM'],
          PM: ['PM', 'pm', 'PM'],
          eras: [
            // eras in reverse chronological order.
            // name: the name of the era in this culture (e.g. A.D., C.E.)
            // start: when the era starts in ticks (gregorian, gmt), null if it is the earliest supported era.
            // offset: offset in years from gregorian calendar
            { 'name': 'A.D.', 'start': null, 'offset': 0 }
          ],
          twoDigitYearMax: 2090,
          patterns:
          {
            d: 'dd.MM.yyyy',
            D: 'dddd, d. MMMM yyyy',
            t: 'HH:mm',
            T: 'HH:mm:ss',
            f: 'dddd, d. MMMM yyyy HH:mm',
            F: 'dddd, d. MMMM yyyy HH:mm:ss',
            M: 'dd MMMM',
            Y: 'MMMM yyyy'
          },
          percentSymbol: '%',
          currencySymbol: '€',
          currencySymbolPosition: 'after',
          decimalSeparator: '.',
          thousandsSeparator: ',',
          pagerGotoPageString: 'Gehe zu',
          pagerShowRowsString: 'Zeige Zeile:',
          pageRrangeString: ' von ',
          pagerPreviousButtonString: 'nächster',
          pagerNextButtonString: 'voriger',
          pagerFirstButtonString: 'first',
          pagerLastButtonString: 'last',
          groupsHeaderString: 'Ziehen Sie eine Kolumne und legen Sie es hier zu Gruppe nach dieser Kolumne',
          sortAscendingString: 'Sortiere aufsteigend',
          sortDescendingString: 'Sortiere absteigend',
          sortRemoveString: 'Entferne Sortierung',
          groupByString: 'Group By this column',
          groupRemoveString: 'Remove from groups',
          filterClearString: 'Löschen',
          filterString: 'Filter',
          filterShowRowString: 'Zeige Zeilen, in denen:',
          filterOrConditionString: 'Oder',
          filterAndConditionString: 'Und',
          filterSelectAllString: '(Alle auswählen)',
          filterChooseString: 'Bitte wählen Sie:',
          filterStringComparisonOperators: ['leer', 'nicht leer', 'enthält', 'nicht enthalten', 'beginnt mit', 'endet mit', 'equal'],
          filterNumericComparisonOperators: ['gleich', 'nicht gleich', 'weniger als', 'kleiner oder gleich', 'größer als', 'größer oder gleich', 'null', 'nicht null'],
          filterDateComparisonOperators: ['gleich', 'nicht gleich', 'weniger als', 'kleiner oder gleich', 'größer als', 'größer oder gleich', 'null', 'nicht null'],
          filterBooleanComparisonOperators: ['gleich', 'nicht gleich'],
          validationString: 'Der eingegebene Wert ist ungültig',
          emptyDataString: 'Nokeine Daten angezeigt',
          filterSelectString: 'Wählen Sie Filter',
          loadText: 'Loading...',
          clearString: 'Löschen',
          todayString: 'Heute',
          dayViewString: 'Tag',
          weekViewString: 'Woche',
          monthViewString: 'Monat',
          // Para los jqxedit
          bold: "Fett",
          italic: "Kursiv",
          underline: "Unterstreichen",
          format: "Block-Format",
          font: "Schriftname",
          size: "Schriftgröße",
          color: "Textfarbe",
          background: "Hintergrundfarbe",
          left: "Links ausrichten",
          center: "Mitte ausrichten",
          right: "Rechts ausrichten",
          outdent: "Weniger Einzug",
          indent: "Mehr Einzug",
          ul: "Legen Sie ungeordnete Liste",
          ol: "Geordnete Liste einfügen",
          image: "Bild einfügen",
          link: "Link einfügen",
          html: "html anzeigen",
          clean: "Formatierung entfernen"
        }
      case 'fr':
      case 'fr-FR':
        return {
          percentSymbol: '%',
          currencySymbol: '$',
          currencySymbolPosition: 'after',
          decimalSeparator: '.',
          thousandsSeparator: ',',
          pagerGotoPageString: 'Aller à la pag:',
          pagerShowRowsString: 'Afficher les lignes:',
          pagerRangeString: ' de ',
          pagerPreviousButtonString: 'précédent',
          pagerNextButtonString: 'siguiente',
          groupsHeaderString: 'Faites glisser une colonne vers le groupe',
          sortAscendingString: 'Vers le haut',
          sortDescendingString: 'Chute',
          sortRemoveString: 'Supprimer l\'ordre',
          groupByString: 'Regrouper par cette colonne',
          groupRemoveString: 'Supprimer des groupes',
          filterClearString: 'Nettoyer',
          filterString: 'Filtre',
          filterShowrowString: 'Afficher les lignes où:',
          filterShowRowDateString: 'Afficher les lignes où la date:',
          filterOrConditionString: 'O',
          filterAndConditionString: 'Y',
          filterSelectAllString: '(Tout sélectionner)',
          filterChooseString: 'Veuillez sélectionner:',
          filterStringComparisonOperators: ['vide', 'pas vide', 'contenir', 'ne contient pas', 'commence avec', 'se termine par', 'égal'],
          filternumericcomparisonoperators: ['Égal', 'Différent de ', 'Inférieur à', 'Inférieur ou égal à', 'Supérieur à', 'Supérieur ou égal à', 'Nul', 'Non nul'],
          filterDateComparisonOperators: ['Égal', 'Différent de ', 'Inférieur à', 'Inférieur ou égal à', 'Supérieur à', 'Supérieur ou égal à', 'Nul', 'Non nul'],
          filterBooleanComparisonOperators: ['Égal', 'Différent de '],
          validationString: 'valeur invalide',
          emptyDataString: 'Il n\'y a aucun enregistrement à afficher',
          filterSelectString: 'Sélectionnez un filtre',
          loadText: 'Mise en charge...',
          clearString: 'Nettoyer',
          todayString: 'aujourd\'hui',
          dayViewString: 'Jour',
          weekViewString: 'Semaine',
          monthViewString: 'Mois',
          firstDay: 1,
          days: {
            names: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi',
              'Vendredi', 'Samedi'],
            namesAbbr: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
            namesShort: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa']
          },
          months: {
            names: ['Janvier', 'Février', 'Mars', 'Avril', 'Peut', 'Juin', 'Juillet',
              'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre', ''],
            namesAbbr: ['Jan', 'Fév', 'Mar', 'Avr', 'Peu', 'Jui', 'Jui', 'Aoû',
              'Sep', 'Oct', 'Nov', 'Déc', '']
          },
          AM: ['AM', 'am', 'AM'],
          PM: ['PM', 'pm', 'PM'],
          eras: [
            { name: 'A.D.', start: null, offset: 0 }
          ],
          twoDigitYearMax: 2090,
          patterns:
          {
            d: 'dd/MM/yyyy',
            D: 'dddd, d. MMMM yyyy',
            t: 'HH:mm',
            T: 'HH:mm:ss',
            f: 'dddd, d. MMMM yyyy HH:mm',
            F: 'dddd, d. MMMM yyyy HH:mm:ss',
            M: 'dd MMMM',
            Y: 'MMMM yyyy'
          },
          // Para los jqxedit
          bold: "Gras",
          italic: "Italique",
          underline: "Souligner",
          format: "Formater",
          font: "Police",
          size: "Taille",
          color: "Couleur du texte",
          background: "Couleur d'arrière-plan",
          left: "Justifier à gauche",
          center: "Centre",
          right: "Justifier à droite",
          outdent: "Tableau <",
          indent: "Tableau >",
          ul: "Insérer une liste non ordonnée",
          ol: "Insérer une liste ordonnée",
          image: "Image",
          link: "Lien hypertexte",
          html: "Vue HTML",
          clean: "Effacer la mise en forme"
        }

      case 'it':
      case 'it-IT':
        return {
          // separator of parts of a date (e.g. '/' in 11/05/1955)
          '/': "/",
          // separator of parts of a time (e.g. ':' in 05:44 PM)
          ':': ":",
          // the first day of the week (0 = Sunday, 1 = Monday, etc)
          firstDay: 1,
          days: {
            // full day names
            names: ["Domenica", "Lunedì", "Martedì", "Mercoledì", "Giovedì", "Venerdì", "Sabato"],
            // abbreviated day names
            namesAbbr: ["Dom", "Lun", "Mar", "Mer", "Gio", "Ven", "Sab"],
            // shortest day names
            namesShort: ["Do", "Lu", "Ma", "Me", "Gi", "Ve", "Sa"]
          },
          months: {
            // full month names (13 months for lunar calendards -- 13th month should be "" if not lunar)
            names: ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre", ""],
            // abbreviated month names
            namesAbbr: ["Gen", "Feb", "Mar", "Apr", "Mag", "Giu", "Lug", "Ago", "Set", "Ott", "Nov", "Dic", ""]
          },
          // AM and PM designators in one of these forms:
          // The usual view, and the upper and lower case versions
          //      [standard,lowercase,uppercase]
          // The culture does not use AM or PM (likely all standard date formats use 24 hour time)
          //      null
          AM: ["AM", "am", "AM"],
          PM: ["PM", "pm", "PM"],
          eras: [
            // eras in reverse chronological order.
            // name: the name of the era in this culture (e.g. A.D., C.E.)
            // start: when the era starts in ticks (gregorian, gmt), null if it is the earliest supported era.
            // offset: offset in years from gregorian calendar
            { "name": "A.D.", "start": null, "offset": 0 }
          ],
          twoDigitYearMax: 2090,
          patterns: {
            // short date pattern
            d: "d/M/yyyy",
            // long date pattern
            D: "dddd, dd MMMM, yyyy",
            // short time pattern
            t: "h:mm tt",
            // long time pattern
            T: "h:mm:ss tt",
            // long date, short time pattern
            f: "dddd, dd MMMM, yyyy h:mm tt",
            // long date, long time pattern
            F: "dddd, dd MMMM, yyyy h:mm:ss tt",
            // month/day pattern
            M: "MMMM dd",
            // month/year pattern
            Y: "yyyy MMMM",
            // S is a sortable format that does not vary by culture
            S: "yyyy\u0027-\u0027MM\u0027-\u0027dd\u0027T\u0027HH\u0027:\u0027mm\u0027:\u0027ss"
          },
          percentSymbol: "%",
          currencySymbol: "€",
          currencySymbolPosition: "prima",
          decimalSeparator: ',',
          thousandsSeparator: '.',
          pagerGotoPageString: "Vai a pag.:",
          pagerShowRowsString: "Mostra righe:",
          pageRrangeString: " di ",
          pagerPreviousButtonString: "Prec.",
          pagerNextButtonString: "Pros.",
          groupSheaderString: "Trascina una colonna e rilasciala qui per raggurppare per quella colonna",
          sortAscendingString: "Ordine crescente",
          sortDescendingString: "Ordine decrescente",
          sortRemoveString: "Rimuovi ordinamento",
          groupByString: "Raggruppa per questa colonna",
          groupRemoveString: "Rimuovi dai gruppi",
          filterClearString: "Rimuovi filtro",
          filterString: "Filtra",
          filterShowRowString: "Mostra righe dove:",
          filterShowRowDateString: "Mostra righe dove la data:",
          filterOrConditionString: "oppure",
          filterAndConditionString: " e ",
          filterSelectAllString: "(Seleziona tutto)",
          filterChooseString: "Scegli voce:",
          filterStringComparisonOperators: ['vuoto', 'non vuoto', 'contiene', 'non contiene', 'inizia con', 'termina con', 'uguale a'],
          filterNumericComparisonOperators: ['uguale a', 'diverso da', 'minore di', 'minore o uguale di', 'maggiore di', 'maggiore o uguale di', 'vuoto', 'non vuoto'],
          filterDateComparisonOperators: ['uguale a', 'diverso da', 'minore di', 'minore o uguale di', 'maggiore di', 'maggiore o uguale di', 'vuoto', 'non vuoto'],
          filterBooleanComparisonOperators: ['uguale a', 'diverso da'],
          validationString: "Valore inserito non valido",
          emptyDataString: "Nessun dato da visualizzare",
          filterSelectString: "Seleziona filtro",
          loadText: "Elaborazione...",
          clearString: "Svuota",
          todayString: "Oggi",
          dayViewString: 'Giorno',
          weekViewString: 'Settimana',
          monthViewString: 'Mese',
          // Para los jqxedit
          bold: "Grassetto",
          italic: "Corsivo",
          underline: "Sottolinea",
          format: "Formato",
          font: "Carattere",
          size: "Taglia",
          color: "Colore testo",
          background: "Colore sfondo",
          left: "Giustifica a sinistra",
          center: "Centro",
          right: "Giusta giustificazione",
          outdent: "Tabellare <",
          indent: "Tabellare >",
          ul: "Inserisci elenco non ordinato",
          ol: "Inserisci elenco ordinato",
          image: "Immagine",
          link: "Collegamento ipertestuale",
          html: "Vista HTML",
          clean: "Cancella formattazione"
        }
      case 'en':
      case 'en-US':
      default:
        return {
          // separator of parts of a date (e.g. '/' in 11/05/1955)
          '/': '/',
          // separator of parts of a time (e.g. ':' in 05:44 PM)
          ':': ':',
          // the first day of the week (0 = Sunday, 1 = Monday, etc)
          firstDay: 0,
          days: {
            // full day names
            names: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
            // abbreviated day names
            namesAbbr: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            // shortest day names
            namesShort: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']
          },
          months: {
            // full month names (13 months for lunar calendards -- 13th month should be '' if not lunar)
            names: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December', ''],
            // abbreviated month names
            namesAbbr: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', '']
          },
          // AM and PM designators in one of these forms:
          // The usual view, and the upper and lower case versions
          //      [standard,lowercase,uppercase]
          // The culture does not use AM or PM (likely all standard date formats use 24 hour time)
          //      null
          AM: ['AM', 'am', 'AM'],
          PM: ['PM', 'pm', 'PM'],
          eras: [
            // eras in reverse chronological order.
            // name: the name of the era in this culture (e.g. A.D., C.E.)
            // start: when the era starts in ticks (gregorian, gmt), null if it is the earliest supported era.
            // offset: offset in years from gregorian calendar
            { 'name': 'A.D.', 'start': null, 'offset': 0 }
          ],
          twoDigitYearMax: 2090,
          patterns: {
            // short date pattern
            d: 'M/d/yyyy',
            // long date pattern
            D: 'dddd, MMMM dd, yyyy',
            // short time pattern
            t: 'h:mm tt',
            // long time pattern
            T: 'h:mm:ss tt',
            // long date, short time pattern
            f: 'dddd, MMMM dd, yyyy h:mm tt',
            // long date, long time pattern
            F: 'dddd, MMMM dd, yyyy h:mm:ss tt',
            // month/day pattern
            M: 'MMMM dd',
            // month/year pattern
            Y: 'yyyy MMMM',
            // S is a sortable format that does not vary by culture
            S: 'yyyy\u0027-\u0027MM\u0027-\u0027dd\u0027T\u0027HH\u0027:\u0027mm\u0027:\u0027ss',
            // formatting of dates in MySQL DataBases
            ISO: 'yyyy-MM-dd hh:mm:ss',
            ISO2: 'yyyy-MM-dd HH:mm:ss',
            d1: 'dd.MM.yyyy',
            d2: 'dd-MM-yyyy',
            d3: 'dd-MMMM-yyyy',
            d4: 'dd-MM-yy',
            d5: 'H:mm',
            d6: 'HH:mm',
            d7: 'HH:mm tt',
            d8: 'dd/MMMM/yyyy',
            d9: 'MMMM-dd',
            d10: 'MM-dd',
            d11: 'MM-dd-yyyy'
          },
          percentSymbol: '%',
          currencySymbol: '$',
          currencySymbolPosition: 'before',
          decimalSeparator: '.',
          thousandsSeparator: ',',
          pagerGotoPageString: 'Go to page:',
          pagerShowRowsString: 'Show rows:',
          pagerRangeString: ' of ',
          pagerPreviousButtonString: 'previous',
          pagerNextButtonString: 'next',
          pagerFirstButtonString: 'first',
          pagerLastButtonString: 'last',
          groupsHeaderString: 'Drag a column and drop it here to group by that column',
          sortAscendingString: 'Sort Ascending',
          sortDescendingString: 'Sort Descending',
          sortRemoveString: 'Remove Sort',
          groupByString: 'Group By this column',
          groupRemoveString: 'Remove from groups',
          filterClearString: 'Clear',
          filterString: 'Filter',
          filterShowRowString: 'Show rows where:',
          filterOrConditionString: 'Or',
          filterAndConditionString: 'And',
          filterSelectAllString: '(Select All)',
          filterChooseString: 'Please Choose:',
          filterStringComparisonOperators: ['empty', 'not empty', 'contain', 'does not contain', 'starts with', 'ends with', 'equal'],
          filterNumericComparisonOperators: ['equal', 'not equal', 'less than', 'less than or equal', 'greater than', 'greater than or equal', 'null', 'not null'],
          filterDateComparisonOperators: ['equal', 'not equal', 'less than', 'less than or equal', 'greater than', 'greater than or equal', 'null', 'not null'],
          filterBooleanComparisonOperators: ['equal', 'not equal'],
          ValidationString: 'Entered value is not valid',
          emptyDataString: 'No data to display',
          filterSelectString: 'Select Filter',
          loadText: 'Loading...',
          clearString: 'Clear',
          todayString: 'Today',
          dayViewString: 'Day',
          weekViewString: 'Week',
          monthViewString: 'Month'
        }
    }
  }

  static setCulture(window: any, lang: string): any {
    switch (lang.substring(0, 2)) {
      case 'es':
        window.Globalize.addCultureInfo("es-ES", "default", {
          name: "es-ES",
          englishName: "Spanish (Spain, International Sort)",
          nativeName: "Español (España, alfabetización internacional)",
          language: "es",
          numberFormat: {
            ",": ".",
            ".": ",",
            "NaN": "NeuN",
            negativeInfinity: "-Infinito",
            positiveInfinity: "Infinito",
            percent: {
              ",": ".",
              ".": ","
            },
            currency: {
              pattern: ["-n $", "n $"],
              ",": ".",
              ".": ",",
              symbol: "€"
            }
          },
          calendars: {
            standard: {
              firstDay: 1,
              days: {
                names: ["domingo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado"],
                namesAbbr: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
                namesShort: ["do", "lu", "ma", "mi", "ju", "vi", "sá"]
              },
              months: {
                names: ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre", ""],
                namesAbbr: ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic", ""]
              },
              AM: null,
              PM: null,
              eras: [{ "name": "d.C.", "start": null, "offset": 0 }],
              patterns: {
                d: "dd/MM/yyyy",
                D: "dddd, dd' de 'MMMM' de 'yyyy",
                t: "H:mm",
                T: "H:mm:ss",
                f: "dddd, dd' de 'MMMM' de 'yyyy H:mm",
                F: "dddd, dd' de 'MMMM' de 'yyyy H:mm:ss",
                M: "dd MMMM",
                Y: "MMMM' de 'yyyy"
              }
            }
          }
        });
        break;
      case 'ca':
        window.Globalize.addCultureInfo("ca-ES", "default", {
          name: "ca-ES",
          englishName: "Catalan (Spain, International Sort)",
          nativeName: "Catalan (España, alfabetización internacional)",
          language: "ca",
          numberFormat: {
            ",": ".",
            ".": ",",
            "NaN": "NeuN",
            negativeInfinity: "-Infinito",
            positiveInfinity: "Infinito",
            percent: {
              ",": ".",
              ".": ","
            },
            currency: {
              pattern: ["-n $", "n $"],
              ",": ".",
              ".": ",",
              symbol: "€"
            }
          },
          calendars: {
            standard: {
              firstDay: 1,
              days: {
                names: ['Diumenge', 'Dilluns', 'Dimarts', 'Dimecres', 'Dijous',
                  'Divendres', 'Dissabte'],
                namesAbbr: ['Dg', 'Dl', 'Dt', 'Dc', 'Dj', 'Dv', 'Ds'],
                namesShort: ['Dg', 'Dl', 'Dt', 'Dc', 'Dj', 'Dv', 'Ds']
              },
              months: {
                names: ['Gener', 'Febrer', 'Març', 'Abril', 'Maig', 'Juny', 'Juliol',
                  'Agost', 'Setembre', 'Octubre', 'Novembre', 'Desembre', ''],
                namesAbbr: ['Gen', 'Febr', 'Març', 'Abr', 'Maig', 'Juny', 'Jul', 'Ag',
                  'Set', 'Oct', 'Nov', 'Des', '']
              },
              AM: ['AM', 'am', 'AM'],
              PM: ['PM', 'pm', 'PM'],
              eras: [
                { name: 'A.D.', start: null, offset: 0 }
              ],
              twoDigitYearMax: 2090,
              patterns:
              {
                d: 'dd/MM/yyyy',
                D: 'dddd, d. MMMM yyyy',
                t: 'HH:mm',
                T: 'HH:mm:ss',
                f: 'dddd, d. MMMM yyyy HH:mm',
                F: 'dddd, d. MMMM yyyy HH:mm:ss',
                M: 'dd MMMM',
                Y: 'MMMM yyyy'
              },
            }
          }
        });
        break;
      case 'de':
        window.Globalize.addCultureInfo("de-DE", "default", {
          name: "de-DE",
          englishName: "German (Germany)",
          nativeName: "Deutsch (Deutschland)",
          language: "de",
          numberFormat: {
            ",": ".",
            ".": ",",
            "NaN": "n. def.",
            negativeInfinity: "-unendlich",
            positiveInfinity: "+unendlich",
            percent: {
              pattern: ["-n%", "n%"],
              ",": ".",
              ".": ","
            },
            currency: {
              pattern: ["-n $", "n $"],
              ",": ".",
              ".": ",",
              symbol: "€"
            }
          },
          calendars: {
            standard: {
              "/": ".",
              firstDay: 1,
              days: {
                names: ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
                namesAbbr: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
                namesShort: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"]
              },
              months: {
                names: ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember", ""],
                namesAbbr: ["Jan", "Feb", "Mrz", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez", ""]
              },
              AM: null,
              PM: null,
              eras: [{ "name": "n. Chr.", "start": null, "offset": 0 }],
              patterns: {
                d: "dd.MM.yyyy",
                D: "dddd, d. MMMM yyyy",
                t: "HH:mm",
                T: "HH:mm:ss",
                f: "dddd, d. MMMM yyyy HH:mm",
                F: "dddd, d. MMMM yyyy HH:mm:ss",
                M: "dd MMMM",
                Y: "MMMM yyyy"
              }
            }
          }
        });
        break;
      case 'fr':
        window.Globalize.addCultureInfo("fr-FR", "default", {
          name: "fr-FR",
          englishName: "French (France)",
          nativeName: "français (France)",
          language: "fr",
          numberFormat: {
            ",": " ",
            ".": ",",
            "NaN": "Non Numérique",
            negativeInfinity: "-Infini",
            positiveInfinity: "+Infini",
            percent: {
              ",": " ",
              ".": ","
            },
            currency: {
              pattern: ["-n $", "n $"],
              ",": " ",
              ".": ",",
              symbol: "€"
            }
          },
          calendars: {
            standard: {
              firstDay: 1,
              days: {
                names: ["dimanche", "lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi"],
                namesAbbr: ["dim.", "lun.", "mar.", "mer.", "jeu.", "ven.", "sam."],
                namesShort: ["di", "lu", "ma", "me", "je", "ve", "sa"]
              },
              months: {
                names: ["janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre", ""],
                namesAbbr: ["janv.", "févr.", "mars", "avr.", "mai", "juin", "juil.", "août", "sept.", "oct.", "nov.", "déc.", ""]
              },
              AM: null,
              PM: null,
              eras: [{ "name": "ap. J.-C.", "start": null, "offset": 0 }],
              patterns: {
                d: "dd/MM/yyyy",
                D: "dddd d MMMM yyyy",
                t: "HH:mm",
                T: "HH:mm:ss",
                f: "dddd d MMMM yyyy HH:mm",
                F: "dddd d MMMM yyyy HH:mm:ss",
                M: "d MMMM",
                Y: "MMMM yyyy"
              }
            }
          }
        });
        break;
      case 'it':
        window.Globalize.addCultureInfo("it-IT", "default", {
          name: "it-IT",
          englishName: "Italian (Italy)",
          nativeName: "italiano (Italia)",
          language: "it",
          numberFormat: {
            ",": ".",
            ".": ",",
            "NaN": "Non un numero reale",
            negativeInfinity: "-Infinito",
            positiveInfinity: "+Infinito",
            percent: {
              pattern: ["-n%", "n%"],
              ",": ".",
              ".": ","
            },
            currency: {
              pattern: ["-$ n", "$ n"],
              ",": ".",
              ".": ",",
              symbol: "€"
            }
          },
          calendars: {
            standard: {
              firstDay: 1,
              days: {
                names: ["domenica", "lunedì", "martedì", "mercoledì", "giovedì", "venerdì", "sabato"],
                namesAbbr: ["dom", "lun", "mar", "mer", "gio", "ven", "sab"],
                namesShort: ["do", "lu", "ma", "me", "gi", "ve", "sa"]
              },
              months: {
                names: ["gennaio", "febbraio", "marzo", "aprile", "maggio", "giugno", "luglio", "agosto", "settembre", "ottobre", "novembre", "dicembre", ""],
                namesAbbr: ["gen", "feb", "mar", "apr", "mag", "giu", "lug", "ago", "set", "ott", "nov", "dic", ""]
              },
              AM: null,
              PM: null,
              eras: [{ "name": "d.C.", "start": null, "offset": 0 }],
              patterns: {
                d: "dd/MM/yyyy",
                D: "dddd d MMMM yyyy",
                t: "HH:mm",
                T: "HH:mm:ss",
                f: "dddd d MMMM yyyy HH:mm",
                F: "dddd d MMMM yyyy HH:mm:ss",
                M: "dd MMMM",
                Y: "MMMM yyyy"
              }
            }
          }
        });
        break;
      case 'en':
      default:
        window.Globalize.addCultureInfo("en-US", "default", {
          name: "en-US",
          englishName: "English (United States)"
        });
        break;
    }
  }
}
