import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CuadriculasComponent } from './cuadriculas.component';
import { HeaderModule } from '../header/header.module';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [
    CuadriculasComponent,
  ],
  imports: [
    FormsModule,
    CommonModule,
   SharedModule,
    ReactiveFormsModule,
    HeaderModule
  ],
  exports: [
    CuadriculasComponent
  ]
})
export class CuadriculasModule { }
