export class LopdCiudadanoModel {
    id: number = 0;
    datoPersonal: string = '';
    empresa: number = 0;
    obligatorio: boolean = true;
    nombre: string = '';
    idAplicacion: number = 0;
}


export class CamposCiudadanosVisibleModel {
    datosLOPD: RolCamposCiudadanoModel[] = [];
    empresa: number = 0;
    idAplicacion: number = 0;
    idRol: number = 0;
}


export class RolCamposCiudadanoModel {
    id: number = 0;
    visible: boolean = true;
}