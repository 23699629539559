import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

import { HeaderModule } from '../header/header.module';
import { SharedModule } from 'src/app/shared/shared.module';

import { EdificiosInstalacionesListComponent } from './edificios-instalaciones-list/edificios-instalaciones-list.component';
import { EdificiosInstalacionesEditComponent } from './edificios-instalaciones-edit/edificios-instalaciones-edit.component';

@NgModule({
  declarations: [
    EdificiosInstalacionesListComponent,
    EdificiosInstalacionesEditComponent,
  ],
  imports: [
    FormsModule,
    CommonModule,
    HeaderModule,
    SharedModule,
    BrowserModule,
  ]
})
export class EdificiosInstalacionesModule { }
