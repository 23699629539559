<div class="loader-container" *ngIf="showLoader">
  <div class=" loader"></div>
</div>
<jqxWindow #form [jqxWindowAutoResize] [theme]="theme" [position]="getFormPos(form, 24)" [zIndex]="900"
  [width]="mapWidth" [height]="mapHeight - 24" [autoOpen]="true" [showCloseButton]="true" [showCollapseButton]="true"
  (onClose)="onClose()" [resizable]="true">
  <div class="formHeader" style="overflow-x: hidden;">
    {{translate('Cerradura')}}
  </div>
  <div class="form" style="float:left; overflow-x: hidden;">
    <div>
      <app-header #header [exportar]="true" [imprimir]="true" [buscador]="true" [filtro]="true" [resetFiltro]="true"
        [seeFilterMetros]="true" (eventExportar)="onExportar()" (eventImprimir)="onPrint()" (eventBuscador)="onBuscar()"
        (eventChangeMetros)="changeFilterMetros($event)" (eventFilter)="eventFilter()" (eventResetFilter)="eventResetFilter()">
      </app-header>
    </div>
    <div style="width: 100%; height: calc(100% - 80px);">
      <jqxGrid #myGrid [theme]="theme" [width]="'100%'" [height]="'100%'" [source]="dataAdapter" [columnsresize]="true"
        [localization]="langGrid" [filterable]="true" [columns]="columns" [showrowlines]="false" [appCellClick]
        [enablebrowserselection]="true" [showcolumnlines]="true" [pageable]="false" [sortable]="true" [altrows]="true"
        [showstatusbar]="true" [statusbarheight]="20" [showaggregates]="true" [rowsheight]="25" [columnsheight]="25"
        [editable]="false" [groupable]="true" [selectionmode]="'checkbox'" [filter]="filter" [showemptyrow]="false"
        [updatefilterconditions]="updatefilterconditions" (onRowselect)="onSelect($event)"
        (onCellclick)="onCellClick($event)" (onRowunselect)="onUnSelect($event)"
        (onRowdoubleclick)="onRowdoubleclick($event)">
      </jqxGrid>
    </div>
    <div>
      <jqxButton style="float: left; margin-left: 5px; margin-top: 10px;" [width]="100" [height]="26"
        [textImageRelation]="'imageBeforeText'" [textPosition]="'left'" [imgSrc]="'/assets/images/localizar.png'"
        [value]="translate('Centrar')" (onClick)="onPosicionar()">
      </jqxButton>

      <jqxButton style="float: left; margin-left: 5px; margin-top: 10px;" [width]="142" [height]="26" [hidden]="false"
        [textImageRelation]="'imageBeforeText'" [textPosition]="'left'" [imgSrc]="'/assets/images/localizar.png'"
        [value]="translate('Pedir_localizacion')" (onClick)="onLocalizar()">
      </jqxButton>

      <jqxButton style="float: left; margin-left: 5px; margin-top: 10px;" [width]="100" [height]="26"
        [textImageRelation]="'imageBeforeText'" [textPosition]="'left'" (onClick)="closeWindow()"
        [value]="translate('Salir')">
      </jqxButton>
    </div>
  </div>
</jqxWindow>

<div hidden="viewForm" style="z-index: 900;">
  <jqxWindow #formDetalle [theme]="theme" [zIndex]="900" [position]="getFormPos(formDetalle)" [width]="650"
    [height]="450" [autoOpen]="false" [showCloseButton]="true" [showCollapseButton]="true" [resizable]="true"
    (onClose)="onCloseForm()">
    <div class="formHeader" style="overflow-x: hidden;">
      {{translate('Acciones')}}
    </div>

    <div class="form" style="float:left; overflow-x: hidden; ">
      <div class="row" style="width: calc(100% - 5px);">
        <div style="width: 33%;">
          <div>
            {{translate('Tipo_producto')}}
          </div>
          <jqxInput [width]="'100%'" [height]="25" [(ngModel)]="cerradura.tipoOperacionStr" [disabled]="true">
          </jqxInput>
        </div>
        <div style="width: 33%;">
          <div>
            {{translate('Ns_movisat')}}
          </div>
          <jqxInput [width]="'100%'" [height]="25" [(ngModel)]="cerradura.nsMovisat" [disabled]="true">
          </jqxInput>
        </div>
        <div style="width: 33%;">
          <div>
            {{translate('Ns_fabricante')}}
          </div>
          <jqxInput [width]="'100%'" [height]="25" [(ngModel)]="cerradura.nsFabricante" [disabled]="true">
          </jqxInput>
        </div>
      </div>
      <div class="row">
        <div style=" width: calc(50% - 5px);">
          <div>
            {{translate('Imei')}}
          </div>
          <jqxInput [width]="'100%'" [height]=" 25" [rtl]="true" [(ngModel)]="cerradura.imei" [disabled]="true">
          </jqxInput>

          <div style="margin-top: 2px;">
            {{translate('Sim')}}
          </div>
          <jqxInput [width]="'100%'" [height]="25" [rtl]="true" [(ngModel)]="cerradura.sim" [disabled]="true">
          </jqxInput>
        </div>

        <div style="width: calc(50% - 5px);">
          <fieldset>
            <legend>
              <div>IMSI</div>
            </legend>

            <div style="width: 100%;">
              <jqxInput [width]="'100%'" [height]="25" [rtl]="true" [ngModel]="cerradura.imsi" [disabled]="true">
              </jqxInput>
            </div>

            <div style="display: flex; gap:2px">
              <div style="width: 20%;">
                <div>
                  MCC
                </div>
                <jqxInput [width]="'100%'" [height]="25" [rtl]="true" [(ngModel)]="cerradura.mcc" [disabled]="true">
                </jqxInput>
              </div>
              <div style="width: 20%;">
                <div>
                  MNC
                </div>
                <jqxInput [width]="'100%'" [height]="25" [rtl]="true" [(ngModel)]="cerradura.mnc" [disabled]="true">
                </jqxInput>
              </div>
              <div style="width: 100%;">
                <div>
                  MSIN
                </div>
                <jqxInput [width]="'100%'" [height]="25" [rtl]="true" [(ngModel)]="cerradura.icc" [disabled]="true">
                </jqxInput>
              </div>
            </div>

          </fieldset>
        </div>
      </div>

      <div class="row" style="width: calc(100% - 5px);">
        <div style="width: 60%;">
          <div>
            {{translate('Nombre_elemento')}}
          </div>
          <jqxInput [width]="'100%'" [height]="25" [ngModel]="cerradura?.elemento?.nombre" [disabled]="true">
          </jqxInput>
        </div>

        <div style="width: 40%;">
          <div>
            {{translate('Matricula')}}
          </div>
          <jqxInput [width]="'100%'" [height]="25" [ngModel]="cerradura?.elemento?.tag?.matricula" [disabled]="true">
          </jqxInput>
        </div>
      </div>
      <div class="row">
        <div style="width: 50%;">
          <div>
            {{translate('Marca')}}
          </div>
          <jqxInput [width]="'100%'" [height]="25" [ngModel]="cerradura?.elemento?.marca" [disabled]="true">
          </jqxInput>
        </div>
        <div style="width: 50%;">
          <div>
            {{translate('Modelo')}}
          </div>
          <jqxInput [width]="'100%'" [height]="25" [ngModel]="cerradura?.elemento?.modelo" [disabled]="true">
          </jqxInput>
        </div>
      </div>
      <div class="row">
        <div style="width: 50%;">
          <div>
            {{translate('Version')}}
          </div>
          <jqxInput [width]="'100%'" [height]="25" [ngModel]="cerradura?.versoft" [disabled]="true">
          </jqxInput>
        </div>
        <div style="width: 50%;">
          <div>
            {{translate('F_ult_conex')}}
          </div>
          <jqxInput [width]="'100%'" [height]="25" [value]="cerradura?.fechaUltimaConexionStr" [disabled]="true">
          </jqxInput>
        </div>
      </div>

      <div class="row" style="width: calc(100% - 5px);">
        <div style="width: 100%;">
          <div>
            {{translate('Observaciones')}}
          </div>
          <jqxInput [width]="'100%'" [height]="50" [ngModel]="cerradura?.elemento?.observaciones" [disabled]="true">
          </jqxInput>
        </div>
      </div>

      <div style="position:absolute; bottom:5px; left:5px; margin-top: 15px; clear: both;">
        <button class="jqx-rc-all jqx-button jqx-widget jqx-fill-state-normal" (click)="onCancelBtn()">{{ translate('CANCELAR') }}</button>
      </div>
    </div>
  </jqxWindow>
</div>

<div #formLockConfiguracion></div>
