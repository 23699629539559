import { Component, HostListener, Injectable, ViewChild, ViewContainerRef } from '@angular/core';
import { CanActivate, CanActivateChild, NavigationError, NavigationStart, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { MainComponent } from './components/main/main.component';
import { ApiService } from './services/api/api.service';
import { LangService } from './services/lang/lang.service';
import { SsoService } from './services/sso/sso.service';
import { AuditoriaService } from './services/auditoria/auditoria.service';
import { Accion } from './services/auditoria/models/accion.model';
import { AuditoriaModel } from './services/auditoria/models/auditoria.model';
import { CustomForms } from './components/forms/custom-forms';
import { HttpClient } from '@angular/common/http';
import { MenuModel } from './services/menu/models/menu.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  @HostListener('window:resize', ['$event'])
  onResize(event) {
  }

  @ViewChild('mainContainer', { read: ViewContainerRef }) mainContainer;
  private static instance: AppComponent;
  public idioma = 'es-ES';
  public title = 'newp';
  public urlOrigin = '';
  public entorno = '';
  private usuarioEmail = '';
  private auditoria = new AuditoriaModel(this.usuarioEmail, 0);
  public menuData: any = null;

  constructor(
    private ssoService: SsoService,
    private router: Router,
    private apiService: ApiService,
    private langService: LangService,
    private auditoriaService: AuditoriaService,
    private http: HttpClient
  ) {
    AppComponent.instance = this;
    const t = setTimeout(async () => {
      clearTimeout(t);
      // Compruebo la URL de origen
      this.urlOrigin = new URLSearchParams(window.location.search).get('origin');
      if (!this.urlOrigin) {
        this.urlOrigin = sessionStorage.getItem('np-origen');
        if (!this.urlOrigin) {
          this.urlOrigin = '';
        }
      }
      if (this.urlOrigin.startsWith('my.movisat.com')) {
        environment.apiSSO = 'https://api.sso.movisat.com';
        environment.webSSO = 'https://my.movisat.com';
        this.entorno = '';
      } else {
        if (this.urlOrigin.startsWith('preproduccion.my.movisat.com')) {
          environment.apiSSO = 'https://api.sso.preproduccion.movisat.com';
          environment.webSSO = 'https://preproduccion.my.movisat.com';
          this.entorno = 'Pre-producción';
        } else {
          environment.apiSSO = 'https://api.sso.dev.movisat.com';
          environment.webSSO = 'https://dev.my.movisat.com';
          this.entorno = 'Desarrollo';
        }
      }
      sessionStorage.setItem('np-origen', this.urlOrigin);
      // Antes de nada pido el token del SSO para que se almacene en el localstoragecd
      await this.ssoService.getToken();
      // Control de eventos de enrutamiento
      this.router.events.subscribe(async (event) => {
        // Cada vez que se carga una página
        if (event instanceof NavigationStart) {
          if (!event.url.startsWith('/?ticketId=')) {
            // Se cormprueba que hay ticket SSO válido
            const ticket = this.ssoService.getTicket();
            if (ticket === null || !await this.ssoService.isUsuarioValidado(ticket.Usuario.Id)) {
              this.logout();
            }
          }
          // Si se accede a una página que no existe
          if (event instanceof NavigationError) {
            this.logout();
          }
        }
      });
      // Si no tengo el ticket del SSO intento recuperarlo
      const ticketId = new URLSearchParams(window.location.search).get('ticketId');
      if (ticketId || !await this.ssoService.getTicket()) {
        // Recupero el ticket del SSO con el id recibido
        if (await this.ssoService.initSession(ticketId)) {
          // Recupero el e-mail del usuario
          this.usuarioEmail = this.ssoService.getTicket().Usuario.Email;
          this.auditoria = new AuditoriaModel(this.usuarioEmail, 0);
          this.auditoria.AccionId = Accion.ACCESO_USUARIO;
          this.auditoriaService.addAuditoria(this.auditoria);
          // Para que vaya a la pagina principal y no se quede el ticketId visible en la URL
          window.location.href = "/";
        } else {
          this.logout();
        }
      } else {
        if (window.location.pathname === '/') {
          this.login();
        }
      }
    }, 0);
  }

  public static getInstance(): AppComponent {
    return AppComponent.instance;
  }

  // Redirijo a la página principal
  private async login(): Promise<void> {
    // Recupero el ticket del SSO
    const ticketSSO = this.ssoService.getTicket();
    // Obtengo un token de la API
    if (ticketSSO.UrlApi && ticketSSO.UrlApi.length > 0) {
      if (!(await this.apiService.initSessionApi())) {
        alert('No se ha obtenido token de ' + ticketSSO.UrlApi);
        this.logout();
      }
    }

    // Obtengo un token de la api de la BDT si se ha configurado
    if (environment.urlApiBdt && environment.urlApiBdt.length > 0) {
      // if (!await this.apiService.initSessionApiBdt()) {
      if (!this.apiService.initSessionApiBdt()) {
        alert('No se ha obtenido token de ' + environment.urlApiBdt);
        this.logout();
      }
    }

    // Obtengo un token de la api de IA si se ha configurado
    if (ticketSSO.UrlApiIA && ticketSSO.UrlApiIA.length > 0) {
      // if (!await this.apiService.initSessionApiIA()) {
      if (!this.apiService.initSessionApiIA()) {
        alert('No se ha obtenido token de ' + ticketSSO.UrlApiIA);
        //this.logout();
      }
    }

    // Obtengo un token de la api de Cartografía si se ha configurado
    if (environment.carto.baseUrl && environment.carto.baseUrl.length > 0) {
      // if (!await this.apiService.initSessionApiCarto()) {
      if (!this.apiService.initSessionApiCarto()) {
        alert('No se ha obtenido token de ' + environment.carto.baseUrl);
        //this.logout();
      }
    }

    // Almaceno el idioma del usuario
    this.idioma = ticketSSO.Usuario.Idioma.Codigo;
    let idIdioma = ticketSSO.Usuario.Idioma.Id;
    // Recupero los textos y cargo los del idioma del usuario
    if (idIdioma) {
      await this.langService.getText(idIdioma);
    }
    // Cargo el menú de la aplicación
    await this.loadMenu();
    // Una vez cargado el idioma abro el componente principal de la aplicación
    this.mainContainer.createComponent(MainComponent);
  }

  // Obtengo el menú y los permisos de usuario
  async loadMenu() {
    this.menuData = await this.http.get<MenuModel>(environment.apiSSO + '/sso/menu/aplicacion/rol/' +
      this.ssoService.getTicket().Empresa.Id + '/' + this.ssoService.getTicket().Aplicacion.Id + "/" +
      this.ssoService.getTicket().Rol.Id).toPromise();
    if (this.menuData && this.menuData.Opciones) {
      this.menuData.Opciones.forEach(item => {
        // Relleno las acciones del menú
        if (item.AccionId.length > 0) {
          CustomForms.menuActions.set(item.AccionId, item);
        }
        // Relleno los permisos de cada acción
        if (item.AccionId.length > 0 && item.Permisos && item.Permisos.length > 0) {
          CustomForms.permisos.set(item.AccionId, item.Permisos);
        }
      });
    }
  }

  // Redirijo a la página de login del SSO
  private logout(): void {
    this.ssoService.endAppSession();
  }

  // Método etático para traducciones
  public static translate(text): string {
    try {
      return AppComponent.getInstance().langService.translate(text);
    } catch (e) {

    }
  }
}

// Clase que sirve para controlar todas las redirecciones
// Si no hay ticket del SSO no dejo redireccionar a ninguna página
@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(private ssoService: SsoService) { }

  canActivate() {
    return this.ssoService.getTicket() !== null;
  }

  canActivateChild() {
    return this.ssoService.getTicket() !== null;
  }

}
