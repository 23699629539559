<jqxWindow #form [jqxWindowAutoResize] [position]="getFormPos(form,0)" [theme]="theme" [width]="mapWidth" [zIndex]="900"
  [height]="mapHeight" [autoOpen]="true" [showCloseButton]="false" style="margin-top: 24px;">

  <div hidden="true"></div>
  <div class="form">
    <div>
      <app-header #header [buscador]="true" [exportar]="true" [periodo]="true" [filtro]="true" [resetFiltro]="true"
        [seeCheckAlarms]="true" (eventExportar)="onExportar()" (eventFilter)="onAceptar()"
        (eventResetFilter)="onResetFilter()" (eventBuscador)="onBuscar()" (eventCheckAlarms)="onCheckAlarms($event)">
      </app-header>
    </div>
    <div style="width: 100% !important;height: calc(100% - 40px) !important; position: absolute; z-index: 999;">
      <jqxGrid #grid [theme]="theme" [width]="'100%'" [height]="'98%'" [source]="dataAdapter" [columnsresize]="true"
        [appCellClick] [sortable]="true" [showrowlines]="false" [showcolumnlines]="true" [columns]="columns"
        [columnmenuopening]='columnmenuopening' [altrows]="true" [filterable]="true"
        [showstatusbar]="true" [showaggregates]="true" [columnsautoresize]="true" [enablebrowserselection]="true"
        [localization]="langGrid" [groupable]="true" [showemptyrow]="false" [statusbarheight]="20"
        (onGroupschanged)="resizeColumns()" [columnsheight]="20" [rowsheight]="20" [filter]="filter"
        [updatefilterconditions]="updatefilterconditions">
      </jqxGrid>
    </div>
  </div>
</jqxWindow>