<div class="loader-container" *ngIf="!columns.length">
  <div class=" loader"></div>
</div>
<jqxWindow #form [jqxWindowAutoResize] [theme]="environment.tema" [width]="mapWidth" [height]="mapHeight - 24" [position]="getFormPos(form, 24)"
  [zIndex]="900" [autoOpen]="true" [showCloseButton]="true" [showCollapseButton]="true" [resizable]="true"
  (onClose)="onClose()">
  <div class="formHeader" style="overflow-x: hidden;">
    {{translate('Listado_sensores')}}
  </div>
  <div class="form" style="height: 100%; overflow: hidden;">
    <div style="float: left; margin-top: 2px; height: calc(100% - 40px); width: 100%;">
      <jqxSplitter #mainSplitter [panels]="[{ min: '15%', size: '15%' }]" [height]="'100%'" [width]="'100%'">
        <div>
          <div style="height: 100%;">
            <app-moviles-select (passArray)="selected($event)"></app-moviles-select>
          </div>
        </div>
        <div>
          <div style="height: calc(100% - 40px);">
            <div>
              <app-header #header [exportar]="true" [periodo]="true" [filtro]="true" [resetFiltro]="true"
                [buscador]="true" (eventExportar)="onExportar()" (eventFilter)="onAceptar()"
                (eventResetFilter)="onResetFilter()" (eventBuscador)="onBuscar()">
              </app-header>
            </div>

            <jqxGrid #grid [theme]="environment.tema" [width]="'100%'" [height]="'100%'" [source]="dataAdapter"
              [appCellClick] [enablebrowserselection]="true" [columnsresize]="true" [filterable]="true"
              [showfilterrow]="false" [columns]="columns" [sortable]="true" [altrows]="true" [showrowlines]="false"
              [filterrowheight]="30" [rowsheight]="20" [columnsheight]="20" [showaggregates]="true"
              [enabletooltips]="true" [groupable]="true" [showstatusbar]="true" [statusbarheight]="20"
              [localization]="langGrid" [filter]="filter" [updatefilterconditions]="updatefilterconditions"
              (onRowselect)="onRowClick($event)">
            </jqxGrid>


            <div class="form" style="float: left; width: 100%; height: 100%;">
              <jqxButton style="float:left; margin-left: 10px;" [width]='100' [height]='26'
                [textImageRelation]='"imageBeforeText"' [textPosition]='"left"' [imgSrc]='"/assets/images/center.png"'
                [value]="translate('Centrar')" (onClick)="onCentrar($event)">
              </jqxButton>

              <jqxButton [width]="100" [height]="26" style="float:left; margin-left: 10px;"
                [textImageRelation]="'imageBeforeText'" [textPosition]="'left'" (onClick)="closeWindow()"
                [value]="translate('Salir')">
              </jqxButton>
            </div>
          </div>
        </div>
      </jqxSplitter>
    </div>
    <div class="form" style="float: left; width: 100%; height: 100%; margin-top: 10px;">
      <jqxButton style="float:left; margin-left: 10px;" [width]='100' [height]='26' [textImageRelation]='"imageBeforeText"'
        [textPosition]='"left"' [imgSrc]='"/assets/images/center.png"' [value]="translate('Centrar')"
        (onClick)="onCentrar($event)">
      </jqxButton>

      <jqxButton [width]="100" [height]="26" style="float:left; margin-left: 10px;"
        [textImageRelation]="'imageBeforeText'" [textPosition]="'left'"
        (onClick)="closeWindow()" [value]="translate('Salir')">
      </jqxButton>
    </div>
  </div>
</jqxWindow>
