export class ElemCoordenadasModel {
  Calle: string;
  CodigoPostal: string;
  ComunidadAutonoma: string;
  Empresa: number;
  Fecha: Date;
  GuidElemento: string;
  Id: number;
  IdElemento: number;
  Lat: number;
  Lng: number;
  Municipio: string;
  NumeroCalle: string;
  Pais: string;
  Poblacion: string;
  Provincia: string;
}
