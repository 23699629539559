<div class="loader-container" *ngIf="showLoader">
  <div class=" loader"></div>
</div>
<jqxWindow #form [jqxWindowAutoResize] [theme]="environment.tema" [position]="getFormPos(form, 24)" [zIndex]="900" [width]="mapWidth"
  [height]="mapHeight - 24" [autoOpen]="true" [showCloseButton]="true" [showCollapseButton]="true" [resizable]="true"
  (onClose)="onClose()">
  <div class="formHeader" style="overflow-x: hidden;">
    {{translate('Vehiculos')}}
  </div>
  <div class="form" style="float:left; overflow-x: hidden;">
    <div>
      <app-header #header [exportar]="true" [imprimir]="true" [buscador]="true" [filtro]="true" [resetFiltro]="true"
        (eventFilter)="eventFilter()" (eventResetFilter)="eventResetFilter()" (eventExportar)="onExportar()"
        (eventImprimir)="onPrint()" (eventBuscador)="onBuscar()">
      </app-header>
    </div>
    <div style="float:left; width: 100%; height: 100%; margin-top: 1px;">
      <jqxGrid #grid [theme]="environment.tema" [width]="'100%'" [height]="'calc(100% - 85px)'" [source]="dataAdapter"
        [appCellClick] [columnsresize]="true" [localization]="langGrid" [filterable]="true" [showfilterrow]="false"
        [selectionmode]="'checkbox'" [columns]="columns" [showrowlines]="false" [showcolumnlines]="true"
        [enablebrowserselection]="true" [pageable]="false" [sortable]="true" [altrows]="true" [showstatusbar]="true"
        [statusbarheight]="20" [showaggregates]="true" [rowsheight]="30" [filterrowheight]="25" [columnsheight]="25"
        [enabletooltips]="false" [editable]="false" [filtermode]="'excel'" [columngroups]="columngroups"
        [filter]="filter" [updatefilterconditions]="updatefilterconditions" (onBindingcomplete)="onBindingComplete()"
        (onRowdoubleclick)="onRowDoubleClick($event)">
      </jqxGrid>

      <div style="float: left; margin-top: 4px; display: flex; gap: 2px;">
        <jqxButton style="float:left" [width]='100' [height]='26' [textImageRelation]='"imageBeforeText"'
          [textPosition]='"left"' [imgSrc]='"/assets/images/save.png"' [value]="translate('Visualizar')"
          (onClick)="onGuardar()">
        </jqxButton>

        <jqxButton style="float: left; margin-left: 5px" [width]="100" [height]="26"
          [textImageRelation]="'imageBeforeText'" [textPosition]="'left'" (onClick)="closeWindow()"
          [value]="translate('Salir')">
        </jqxButton>
      </div>
    </div>
  </div>
</jqxWindow>

<div #formMovil></div>
