import {
  HttpClient,
  HttpHeaders
} from '@angular/common/http';

import { Injectable } from '@angular/core';
import { SsoService } from '../../sso/sso.service';

import { ElemInstalacionModel } from '../models/instalacion/elem-instalacion.model';

@Injectable({
  providedIn: 'root'
})
export class InstalacionService {
  private empresaId: number = this.ssoService.getTicket().Empresa.IdGestion;

  constructor(
    private http: HttpClient,
    private ssoService: SsoService) {}

  async getInstalacionByElement(elemId: number): Promise<ElemInstalacionModel> {
    let instalacion: ElemInstalacionModel = new ElemInstalacionModel();
    try {
      instalacion = await this.http.get<ElemInstalacionModel>(
        `${this.ssoService.getTicket().UrlApi}/api/instalacion/elemento?id=${elemId}&enterprise=${this.empresaId}&include=all`
      ).toPromise();
    } catch (error) {
    }

    return instalacion;
  }

  async saveInstalacion(instalacion: ElemInstalacionModel, elemId: number) {
    let response: ElemInstalacionModel;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    instalacion.empresa = this.empresaId;
    instalacion.idElemento = elemId;

    try {
      response = await this.http.post<ElemInstalacionModel>(this.ssoService.getTicket().UrlApi + '/api/instalacion',
        JSON.stringify(instalacion), httpOptions).toPromise();

      if(response) {
        return response;
      }else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }
}
