import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CatastroModel } from '../ciudadanos/models/catastro.model';
import { SsoService } from '../sso/sso.service';
import { AuditoriaModel } from '../auditoria/models/auditoria.model';
import { Accion } from '../auditoria/models/accion.model';
import { AuditoriaService } from '../auditoria/auditoria.service';

@Injectable({
  providedIn: 'root'
})
export class CatastroService {
  private urlApi = this.ssoService.getTicket().UrlApi;

  private usuario = this.ssoService.getTicket().Usuario.Email;
  private auditoria: AuditoriaModel = new AuditoriaModel(this.usuario, 0);

  constructor(private http: HttpClient, private ssoService: SsoService, private auditoriaService: AuditoriaService) { }

  async getCatastros(): Promise<CatastroModel[]> {
    let response: CatastroModel[] = [];

    try {
      await this.http.get<CatastroModel[]>(this.urlApi + '/api/referencias/catastrales').toPromise().then(
        res => {
          response = res;
        }, error => {
          response = [];
        }
      );
    } catch (e) {
      response = [];
    }

    return response;
  }

  async saveCatastro(catastro: CatastroModel): Promise<CatastroModel>{
    let response: CatastroModel;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    try{
      response = await this.http.post<CatastroModel>(
        this.urlApi + '/api/referencia/catastral',
        JSON.stringify(catastro),
        httpOptions
      ).toPromise();


      this.auditoria.AccionId = Accion.CREAR_CATASTRO;
      this.auditoria.Info = 'ID catastro: ' + catastro.id;
      this.auditoriaService.addAuditoria(this.auditoria);
    }catch(e){
      response = null;
    }

    return response;
  }
}
