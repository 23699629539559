<div class="loader-container" *ngIf="showLoader">
  <div class="loader"></div>
</div>

<jqxWindow #formAsociarTarjeta [width]="600" [height]="450" [zIndex]="900" [isModal]="true" [position]="'center'"
    [showCloseButton]="true" [resizable]="false" [autoOpen]="true" [theme]="environment.tema"
    [title]="translate('Asociar') + ' ' + translate('Tarjeta') + ' ' + tarjetaToAsociar.descripcion" (onClose)="onClose()">
    <div class="formHeader" style="overflow-x: hidden;">
      {{ translate('Asociar') }} {{ translate('Tarjeta') }}
    </div>
    <div class="form" style="float: left; overflow-x: hidden;">
      <div class="row" style="overflow: hidden; background-color: transparent; width: 100%; height: calc(100% - 50px);">
        <div class="row" style="margin-top: 10px;">
          <span style="display: block; width: 99%;">
            <div style="margin-top: 5px">
              {{ translate('Ciudadano') }}
            </div>
            <jqxDropDownButton #cbCiudadanos [width]="'100%'" [theme]="environment.tema" [animationType]="'none'"
              (onClose)="onCloseSelectCiudadanos()" (onOpen)="onOpenSelectCiudadanos($event)"
              (focusout)="cbCiudadanos.close()">
            </jqxDropDownButton>
            <div style="position: absolute; z-index: 999;">
              <jqxGrid [hidden]="!verGridCiudadanos" #gridDropDownCiudadanos [theme]="environment.tema" [appCellClick]
                [width]="cbCiudadanos.elementRef.nativeElement.parentElement.clientWidth" [filterable]="true" [sortable]="true"
                [height]="ciudadanos !== null && ciudadanos.length < 10 ?
                  (ciudadanos.length + 1) * 20 : 11 * 20"
                [rowsheight]="20"
                [columnsheight]="20"
                [source]="adapterCiudadanos" [columns]="columnsCiudadanos" [localization]="langGrid"
                [enablebrowserselection]="true" (onRowselect)="onRowClickCiudadanos($event)">
              </jqxGrid>
            </div>
          </span>
        </div>

        <div class="row" style="margin-top: 10px;">
          <span style="display: block; width: 99%;">
            <div style="margin-top: 5px">
              {{ translate('Direccion') }}
            </div>
            <jqxDropDownButton #cbDirecciones [width]="'100%'" [theme]="environment.tema" [animationType]="'none'"
              (onClose)="onCloseSelectDirecciones()" (onOpen)="onOpenSelectDirecciones()"
              (focusout)="cbDirecciones.close()">
            </jqxDropDownButton>
            <div style="position: absolute; z-index: 999;">
              <jqxGrid [hidden]="!verGridDirecciones" #gridDropDownDirecciones [theme]="environment.tema" [appCellClick]
                [width]="cbDirecciones.elementRef.nativeElement.parentElement.clientWidth" [filterable]="true" [sortable]="true"
                [height]="ciudadanoSelec?.direcciones !== null && ciudadanoSelec?.direcciones.length < 10 ?
                  (ciudadanoSelec?.direcciones.length + 1) * 20 : 11 * 20"
                [rowsheight]="20"
                [columnsheight]="20"
                [source]="adapterDirecciones" [columns]="columnsDirecciones" [localization]="langGrid"
                [enablebrowserselection]="true" (onRowselect)="onRowClickDirecciones($event)">
              </jqxGrid>
            </div>
          </span>
        </div>
      </div>
      <jqxButton style="float: left; margin-left: 5px; margin-top: 10px;" [width]="100" [height]="26"
        [textPosition]="'center'" [value]="translate('Guardar')" (onClick)="onGuardar()">
      </jqxButton>

      <jqxButton style="float: left; margin-left: 5px; margin-top: 10px;" [width]="100" [height]="26"
        [textPosition]="'center'" [value]="translate('CANCELAR')" (onClick)="closeWindow()">
      </jqxButton>
    </div>
</jqxWindow>
