import { Component, OnInit, ViewChild } from '@angular/core';

import { AppComponent } from 'src/app/app.component';
import { CustomForms } from '../../forms/custom-forms';
import { environment } from 'src/environments/environment';

import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';

import { CiudadanosService } from 'src/app/services/ciudadanos/ciudadanos.service';

import { TarjetaModel } from 'src/app/services/ciudadanos/models/tarjeta.model';
import { CiudadanoModel } from 'src/app/services/ciudadanos/models/ciudadano.model';
import { jqxDropDownButtonComponent } from 'jqwidgets-ng/jqxdropdownbutton';
import { DireccionModel } from 'src/app/services/ciudadanos/models/direccion.model';
import { JqWidgets } from 'src/app/utils/jqWidgets';
import { TarjetasCiudadanosComponent } from '../tarjetas-ciudadanos.component';
import { TarjetasService } from 'src/app/services/ciudadanos/tarjetas/tarjetas.service';
import { MainComponent } from '../../main/main.component';

@Component({
  selector: 'app-asociar-tarjetas',
  templateUrl: './asociar-tarjetas.component.html',
  styleUrls: ['./asociar-tarjetas.component.css']
})
export class AsociarTarjetasComponent extends CustomForms implements OnInit {
  @ViewChild('formAsociarTarjeta') formAsociarTarjeta: jqxWindowComponent;

  showLoader: boolean = false;

  public tarjetaToAsociar: TarjetaModel = new TarjetaModel();

  private componentRef = null;
  public environment = environment;
  public static _this: AsociarTarjetasComponent;
  public langGrid = JqWidgets.getLocalization('es');

  verGridCiudadanos: boolean = false;
  verGridDirecciones: boolean = false;

  cellClass = (row: number, columnfield: any, value: any): string => {
    if (value.length > 10 || (typeof value === 'object') ) {
      return 'cellTooltip';
    }
    return '';
  }

  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  constructor(
    private tarjetaServicio: TarjetasService,
    private ciudadanoService: CiudadanosService
  ) {
    super();
    AsociarTarjetasComponent._this = this;
  }

  ngOnInit(): void {
  }

  init(componentRef: any, tarjetaToAsociar: TarjetaModel) {
    this.componentRef = componentRef;
    this.tarjetaToAsociar = tarjetaToAsociar;
  }

  ngAfterViewInit() {
    this.addCustomForm(this.formAsociarTarjeta);

    this.initGridCiudadanos();
  }

  async onGuardar(){
    if(this.ciudadanoSelec && this.direccionSelec) {
      await this.tarjetaServicio.associateTarjetasToCiudadano([{ "id": this.tarjetaToAsociar.id, "master": false, "idDireccion": this.direccionSelec.id }], this.ciudadanoSelec.id).then(
        (result) => {
          MainComponent.getInstance().showInfo(
            'ATENCION',
            'Registro_almacenado',
            2000
          );
        }
      )
      this.closeWindow();
    }else {
      MainComponent.getInstance().showError(
        'ATENCION',
        'Selecciona_direccion_tarjeta',
        2000
      );
    }
  }

  closeWindow() {
    this.formAsociarTarjeta.close();
  }

  onClose() {
    if(this.componentRef) {
      this.componentRef.destroy();
      TarjetasCiudadanosComponent._this.form.expand();
    }
  }

  //GRID CIUDADANOS
  @ViewChild('gridDropDownCiudadanos') gridDropDownCiudadanos: jqxGridComponent;
  @ViewChild('cbCiudadanos') cbCiudadanos: jqxDropDownButtonComponent;

  protected ciudadanos: CiudadanoModel[] = [];

  protected ciudadanoSelec: CiudadanoModel;

  public columnsCiudadanos: any = [
    { text: 'Id', columntype: 'textbox', datafield: 'id', width: 80, hidden: true },
    { text: this.translate('Nombre'), columntype: 'textbox', datafield: 'nombre' },
    { text: this.translate('Apellidos'), columntype: 'textbox', datafield: 'apellidos' },
    { text: this.translate('Dni'), columntype: 'textbox', datafield: 'dni' },
  ];

  public sourceCiudadanos: any = {
    datatype: 'json',
    datafields: [
      { name: 'id', type: 'number', map: 'id' },
      { name: 'nombre', type: 'string', map: 'nombre' },
      { name: 'apellidos', type: 'string', map: 'apellidos' },
      { name: 'dni', type: 'string', map: 'nif' },
    ],
    localdata: [],
  };
  public adapterCiudadanos: any = new jqx.dataAdapter(this.sourceCiudadanos);

  async initGridCiudadanos(){
    this.showLoader = true;
    await this.ciudadanoService.getCiudadanos().then(
      (result) => {
        this.ciudadanos = result;
        this.showLoader = false;
      }
    )
    this.sourceCiudadanos.localdata = this.ciudadanos;

    this.gridDropDownCiudadanos.updatebounddata('data');

    if(this.tarjetaToAsociar.ciudadano !== null) {
      this.ciudadanoSelec = this.tarjetaToAsociar.ciudadano;
      this.gridDropDownCiudadanos.selectrow(this.gridDropDownCiudadanos.getrows().find(ciud => ciud.id === this.ciudadanoSelec.id));
    }
  }

  onOpenSelectCiudadanos(event) {
    this.verGridCiudadanos = true;
  }

  onRowClickCiudadanos(event) {
    this.ciudadanoSelec = this.ciudadanos.find(ciud => ciud.id === event.args.rowindex.id);

    this.setCBCiudadanosContent();

    if (this.ciudadanoSelec && this.ciudadanoSelec.direcciones) {
      this.ciudadanoSelec.direcciones.sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0));
    }else {
      this.ciudadanoSelec.direcciones = [];
    }

    this.initGridDirecciones();
  }

  setCBCiudadanosContent() {
    if (this.ciudadanoSelec) {
      let content = '<div style="position: relative; margin-left: 3px; margin-top: 5px;">';

      content += `${this.ciudadanoSelec.nombre} ${this.ciudadanoSelec.apellidos} (${this.ciudadanoSelec.nif})`;

      content += '</div>';

      if (this.cbCiudadanos) {
        this.cbCiudadanos.setContent(content);
      }
    }

    this.verGridCiudadanos = false;
  }

  onCloseSelectCiudadanos() {
    this.verGridCiudadanos = false;
  }

  //GRID DIRECCIONES
  @ViewChild('gridDropDownDirecciones') gridDropDownDirecciones: jqxGridComponent;
  @ViewChild('cbDirecciones') cbDirecciones: jqxDropDownButtonComponent;

  protected direccionSelec: DireccionModel;

  public columnsDirecciones: any = [
    { text: 'Id', columntype: 'textbox', datafield: 'id', width: 80, hidden: true },
    { text: this.translate('Num_tarjetas'), columntype: 'textbox', datafield: 'numeroTarjetas', width: 80 },
    { text: this.translate('Num_abonado'), columntype: 'textbox', datafield: 'numeroAbonado' },
    { text: this.translate('Numero_catastro'), columntype: 'textbox', datafield: 'numeroCatastro' },
    { text: this.translate('Direccion_catastro'), columntype: 'textbox', datafield: 'direccionCatastro' },
    { text: this.translate('Direccion'), columntype: 'textbox', datafield: 'direccion' },
    { text: this.translate('Numero'), columntype: 'textbox', datafield: 'numero' },
    { text: this.translate('Municipio'), columntype: 'textbox', datafield: 'municipio' },
    { text: this.translate('Provincia'), columntype: 'textbox', datafield: 'provincia' },
  ];

  public sourceDirecciones: any = {
    datatype: 'json',
    datafields: [
      { name: 'id', type: 'number', map: 'id' },
      { name: 'numeroTarjetas', type: 'string', map: 'numeroTarjetas' },
      { name: 'numeroAbonado', type: 'string', map: 'numeroAbonado' },
      { name: 'numeroCatastro', type: 'string', map: 'catastro>referenciaCatastral' },
      { name: 'direccionCatastro', type: 'string', map: 'catastro>direccionCatastral' },
      { name: 'direccion', type: 'string', map: 'direccion' },
      { name: 'numero', type: 'string', map: 'numero' },
      { name: 'municipio', type: 'string', map: 'municipio' },
      { name: 'provincia', type: 'string', map: 'provincia' },
    ],
    localdata: [],
  };
  public adapterDirecciones: any = new jqx.dataAdapter(this.sourceDirecciones);

  initGridDirecciones(){
    this.columnsDirecciones = [
      { text: 'Id', columntype: 'textbox', datafield: 'id', width: 80, hidden: true },
      { text: this.translate('Num_tarjetas'), columntype: 'textbox', datafield: 'numeroTarjetas', width: 80, cellclassname: this.cellClass },
      { text: this.translate('Num_abonado'), columntype: 'textbox', datafield: 'numeroAbonado' },
      { text: this.translate('Numero_catastro'), columntype: 'textbox', datafield: 'numeroCatastro', cellclassname: this.cellClass },
      { text: this.translate('Direccion_catastro'), columntype: 'textbox', datafield: 'direccionCatastro', cellclassname: this.cellClass },
      { text: this.translate('Direccion'), columntype: 'textbox', datafield: 'direccion', cellclassname: this.cellClass },
      { text: this.translate('Numero'), columntype: 'textbox', datafield: 'numero', cellclassname: this.cellClass },
      { text: this.translate('Municipio'), columntype: 'textbox', datafield: 'municipio', cellclassname: this.cellClass },
      { text: this.translate('Provincia'), columntype: 'textbox', datafield: 'provincia', cellclassname: this.cellClass },
    ];

    this.ciudadanoSelec.direcciones.forEach(dir => {
      try {
        if(dir && dir.catastro) {
          dir.catastro['direccionCatastral'] = dir?.catastro?.direccion != '' ? dir?.catastro?.direccion : '';
          dir.catastro['direccionCatastral'] +=  dir?.catastro?.numero != '' ? (dir?.catastro['direccionCatastral'] !== '' ? ', ' + dir.catastro?.numero : dir.catastro?.numero) : '';
        }
      } catch (error) {

      }
    });

    this.sourceDirecciones = {
      datatype: 'json',
      datafields: [
        { name: 'id', type: 'number', map: 'id' },
        { name: 'numeroTarjetas', type: 'string', map: 'numeroTarjetas' },
        { name: 'numeroAbonado', type: 'string', map: 'numeroAbonado' },
        { name: 'numeroCatastro', type: 'string', map: 'catastro>referenciaCatastral' },
        { name: 'direccionCatastro', type: 'string', map: 'catastro>direccionCatastral' },
        { name: 'direccion', type: 'string', map: 'direccion' },
        { name: 'numero', type: 'string', map: 'numero' },
        { name: 'municipio', type: 'string', map: 'municipio' },
        { name: 'provincia', type: 'string', map: 'provincia' },
      ],
      localdata: this.ciudadanoSelec.direcciones,
    }

    this.adapterDirecciones = new jqx.dataAdapter(this.sourceDirecciones);
    this.gridDropDownDirecciones.updatebounddata('data');

    setTimeout(() =>{
      if(this.tarjetaToAsociar.direccion !== null) {
        this.direccionSelec = this.tarjetaToAsociar.direccion;
        this.gridDropDownDirecciones.selectrow(this.gridDropDownDirecciones.getrows().find(dir => dir.id === this.direccionSelec.id));
      }else {
        this.ciudadanoSelec.direcciones.forEach(dir => {
          if (dir.direccionPrincipal) {
            this.direccionSelec = dir;
            this.gridDropDownDirecciones.selectrow(this.gridDropDownDirecciones.getrows().find(ciud => ciud.id === this.direccionSelec.id));
          }
        });
      }
    }, 0)

    this.verGridCiudadanos = false;
  }

  onOpenSelectDirecciones() {
    this.verGridDirecciones = true;
  }

  onRowClickDirecciones(event: any) {
    this.direccionSelec = this.ciudadanoSelec.direcciones.find(ciud => ciud.id === event.args.rowindex.id);
    this.setCBDireccionesContent();

    this.cbDirecciones.close();
  }

  setCBDireccionesContent() {
    if (this.direccionSelec) {
      let content = '<div style="position: relative; margin-left: 3px; margin-top: 5px;">';

      content += (this.direccionSelec.direccion ? this.direccionSelec.direccion : '') +
        (this.direccionSelec.numero ? ', ' + this.direccionSelec.numero : '') +
        (this.direccionSelec.municipio ? ', ' + this.direccionSelec.municipio : '') +
        (this.direccionSelec.provincia ? ', ' + this.direccionSelec.provincia : '');

      if(this.direccionSelec.catastro) {
        content +=
        '(' +
          (this.direccionSelec.catastro.referenciaCatastral ? this.direccionSelec.catastro.referenciaCatastral : '') +
          (this.direccionSelec.catastro['direccionCatastral'] ? ', ' + this.direccionSelec.catastro['direccionCatastral'] : '') +
        ')';
      }

      content += '</div>';

      if (this.cbDirecciones) {
        this.cbDirecciones.setContent(content);
      }
    }
  }

  onCloseSelectDirecciones() {
    this.verGridDirecciones = false;
  }
}
