<jqxLoader #loader [imagePosition]="'top'" [width]="100" [height]="60" [text]="translate('Procesando')">
</jqxLoader>
<div #filterContainer>
</div>

<jqxWindow #reproductor [theme]="environment.tema" [width]="420" [showCloseButton]="true" [showCollapseButton]="true"
    [zIndex]="90" [height]="95" [resizable]="false" [position]="getFormPos(reproductor)" (onClose)="onClose()">
    <div class="formHeader" style="overflow-x: hidden; height: 20px; border: 0px; margin: 0px; padding: 4px;">
        {{translate('Recorridos')}}
    </div>
    <div style="float: left; overflow: hidden;" class="form">
        <div style="float: left; overflow: hidden;">
            <div style="float: left;">
                <input type=image src="assets/images/reproductor/play.png" width="26" height="25"
                    title="Reproducir paso a paso" (click)="onPlay(true)">
                <input style="margin-left: 3px;" type=image src="assets/images/reproductor/pause.png" width="25"
                    height="25" title="Pausar la reproducción" (click)="onPause()">
                <input style="margin-left: 3px;" type=image src="assets/images/reproductor/stop.png" width="25"
                    height="25" title="Finalizar la reproducción" (click)="onStop()">
                <input style="margin-left: 3px;" type=image src="assets/images/reproductor/play2.png" width="25"
                    height="25" title="Reproducir completo" (click)="onPlay(false)">
            </div>
            <div style="float: left; padding-left: 5px; overflow: hidden;">
                <jqxSlider #speed [theme]="environment.tema" (onChange)='onChangeSpeed($event)'
                    [orientation]="'horizontal'" [width]='170' [height]='25' [min]='0' [max]='100' [value]="interval"
                    [step]='5' [mode]="'fixed'" [showTickLabels]="true" [tooltip]="false" [ticksFrequency]="5"
                    [showMinorTicks]="false">
                </jqxSlider>
            </div>
            <button style="float: right; margin-left: 10px; width: 35px; height: 25px;" (click)="onClickSensores()">
                <img style="left: right; height: 20px; width: 20px;" src="../assets/images/config.png" />
            </button>
        </div>
        <div style="float: left; margin-top: 4px; overflow: hidden;">
            <app-periodo-select #periodoSelect [noMes]="true" [noTrimestre]="true" [noSemestre]="true" [noAnno]="true">
            </app-periodo-select>
        </div>
    </div>
</jqxWindow>


<div #leyendaMoviles></div>