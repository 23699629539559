import {
  Input,
  Output,
  OnInit,
  ViewChild,
  Component,
  HostListener,
  EventEmitter,
  SimpleChanges,
  AfterViewInit, // Add this line
} from '@angular/core';

import { AppComponent } from 'src/app/app.component';
import { environment } from 'src/environments/environment';

import { jqxCheckBoxComponent } from 'jqwidgets-ng/jqxcheckbox';
import { jqxNumberInputComponent } from 'jqwidgets-ng/jqxnumberinput';
import { jqxDropDownListComponent } from 'jqwidgets-ng/jqxdropdownlist';

import { PeriodoSelectComponent } from '../periodo-select/periodo-select.component';
import { RolModel } from 'src/app/services/sso/models/rol.model';
import { SsoService } from 'src/app/services/sso/sso.service';
import { jqxInputComponent } from 'jqwidgets-ng/jqxinput';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, AfterViewInit { // Replace 'ngAfterViewInit' with 'AfterViewInit'
  adapterRoles: any;
  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.code === "Enter") {
      this.onFilter();
    }
  }

  static _this: any;

  // Elementos html
  @ViewChild('searchInput') searchInput: HTMLInputElement;
  @ViewChild('checkAlarms') checkAlarms: jqxCheckBoxComponent;
  @ViewChild('metrosInput') metrosInput: jqxNumberInputComponent;
  @ViewChild('periodoSelect') periodoSelect: PeriodoSelectComponent;
  @ViewChild('currencyInput') currencyInput: jqxNumberInputComponent;
  @ViewChild('checkMasXmetros') checkMasXmetros: jqxCheckBoxComponent;
  @ViewChild('cbVolumetricos') cbVolumetricos: jqxDropDownListComponent;
  @ViewChild('selectSubflotas') selectSubflotas: jqxDropDownListComponent;
  @ViewChild('listRoles') listRoles: jqxDropDownListComponent;
  @ViewChild('listApps') listApps: jqxDropDownListComponent;
  @ViewChild('searchInforme') searchInforme: jqxInputComponent;

  // Periodo select
  @Input() height: number = 25;
  @Input() noDia: boolean = false;
  @Input() noMes: boolean = false;
  @Input() noAnno: boolean = false;
  @Input() noLibre: boolean = false;
  @Input() noSemana: boolean = false;
  @Input() noSemestre: boolean = false;
  @Input() noTrimestre: boolean = false;
  @Input() periodoAnterior: boolean = false;

  // Mostrar/ocultar componentes
  @Input() filtro: boolean = false;
  @Input() periodo: boolean = false;
  @Input() buscador: boolean = false;
  @Input() exportar: boolean = false;
  @Input() imprimir: boolean = false;
  @Input() subFlota: boolean = false;
  @Input() seeZonas: boolean = false;
  @Input() informesSso: boolean = false;
  @Input() checkMetros: boolean = false;
  @Input() resetFiltro: boolean = false;
  @Input() volumetricos: boolean = false;
  @Input() seeNumTarjeta: boolean = false;
  @Input() seeCheckAlarms: boolean = false;
  @Input() listadoEstados: boolean = false;
  @Input() lopdCiudadanos: boolean = false;
  @Input() seeDropModoTarj: boolean = false;
  @Input() seeDropTipoTarj: boolean = false;
  @Input() seeFilterMetros: boolean = false;
  @Input() activeAppFilter: boolean = false;
  @Input() seeCheckTarjVirtual: boolean = false;

  // Lanza la accion cuando se interactua con un componente
  @Output() informeSearchChange = new EventEmitter<string>();
  @Output() eventFilter: EventEmitter<Boolean> = new EventEmitter;
  @Output() eventExportar: EventEmitter<Boolean> = new EventEmitter;
  @Output() eventImprimir: EventEmitter<Boolean> = new EventEmitter;
  @Output() eventBuscador: EventEmitter<Boolean> = new EventEmitter;
  @Output() eventResetFilter: EventEmitter<Boolean> = new EventEmitter;

  @Output() eventCheckRol: EventEmitter<any> = new EventEmitter;
  @Output() eventCheckApps: EventEmitter<any> = new EventEmitter;
  @Output() eventCheckEstados: EventEmitter<any> = new EventEmitter;
  @Output() eventCheckAlarms: EventEmitter<Boolean> = new EventEmitter;
  @Output() eventCheckMasMetros: EventEmitter<Boolean> = new EventEmitter;

  @Output() eventChangeZona: EventEmitter<string> = new EventEmitter;
  @Output() eventSelectSubFlota: EventEmitter<any> = new EventEmitter;
  @Output() eventChangeMetros: EventEmitter<string> = new EventEmitter
  @Output() eventChangedNumber: EventEmitter<Boolean> = new EventEmitter;
  @Output() eventChangeModoTarjeta: EventEmitter<string> = new EventEmitter;
  @Output() eventChangeTipoTarjeta: EventEmitter<string> = new EventEmitter;
  @Output() eventChangeNumeroTarjeta: EventEmitter<string> = new EventEmitter;



  public environment = environment;
  public theme = this.environment.tema;
  roles: RolModel[];

  adapterApps: any;

  // Para traducir los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  constructor(private ssoService: SsoService) {
    HeaderComponent._this = this;
  }


  ngAfterViewInit(): void {

  }

  ngOnInit(): void {
    if (this.lopdCiudadanos) {
      this.loadRoles();
    }
    if (this.activeAppFilter) {
      this.loadApps();
    }
  }

  // Detecta cambios en variables de entrada
  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      this[propName] = changes[propName].currentValue;
    }
  }

  /* METODOS PARA TODAS LAS VENTANAS */
  onExportar() {
    this.eventExportar.emit(true);
  }

  onPrint() {
    this.eventImprimir.emit(true);
  }

  onBuscar() {
    this.eventBuscador.emit(true);
  }

  onSearchInformes(event) {
    let searchInformes = this.searchInforme.val();
    this.informeSearchChange.emit(searchInformes);
  }

  onFilter() {
    this.eventFilter.emit(true);
  }

  onResetFilter() {
    this.eventResetFilter.emit(true);
  }
  /* FIN METODOS PARA TODAS LAS VENTANAS */

  /* METODOS PARA VENTANAS CONCRETAS */
  changeMasXmetros(event: any) {
    if (event.args.checked) {
      this.currencyInput.disabled(true);
      this.currencyInput.disabled(false);
    } else {
      this.currencyInput.disabled(true);
    }

    this.eventCheckMasMetros.emit(true);
  }

  changeMetros(event: any) {
    this.eventChangeMetros.emit(event.args.value);
  }

  onChangeCheckAlarms(event: any) {
    if (event.args.checked) {
      this.eventCheckAlarms.emit(true);
    } else {
      this.eventCheckAlarms.emit(false);
    }
  }

  onCheckEstados(event: any) {
    this.eventCheckEstados.emit(event);
  }

  onCheckRol(event: any) {
    let selectedItems = this.listRoles.getCheckedItems().map(x => ({
      value: x.value,
      label: x.label
    }));

    this.eventCheckRol.emit(selectedItems);
  }

  onCheckApps(event: any) {
    let selectedItems = this.listApps.getCheckedItems().map(x => ({
      value: x.value,
      label: x.label
    }));
    this.eventCheckApps.emit(selectedItems);
  }

  onChangeSubflotas(event: any) {
    this.eventSelectSubFlota.emit(event);
  }

  onChangeNumeroTarjeta(event) {
    this.eventChangeNumeroTarjeta.emit(event.args.value);
  }

  /* LISTADO MODO TARJETAS */
  @ViewChild('dropModoTarjeta') dropModoTarjeta: jqxDropDownListComponent;
  public sourceModoTarjetas: string[] =
  [
    AppComponent.translate('Todas'),
    AppComponent.translate('Virtuales'),
    AppComponent.translate('Fisicas')
  ];

  changeModoTarjeta(event: any) {
    this.eventChangeModoTarjeta.emit(event.args.item.value);
  }

  onLoadModoTarjeta() {
    setTimeout(() => {
      this.selectDefaultModoTarjeta();
    }, 50);
  }

  selectDefaultModoTarjeta() {
    if (this.dropModoTarjeta) {
      let items = this.dropModoTarjeta.getItems();
      if (items) {
        items.forEach((item, i) => {
          if (item['originalItem'] == AppComponent.translate('Todas')) {
            this.dropModoTarjeta.selectItem(item['originalItem']);
          }
        })
      }
    }
  }

  /* LISTADO TIPO TARJETAS */
  @ViewChild('dropTipoTarjeta') dropTipoTarjeta: jqxDropDownListComponent;
  public sourceTipoTarjetas: string[] =
  [
    AppComponent.translate('Todas'),
    AppComponent.translate('Virtuales'),
    AppComponent.translate('Fisicas')
  ];

  changeTipoTarjeta(event: any) {
    this.eventChangeTipoTarjeta.emit(event.args.item.value);
  }

  onLoadTipoTarjeta() {
    setTimeout(() => {
      this.selectDefaultTipoTarjeta();
    }, 50);
  }

  selectDefaultTipoTarjeta() {
    if (this.dropTipoTarjeta) {
      let items = this.dropTipoTarjeta.getItems();
      if (items) {
        items.forEach((item, i) => {
          if (item['originalItem'] == AppComponent.translate('Todas')) {
            this.dropTipoTarjeta.selectItem(item['originalItem']);
          }
        })
      }
    }
  }

  /* LISTADO ZONAS */
  @ViewChild('dropZonas') dropZonas: jqxDropDownListComponent;
  @Input() sourceZonas: any;

  changeZona(event: any) {
    this.eventChangeZona.emit(event.args.item.value)
  }

  async loadRoles() {
    this.roles = await this.ssoService.getRoles();
    if (this.roles) {
      let dataSource = {
        datatype: 'json',
        datafields: [
          { name: 'Id', type: 'string', map: 'Id' },
          { name: 'Nombre', type: 'string', map: 'Nombre' },
        ],
        localdata: this.roles,
      };

      dataSource.localdata.sort((a, b) => (a.Nombre < b.Nombre ? -1 : 1));
      this.adapterRoles = new jqx.dataAdapter(dataSource,
        {
          beforeLoadComplete: (records: any[]): void => {
            records.sort((a, b) => (a.Nombre < b.Nombre ? -1 : 1));
          },
        }
      );
    }
  }

  loadApps() {
    this.ssoService.getAppsBusiness(this.ssoService.getTicket().Empresa.Id).subscribe(apps => {
      if (apps) {
        let dataSource = {
          datatype: 'json',
          datafields: [
            { name: 'Id', type: 'string', map: 'Id' },
            { name: 'Nombre', type: 'string', map: 'Nombre' },
          ],
          localdata: apps,
        };

        dataSource.localdata.sort((a, b) => (a.Nombre < b.Nombre ? -1 : 1));
        this.adapterApps = new jqx.dataAdapter(dataSource,
          {
            beforeLoadComplete: (records: any[]): void => {
              records.sort((a, b) => (a.Nombre < b.Nombre ? -1 : 1));
            },
          }
        );
      }
    });
  }

  /* LISTADO ESTADOS */
  @ViewChild('listEstados') listEstados: jqxDropDownListComponent;
  @Input() adapterEstados: any;

  //una vez cargado del dropdown se hace check en los estados por defecto
  onLoadList() {
    setTimeout(() => {
      this.selectItems();
    }, 50);
  }

  //selecciona estados por defecto
  selectItems() {
    if (this.listEstados) {
      let items = this.listEstados.getItems();
      if (items) {
        items.forEach((item, i) => {
          if (item['originalItem'].Nombre == 'En proceso' || item['originalItem'].Nombre == 'Abierta') {
            this.listEstados.checkIndex(i)
          }
        })
      }
    }
  }

  selectionRolRenderer = (): string => {
    if (this.listRoles) {
      let selectedItems = this.listRoles.getCheckedItems();
      if (selectedItems !== undefined && selectedItems.length > 0) {
        let itemValue = selectedItems.map(x => x.label).join(', ');
        return `<div style="position: relative; margin-left: 3px; margin-top: 5px;">${itemValue}</div>`;
      } else {
        return `<div style="position: relative; margin-left: 5px; margin-top: 5px;">${AppComponent.translate("Filtro_por_rol ")}</div>`;
      }
    }
  }

  selectionAppRenderer = (): string => {
    if (this.listApps) {
      let selectedItems = this.listApps.getCheckedItems();
      if (selectedItems !== undefined && selectedItems.length > 0) {
        let itemValue = selectedItems.map(x => x.label).join(', ');
        return `<div style="position: relative; margin-left: 3px; margin-top: 5px;">${itemValue}</div>`;
      } else {
        return `<div style="position: relative; margin-left: 5px; margin-top: 5px;">${AppComponent.translate("Filtro_por_aplicacion")}</div>`;
      }
    }
  }
  /* FIN METODOS PARA VENTANAS CONCRETAS */
}
