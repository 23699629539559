import {
  Input,
  OnInit,
  Component,
  ViewChild,
  SimpleChanges,
}from '@angular/core';

import { AppComponent } from 'src/app/app.component';
import { environment } from 'src/environments/environment';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxDropDownListComponent } from 'jqwidgets-ng/jqxdropdownlist';
import { jqxDropDownButtonComponent } from 'jqwidgets-ng/jqxdropdownbutton';

import { ElementoModel } from 'src/app/services/elements/models/elem.model';
import { ElemInstalacionDetalleModel } from 'src/app/services/elements/models/instalacion/elem-instalacion-detalle.model';

import { ElementsEditComponent } from '../elements-edit.component';
import { ElemInstalacionNuevaInstalacion } from 'src/app/services/elements/models/instalacion/elem-instalacion-nueva-instalacion.model';
import { jqxCheckBoxComponent } from 'jqwidgets-ng/jqxcheckbox';

@Component({
  selector: 'app-elements-edit-instalaciones',
  templateUrl: './elements-edit-instalaciones.component.html',
  styleUrls: ['./elements-edit-instalaciones.component.css', '../elements-edit.component.css']
})
export class ElementsEditInstalacionesComponent implements OnInit {
  @Input() elemEdit: ElementoModel;
  @ViewChild('checkCerraduraGeneral') checkCerraduraGeneral: jqxCheckBoxComponent;
  @ViewChild('checkCerraduraEcolock') checkCerraduraEcolock: jqxCheckBoxComponent;

  public environment = environment;
  public langGrid = JqWidgets.getLocalization('es');

  public editTag: boolean = true;
  public editVolumetrico: boolean = true;
  public editEcoLock: boolean = true;
  public editIdentificaciones: boolean = true;

  public static _this: ElementsEditInstalacionesComponent;

  translate(text: string): string {
    return AppComponent.translate(text);
  }

  constructor() {
    ElementsEditInstalacionesComponent._this = this
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.dropEstadoInstalacionTag) {
        if (this.getDetalleInstalacion(1)) {
          let detalle = this.getDetalleInstalacion(1)
          switch (detalle.idEstado) {
            case 4:
              this.dropEstadoInstalacionTag.selectIndex(0);
              break;
            case 5:
              this.dropEstadoInstalacionTag.selectIndex(1);
              break;
            case 29:
              this.dropEstadoInstalacionTag.selectIndex(2);
              break;
            case 6:
              this.dropEstadoInstalacionTag.selectIndex(3);
            default:
              break;
          }
        }
      }

      if (this.dropEstadoInstalacionVolum) {
        if (this.getDetalleInstalacion(2)) {
          switch (this.getDetalleInstalacion(2).idEstado) {
            case 4:
              this.dropEstadoInstalacionVolum.selectIndex(0);
              break;
            case 5:
              this.dropEstadoInstalacionVolum.selectIndex(1);
              break;
            case 29:
              this.dropEstadoInstalacionVolum.selectIndex(2);
              break;
            case 6:
              this.dropEstadoInstalacionVolum.selectIndex(3);
            default:
              break;
          }
        }
      }

      if (this.dropEstadoInstalacionEcolock) {
        if (this.getDetalleInstalacion(3)) {
          switch (this.getDetalleInstalacion(3).idEstado) {
            case 4:
              this.dropEstadoInstalacionEcolock.selectIndex(0);
              break;
            case 5:
              this.dropEstadoInstalacionEcolock.selectIndex(1);
              break;
            case 29:
              this.dropEstadoInstalacionEcolock.selectIndex(2);
              break;
            case 6:
              this.dropEstadoInstalacionEcolock.selectIndex(3);
            default:
              break;
          }
        }
      }

      if (this.dropEstadoInstalacionIdentificaciones) {
        if (this.getDetalleInstalacion(4)) {
          switch (this.getDetalleInstalacion(4).idEstado) {
            case 4:
              this.dropEstadoInstalacionIdentificaciones.selectIndex(0);
              break;
            case 5:
              this.dropEstadoInstalacionIdentificaciones.selectIndex(1);
              break;
            case 29:
              this.dropEstadoInstalacionIdentificaciones.selectIndex(2);
              break;
            case 6:
              this.dropEstadoInstalacionIdentificaciones.selectIndex(3);
            default:
              break;
          }
        }
      }
    }, 500)
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'elemEdit': {
            this.elemEdit = changes[propName].currentValue;

            if(this.elemEdit && this.elemEdit.Instalacion && this.elemEdit.Instalacion.detalle) {
              let combDet = {...this.elemEdit.Instalacion.detalle.find(det => det.idTipo === 3)};
              combDet.id = 0;
              combDet.idTipo = 5;

              this.elemEdit.Instalacion.detalle.find(det => det.idTipo === 4)?.imagenes.forEach(img => {
                combDet.imagenes.push(img);
              });

              this.elemEdit.Instalacion.detalle.find(det => det.idTipo === 4)?.notificaciones.forEach(not => {
                combDet.notificaciones.push(not);
              });

              this.elemEdit.Instalacion.detalle.push(combDet);
            }

            this.initGridIncidenciasTag();
            this.initGridIncidenciasVolum();
            this.initGridIncidenciasEcolock();
            break;
          }
          default: {
            break;
          }
        }
      }
    }
  }

  checkRequire(event: any, idTipo: number) {
    /* Se instancia la nuevaInstalacion con el tipo que corresponde y la accion de instalacion(1) */
    let newInstalacion: ElemInstalacionNuevaInstalacion = new ElemInstalacionNuevaInstalacion();
    newInstalacion.idAccion = 1;
    newInstalacion.idTipo = idTipo;

    let detalleInstalacion: ElemInstalacionDetalleModel = new ElemInstalacionDetalleModel();
    detalleInstalacion.idAccion = 1;
    detalleInstalacion.idTipo = idTipo;

    switch(idTipo) {
      case 1:
        this.elemEdit.Instalacion.requiereInstalacionTag = event.args.checked;
        break;
      case 2:
        this.elemEdit.Instalacion.requiereInstalacionVolumetrico = event.args.checked;
        break;
      case 3:
        this.elemEdit.Instalacion.requiereInstalacionCerradura = event.args.checked;
        break;
      case 4:
        this.elemEdit.Instalacion.requiereInstalacionIdentificador = event.args.checked;

        if(!this.elemEdit.Instalacion.requiereInstalacionIdentificador && this.elemEdit.Instalacion.requiereInstalacionCerradura) {
          this.checkCerraduraGeneral.uncheck();
        }
        break;
      default:
    }

    if(this.elemEdit.Instalacion.instalaciones) {
      let index = this.elemEdit.Instalacion.instalaciones.findIndex(newInstal => newInstal.idTipo === idTipo);

      if(index !== -1) {
        this.elemEdit.Instalacion.instalaciones.splice(index, 1);
        this.elemEdit.Instalacion.detalle.splice(index, 1);
      } else {
        this.elemEdit.Instalacion.instalaciones.push(newInstalacion);
        this.elemEdit.Instalacion.detalle.push(detalleInstalacion);
      }
    }
  }

  selectEstadoDetalleInstalacion(event: any, idTipo: number) {
    this.getDetalleInstalacion(idTipo).idEstado = event.args.item.value;
  }

  getDetalleInstalacion(tipo: number): ElemInstalacionDetalleModel {
    let detalleInstalacion: ElemInstalacionDetalleModel;

    if(this.elemEdit && this.elemEdit.Instalacion && this.elemEdit.Instalacion.detalle) {
      detalleInstalacion = this.elemEdit.Instalacion.detalle.find(det => det.idTipo === tipo);
    }

    if(detalleInstalacion) {
      return detalleInstalacion;
    }else {
      return undefined;
    }
  }

  addImageDetalle(event: any, tipo: number) {
    this.getDetalleInstalacion(tipo).imagenes.push(event);
  }

  rmvImageDetalle(event: any, tipo: number) {
    this.getDetalleInstalacion(tipo).imagenes.splice(this.getDetalleInstalacion(tipo).imagenes.indexOf(event), 1);
    ElementsEditComponent._this.form.collapse();
    setTimeout(() => {
      ElementsEditComponent._this.form.expand();
    }, 250)
  }

  addImageNotificacion(event: any, selectNotif: any) {
    selectNotif.Imagenes.push(event);
  }

  rmvImageNotificacion(event: any, selectNotif: any) {
    selectNotif.Imagenes.splice(selectNotif.Imagenes.indexOf(event), 1);
    ElementsEditComponent._this.form.collapse();
    setTimeout(() => {
      ElementsEditComponent._this.form.expand();
    }, 300)
  }

  async initBtnColumn(
    row: any,
    column: any,
    value: string,
    htmlElement: HTMLElement,
    from: number
  ) {
    let rowdata: any;
    htmlElement.innerHTML = '';

    // Crea un contenedor para los botones
    const btnContainer = document.createElement('div');
    btnContainer.style.display = 'flex';
    btnContainer.style.justifyContent = 'space-around';
    btnContainer.style.gap = '2px';
    btnContainer.style.padding = '2px';

    const btnSeeImgs = document.createElement('div');
    btnSeeImgs.innerHTML = `
        <button class="button" style="height: 23px; cursor: pointer;" title="`+AppComponent.translate('Ver_imagenes')+`">
          <i class="fa-solid fa-eye"></i>
        </button>
      `;
    btnSeeImgs.id = `buttonEdit_jqxButton`;
    btnContainer.appendChild(btnSeeImgs);

    btnSeeImgs.addEventListener('click', async (event: any) => {
      switch(from) {
        case 1: // Tag
          if (isNaN(row)) {
            rowdata = row.bounddata;
          } else {
            rowdata = this.gridIncidenciasTag.getrowdata(row);
          }

          this.incdTagSelec = null;

          this.incdTagSelec = this.getDetalleInstalacion(1).notificaciones[this.getDetalleInstalacion(1).notificaciones.findIndex(not => not.Id === rowdata.id)];
          break;
        case 2: // Volumetrico
          if (isNaN(row)) {
            rowdata = row.bounddata;
          } else {
            rowdata = this.gridIncidenciasVolum.getrowdata(row);
          }

          this.incdVolumSelec = null;

          this.incdVolumSelec = this.getDetalleInstalacion(2).notificaciones[this.getDetalleInstalacion(2).notificaciones.findIndex(not => not.Id === rowdata.id)];
          break;
        case 3: // Ecolock
          if (isNaN(row)) {
            rowdata = row.bounddata;
          } else {
            rowdata = this.gridIncidenciasEcolock.getrowdata(row);
          }

          this.incdEcolockSelec = null;

          this.incdEcolockSelec = this.getDetalleInstalacion(3).notificaciones[this.getDetalleInstalacion(3).notificaciones.findIndex(not => not.Id === rowdata.id)];
          break;
        default:
          break;
      }
    });

    htmlElement.appendChild(btnContainer);
  }

  sourceEstadoInstalacion =
  [
    { label: AppComponent.translate('Pendiente_instalacion'), value: 4 }, //Pendiente
    { label: AppComponent.translate('Sin_lectura'), value: 5 }, //Sin lectura
    { label: AppComponent.translate('Instalado_correctamente'), value: 6 }, //Instalado
    { label: AppComponent.translate('Instalado_sin_cobertura'), value: 29 }, //Instalado sin cobertura
  ];

  /* APARTADO INSTALACION GENERAL */
  @ViewChild('dropEstadoInstalacionGeneral') dropEstadoInstalacionGeneral: jqxDropDownButtonComponent;

  sourceEstadoInstalacionGeneral =
  [
    { label: AppComponent.translate('Pendiente_instalacion'), value: 1 },
    { label: AppComponent.translate('En_progreso'), value: 2 },
    { label: AppComponent.translate('Instalado_correctamente'), value: 3 },
  ];

  /* APARTADO INSTALACION VOLUMETRICO */
  /* Estado instalacion volumetrico */
  @ViewChild('dropEstadoInstalacionVolum') dropEstadoInstalacionVolum: jqxDropDownListComponent;

  /* Grid incidencias instalacion volumetrico */
  @ViewChild('gridIncidenciasVolum') gridIncidenciasVolum: jqxGridComponent;
  columnsIncidenciasVolum;
  public sourceIncidenciasVolum;
  public dataAdapterIncidenciasVolum;

  initGridIncidenciasVolum() {
    this.columnsIncidenciasVolum =
    [
      { text: 'ID', datafield: 'id', hidden: true },
      { text: this.translate('Fecha'), datafield: 'fecha', width: 100 },
      { text: this.translate('Descripcion'), datafield: 'descripcion', width: 150 },
      {
        text: this.translate('Informacion_grafica'), datafield: 'informacionGrafica', width: 150,
        createwidget: (
          row: any,
          column: any,
          value: string,
          htmlElement: HTMLElement
        ): void => {
          this.initBtnColumn(row, column, value, htmlElement, 2);
        },
        initwidget: (
          row: any,
          column: any,
          value: string,
          htmlElement: HTMLElement
        ) => {
          this.initBtnColumn(row, column, value, htmlElement, 2);
        }
      },
    ];

    this.sourceIncidenciasVolum = {
      datatype: 'json',
      datafields: [
        { name: 'id', type: 'number', map: 'Id' },
        { name: 'fecha', type: 'date', map: 'Fecha'},
        { name: 'descripcion', type: 'string', map: 'Texto' },
        { name: 'informacionGrafica', map: 'Imagenes' },
      ],
      localdata: this.getDetalleInstalacion(2)?.notificaciones?.length > 0 ? this.getDetalleInstalacion(2).notificaciones : []
    };

    this.dataAdapterIncidenciasVolum = new jqx.dataAdapter(this.sourceIncidenciasVolum);
  }

  /* Imagenes incidencias instalacion volumetrico */
  public incdVolumSelec: any;

  /* FIN APARTADO INSTALACION VOLUMETRICO */

  /* APARTADO INSTALACION TAG */
  /* Estado instalacion tag */
  @ViewChild('dropEstadoInstalacionTag') dropEstadoInstalacionTag: jqxDropDownListComponent;

  /* Grid incidencias instalacion tag */
  @ViewChild('gridIncidenciasTag') gridIncidenciasTag: jqxGridComponent;

  columnsIncidenciasTag;
  public sourceIncidenciasTag;
  public dataAdapterIncidenciasTag;

  initGridIncidenciasTag() {
    this.columnsIncidenciasTag =
    [
      { text: 'ID', datafield: 'id', hidden: true },
      { text: this.translate('Fecha'), datafield: 'fecha', width: 100 },
      { text: this.translate('Descripcion'), datafield: 'descripcion', width: 150 },
      {
        text: this.translate('Informacion_grafica'), datafield: 'informacionGrafica', width: 150,
        createwidget: (
          row: any,
          column: any,
          value: string,
          htmlElement: HTMLElement
        ): void => {
          this.initBtnColumn(row, column, value, htmlElement, 1);
        },
        initwidget: (
          row: any,
          column: any,
          value: string,
          htmlElement: HTMLElement
        ) => {
          this.initBtnColumn(row, column, value, htmlElement, 1);
        }
      },
    ];

    this.sourceIncidenciasTag = {
      datatype: 'json',
      datafields: [
        { name: 'id', type: 'number', map: 'Id' },
        { name: 'fecha', type: 'date', map: 'Fecha'},
        { name: 'descripcion', type: 'string', map: 'Texto' },
        { name: 'informacionGrafica', map: 'Imagenes' },
      ],
      localdata: this.getDetalleInstalacion(1)?.notificaciones?.length > 0 ? this.getDetalleInstalacion(1).notificaciones : []
    };

    this.dataAdapterIncidenciasTag = new jqx.dataAdapter(this.sourceIncidenciasTag);
  }

  /* Imagenes incidencias instalacion tag */
  public incdTagSelec: any;

  /* FIN APARTADO INSTALACION TAG */

  /* APARTADO INSTALACION ECOLOCK */

  /* Identificacion ecolock */
  @ViewChild('dropIdentificacionEcolock') dropIdentificacionEcolock: jqxDropDownListComponent;

  /* Opciones dropdown identificacion ecolock */
  sourceIdentificacionEcolock =
  [
    { label: AppComponent.translate('Tarjeta_nfc'), value: 0 },
    { label: 'Bluetooth', value: 1 },
  ];

  /* Estado instalacion ecolock */
  @ViewChild('dropEstadoInstalacionEcolock') dropEstadoInstalacionEcolock: jqxDropDownListComponent;

  /* Estado instalacion identificacion */
  @ViewChild('dropEstadoInstalacionIdentificaciones') dropEstadoInstalacionIdentificaciones: jqxDropDownListComponent;

  /* Tipo dispositivo ecolock */
  @ViewChild('dropTipoDispositivoEcolock') dropTipoDispositivoEcolock: jqxDropDownListComponent;
  sourceTipoDispositivoEcolock = [];

  /* Grid incidencias instalacion ecolock */
  @ViewChild('gridIncidenciasEcolock') gridIncidenciasEcolock: jqxGridComponent;

  columnsIncidenciasEcolock;
  public sourceIncidenciasEcolock;
  public dataAdapterIncidenciasEcolock;

  initGridIncidenciasEcolock() {
    this.columnsIncidenciasEcolock =
    [
      { text: 'ID', datafield: 'id', hidden: true },
      { text: this.translate('Fecha'), datafield: 'fecha', width: 100 },
      { text: this.translate('Descripcion'), datafield: 'descripcion', width: 150 },
      {
        text: this.translate('Informacion_grafica'), datafield: 'informacionGrafica', width: 150,
        createwidget: (
          row: any,
          column: any,
          value: string,
          htmlElement: HTMLElement
        ): void => {
          this.initBtnColumn(row, column, value, htmlElement, 3);
        },
        initwidget: (
          row: any,
          column: any,
          value: string,
          htmlElement: HTMLElement
        ) => {
          this.initBtnColumn(row, column, value, htmlElement, 3);
        }
      },
    ];

    this.sourceIncidenciasEcolock = {
      datatype: 'json',
      datafields: [
        { name: 'id', type: 'number', map: 'Id' },
        { name: 'fecha', type: 'date', map: 'Fecha'},
        { name: 'descripcion', type: 'string', map: 'Texto' },
        { name: 'informacionGrafica', map: 'Imagenes' },
      ],
      localdata: this.getDetalleInstalacion(3)?.notificaciones?.length > 0 ? this.getDetalleInstalacion(3).notificaciones : []
    };
    this.dataAdapterIncidenciasEcolock = new jqx.dataAdapter(this.sourceIncidenciasEcolock);
  }

  /* Imagenes incidencias instalacion ecolock */
  public incdEcolockSelec: any;
  /* FIN APARTADO INSTALACION ECOLOCK */
}
