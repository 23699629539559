<div class="loader-container" *ngIf="showLoader">
  <div class="loader"></div>
</div>

<jqxWindow #formWindow [theme]="environment.tema" [position]="getFormPos(formWindow, 24)" [width]="1750"
  [height]="450" [zIndex]="900" [autoOpen]="true" [showCloseButton]="true" [showCollapseButton]="true"
  [resizable]="false" (onClose)="onClose()">
  <div class="formHeader" style="overflow-x: hidden;">
    {{ translate("Jobs") }}
  </div>
  <div class="form" style="float: left; overflow-x: hidden;">
    <div class="row" style="height: calc(100% - 30px); overflow-y: auto; overflow-x: hidden;">
      <span style="width: 100%;">
        <div style="height: 100%; display: flex;">
          <fieldset>
            <div class="row">
              <div class="rowC" style="justify-content: flex-start;">
                <div class="row">
                  <span style="width: 100%; clear: both;">
                    <div style="margin-top: 5px">
                      {{ translate('Descripcion') }}
                    </div>
                    <jqxInput [(ngModel)]="rango.nombre" [width]="'100%'">
                    </jqxInput>
                  </span>
                </div>

                <div class="row">
                  <span style="width: 15%; clear: both;">
                    <div style="margin-top: 5px">
                      {{ translate('Tarjeta_inicial') }}
                    </div>
                    <jqxInput [(ngModel)]="rango.idTarjetaInicial" [width]="'100%'">
                    </jqxInput>
                  </span>

                  <span style="width: 70%; clear: both;">
                    <div style="display: flex;margin-top: 5px;justify-content: center;">
                      {{ translate('Cantidad') }} {{ translate('Rango') }}
                    </div>
                    <jqxSlider #sliderRango [min]="0" [max]="1" [tooltip]="true" [tickLabelFormatFunction]='tickLabelFormatFunction'
                      [showMinorTicks]="true" [minorTicksFrequency]="1" [height]="60" [showTickLabels]="true"
                      [width]="'100%'" [mode]="'fixed'" (onChange)="changeSliderRango($event)">
                    </jqxSlider>
                  </span>

                  <span style="width: 15%; clear: both;">
                    <div style="margin-top: 5px">
                      {{ translate('Tarjeta_final') }}
                    </div>
                    <jqxInput [value]="rango.idTarjetaFinal" [width]="'100%'" [disabled]="true">
                    </jqxInput>
                  </span>
                </div><div class="row">
                  <span style="width: 100%; clear: both;">
                    <div style="margin-top: 5px">
                      {{ translate('Listado') }}
                    </div>

                    <div class="rowC">
                      <span>
                        <jqxRadioButton (onChange)="setLista($event, 'blanca')"
                        [width]="250" [height]="25" [checked]="rango.listaBlanca">
                        <span>{{ translate('Blanca') }}</span>
                        </jqxRadioButton>
                      </span>

                      <span>
                        <jqxRadioButton (onChange)="setLista($event, 'master')"
                        [width]="250" [height]="25" [checked]="rango.listaMaster">
                        <span>{{ translate('Master') }}</span>
                        </jqxRadioButton>
                      </span>

                      <span>
                        <jqxRadioButton (onChange)="setLista($event, 'negra')"
                        [width]="250" [height]="25" [checked]="rango.listaNegra">
                        <span>{{ translate('Negra') }}</span>
                        </jqxRadioButton>
                      </span>
                    </div>
                  </span>
                </div>
              </div>

              <div class="row">
                <span style="width: 100%; clear: both;">
                  <div style="margin-top: 5px">
                    {{ translate('Zonas') }}
                  </div>

                  <div class="row" style="height: 340px;">
                    <span>
                      <jqxGrid #gridZonas [theme]="environment.tema" [width]="'100%'" [height]="'100%'" [filterrowheight]='30'
                      [source]="adapterZona" [columnsresize]="false" [localization]="langGrid" [filterable]="true" [showfilterrow]="true"
                      [columns]="columnsZonas" [showrowlines]="false" [showcolumnlines]="true" [pageable]="false" [sortable]="true"
                      [altrows]="true" [showstatusbar]="false" [statusbarheight]="0" [enablebrowserselection]="true" [showaggregates]="false"
                      [rowsheight]="15" [columnsheight]="20" [enabletooltips]="false" [editable]="false" [groupable]="false" [selectionmode]="'singlerow'">
                      </jqxGrid>
                    </span>

                    <div class="rowC">
                      <jqxButton class="button" style="cursor: pointer;" [width]="25" [height]="25" [imgHeight]="15"
                        [imgWidth]="15" [imgSrc]='"/assets/images/derecha.png"' [imgPosition]="'center'"
                        (onClick)="associateZonaTarjeta()">
                      </jqxButton>
                      <jqxButton class="button" style="cursor: pointer;" [width]="25" [height]="25" [imgHeight]="15"
                        [imgWidth]="15" [imgSrc]='"/assets/images/izquierda.png"' [imgPosition]="'center'"
                        (onClick)="disassociateeZonaTarjeta()">
                      </jqxButton>
                    </div>

                    <span>
                      <jqxGrid #gridZonasToAsign [theme]="environment.tema" [width]="'100%'" [height]="'100%'" [filterrowheight]='30'
                      [source]="adapterZonaToAsign" [columnsresize]="false" [localization]="langGrid" [filterable]="true" [showfilterrow]="true"
                      [columns]="columnsZonas" [showrowlines]="false" [showcolumnlines]="true" [pageable]="false" [sortable]="true"
                      [altrows]="true" [showstatusbar]="false" [statusbarheight]="0" [enablebrowserselection]="true" [showaggregates]="false"
                      [rowsheight]="15" [columnsheight]="20" [enabletooltips]="false" [editable]="false" [groupable]="false" [selectionmode]="'singlerow'">
                      </jqxGrid>
                    </span>
                  </div>
                </span>
              </div>
            </div>
          </fieldset>
        </div>
      </span>
    </div>

    <div style="position: absolute; bottom: 5px; left: 0px; margin-top: 5px;
      clear: both;">
      <jqxButton style="float: left; margin-left: 5px" [width]="100" [height]="26"
        [textImageRelation]="'imageBeforeText'" [textPosition]="'left'" [imgSrc]="'/assets/images/save.png'"
        (onClick)="onGuardar()" [value]="translate('Guardar')">
      </jqxButton>

      <jqxButton style="float: left; margin-left: 5px" [width]="100" [height]="26"
        [textImageRelation]="'imageBeforeText'" [textPosition]="'left'"
        (onClick)="closeWindow()" [value]="translate('CANCELAR')">
      </jqxButton>
    </div>
  </div>
</jqxWindow>
