import { Component, OnInit, ViewChild } from '@angular/core';
import { CustomForms } from '../../forms/custom-forms';
import { environment } from 'src/environments/environment';
import { AppComponent } from 'src/app/app.component';
import { DireccionModel } from 'src/app/services/ciudadanos/models/direccion.model';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';
import { MainComponent } from '../../main/main.component';
import { CiudadanosEditComponent } from '../ciudadanos-edit/ciudadanos-edit.component';
import { DireccionService } from 'src/app/services/direccion/direccion.service';
import { DireccionListadoComponent } from '../direccion/direccion-listado/direccion-listado.component';
import { CatastroService } from 'src/app/services/direccion/catastro.service';
import { SelectorCatastroComponent } from '../direccion/selector-catastro/selector-catastro.component';
import { ZonesService } from 'src/app/services/zones/zones.service';
import { MapComponent, MapLatLng } from 'movisat-maps';
import { JqWidgets } from 'src/app/utils/jqWidgets';
import { TarjetaModel } from 'src/app/services/ciudadanos/models/tarjeta.model';
import { CiudadanoModel } from 'src/app/services/ciudadanos/models/ciudadano.model';
import { Utils } from 'src/app/utils/utils';

@Component({
  selector: 'app-ciudadanos-direccion-edit',
  templateUrl: './ciudadanos-direccion-edit.component.html',
  styleUrls: ['./ciudadanos-direccion-edit.component.css']
})
export class CiudadanosDireccionEditComponent extends CustomForms implements OnInit {
  // Componentes del HTML
  @ViewChild('windowForm') windowForm: jqxWindowComponent;
  @ViewChild('mapSearch') mapSearch: any;
  @ViewChild('selectorCatastro') selectorCatastro: SelectorCatastroComponent;
  inputMapSearch: HTMLInputElement;

  // Funcionalidad
  private componentRef = null;
  public environment = environment;
  public langGrid = JqWidgets.getLocalization('es');
  public map: MapComponent;

  // Direccion editando
  direccion: DireccionModel = new DireccionModel();

  protected ciudadano: CiudadanoModel;
  protected tarjetas: TarjetaModel[] = [];

  // Traducción de los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  closeWindow(){
    this.windowForm.close();
  }

  public onClose() {
    if (this.subscriptionMapClick) {
      this.subscriptionMapClick.unsubscribe();
      this.subscriptionMapClick = null;
      this.setPunteroPosicionar(false);
    }
    if (this.componentRef) {
      CiudadanosEditComponent._this.windowCiudadanosForm.expand();
      this.componentRef.destroy();
    }
  }

  constructor(
    private direccionService: DireccionService,
    private catastroService: CatastroService,
    private zonesService: ZonesService,
  ) {
    super();
  }

  public init(componentRef: any, direccion: DireccionModel, tarjetas: TarjetaModel[], ciudadano: CiudadanoModel) {
    this.componentRef = componentRef;
    this.direccion = direccion;
    this.tarjetas = tarjetas;
    this.ciudadano = ciudadano;

    this.initGridTarjeta();
  }

  ngOnInit(): void {
    // Instancia el mapa
    this.map = MainComponent.getInstance().getMap();
  }

  ngAfterViewInit() {
    this.addCustomForm(this.windowForm);

    this.windowForm.position(this.getFormPos(this.windowForm, 24))
    this.windowForm.setTitle(this.translate('Ficha_direccion') + ': ' +
    this.ciudadano.nombre + ' ' + this.ciudadano.apellidos);

    setTimeout(()=> {
      this.inputMapSearch = this.mapSearch.nativeElement.children[0].children[0].children[0].children[0];
    }, 500);
  }

  onExpand(event: any) {
    if (this.subscriptionMapClick) {
      this.subscriptionMapClick.unsubscribe();
      this.subscriptionMapClick = null;
      this.setPunteroPosicionar(false);
    }
  }

  onMapSearch(event: any) {
    this.direccion.direccion = event.place.Street;
    this.direccion.codigoPostal = event.place.PostalCode;
    this.direccion.poblacion = event.place.District;
    this.direccion.municipio = event.place.Locality;
    this.direccion.provincia = event.place.AdministrativeArea2;
    this.direccion.comunidadAutonoma = event.place.AdministrativeArea1;
    this.direccion.numero = event.place.StreetNumber;

    this.inputMapSearch.value = '';
  }

  setPunteroPosicionar(posicionando: boolean){
    let mp = <HTMLElement>document.getElementsByClassName('gm-style')[0].children[0];
    if(posicionando) {
      mp.classList.add('setPosition');
    }else {
      mp.classList.remove('setPosition');
    }
  }

  private subscriptionMapClick: any = null;
  onPosicionar() {
    this.windowForm.collapse();
    // Cambio el puntero del ratón sobre el mapa
    this.setPunteroPosicionar(true);
    if (!this.subscriptionMapClick) {
      this.subscriptionMapClick = this.subscribeOnMapClick();
    }
  }

  // Cada vez que se pincha sobre la cartogafía
  subscribeOnMapClick(): any {
    return this.map.subscribeOnMapClick(
      this,
      async (_this: any, position: MapLatLng) => {
        let direccion = await this.zonesService.getDireccion(position.lat, position.lng);
        this.direccion.direccion = direccion.Street;
        this.direccion.numero = direccion.StreetNumber;
        this.direccion.codigoPostal = direccion.PostalCode;
        this.direccion.poblacion = direccion.District;
        this.direccion.municipio = direccion.Locality;
        this.direccion.provincia = direccion.AdministrativeArea2;
        this.direccion.comunidadAutonoma = direccion.AdministrativeArea1;
        this.subscriptionMapClick.unsubscribe();
        this.subscriptionMapClick = null;
        this.setPunteroPosicionar(false);
        this.windowForm.expand();
      }
    );
  }

  async saveCatastro(){
    this.selectorCatastro.catastro.referenciaCatastral = this.selectorCatastro.referenciaCatastral.host[0].value;
    await this.catastroService.saveCatastro(this.selectorCatastro.catastro);
  }

  async onGuardar(): Promise<void> {
    this.saveCatastro();

    let direccion = await this.direccionService.saveDireccion(this.direccion);

    if(direccion != null) {
      DireccionListadoComponent._this.savedDirection(direccion);

      MainComponent.getInstance().showInfo(
        'ATENCION',
        'Registro_almacenado',
        2000
      );

      this.windowForm.close();
    }else {
      MainComponent.getInstance().showError(
        'ATENCION',
        'Fallo_almacenar_info',
        2000
      );
    }
  }

  updatefilterconditions = (type: string, defaultconditions: any): string[] => {
    return Utils.updatefilterconditions(type, defaultconditions);
  };

  public filter(cellValue?: any, rowData?: any, dataField?: string, filterGroup?: any, defaultFilterResult?: boolean): any {
    let filterColumns = [
      'nsMovisat',
      'descripcion'
    ]

    return Utils.filterRow(cellValue, dataField, filterGroup, defaultFilterResult, filterColumns);
  }

  public columnsTarjetas;
  public sourceTarjetas;
  public dataAdapterTarjetas;

  initGridTarjeta() {
    this.columnsTarjetas = [
      { text: this.translate('Modo'), columntype: 'textbox', datafield: 'tipo', width: 80, cellsrenderer: this.renderTipo },
      { text: this.translate('Ns_movisat'), columntype: 'textbox', datafield: 'nsMovisat', editable: false, width: 'auto' },
      { text: this.translate('Descripcion'), columntype: 'textbox', datafield: 'descripcion', editable: false, width: 'auto' },
      { text: this.translate('Tipo'), columntype: 'textbox', datafield: 'master', cellsrenderer: this.renderMasterColumn, width: 'auto' }
    ];

    this.sourceTarjetas = {
      datatype: 'json',
      datafields: [
        { name: 'tipo', type: 'string', map: 'virtual' },
        { name: 'nsMovisat', type: 'string', map: 'nsMovisat' },
        { name: 'descripcion', type: 'string', map: 'descripcion' },
        { name: 'master', type: 'string', map: 'master' }
      ],
      localdata: this.tarjetas
    };

    this.dataAdapterTarjetas = new jqx.dataAdapter(this.sourceTarjetas);
  }

  renderTipo(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (value) {
      return '<div class="jqx-grid-cell-middle-align" style="margin-top: 8px; cursor: pointer">V</div>';
    } else {
      return '<div class="jqx-grid-cell-middle-align" style="margin-top: 8px; cursor: pointer">F</div>';
    }
  }

  renderMasterColumn = (row: number, columnfield: string, value: string | number, defaulthtml: string, columnproperties: any, rowdata: any): string => {
    if (value) {
      return '<div class="jqx-grid-cell-left-align" style="margin-top: 8px;">'+this.translate('Master')+'</div>';
    }
    else{
      return '<div class="jqx-grid-cell-left-align" style="margin-top: 8px;">'+this.translate('Blanca')+'</div>';
    }
  };
}
