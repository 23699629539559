<div class="loader-container" *ngIf="showLoader">
  <div class="loader" style="margin-top: 35%;"></div>
</div>
<div #ciudadanoContainer id="ciudadanoGrid" style="padding-bottom: 2px; overflow: hidden; height: 100%; width: 100%;">
  <jqxGrid #gridCiudadanos [theme]="environment.tema" [height]="'100%'" [width]="'100%'" [showtoolbar]="true"
    [appCellClick] [autoshowloadelement]='false' [columnsresize]="true" [localization]="langGrid" [filterable]="true"
    [enablebrowserselection]="true" [showfilterrow]="false" [rendertoolbar]="createToolBar" [columngroups]="columnsGroupCiudadanos"
    [columns]="columnsCiudadanos" [showrowlines]="false" [showcolumnlines]="true" [pageable]="false" [sortable]="true"
    [altrows]="true" [showstatusbar]="true" [statusbarheight]="0" [showaggregates]="false" [rowsheight]="25"
    [source]="adapterCiudadanos" [columnsheight]="20" [editable]="false" [groupable]="true" [selectionmode]="'checkbox'"
    [toolbarheight]="30" [columnmenuopening]="columnmenuopening" [filter]="filter"
    [updatefilterconditions]="updatefilterconditions" (onGroupschanged)="resizeColumns()"
    (onRowdoubleclick)="rowDoubleClick($event)" (onFilter)="resizeColumns()">
  </jqxGrid>
</div>

<div #formCiudadano></div>
<div #tarjetasCiudadano></div>
