import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SsoService } from 'src/app/services/sso/sso.service';
import { ElemInstalacionDetalleModel } from '../../models/instalacion/elem-instalacion-detalle.model';

@Injectable({
  providedIn: 'root'
})
export class DetalleService {
  constructor(
    private http: HttpClient,
    private ssoService: SsoService) { }

  async saveDetalleInstalacion(detalles: ElemInstalacionDetalleModel[], idInstalacion: number) {
    let response: ElemInstalacionDetalleModel;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    try {
      detalles.forEach(async detalle => {
        detalle.idInstalacion = idInstalacion;

        try {
          detalle = await this.http.post<ElemInstalacionDetalleModel>(this.ssoService.getTicket().UrlApi + '/api/instalacion/detalle',
            JSON.stringify(detalle), httpOptions).toPromise();
        } catch (error) {

        }
      });

      return detalles;
    } catch (error) {
      return null;
    }
  }
}
