import {
  AfterViewInit, Component, OnDestroy, OnInit,
  ViewChild, ViewContainerRef
} from '@angular/core';

import { AppComponent } from 'src/app/app.component';
import { MainComponent } from '../main/main.component';
import { NumberUtils } from 'src/app/utils/number-utils';
import { environment } from 'src/environments/environment';

import { MapComponent, MapLatLng, MapMarker } from 'movisat-maps';
import { MapClusterV2 } from 'movisat-maps/lib/movisat/map-cluster-v2';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';

import { NzModalService } from 'ng-zorro-antd/modal';
import { PuService } from 'src/app/services/pu/pu.service';
import { BdtService } from 'src/app/services/bdt/bdt.service';
import { SsoService } from 'src/app/services/sso/sso.service';
import { ConfigService } from 'src/app/services/config/config.service';
import { ElementsService } from 'src/app/services/elements/elements.service';

import { PuModel } from 'src/app/services/pu/models/pu.model';
import { ElementoModel } from 'src/app/services/elements/models/elem.model';

import { PuEditComponent } from './pu-edit/pu-edit.component';
import { Utils } from 'src/app/utils/utils';

@Component({
  selector: 'app-pu',
  templateUrl: './pu.component.html',
  styleUrls: ['./pu.component.css']
})
export class PuComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('puContainer', { read: ViewContainerRef }) puContainer;
  @ViewChild('gridPU') gridPU: jqxGridComponent;

  private static instance: PuComponent;
  public environment = environment;
  private puSelec: PuModel = null;
  private searchText = '';
  private timerSearch = null;
  private initialFilter: any = null;
  public puListTotal: PuModel[] = [];
  public puList: PuModel[] = [];
  private map: MapComponent;
  private orderBy = 'elemento,asc';
  private equipModelFilter: any[] = [];
  private oldFilter: any = null;
  private equipModelFilterIA: any[] = [];
  public canEdit = true;
  private toolbarOk = false;
  private readonly MARKERS_ZOOM = 18; // Nivel de zoom al que se ven los PU
  public puCluster: MapClusterV2;
  private canMove = false;

  // Subscripciones
  private subscriptionOnAddPU: any = null;
  private subscriptionOnNewPU: any = null;
  private subscriptionOnModifyPU: any = null;
  private subscriptionOnDeletePU: any = null;
  private subscriptionOnChangeFilterElements: any = null;
  private subscriptionOnEndLoadPU: any = null;
  private subscriptionOnNewElements: any = null;
  private subscriptionOnModifyElements: any = null;
  private subscriptionOnDeleteElements: any = null;

  // Varibles para el datagrid
  public source: any = [];
  public dataAdapter = new jqx.dataAdapter(this.source);

  renderClasesColumn = (row: number, columnfield: string, value: any, defaulthtml: string, columnproperties: any, rowdata: any): string => {
    if (value) {
      try {
        let textToShow = '';
        value.forEach(element => {
          textToShow += textToShow == '' ? element.Nombre : ', ' + element.Nombre;
        });

        return `<div style="margin-left: 4px; margin-top: 5px; text-align: left;" onmouseover="this.style.backgroundColor='gray'; this.style.color='white'; this.style.position='fixed';" onmouseout="this.style.backgroundColor=''; this.style.color=''; this.style.position='';">${textToShow}</div>`;
      } catch (error) {
      }
    }
  }

  public columns: any = [
    {
      text: 'Id', columntype: 'textbox', filtertype: 'textbox', datafield: 'id', width: 1, hidden: true
    },
    {
      text: 'EqId', columntype: 'textbox', filtertype: 'textbox', datafield: 'eqId', width: 1, hidden: true
    },
    {
      text: AppComponent.translate('Descripcion'), columntype: 'textbox', menu: false, datafield: 'descripcion', width: 250,
      aggregates: [{
        'Total': function (aggregatedValue, currentValue: number) {
          return currentValue ? aggregatedValue + 1 : aggregatedValue;
        }
      }],
      aggregatesrenderer: function (aggregates) {
        let renderstring = '';
        if (aggregates["Total"] !== undefined) {
          renderstring = '<div style="text-align: center;">' + aggregates["Total"] + '</div>';
        }
        return renderstring;
      }
    },
    {
      text: AppComponent.translate('Tipo'), columntype: 'textbox', filtertype: 'textbox', datafield: 'elemento', width: 150
    },
    {
      text: AppComponent.translate('Residuo'), columntype: 'textbox', filtertype: 'textbox', datafield: 'residuo', width: 150,
      hidden: true
    },
    {
      text: AppComponent.translate('Clase'), columntype: 'textbox', filtertype: 'textbox', datafield: 'clase', width: 150,
      cellsrenderer: this.renderClasesColumn,
      hidden: true
    },
    {
      text: AppComponent.translate('Elemento'), columntype: 'textbox', menu: false, datafield: 'elemNombre', width: 250,
      aggregates: [{
        'TotalSinElem': function (aggregatedValue, currentValue: number) {
          return !currentValue ? aggregatedValue + 1 : aggregatedValue;
        }
      }],
      aggregatesrenderer: function (aggregates) {
        let renderstring = '';
        if (aggregates["TotalSinElem"] !== undefined) {
          renderstring = '<div style="text-align: center; color: red;">' + aggregates["TotalSinElem"] + ' ' + AppComponent.translate('Sin_elemento') + '</div>';
        }
        return renderstring;
      }
    },
    {
      text: AppComponent.translate('Marca'), columntype: 'textbox', filtertype: 'textbox', datafield: 'marca', width: 120,
      hidden: true
    },
    {
      text: AppComponent.translate('Modelo'), columntype: 'textbox', filtertype: 'textbox', datafield: 'modelo', width: 150,
      hidden: true
    },
    {
      text: AppComponent.translate('Capacidad'), columntype: 'textbox', filtertype: 'textbox', datafield: 'capacidad', width: 80,
      align: 'center', cellsalign: 'right', hidden: true, cellsrenderer: this.numberrenderer
    },
    { text: 'Selec', columntype: 'textbox', filtertype: 'textbox', datafield: 'selec', hidden: true, width: 1 }
  ];

  // Pongo por defecto los textos en los controles del grid en español
  public langGrid = JqWidgets.getLocalization('es');

  constructor(
    private ssoService: SsoService,
    private configService: ConfigService,
    private elementsService: ElementsService,
    private puService: PuService,
    private modal: NzModalService
  ) {
    PuComponent.instance = this;
  }

  public static getInstance(): PuComponent {
    return PuComponent.instance;
  }

  ngOnInit(): void {
    this.canEdit = true; // TODO: por hacer...
    // Cargo el idioma para los componentes jqwidgets
    this.langGrid = JqWidgets.getLocalization(this.ssoService.getTicket().Usuario.Idioma.Codigo.substring(0, 2));
    // Me subscribo a eventos
    this.subscribeOnAddPU();
    this.subscribeOnNewPU();
    this.subscribeOnEndLoadPU();
    this.subscribeModifyPU();
    this.subscribeDeletePU();
    this.subscribeOnNewElements();
    this.subscribeModifyElements();
    this.subscribeDeleteElements();
    this.subscribeChangeFilterElements();
    // Recupero el filtro almacenado si lo hay
    this.getInitFilter();
  }

  // Inicializo el toolbar y preparo el grid
  async ngAfterViewInit(): Promise<void> {
    // Espera a que el mapa esté listo para empezar a descargar los PU
    const timer1 = setInterval(async () => {
      this.map = MainComponent.getInstance().getMap();
      if (this.map) {
        clearInterval(timer1);
        // Me subscribo a eventos del mapa
        this.map.subscribeOnMarkerClick(this, this.onMarkerClick);
        this.map.subscribeOnMarkerDragEnd(this, this.onMarkerDragEnd);
        this.map.subscribeOnZoomChange(this, (_this: any, zoom: number) => {
          this.puList.forEach(pu => {
            if (pu.Marker) {
              pu.Marker.setVisible(zoom >= this.MARKERS_ZOOM && MainComponent.getInstance().formPuVisible);
            }
          });
        });
        // Creo el cluster para los elementos
        this.puCluster = this.map.addClusterV2(this.MARKERS_ZOOM);
        // Espero a que se descarguen los elementos poque necesito
        // asociar los elementos a los PU
        MainComponent.onLoadElements(async (elementos: ElementoModel[]) => {
          // Este evento se produce tb si se cambia el filtro del catálogo de elementos
          // por eso lo cojo sólo la primera vez
          if (this.puListTotal && this.puListTotal.length < 1) {
            // Recupero el filtro de modelos de elementos
            await this.elementsService.getFilterModel();
            // Recupero los PU
            await this.puService.getPuntosUbicacion();
            if (!this.elementsService.elemGenericos) {
              this.showHideColums();
            }
          }
        });
      }
    }, 1000);
  }

  // Este método es llamado por el creador del componente
  public init(componentRef: any) {
  }

  // Para traducir los textos del template
  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  numberrenderer(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (value) {
      return (
        '<div style="margin-right: 4px; margin-top: 5px; text-align: right;">' +
        NumberUtils.format(value, 0) +
        '</div>'
      );
    } else if (value === 0) {
      return (
        '<div style="margin-right: 4px; margin-top: 5px; text-align: right;">' +
        NumberUtils.format(value, 0) +
        '</div>'
      );
    }
  }

  showHideColums() {
    this.gridPU.showcolumn('residuo');
    this.gridPU.showcolumn('marca');
    this.gridPU.showcolumn('clase');
    this.gridPU.showcolumn('modelo');
    this.gridPU.showcolumn('capacidad');
  }

  // Cuando se pincha sobre un PU
  onMarkerClick(_this: any, marker: MapMarker) {
    // Compruebo que se trata de un PU
    if (marker.dataModel.hasOwnProperty('Equipamiento') && !marker.dataModel.hasOwnProperty('IdPU')) {
      // Busco el PU en el grid y lo selecciono
      const rows = _this.gridPU.getrows();
      if (rows) {
        rows.forEach((row, i) => {
          if (row.id === marker.dataModel.Id) {
            _this.gridPU.ensurerowvisible(i);
            const event = {
              args: {
                rowindex: row.dataindex ? row.dataindex : row.boundindex,
                fromCarto: true
              }
            }
            _this.onRowClick(event);
          }
        });
      }
    } else {
      if (marker.dataModel.hasOwnProperty('numElements')) { // Un cluster
        _this.map.setCenter(marker.position);
        _this.map.setZoom(_this.map.zoom < _this.MARKERS_ZOOM - 2 ? _this.map.zoom + 2 : _this.MARKERS_ZOOM);
      }
    }
  }

  // Cuando se arrastra un PU
  onMarkerDragEnd(_this: any, marker: MapMarker) {
    // Compruebo que se trata de un PU
    if (marker.dataModel.hasOwnProperty('Equipamiento') && !marker.dataModel.hasOwnProperty('IdPU')) {
      // Sólo me interesan los PU que ya existen, los nuevos no
      if (marker.dataModel.Id > 0) {
        _this.modal.confirm({
          nzTitle: '<i>' + AppComponent.translate('ATENCION') + '</i>',
          nzContent: AppComponent.translate('Quiere_mover_PU') + ': ' + marker.dataModel.Nombre + ' ?',
          nzCentered: true,
          nzCancelText: AppComponent.translate('CANCELAR'),
          nzOkText: AppComponent.translate('SI'),
          nzOnOk: async () => {
            let i = 0;
            for (; i < _this.puList.length; i++) {
              if (_this.puList[i].Id === marker.dataModel.Id) {
                _this.puList[i].Lat = marker.position.lat;
                _this.puList[i].Lng = marker.position.lng;
                break;
              }
            }
            if (await _this.puService.savePU(_this.puList[i])) {
              MainComponent.getInstance().showSuccess('ATENCION', 'Registro_almacenado', 2000);
            } else {
              MainComponent.getInstance().showError('ATENCION', 'Fallo_almacenar_info', 2000);
            }
          },
          nzOnCancel: async () => {
            for (let i = 0; i < _this.puList.length; i++) {
              if (_this.puList[i].Id === marker.dataModel.Id) {
                marker.setPosition(new MapLatLng(_this.puList[i].Lat, _this.puList[i].Lng));
                break;
              }
            }
          }
        });
      }
    }
  }

  showPU() {
    this.puList.forEach(pu => {
      if (pu.Marker) {
        pu.Marker.setVisible(true);
      }
    });
  }

  hidePU() {
    this.puList.forEach(pu => {
      if (pu.Marker) {
        pu.Marker.setVisible(false);
      }
    });
  }

  // Crea los componentes de la cabecera
  createToolBar(statusbar: any) {
    if (statusbar[0] !== undefined && !PuComponent.getInstance().toolbarOk) {
      PuComponent.getInstance().toolbarOk = true;
      // Añado el control de búsqueda a la cabecera
      const toolbarContainer = document.createElement('div');
      toolbarContainer.style.cssText = 'overflow: hidden; position: relative; margin-left: 4px; margin-top: 0px';
      const searchControl: any = document.createElement('div');
      searchControl.id = 'searchControlPU';
      searchControl.style.cssText = `
        float: left;
        background-color: white;
        background-image: url('../assets/images/search.png');
        background-repeat: no-repeat;
        background-position: 4px center;
        background-size: 18px;
        display: flex;
        align-items: center;
        width: 206px;
        margin-top: 2px;
        padding-top: 2px;
        padding-left: 28px;
        height: 25px;
        border: 1px solid rgba(0, 0, 0, 0.5);
        border-radius: 3px;
        overflow: hidden;
      `;
      searchControl.innerHTML = '<input type="text" style="border: 0;width: 100%; outline: none;" (keydown.enter)="$event.preventDefault()" ' +
        'placeholder="' + AppComponent.translate('Buscar') + '..." autocorrect = "off" autocapitalize = "off" spellcheck = "off">';
      toolbarContainer.appendChild(searchControl);
      // Creo el botón para crear
      const btnCrear: any = document.createElement('div');
      btnCrear.id = 'btnCrearPU';
      btnCrear.style.cssText = 'float: left; height: 25px; width: 85px; padding-left: 3px; padding-top: 2px;';
      btnCrear.innerHTML = `
        <button type="submit" style="width: 80px; height: 25px; align-items: center;">
        <div style="float: right; padding-top: 2px;">`+ AppComponent.translate('Crear') + `</div>
        <img style="float: left; height: 18px; width: 18px;" src="../assets/images/mas.png" /></button>
      `;
      toolbarContainer.appendChild(btnCrear);
      // Creo el botón para editar
      const btnEditar: any = document.createElement('div');
      btnEditar.id = 'btnEditarPU';
      btnEditar.style.cssText = 'float: left; height: 25px; width: 85px; padding: 2px;';
      btnEditar.innerHTML = `
        <button type="submit" style="width: 80px; height: 25px; align-items: center;">
        <div style="float: right; padding-top: 2px;">`+ AppComponent.translate('Editar') + `</div>
        <img style="float: left; height: 18px; width: 18px;" src="../assets/images/editar.png" /></button>
      `;
      toolbarContainer.appendChild(btnEditar);
      // Creo el botón para borrar
      const btnBorrar: any = document.createElement('div');
      btnBorrar.id = 'btnBorrarPU';
      btnBorrar.style.cssText = 'float: left; height: 25px; width: 85px; padding: 2px;';
      btnBorrar.innerHTML = `
        <button type="submit" style="width: 80px; height: 25px; align-items: center;">
        <div style="float: right; padding-top: 2px;">`+ AppComponent.translate('Borrar') + `</div>
        <img style="float: left; height: 18px; width: 18px;" src="../assets/images/borrar.png" /></button>
      `;
      toolbarContainer.appendChild(btnBorrar);

      // Creo el check para activar el movimiento de PU
      const btnMover: any = document.createElement('div');
      btnMover.id = 'btnMoverPU';
      btnMover.style.cssText = 'float: left; height: 25px; width: 85px; padding: 2px;';
      btnMover.innerHTML = `
            <div style="float: left; margin-left: 4px; padding-top: 5px;">`+ AppComponent.translate('Mover') + `</div>
            <input type="checkbox" style="float:left; margin-left: 5px; margin-top: 5px; width: 16px; height: 16px;" />
            `;
      toolbarContainer.appendChild(btnMover);

      statusbar[0].appendChild(toolbarContainer);
      // Creo los botones y el componente de búsqueda
      PuComponent.getInstance().initSearchControl();
      PuComponent.getInstance().initToolbarButtons();
    }
  }

  // Inicializa los botones de la cabecera
  initToolbarButtons(): void {
    const btnCrear = document.getElementById('btnCrearPU');
    if (btnCrear) {
      btnCrear.addEventListener('click', (event: any) => {
        const component = PuComponent.getInstance().puContainer.createComponent(PuEditComponent);
        component.instance.init(component, null);
      });
    }
    const btnEditar = document.getElementById('btnEditarPU');
    if (btnEditar) {
      btnEditar.addEventListener('click', (event: any) => {
        const rowsSelec = this.gridPU.getselectedrowindexes();
        if (rowsSelec && rowsSelec.length > 0) {
          if (rowsSelec.length < 11) {
            rowsSelec.forEach(rowIndex => {
              const component = PuComponent.getInstance().puContainer.createComponent(PuEditComponent);
              component.instance.init(component, this.puList[rowIndex]);
            });
          } else {
            MainComponent.getInstance().showWarning('ATENCION', 'Diez_registros_maximo', 2000);
          }
        } else {
          MainComponent.getInstance().showWarning('ATENCION', 'Seleccione_registro', 2000);
        }
      });
    }
    const btnBorrar = document.getElementById('btnBorrarPU');
    if (btnBorrar) {
      btnBorrar.addEventListener('click', (event: any) => {
        const rowsSelec = this.gridPU.getselectedrowindexes();
        if (rowsSelec && rowsSelec.length > 0) {
          if (rowsSelec.length < 11) {
            this.modal.confirm({
              nzTitle: '<i>' + AppComponent.translate('ATENCION') + '</i>',
              nzContent: AppComponent.translate('Quiere_borrar_puntos_ubicacion') + ' ?',
              nzCentered: true,
              nzCancelText: AppComponent.translate('CANCELAR'),
              nzOkText: AppComponent.translate('SI'),
              nzOnOk: async () => {
                rowsSelec.forEach(async (rowIndex) => {
                  if (this.puList[rowIndex].IdElemento > 0) {
                    MainComponent.getInstance().showWarning('ATENCION', 'PU_tiene_elemento', 2000);
                  } else {
                    await this.puService.deletePU(this.puList[rowIndex]);
                    MainComponent.getInstance().showSuccess('ATENCION', 'Registro_borrado', 2000);
                  }
                });
                this.gridPU.clearselection();
              }
            });
          } else {
            MainComponent.getInstance().showWarning('ATENCION', 'Diez_registros_maximo', 2000);
          }
        } else {
          MainComponent.getInstance().showWarning('ATENCION', 'Seleccione_registro', 2000);
        }
      });
    }
    const btnMover = document.getElementById('btnMoverPU');
    if (btnMover) {
      btnMover.addEventListener('click', (event: any) => {
        this.canMove = event.target.checked;
        if (this.puList) {
          this.puList.forEach(pu => {
            if (pu.Marker) {
              pu.Marker.setDraggable(this.canMove);
            }
          });
        }
      });
    }
  }

  // Inicializa el control de búsqueda de la cabecera
  initSearchControl(): void {
    const searchControl = document.getElementById('searchControlPU');
    if (searchControl) {
      searchControl.addEventListener('input', (event: any) => {
        this.searchText = event.target.value.toUpperCase();
      });
      let lastSearch = '';
      let timer = null;
      // Cada segundo compruebo si se ha filtrado la información
      setInterval(() => {
        if (this.searchText !== lastSearch) {
          if (timer) {
            clearTimeout(timer);
          }
          lastSearch = this.searchText;
          timer = setTimeout(() => {
            // Marco los registros que cumplen la condición de búsqueda y pongo el campo
            // oculto a "selec"
            if (this.puList) {
              this.puList.forEach(pu => {
                if ((pu.Equipamiento.Elemento.Nombre && pu.Equipamiento.Elemento.Nombre.toUpperCase().indexOf(this.searchText) > -1) ||
                  (pu.Equipamiento.Residuo.Nombre && pu.Equipamiento.Residuo.Nombre.toUpperCase().indexOf(this.searchText) > -1) ||
                  (pu.Nombre && pu.Nombre.toUpperCase().indexOf(this.searchText) > -1)) {
                  pu.selec = 'selec';
                } else {
                  if ((pu.Elemento && pu.Elemento.Nombre && pu.Elemento.Nombre.toUpperCase().indexOf(this.searchText) > -1) ||
                    (pu.Elemento && pu.Elemento.Nombre && pu.Elemento.Nombre.toUpperCase().indexOf(this.searchText) > -1) ||
                    (pu.Elemento && pu.Elemento.Equipamiento.Marca.Nombre && pu.Elemento.Equipamiento.Marca.Nombre.toUpperCase().indexOf(this.searchText) > -1) ||
                    (pu.Elemento && pu.Elemento.Equipamiento.Modelo.Nombre && pu.Elemento.Equipamiento.Modelo.Nombre.toUpperCase().indexOf(this.searchText) > -1)
                  ) {
                    pu.selec = 'selec';
                  } else {
                    pu.selec = '';
                  }
                }
              });
            }
            // Compruebo si ya he creado el filtro "selec" anteriormente
            const filters = this.gridPU.getfilterinformation();
            if (filters.find(s => s.datafield === 'selec') === undefined) {
              const filtergroup = new jqx.filter();
              filtergroup.addfilter(1, filtergroup.createfilter('stringfilter', 'selec', 'equal'));
              this.gridPU.addfilter('selec', filtergroup);
            }
            this.gridPU.applyfilters();
            this.gridPU.updatebounddata('data');
          }, 500);
        }
      }, 500);
    }
  }

  // Cuando se destruye el componente borro las subscripciones y los temporizadores
  ngOnDestroy() {
    if (this.subscriptionOnAddPU) {
      this.subscriptionOnAddPU.unsubscribe();
    }
    if (this.subscriptionOnNewPU) {
      this.subscriptionOnNewPU.unsubscribe();
    }
    if (this.subscriptionOnModifyPU) {
      this.subscriptionOnModifyPU.unsubscribe();
    }
    if (this.subscriptionOnDeletePU) {
      this.subscriptionOnDeletePU.unsubscribe();
    }
    if (this.subscriptionOnChangeFilterElements) {
      this.subscriptionOnChangeFilterElements.unsubscribe();
    }
    if (this.subscriptionOnEndLoadPU) {
      this.subscriptionOnEndLoadPU.unsubscribe();
    }
    if (this.subscriptionOnNewElements) {
      this.subscriptionOnNewElements.unsubscribe();
    }
    if (this.subscriptionOnModifyElements) {
      this.subscriptionOnModifyElements.unsubscribe();
    }
    if (this.subscriptionOnDeleteElements) {
      this.subscriptionOnDeleteElements.unsubscribe();
    }
    if (this.timerSearch === null) {
      clearInterval(this.timerSearch);
    }
  }

  // Cuando se selecciona una fila
  onRowClick(event: any) {
    const rowSel = this.gridPU.getselectedrowindexes();
    if (rowSel && rowSel.length < 2) {
      this.gridPU.clearselection();
      this.gridPU.selectrow(event.args.rowindex);
    }
    this.puSelec = this.puList[event.args.rowindex];
    if (!event.args.fromCarto) {
      if (!this.puSelec.Marker) {
        this.puSelec.Marker = this.addMarker(this.puSelec);
      }
      this.map.setCenter(this.puSelec.Marker.position);
      this.map.setZoom(this.MARKERS_ZOOM);
      this.puSelec.Marker.animate(2850);
    }
  }

  onRowdoubleclick(event: any) {
    this.onRowClick(event);

    const btnEditar = document.getElementById('btnEditar');
    btnEditar.click();
  }

  getContent(pu: PuModel): string {
    let res = '<b>' + pu.Nombre + '</b><hr>';
    if (pu.Equipamiento) {
      res += pu.Equipamiento.Elemento.Nombre + '<br>' +
        (pu.Equipamiento.Residuo.Nombre ? pu.Equipamiento.Residuo.Nombre + '<br>' : '');
    }
    if (pu.Elemento) {
      res += '<br>' + pu.Elemento.Nombre + '<br>' +
        (pu.Elemento.Equipamiento.Marca.Nombre ? pu.Elemento.Equipamiento.Marca.Nombre + '<br>' : '') +
        (pu.Elemento.Equipamiento.Modelo.Nombre ? pu.Elemento.Equipamiento.Modelo.Nombre : '');
    }
    return res;
  }

  // Recupera los PU de la empresa
  public initGrid(): void {
    this.columns.forEach(column => {
      column.rendered = (element) => { Utils.tooltiprenderer(element) };
    });
    this.source = {
      datatype: 'json',
      datafields: [
        { name: 'id', type: 'number', map: 'Id' },
        { name: 'eqId', type: 'number', map: 'Equipamiento>Modelo>Id' },
        { name: 'elemento', type: 'string', map: 'Equipamiento>Elemento>Nombre' },
        { name: 'residuo', type: 'string', map: 'Equipamiento>ResiduosNombres' },
        { name: 'clase', type: 'object', map: 'Equipamiento>ClasesNombre' },
        { name: 'descripcion', type: 'string', map: 'Nombre' },
        { name: 'elemNombre', type: 'string', map: 'Elemento>Nombre' },
        { name: 'marca', type: 'string', map: 'Elemento>Equipamiento>Marca>Nombre' },
        { name: 'modelo', type: 'string', map: 'Elemento>Equipamiento>Modelo>Nombre' },
        { name: 'capacidad', type: 'int', map: 'Elemento>Equipamiento>Capacidad' },
        { name: 'selec', map: 'selec' }
      ],
      localdata: this.puList,
      // sortcolumn: 'marca',
      // sortdirection: 'asc'
    };
    this.dataAdapter = new jqx.dataAdapter(this.source);

    this.gridPU.columnmenuopening(this.columnmenuopening);
  }

  updatefilterconditions = (type: string, defaultconditions: any): string[] => {
    return Utils.updatefilterconditions(type, defaultconditions);
  };

  public filter(cellValue?: any, rowData?: any, dataField?: string, filterGroup?: any, defaultFilterResult?: boolean): any {
    let filterColumns = [
      'elemento',
      'residuo',
      'clase',
      'descripcion',
      'elemNombre',
      'marca',
      'modelo'
    ]

    return Utils.filterRow(cellValue, dataField, filterGroup, defaultFilterResult, filterColumns);
  }

  /*
  Ajusto el menu del filtro en funcion de la posicion
  */
  public columnmenuopening (menu: any, datafield: any, height: any): void {
    if(menu && menu[0]){
      let menuElement = <HTMLElement>document.getElementById(menu[0].id);
      let areaGestion = <HTMLElement>document.getElementById('puGrid').children[0];
      let column = PuComponent.instance.gridPU.getcolumn(datafield)['element'];

      // En caso de que vaya a sobresalir por debajo de la pantalla lo ajusto hacia arriba
      if(height > areaGestion.offsetHeight){
        let top = height - areaGestion.offsetHeight + 80;

        menuElement.parentElement.style.top = '-'+top+'px';
      }

      // Ajuste del menu para que se vea el boton de filtro en el grid
      setTimeout(() => {
        let columnPos = column.offsetLeft + column.offsetWidth;
        let menuWidth = menuElement.offsetWidth;

        if(columnPos + menuWidth >= areaGestion.offsetWidth){
          menuElement.parentElement.style.left = '-20px';
        }else{
          menuElement.parentElement.style.left = '20px';
        }
      }, 0)
    }
  };

  // Cuando se refrescan los datos del grid
  onBindingComplete(): void {
    if (this.gridPU && this.puList && this.puList.length > 0) {
      this.gridPU.sortby(this.orderBy.split(',')[0], this.orderBy.split(',')[1]);
    }
    if (this.initialFilter) {
      this.setStoredFilter();
    }
    const elemVisibles = new Map<number, number>();
    const rowsVisibles = this.gridPU.getrows();
    if (rowsVisibles) {
      rowsVisibles.forEach(row => {
        elemVisibles.set(row.id, row.id);
      });
    }
    if (this.puList) {
      this.puList.forEach(pu => {
        if (pu.Marker) {
          pu.Marker.setVisible(elemVisibles.get(pu.Id) !== undefined &&
          MainComponent.getInstance().formPuVisible);
        }
      });
    }

    Utils.renderSizeGrid(this.gridPU, 500);
  }

  // Permite añadir PU al mapa y al grid
  async subscribeOnAddPU(): Promise<void> {
    this.subscriptionOnAddPU = this.puService.addPuEmiter.subscribe(async pu => {
      pu.forEach(p => {
        this.puListTotal.push(p);
      });
    });
  }

  // Permite añadir un nuevo PU al mapa y al grid
  async subscribeOnNewPU(): Promise<void> {
    this.subscriptionOnNewPU = this.puService.newPuEmiter.subscribe(async pu => {
      pu.Equipamiento = BdtService.equipamiento.get(pu.IdEquipamiento);
      if (pu.IdElemento > 0) {
        pu.Elemento = this.elementsService.elementos.get(pu.IdElemento);
      }
      if (!pu.Nombre) {
        pu.Nombre = 'PU-' + pu.Id;
      }
      // Añado el marcador refrescando el cluster
      pu.Marker = this.addMarker(pu, true);
      this.puListTotal.push(pu);
      this.puList.push(pu);
      this.gridPU.updatebounddata('data');
    });
  }

  // Cuando se ha terminado de descargar los PU
  async subscribeOnEndLoadPU(): Promise<void> {
    this.subscriptionOnEndLoadPU = this.puService.endLoadPuEmiter.subscribe(async () => {
      this.equipModelFilter = await this.elementsService.getFilterModel();
      if (MainComponent.getInstance().isEcoEvolution) {
        this.equipModelFilterIA = await this.elementsService.getFilterModelIA();
      } else {
        this.equipModelFilterIA = null;
      }
      // Creo la lista de PU filtrados
      this.puList = [];
      this.puListTotal.forEach(pu => {
        pu.Equipamiento = BdtService.equipamiento.get(pu.IdEquipamiento);
        if (pu.IdElemento > 0) {
          pu.Elemento = this.elementsService.elementos.get(pu.IdElemento);
        }
        if (!pu.Nombre) {
          pu.Nombre = 'PU-' + pu.Id;
        }
        if (pu.Equipamiento) {
          if (!this.equipModelFilterIA || this.equipModelFilterIA.find(s => s.id === pu.Equipamiento.Id) !== undefined) {
            if (this.equipModelFilter.find(s => s.id === pu.Equipamiento.Id) !== undefined) {
              pu.selec = '';
              // Añado el marcador sin refrescar el cluster
              pu.Marker = this.addMarker(pu, false);
              this.puList.push(pu);
            }
          }
        }
        // Añado el punto de ubicación al elemento
        const elem = this.elementsService.elementos.get(pu.IdElemento);
        if (elem) {
          elem.PU = pu;
          this.elementsService.elementos.set(elem.Id, elem);
        }
      });
      // Relleno el grid de PU
      this.initGrid();
      this.puCluster.refresh(false);
    });
  }

  // Añade un marcador al mapa
  addMarker(pu: PuModel, refresh = true): MapMarker {
    const icono = this.getMarkerIcon(pu);
    return this.map.addMarkerClusterV2(this.puCluster, {
      dataModel: pu,
      label: undefined,
      title: this.getMarkerTitle(pu),
      content: this.getMarkerContent(pu),
      position: new MapLatLng(pu.Lat, pu.Lng),
      icon: icono,
      zIndex: 0,
      drag: (this.canMove && this.canEdit),
      visible: this.map.zoom >= this.MARKERS_ZOOM && MainComponent.getInstance().formPuVisible
    }, refresh);
  }

  getMarkerIcon(pu: PuModel) {
    let icon = 'assets/images/pu-generico.png';
    switch (pu.Equipamiento?.Residuo?.Acronimo) {
      case 'ORG':
        icon = 'assets/images/pu-organica.png';
        break;
      case 'FRE':
        icon = 'assets/images/pu-resto.png';
        break;
      case 'PYE':
        icon = 'assets/images/pu-envases.png';
        break;
      case 'PYC':
        icon = 'assets/images/pu-papel.png';
        break;
      case 'VID':
        icon = 'assets/images/pu-vidrio.png';
        break;
    }
    return icon;
  }

  // Devuelve el contenido del infowindows del marcador
  getMarkerTitle(pu: PuModel): string {
    return pu.Nombre;
  }

  // Devuelve el contenido del infowindows del marcador
  getMarkerContent(pu: PuModel): string {
    let res = '<b>' + pu.Nombre + '</b><hr>';
    if (!this.elementsService.elemGenericos) {
      if (pu.Equipamiento) {
        res += pu.Equipamiento.Elemento.Nombre + '<br>' +
          (pu.Equipamiento.Residuo.Nombre ? pu.Equipamiento.Residuo.Nombre + '<br>' : '');
      }
      if (pu.Elemento) {
        res += '<br>' + pu.Elemento.Nombre + '<br>' +
          (pu.Elemento.Equipamiento.Marca.Nombre ? (pu.Elemento.Equipamiento.Marca.Nombre + '<br>') : '') +
          (pu.Elemento.Equipamiento.Modelo.Nombre ? pu.Elemento.Equipamiento.Modelo.Nombre : '');
      }
    } else {
      if (pu.Equipamiento) {
        res += pu.Equipamiento.Elemento.Nombre + '<br>';
      }
      if (pu.Elemento) {
        res += '<br>' + pu.Elemento.Nombre;
      }
    }
    return res;
  }

  // Permite saber que se han modificado PU
  subscribeModifyPU(): void {
    this.subscriptionOnModifyPU = this.puService.modifyPuEmiter.subscribe(pu => {
      this.puList.forEach((elem, i) => {
        if (pu.Id === elem.Id) {
          const oldMarker = this.puList[i].Marker;
          this.puList[i] = pu;
          if (this.puList[i].Marker) {
            this.puList[i].Marker.setTitle(this.getMarkerTitle(pu));
            this.puList[i].Marker.setContent(this.getMarkerContent(pu));
          }
        }
      });
      this.puCluster.refresh(false);
      // Actualizo el grid sólo después de haber asignado el dataadapter
      if (this.dataAdapter) {
        this.gridPU.updatebounddata('data');
      }
    });
  }

  // Permite saber que se han borrado PU
  subscribeDeletePU(): void {
    this.subscriptionOnDeletePU = this.puService.deletePuEmiter.subscribe(pu => {
      for (let i = 0; i < this.puList.length; i++) {
        if (pu.Id === this.puList[i].Id) {
          this.map.removeMarker(this.puList[i].Marker);
          this.puList.splice(i, 1);
          this.puCluster.refresh(false);
          // Actualizo el grid sólo después de haber asignado el dataadapter
          if (this.dataAdapter) {
            this.gridPU.updatebounddata('data');
          }
          break;
        }
      }
    });
  }

  // Cuando se cambia el filtro de elementos
  async subscribeChangeFilterElements(): Promise<void> {
    this.subscriptionOnChangeFilterElements = this.elementsService.changeFilterEmiter.subscribe(async () => {
      this.equipModelFilter = await this.elementsService.getFilterModel();
      if (MainComponent.getInstance().isEcoEvolution) {
        this.equipModelFilterIA = await this.elementsService.getFilterModelIA();
      } else {
        this.equipModelFilterIA = null;
      }
      // Creo el cluster para los elementos
      this.map.removeClusterV2(this.puCluster);
      this.puCluster = this.map.addClusterV2(this.MARKERS_ZOOM);
      // Creo la lista de elementos filtrados
      this.puList = [];
      this.puListTotal.forEach(pu => {
        if (pu.Equipamiento) {
          if (!this.equipModelFilterIA || this.equipModelFilterIA.find(s => s.id === pu.Equipamiento.Id) !== undefined) {
            if (this.equipModelFilter.find(s => s.id === pu.Equipamiento.Id) !== undefined) {
              pu.selec = '';
              // Añado el marcador sin refrescar el cluster
              pu.Marker = this.addMarker(pu, false);
              this.puList.push(pu);
            }
          }
        }
      });
      this.initGrid();
    });
  }

  // Cando se crea un filtro
  async onChangeFilter(event: any) {
    this.initialFilter = null;
    let filter = null;
    // Si el primer filtro es el de la búsqueda busco el siguiente filtro
    let columnFilter = event.args.filters[0] ? event.args.filters[0].datafield : '';
    if (columnFilter === 'selec') {
      if (event.args.filters[1]) {
        // Si hay un segundo filtro es por que se han filtrado por alguna columna
        columnFilter = event.args.filters[1].datafield
        filter = event.args.filters[1].filter.getfilters();
      }
    } else {
      filter = event.args.filters[0] ? event.args.filters[0].filter.getfilters() : null;
    }
    // Si ha cambiado el filtro
    if (this.oldFilter !== filter) {
      this.oldFilter = filter;
      // Si no hay filtro pongo la variable en blanco
      if (!filter) {
        // Guardo la variable de configuración en blanco
        this.configService.setUsuEmpApp('elements-filters', '');
      } else {
        const config = {
          column: columnFilter,
          filters: filter
        }
        // Guardo la variable de configuración con los datos del filtro
        this.configService.setUsuEmpApp('elements-filters', JSON.stringify(config));
      }
      // Para que se repinten los cluster
      this.onBindingComplete();
    }
  }

  // '{"column":"subflota","filters":[{"value":"C.Lateral","condition":"EQUAL","operator":1,"type":"stringfilter"}]}'
  setStoredFilter() {
    // Recupero los datos del filtro guardado y los aplico
    const filtergroup = new jqx.filter();
    if (this.initialFilter && this.initialFilter.filters) {
      this.initialFilter.filters.forEach(elem => {
        const filter = filtergroup.createfilter(elem.type, elem.value, elem.condition);
        filtergroup.addfilter(elem.operator, filter);
      });
      this.gridPU.addfilter(this.initialFilter.column, filtergroup);
      this.gridPU.applyfilters();
      this.initialFilter = null;
    }
  }

  // Recupero el filtro guardado si lo hay
  async getInitFilter() {
    this.initialFilter = await this.configService.getUsuEmpApp('elements-filters', null);
    if (this.initialFilter) {
      this.initialFilter = JSON.parse(this.initialFilter);
    }
  }

  // Cuando se crean nuevos elementos
  async subscribeOnNewElements(): Promise<void> {
    this.subscriptionOnNewElements = this.elementsService.newElementEmiter.subscribe(async elemento => {
      // Compruebo si ya existe el punto de ubicación
      const pu = await this.puService.getPuntoUbicacionByElemento(elemento.Id);
      if (pu && this.puList) {
        if (pu.Marker) {
          pu.Marker.setContent(this.getContent(pu));
          pu.Marker.setPosition(new MapLatLng(pu.Lat, pu.Lng));
        } else {
          this.addMarker(pu, true);
        }
        let i = 0;
        for (; i < this.puList.length; i++) {
          if (this.puList[i].Id === pu.Id) {
            this.puList[i].IdElemento = elemento.Id;
            this.puList[i].Elemento = elemento;
            break;
          }
        }
        if (i >= this.puList.length) {
          this.puList.push(pu);
        }
      }
      // Actualizo el grid sólo después de haber asignado el dataadapter
      if (this.dataAdapter) {
        this.gridPU.updatebounddata('data');
      }
    });
  }

  // Cuando se modifican elementos
  subscribeModifyElements(): void {
    this.subscriptionOnModifyElements = this.elementsService.modifyElementEmiter.subscribe(async elemento => {
      if (!elemento.PU) {
        // Compruebo si el elemento modificado ya tiene asignado el PU
        elemento.PU = await this.puService.getPuntoUbicacionByElemento(elemento.Id);
        if (elemento.PU && this.puList) {
          // Asigno el elemento al PU
          for (let i = 0; i < this.puList.length; i++) {
            if (this.puList[i].Id === elemento.PU.Id) {
              this.puList[i].IdElemento = elemento.Id;
              this.puList[i].Elemento = elemento;
              break;
            }
          }
        }
      }
      // Busco el PU que tiene el elemento para modificarlo
      for (let i = 0; i < this.puList.length; i++) {
        if (elemento.Id === this.puList[i].IdElemento) {
          this.puList[i].Elemento = elemento;
          this.puList[i].Lat = elemento.Lat;
          this.puList[i].Lng = elemento.Lng;
          if (this.puList[i].Marker) {
            this.puList[i].Marker.setContent(this.getContent(this.puList[i]));
            this.puList[i].Marker.setPosition(new MapLatLng(elemento.Lat, elemento.Lng));
          }
          break;
        }
      }
      // Actualizo el grid sólo después de haber asignado el dataadapter
      if (this.dataAdapter) {
        this.gridPU.updatebounddata('data');
      }
    });
  }

  // Cuando se borran elementos
  subscribeDeleteElements(): void {
    this.subscriptionOnDeleteElements = this.elementsService.deleteElementEmiter.subscribe(async elemento => {
      // Busco el PU que tiene el elemento para quitarselo
      for (let i = 0; i < this.puList.length; i++) {
        if (elemento.Id === this.puList[i].IdElemento) {
          this.puList[i].IdElemento = 0;
          this.puList[i].Elemento = null;
          if (this.puList[i].Marker) {
            this.puList[i].Marker.setContent(this.getContent(this.puList[i]));
          }
          break;
        }
      }
      // Actualizo el grid sólo después de haber asignado el dataadapter
      if (this.dataAdapter) {
        this.gridPU.updatebounddata('data');
      }
    });
  }

}
