import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { CasaComponent } from './casa/casa.component';
import { MarcoComponent } from './marco/marco.component';
import { AmbitoComponent } from './ambito/ambito.component';
import { MeasureComponent } from './measure/measure.component';
import { ItinerariComponent } from './itinerari/itinerari.component';
import { CartoSelectComponent } from './carto-select/carto-select.component';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [
    CasaComponent,
    MarcoComponent,
    AmbitoComponent,
    MeasureComponent,
    ItinerariComponent,
    CartoSelectComponent,
  ],
  imports: [
    FormsModule,
    CommonModule,
    SharedModule
  ]
})
export class MapsToolsModule { }
