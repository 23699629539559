<div class="row" style="height: 100%; width: 100%;">
  <div style="width: 45%;">
    <h3>{{ translate('Tarjetas_de') }} {{ciudadano?.nombre}} {{ciudadano?.apellidos}}</h3>

    <div class="row">
      <span style="display: block; width: 99%;">
        <jqxDropDownButton #cbDirecciones [width]="'100%'" [theme]="environment.tema" [animationType]="'none'"
          (onClose)="onCloseSelectDirecciones($event)" (onOpen)="onOpenSelectDirecciones($event)"
          (focusout)="cbDirecciones.close()">
        </jqxDropDownButton>
        <div *ngIf="verGridDirecciones" style="position: absolute; z-index: 999;">
          <jqxGrid #gridDropDownDirecciones [theme]="environment.tema" [appCellClick]
            [width]="cbDirecciones.elementRef.nativeElement.parentElement.clientWidth" [height]="220"
            [source]="adapterDirecciones" [columns]="columnsDirecciones" [localization]="langGrid"
            [enablebrowserselection]="true" (onRowclick)="onRowClickDirecciones($event)">
          </jqxGrid>
        </div>
      </span>
    </div>
    <jqxGrid #gridTarjetasAsignadas [theme]="environment.tema" [width]="'99%'" [height]="'calc(100% - 80px)'"
      [appCellClick] [source]="adapterTarjetasAsignadas" [columnsresize]="true" [localization]="langGrid"
      [filterable]="true" [showfilterrow]="true" [columns]="columnsTarjetasAsignadas" [showrowlines]="false"
      [showcolumnlines]="true" [pageable]="false" [sortable]="true" [altrows]="true" [showstatusbar]="false"
      [statusbarheight]="0" [enablebrowserselection]="true" [showaggregates]="false" [rowsheight]="20"
      [columnsheight]="20" [enabletooltips]="false" [editable]="false" [groupable]="false" [selectionmode]="'singlerow'"
      [filter]="filter" [updatefilterconditions]="updatefilterconditions" (onRowclick)="onRowSelectAsignada($event)"
      (onCellendedit)="onCellEdit($event)" (onCellclick)="onCellClick($event)">
    </jqxGrid>
  </div>
  <div style="width: 10%;">
    <div class="rowC" style="height: 88%;">
      <jqxButton class="button" style="margin-bottom: 25%; cursor: pointer;" [width]="50" [height]="50" [imgHeight]="40"
        [imgWidth]="40" [imgSrc]='"/assets/images/izquierda.png"' [imgPosition]="'center'"
        (onClick)="associateTarjetasToCiudadano()" [disabled]="!(tarjetaLibreSelect)">
      </jqxButton>
      <jqxButton class="button" style="margin-top: 25%; cursor: pointer;" [width]="50" [height]="50" [imgHeight]="40"
        [imgWidth]="40" [imgSrc]='"/assets/images/derecha.png"' [imgPosition]="'center'"
        (onClick)="disassociateTarjetasFromCiudadano()" [disabled]="!(tarjetaAsignadaSelect)">
      </jqxButton>
      <jqxButton class="button" style="margin-top: 25%; cursor: pointer;" [width]="120" [height]="30" [imgWidth]="20"
        [imgHeight]="20" [textImageRelation]="'imageBeforeText'" [textPosition]="'left'"
        [imgSrc]='"/assets/images/nfc.png"' [imgPosition]="'left'" [value]="translate('Leer_tarjeta')"
        (onClick)="onNewCard()">
      </jqxButton>
    </div>
  </div>
  <div style="width: 45%;">
    <h3>{{ translate('Tarjetas_libres') }}</h3>
    <jqxGrid #gridTarjetasLibres [theme]="environment.tema" [width]="'99%'" [height]="'calc(100% - 55px)'"
      [appCellClick] [source]="adapterTarjetasLibres" [columnsresize]="true" [localization]="langGrid"
      [filterable]="true" [showfilterrow]="true" [columns]="columnsTarjetasLibres" [showrowlines]="false"
      [showcolumnlines]="true" [pageable]="false" [sortable]="true" [altrows]="true" [rowsheight]="20"
      [columnsheight]="20" [enablebrowserselection]="true" [enabletooltips]="false" [editable]="false"
      [groupable]="false" [selectionmode]="'singlerow'" [filter]="filter" [showstatusbar]="false"
      [updatefilterconditions]="updatefilterconditions" (onRowclick)="onRowSelectLibre($event)">
    </jqxGrid>
  </div>
</div>
