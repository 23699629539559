<jqxWindow #windowForm [jqxWindowAutoResize] [theme]="environment.tema" [position]="getFormPos(windowForm, 24)" [width]="mapWidth" [height]="mapHeight - 24" [zIndex]="900"
  [autoOpen]="true" [showCloseButton]="true" [showCollapseButton]="true" [resizable]="true" (onClose)="onClose()">
  <div class="formHeader" style="overflow-x: hidden">
    {{ translate("Vehiculo") }}
  </div>

  <div class="form" style="float: left; overflow-x: hidden;">
    <jqxRibbon #elemRibbon [theme]="environment.tema" [position]="'top'" [height]="'calc(100% - 30px)'">
      <ul style="width: 100%; text-align: left">
        <li style="height: 26px; vertical-align: middle">
          {{ translate("Datos_generales") }}
        </li>
        <li style="height: 26px; vertical-align: middle">
          {{ translate("Chasis") }}
        </li>
        <li style="height: 26px; vertical-align: middle">
          {{ translate("Carrozado") }}
        </li>
        <li style="height: 26px; vertical-align: middle">
          {{ translate("Vehiculos") }}
        </li>
      </ul>

      <div>
        <div style="background-color: transparent; padding: 0%; overflow-y: auto;">
          <div style="display: inline-table !important; width: 100%; height: 100%;">
            <span style="float: left; width: calc(50% - 5px);">
              <div class="row" style="margin-top: 10px;">
                <span style="width: 10%; clear: both;">
                  <div>
                    {{ translate('Icono') }}
                  </div>
                  <span style="display: flex; justify-content: center">
                    <img *ngIf="movil.icono" src="data:image/png;base64,{{movil.icono}}">
                  </span>
                </span>

                <span style="width: 30%; clear: both;">
                  <div>
                    {{ translate('Recurso') }}
                  </div>
                  <jqxInput [value]="movil.recurso?.nombre" [disabled]="true" [width]="'100%'">
                  </jqxInput>
                </span>

                <span style="width: 60%; clear: both;">
                  <div>
                    {{ translate('Clases') }}
                  </div>
                  <jqxInput [value]="clasesMovil" [width]="'100%'" [disabled]="true">
                  </jqxInput>
                </span>
              </div>
              <div id="fieldPesosMedidas">
                <fieldset>
                  <legend>
                    {{ translate('Pesos_medidas') }}
                  </legend>

                  <div class="row">
                    <span style="width: 15%;">
                      <div>
                        {{ translate('Ancho') }} ({{translate('Mm')}})
                      </div>
                      <app-input-number [value]="movil.ancho" [disabled]="true" [render]="renderer()" [width]="'80%'">
                      </app-input-number>
                    </span>

                    <span style="width: 15%;">
                      <div>
                        {{ translate('Alto') }} ({{translate('Mm')}})
                      </div>
                      <app-input-number [value]="movil.alto" [disabled]="true" [render]="renderer()" [width]="'80%'">
                      </app-input-number>
                    </span>

                    <span style="width: 15%;">
                      <div>
                        {{ translate('Largo') }} ({{translate('Mm')}})
                      </div>
                      <app-input-number [value]="movil.largo" [disabled]="true" [render]="renderer()" [width]="'80%'">
                      </app-input-number>
                    </span>

                    <span style="width: 30%;">
                      <div>
                        {{ translate('Carga_maxima_neta') }} ({{translate('Kg')}})
                      </div>
                      <app-input-number [value]="movil.carga_maxima_neta" [disabled]="true" [render]="renderer()"
                        [width]="'40%'">
                      </app-input-number>
                    </span>

                    <span style="width: 25%;">
                      <div>
                        {{ translate('Volumen') }} (L)
                      </div>
                      <app-input-number [value]="movil.volumen" [disabled]="true" [render]="renderer()" [width]="'50%'">
                      </app-input-number>
                    </span>
                  </div>
                </fieldset>
              </div>

              <div style="width: calc(50% - 5px); float: left; margin-right: 5px;">
                <div id="fieldCombustibleGases">
                  <fieldset>
                    <legend>
                      {{ translate('Combustibles_gases') }}
                    </legend>

                    <div class="row">
                      <span style="width: 70%; clear: both;">
                        <div>
                          {{ translate('Combustible') }}
                        </div>
                        <jqxInput [value]="movil.chasis?.combustible?.nombre" [disabled]="true" [width]="'100%'">
                        </jqxInput>
                      </span>

                      <span style="width: 30%; clear: both;">
                        <div>
                          Km/Co²
                        </div>
                        <app-input-number [value]="movil.kmco2" [disabled]="true" [render]="renderer()"
                          [width]="'100%'">
                        </app-input-number>
                      </span>
                    </div>
                  </fieldset>
                </div>

                <div id="fieldDatosEconomicos">
                  <fieldset>
                    <legend>
                      {{ translate('Datos_economicos') }}
                    </legend>

                    <div class="row">
                      <span style="width: 50%; clear: both;">
                        <div>
                          {{ translate('Precio_compra') }}
                        </div>
                        <app-input-number [value]="movil.precio_compra" [disabled]="true" [render]="renderer()"
                          [width]="'50%'">
                        </app-input-number>
                      </span>

                      <span style="width: 50%; clear: both;">
                        <div>
                          {{ translate('Años_amortizacion') }}
                        </div>
                        <app-input-number [value]="movil.anyo_amortizacion" [disabled]="true" [render]="renderer()"
                          [width]="'50%'">
                        </app-input-number>
                      </span>
                    </div>
                    <div class="row">
                      <span style="width: 50%; clear: both;">
                        <div>
                          {{ translate('Coste_euro_km') }}
                        </div>
                        <app-input-number [value]="movil.coste" [disabled]="true" [render]="renderer()" [width]="'50%'">
                        </app-input-number>
                      </span>

                      <span style="width: 50%; clear: both;">
                        <div>
                          {{ translate('Año_modelo') }}
                        </div>
                        <app-input-number [value]="movil.anyoMatriculacion" [disabled]="true" [render]="renderer()"
                          [width]="'50%'">
                        </app-input-number>
                      </span>
                    </div>
                  </fieldset>
                </div>
              </div>
            </span>

            <span style="float: left; margin-left: 10px; width: calc(50% - 5px); height: calc(100% - 26px);">
              <fieldset>
                <legend>{{ translate("Imagenes") }}</legend>

                <div class="row" style="height: 100%;">
                  <div class="container imagenPrincipal">
                    <div class="row" style="height: calc(100% - 35px)">
                      <div *ngIf="selectedImg != null" style="width: 100%; height: 100%;">
                        <img #imgPrincipal src="data:image/png;base64,{{selectedImg?.imagen}}"
                          style="width: 100%; height: 100%;">
                      </div>
                    </div>

                    <div class="row" style="margin-top: 5px;">
                      <jqxButton style="float:left;" [width]="35" [height]="35" [imgHeight]="25" [imgWidth]="25"
                        [imgSrc]='"/assets/images/previous.png"' [imgPosition]="'center'"
                        (onClick)="changeImage('previous')" [disabled]="selectedImg == null">
                      </jqxButton>
                      <jqxButton style="float:left;" [width]="35" [height]="35" [imgHeight]="25" [imgWidth]="25"
                        [imgSrc]='"/assets/images/next.png"' [imgPosition]="'center'" (onClick)="changeImage('next')"
                        [disabled]="selectedImg == null">
                      </jqxButton>
                      <jqxButton style="float:left;" [width]="35" [height]="35" [imgHeight]="25" [imgWidth]="25"
                        [imgSrc]='"/assets/images/rotate-left.png"' [imgPosition]="'center'" (onClick)="rotate('left')"
                        [disabled]="selectedImg == null">
                      </jqxButton>
                      <jqxButton style="float:left;" [width]="35" [height]="35" [imgHeight]="25" [imgWidth]="25"
                        [imgSrc]='"/assets/images/rotate-right.png"' [imgPosition]="'center'"
                        (onClick)="rotate('right')" [disabled]="selectedImg == null">
                      </jqxButton>
                      <jqxButton style="float:left;" [width]="35" [height]="35" [imgHeight]="25" [imgWidth]="25"
                        [imgSrc]='"/assets/images/refresh.png"' [imgPosition]="'center'" (onClick)="rotate('180')"
                        [disabled]="selectedImg == null">
                      </jqxButton>
                      <!-- <jqxButton style="float:left;" [width]="35" [height]="35" [imgHeight]="25" [imgWidth]="25"
                        [imgSrc]='"/assets/images/upload.png"' [imgPosition]="'center'" (onClick)="openUploadImageWind()">
                      </jqxButton>
                      <jqxButton style="float:left;" [width]="35" [height]="35" [imgHeight]="25" [imgWidth]="25"
                        [imgSrc]='"/assets/images/borrar.png"' [imgPosition]="'center'" (onClick)="openDeleteImageWind()"
                        [disabled]="selectedImg == null">
                      </jqxButton> -->
                    </div>
                  </div>

                  <div class="container sliderImages">
                    <div *ngFor="let img of movil.imagenes; let i = index"
                      style="align-items: center; background-color: white; width: 75px; height: 80px;">
                      <div style="width: 100%; height: 100%;">
                        <img style="padding: auto; align-items: center; width: 75px; height: 80px;"
                          src="data:image/png;base64,{{img.imagen}}" (click)="setImgPrincipal(img)">
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </span>
          </div>
        </div>

        <div style="background-color: transparent; padding: 0%; overflow-y: auto;">
          <div style="display: inline-table !important; width: 100%; height: 100%;">
            <div class="row">
              <span style="width: 20%;">
                <div style="margin-top: 5px; clear: both">
                  {{ translate("Marca") }}
                </div>
                <jqxInput [value]="movil.chasis?.modelo?.marca?.nombre" [disabled]="true" [width]="'100%'">
                </jqxInput>
              </span>
              <span style="width: 20%;">
                <div style="margin-top: 5px; clear: both">
                  {{ translate("Modelo") }}
                </div>
                <jqxInput [value]="movil.chasis?.modelo?.nombre" [disabled]="true" [width]="'100%'">
                </jqxInput>
              </span>
              <span style="width: 10%;">
                <div style="margin-top: 5px; clear: both">
                  {{ translate("Año_fabricacion") }}
                </div>
                <app-input-number [value]="movil.chasis?.anyoFabricacion" [disabled]="true" [render]="renderer()"
                  [width]="'50%'">
                </app-input-number>
              </span>
              <span style="width: 10%;">
                <div style="margin-top: 5px; clear: both">
                  {{ translate("Num_personas") }}
                </div>
                <app-input-number [value]="movil.chasis?.numPersonas" [disabled]="true" [render]="renderer()"
                  [width]="'50%'">
                </app-input-number>
              </span>
              <span style="width: 10%;">
                <div style="margin-top: 5px; clear: both">
                  {{ translate("Num_operarios") }}
                </div>
                <app-input-number [value]="movil.chasis?.numOperarios" [disabled]="true" [render]="renderer()"
                  [width]="'50%'">
                </app-input-number>
              </span>
              <span style="width: 10%;">
                <div style="margin-top: 5px; clear: both">
                  {{ translate("Carga_util") }}
                </div>
                <app-input-number [value]="movil.chasis?.carga_maxima" [disabled]="true" [render]="renderer()"
                  [width]="'50%'">
                </app-input-number>
              </span>
              <span style="width: 20%;">
                <div style="margin-top: 5px; clear: both">
                  {{ translate("Tipo_canbus") }}
                </div>
                <jqxInput [value]="movil.chasis?.canBusMotor?.tipoCombo" [disabled]="true" [width]="'100%'">
                </jqxInput>
              </span>
            </div>
          </div>
        </div>

        <div style="background-color: transparent; padding: 0%; overflow-y: auto;">
          <div style="display: inline-table !important; width: 100%; height: 100%;">
            <div class="row">
              <span style="width: 20%;">
                <div style="margin-top: 5px; clear: both">
                  {{ translate("Marca") }}
                </div>
                <jqxInput [value]="movil.carrozado?.modelo?.marca?.nombre" [disabled]="true" [width]="'100%'">
                </jqxInput>
              </span>
              <span style="width: 20%;">
                <div style="margin-top: 5px; clear: both">
                  {{ translate("Modelo") }}
                </div>
                <jqxInput [value]="movil.carrozado?.modelo?.nombre" [disabled]="true" [width]="'100%'">
                </jqxInput>
              </span>
              <span style="width: 20%;">
                <div style="margin-top: 5px; clear: both">
                  {{ translate("Tipo_de_residuo") }}
                </div>
                <jqxInput [value]="movil.carrozado?.residuo?.nombre" [disabled]="true" [width]="'100%'">
                </jqxInput>
              </span>
              <span style="width: 20%;">
                <div style="margin-top: 5px; clear: both">
                  {{ translate("Tipo_canbus") }}
                </div>
                <jqxInput [value]="movil.carrozado?.chasisCarrozado?.tipoCombo" [disabled]="true" [width]="'100%'">
                </jqxInput>
              </span>
              <span style="width: 20%;">
                <div style="margin-top: 5px; clear: both">
                  {{ translate("Año_fabricacion") }}
                </div>
                <app-input-number [value]="movil.carrozado?.anyoFabricacion" [disabled]="true" [render]="renderer()"
                  [width]="'30%'">
                </app-input-number>
              </span>
            </div>
          </div>
        </div>

        <div style="background-color: transparent; padding: 0%; overflow-y: auto;">
          <div style="display: flex; gap: 2px; height: 100%;">
            <div id="fieldGridVehiculos" style="display: flex !important; flex-direction: column;">
              <div>
                <app-header #header [buscador]="true" (eventBuscador)="onBuscar()">
                </app-header>
              </div>
              <div style="width: 100%; height: 100%; margin-top: 5px;">
                <jqxGrid #gridVehiculos [theme]="environment.tema" [width]="'100%'" [height]="'100%'" [appCellClick]
                  [source]="dataAdapterVehiculos" [columnsresize]="true" [localization]="langGrid" [filterable]="true"
                  [showfilterrow]="false" [columns]="columnsVehiculos" [enablebrowserselection]="true"
                  [showrowlines]="false" [showcolumnlines]="true" [pageable]="false" [sortable]="true" [altrows]="true"
                  [rowsheight]="30" [filterrowheight]="25" [columnsheight]="25" [editable]="false" [filter]="filter"
                  [updatefilterconditions]="updatefilterconditions">
                </jqxGrid>
              </div>
            </div>
          </div>
        </div>
      </div>
    </jqxRibbon>

    <div style="position: absolute; bottom: 5px; left: 0px; margin-top: 23px; clear: both;">
      <jqxButton style="float: left; margin-left: 5px" [width]="100" [height]="26"
        [textImageRelation]="'imageBeforeText'" [textPosition]="'left'" [imgSrc]="'/assets/images/save.png'"
        [value]="translate('Guardar')">
      </jqxButton>
      <jqxButton style="float: left; margin-left: 5px" [width]="100" [height]="26"
        [textImageRelation]="'imageBeforeText'" [textPosition]="'left'" [imgSrc]="'/assets/images/localizar.png'"
        [value]="translate('Centrar')" (onClick)="onCentrar()">
      </jqxButton>
      <jqxButton style="float: left; margin-left: 5px" [width]="100" [height]="26"
        [textImageRelation]="'imageBeforeText'" [textPosition]="'left'" (onClick)="closeWindow()"
        [value]="translate('CANCELAR')">
      </jqxButton>
    </div>
  </div>
</jqxWindow>
