import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';

import { DateUtils } from 'src/app/utils/date-utils';
import { AppComponent } from 'src/app/app.component';
import { environment } from 'src/environments/environment';
import { MapComponent, MapLatLng } from 'movisat-maps';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';
import { jqxDropDownButtonComponent } from 'jqwidgets-ng/jqxdropdownbutton';

import { NzModalService } from 'ng-zorro-antd/modal';
import { SsoService } from 'src/app/services/sso/sso.service';
import { BdtService } from 'src/app/services/bdt/bdt.service';
import { ElementsService } from 'src/app/services/elements/elements.service';
import { HistoricoUbicacionService } from 'src/app/services/historicoUbicacion/historico-ubicacion.service';

import { ElementoModel } from 'src/app/services/elements/models/elem.model';
import { BdtCatalogoEquipamientoModel } from 'src/app/services/bdt/models/bdt-catalogo-equipamiento.model';
import { Utils } from 'src/app/utils/utils';
import { HistoricoUbicacion } from 'src/app/services/historicoUbicacion/models/historico-ubicacion';

@Component({
  selector: 'app-elements-edit-informacion',
  templateUrl: './elements-edit-informacion.component.html',
  styleUrls: ['./elements-edit-informacion.component.css', '../elements-edit.component.css']
})
export class ElementsEditInformacionComponent implements OnInit {
  @ViewChild('fieldInformacion') fieldInformacion;

  @Input() form: jqxWindowComponent;
  @Input() elemEdit: ElementoModel;
  @Input() crearMultiples: boolean;
  @Input() map: MapComponent;
  @Input() expanded: boolean = true;
  @Input() closed: boolean = false;

  @Output() catEquipSelecChange = new EventEmitter;

  public localization = 'es-ES';
  public environment = environment;
  public langGrid = JqWidgets.getLocalization('es');
  verGridEquipamiento: boolean = false;
  translate(text: string): string {
    return AppComponent.translate(text);
  }

  formatDate(fecha) {
    fecha = new Date(fecha);
    return DateUtils.formatDateTimeShort(fecha, true);
  }

  constructor(
    private ssoService: SsoService,
    private bdtService: BdtService,
    private elemService: ElementsService,
    private historicoService: HistoricoUbicacionService,
    private modal: NzModalService) {
  }

  ngOnInit(): void {
    this.langGrid = JqWidgets.getLocalization(
      this.ssoService.getTicket().Usuario.Idioma.Codigo.substring(0, 2)
    );

    this.initGridEquip();
    this.initGridHistorico();
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'form': {
            this.form = changes[propName].currentValue;
            break;
          }
          case 'elemEdit': {
            this.elemEdit = changes[propName].currentValue;
            this.inicializar();
            break;
          }
          case 'crearMultiples': {
            this.crearMultiples = changes[propName].currentValue;
            break;
          }
          case 'map': {
            this.map = changes[propName].currentValue;
            break;
          }
          case 'expanded': {
            this.expanded = changes[propName].currentValue;
            if (!this.expanded) {
              if (this.gridDropDownEquip) {
                this.gridDropEquip = true;
              }
              this.cbEquip.close();
            }
            break;
          }
          case 'closed': {
            this.closed = changes[propName].currentValue;
            break;
          }
          default: {
            break;
          }
        }
      }
    }
  }

  inicializar(): void {
    if (this.elemEdit) {
      if (this.elemEdit.Id !== 0) {
        this.getImages();
        this.getHistoricoUbicaciones();
      }
      //this.createComponent();
      const t = setTimeout(async () => {
        clearTimeout(t);
        this.catEquipList = await this.getCatalogoEquipamiento();
        if (this.elemEdit) {
          if (this.elemEdit.Id < 1) {
            // Relleno el combo de equipamientos
            this.catEquipSelec = this.catEquipList[0];
          } else {
            this.catEquipList.forEach((cat, i) => {
              if (cat.IdEquipamiento === this.elemEdit.IdEquipamiento) {
                this.catEquipSelec = cat;
              }
            });
          }
          this.catEquipSelecChange.emit(this.catEquipSelec);
          this.elemEdit.Equipamiento = this.catEquipSelec.Equipamiento;
          let content = '';
          if (this.elemService && this.elemService.elemGenericos) {
            content =
              '<div style="position: relative; margin-left: 3px; margin-top: 5px;">' +
              this.catEquipSelec.Equipamiento.Elemento.Nombre +
              '</div>';
          } else {
            content =
              '<div style="position: relative; margin-left: 3px; margin-top: 5px;">' +
              this.catEquipSelec.Equipamiento.Elemento.Nombre +
              (this.catEquipSelec.Equipamiento.Marca.Nombre
                ? ' | ' + this.catEquipSelec.Equipamiento.Marca.Nombre
                : '') +
              (this.catEquipSelec.Equipamiento.Modelo.Nombre
                ? ' | ' + this.catEquipSelec.Equipamiento.Modelo.Nombre
                : '') +
              (this.catEquipSelec.Equipamiento.ResiduosNombres
                ? ' | ' + this.catEquipSelec.Equipamiento.ResiduosNombres
                : '') +
              '</div>';
          }
          if (this.cbEquip) {
            this.cbEquip.setContent(content);
          }
        }
      }, 0);
    }
  }

  customComponent(component?: any) {
    setTimeout(() => {
      this.gridDropDownEquip.setOptions({
        showaggregates: true,
        sortable: true,
        altrows: true,
        filterable: true,
        rowsheight: 20,
        columnsheight: 20,
        statusbarheight: 20,
        columnsresize: true,
        showstatusbar: true,
        showcolumnlines: true,
      });
    }, 50);
  }

  onOpenSelectEquipamientos(event) {
    this.customComponent();
    this.verGridEquipamiento = true;
  }

  onCloseSelectEquipamientos(event) {
    this.verGridEquipamiento = false;
  }

  resizeColumns(grid: jqxGridComponent) {
    try {
      Utils.renderSizeGrid(grid, 500);
    } catch (error) {

    }
  }

  /* GRID EQUIPAMIENTO */
  @ViewChild('gridDropDownEquip') gridDropDownEquip: jqxGridComponent;
  @ViewChild('cbEquip') cbEquip: jqxDropDownButtonComponent;

  private catEquipSelec: BdtCatalogoEquipamientoModel;
  public catEquipList: BdtCatalogoEquipamientoModel[] = [];

  public sourceEquip: any = {};
  public dataAdapterEquip: any = {};

  gridDropEquip: boolean;

  public columnsEquip;

  initGridEquip() {
    this.columnsEquip = [
      { text: 'Id', columntype: 'textbox', filtertype: 'textbox', datafield: 'id', hidden: true },
      {
        text: '', columntype: 'image', datafield: 'imagen', cellsrenderer: this.imagerenderer,
      },
      {
        text: this.translate('Elemento'), columntype: 'textbox', filtertype: 'textbox', datafield: 'elemento', aggregates: [{
          'Total': function (aggregatedValue, currentValue: number) {
            return aggregatedValue + 1;
          }
        }], //agrego el total al grid
        aggregatesrenderer: function (aggregates) {
          let renderstring = '';
          if (aggregates["Total"] !== undefined) {
            renderstring = '<div style="margin-left: 4px;">Total: ' +
              aggregates["Total"] + '</div>';
          }
          return renderstring;
        }
      },
      { text: this.translate('Marca'), columntype: 'textbox', filtertype: 'checkedlist', datafield: 'marca', hidden: this.elemService.elemGenericos },
      { text: this.translate('Modelo'), columntype: 'textbox', filtertype: 'checkedlist', datafield: 'modelo', hidden: this.elemService.elemGenericos },
      { text: this.translate('Residuo'), columntype: 'textbox', filtertype: 'checkedlist', datafield: 'residuo', hidden: this.elemService.elemGenericos },
    ];

    this.columnsEquip.forEach(column => {
      column.rendered = (element) => { Utils.tooltiprenderer(element) };
    });

    this.sourceEquip = {
      datatype: 'json',
      datafields: [
        { name: 'id', type: 'number', map: 'Equipamiento>Modelo>Id' },
        { name: 'imagen', type: 'image', map: 'Equipamiento>Icono' },
        { name: 'elemento', type: 'string', map: 'Equipamiento>Elemento>Nombre' },
        { name: 'marca', type: 'string', map: 'Equipamiento>Marca>Nombre' },
        { name: 'modelo', type: 'string', map: 'Equipamiento>Modelo>Nombre' },
        { name: 'residuo', type: 'string', map: 'Equipamiento>ResiduosNombres' },
      ],
      localdata: this.catEquipList,
      sortcolumn: 'marca',
      sortdirection: 'asc',
    };

    this.dataAdapterEquip = new jqx.dataAdapter(this.sourceEquip);
  }

  onOpenDropEquip(event) {
    if (this.gridDropEquip) {
      this.gridDropEquip = false;
    }

    this.resizeColumns(this.gridDropDownEquip);
  }

  onCloseDropEquip(event) {
    if (this.gridDropDownEquip) {
      this.gridDropEquip = true;
    }
    this.cbEquip.close();
  }

  /* Cuando se selecciona un equipamiento */
  onRowClickEquip(event: any) {
    this.catEquipSelec = this.catEquipList[event.args.rowindex];
    this.catEquipSelecChange.emit(this.catEquipSelec);
    this.elemEdit.Equipamiento = this.catEquipSelec.Equipamiento;

    this.elemEdit.AlturaSensor = this.catEquipSelec.Equipamiento.AlturaSensor ? this.catEquipSelec.Equipamiento.AlturaSensor : 0;
    this.elemEdit.AlturaMuerta = this.catEquipSelec.Equipamiento.AlturaMuerta ? this.catEquipSelec.Equipamiento.AlturaMuerta : 0;
    this.elemEdit.GradosSensor = this.catEquipSelec.Equipamiento.GradosSensor ? this.catEquipSelec.Equipamiento.GradosSensor : 0;

    let content = '';
    if (this.elemService.elemGenericos) {
      content =
        '<div style="position: relative; margin-left: 3px; margin-top: 5px;">' +
        this.catEquipSelec.Equipamiento.Elemento.Nombre +
        '</div>';
    } else {
      content =
        '<div style="position: relative; margin-left: 3px; margin-top: 5px;">' +
        this.catEquipSelec.Equipamiento.Elemento.Nombre +
        (this.catEquipSelec.Equipamiento.Marca.Nombre
          ? ' | ' + this.catEquipSelec.Equipamiento.Marca.Nombre
          : '') +
        (this.catEquipSelec.Equipamiento.Modelo.Nombre
          ? ' | ' + this.catEquipSelec.Equipamiento.Modelo.Nombre
          : '') +
        (this.catEquipSelec.Equipamiento.ResiduosNombres
          ? ' | ' + this.catEquipSelec.Equipamiento.ResiduosNombres
          : '') +
        '</div>';
    }

    if (this.cbEquip) {
      this.cbEquip.setContent(content);
    }
    this.cbEquip.close();

    if (this.elemEdit.Marker) {
      if (
        !this.elemService.elemGenericos ||
        (this.elemEdit.Equipamiento.Icono &&
          this.elemEdit.Equipamiento.Icono.length > 50)
      ) {
        this.elemEdit.Marker.setIcon(
          'data:image/png;base64,' + this.elemEdit.Equipamiento.Icono
        );
      } else {
        this.elemEdit.Marker.setIcon('assets/images/elemento.png');
      }
    }
  }

  /* Recupera el catálogo de elementos de la empresa */
  async getCatalogoEquipamiento(): Promise<BdtCatalogoEquipamientoModel[]> {
    this.catEquipList = await this.bdtService.getCatalogoEquipamiento();
    if (this.catEquipList) {
      this.sourceEquip.localdata = this.catEquipList;
      this.dataAdapterEquip = new jqx.dataAdapter(this.sourceEquip);
    }
    return this.catEquipList;
  }

  /* Renderiza la imagen del equipamiento */
  imagerenderer(row: number, columnfield: string, value: any, defaulthtml: string, columnproperties: any, rowdata: any): string {
    if (this.elemService && this.elemService.elemGenericos) {
      if (value && value.length > 50) {
        return (
          '<img style="margin-left: 4px; margin-top: 2px;" height="16" width="16" src="data:image/jpg;base64,' +
          value + '"/>'
        );
      }
      return '<img style="margin-left: 4px; margin-top: 2px;" height="16" width="16" src="assets/images/elemento.png"/>';
    } else {
      return (
        '<img style="margin-left: 4px; margin-top: 2px;" height="16" width="16" src="data:image/jpg;base64,' +
        value + '"/>'
      );
    }
  }
  /* FIN GRID EQUIPAMIENTO */

  /* GRID HISTORICO */
  @ViewChild('gridHistorico') gridHistorico: jqxGridComponent;

  public arrayHistorico: HistoricoUbicacion[] = [];
  columnsHistorico;
  sourceHistorico;
  dataAdapterHistorico: any;

  initGridHistorico() {
    this.columnsHistorico =
      [
        {
          text: this.translate('Ver'), width: 40, columnType: 'button',
          cellsrenderer: this.renderButtonVer,
        },
        { text: this.translate('Fecha'), datafield: 'Fecha', width: 140, cellsformat: 'dd/MM/yyyy HH:mm:ss' },
        { text: 'PU', datafield: 'PU', width: 70 },
        { text: this.translate('Estado'), datafield: 'Estado', width: 60 }
      ];

    this.sourceHistorico =
    {
      datatype: 'json',
      datafields: [
        { name: 'Fecha', type: 'date', map: 'fecha', format: 'dd/MM/yyyy HH:mm:ss' },
        { name: 'PUNombre', type: 'string', map: 'puntoUbicacion>nombre' },
        { name: 'PU', type: 'string', map: 'puntoUbicacion>id' },
        { name: 'Estado', type: 'string', map: 'operacionNombre' },
      ],
      id: 'id',
      localdata: this.arrayHistorico,
      sortcolumn: 'Fecha',
      sortdirection: 'desc'
    };

    this.columnsHistorico.forEach(column => {
      column.rendered = (element) => { Utils.tooltiprenderer(element) };
    });

    this.dataAdapterHistorico = new jqx.dataAdapter(this.sourceHistorico);
  }

  renderButtonVer(row, columnfield, value, defaulthtml, columnproperties) {
    return (
    `
      <div class="jqx-grid-cell-center-align" style="margin-top: -2px; margin-left: 4.5px">
        <i class="fa-solid fa-eye" style></i>
      </div>
    `);
  }

  renderPuntoUbucacionCell(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (rowdata.PUNombre) {
      return (
        `
          <div class="jqx-grid-cell-left-align" style="margin-top: 9.5px;">`
        + rowdata.PUNombre +
        `</div>
        `
      );
    } else if (value) {
      return (
        `
          <div class="jqx-grid-cell-left-align" style="margin-top: 9.5px;">
            PU-` + value +
        `</div>
        `
      );
    }
  }

  async getHistoricoUbicaciones() {
    this.arrayHistorico = [];
    this.arrayHistorico = await this.historicoService.getHistoricoUbicacion(this.elemEdit);
    let arrayTemp = [];

    if (this.arrayHistorico) {
      this.arrayHistorico.forEach(hist => {
        if (hist.operacionNombre === "Alta" || hist.operacionNombre === "Baja" || hist.operacionNombre === "Movimiento") {
          arrayTemp.push(hist);
        }
      });

      this.arrayHistorico = arrayTemp;

      this.sourceHistorico.localdata = this.arrayHistorico;
      this.dataAdapterHistorico = new jqx.dataAdapter(this.sourceHistorico);
      this.gridHistorico.updatebounddata();
    }
  }

  cellClick(e) {
    if(e.args.column.text === AppComponent.translate('Ver')) {
      this.centerHistorico(e.args.rowindex)
    }
  }

  /* Centra el mapa en la ubicacion seleccionada y
   mueve el marcador historico a esa ubicacion */
  centerHistorico(e) {
    this.elemEdit.Marker.animate(0);
    try {
      setTimeout(() => {
        const obj = this.elemEdit.Coordenadas[e];
        this.map.setCenter(new MapLatLng(obj.Lat, obj.Lng));
        this.map.setZoom(18);
        this.elemEdit.Marker.setPosition(new MapLatLng(obj.Lat, obj.Lng));
        this.elemEdit.Marker.animate(2850);
        this.form.collapse();
      }, 0);
    }catch (e) {
    }
  }
  /* FIN GRID HISTORICO */

  async getImages() {
    this.elemEdit.Imagenes = await this.elemService.getImagesElement(this.elemEdit);
  }

  addImageDetalle(event: any, tipo: number) {
    this.elemEdit.Imagenes.push(event);
  }

  rmvImageDetalle(event: any, tipo: number) {
    this.elemEdit.Imagenes.splice(this.elemEdit.Imagenes.indexOf(event), 1);
  }
  /* FIN APARTADO IMAGENES */
}
