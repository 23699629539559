import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';


import { Utils } from 'src/app/utils/utils';
import { AppComponent } from 'src/app/app.component';
import { CustomForms } from '../../forms/custom-forms';
import { NumberUtils } from 'src/app/utils/number-utils';
import { MainComponent } from '../../main/main.component';
import { environment } from 'src/environments/environment';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';

import { SsoService } from 'src/app/services/sso/sso.service';
import { ConfigService } from 'src/app/services/config/config.service';
import { ResourcesService } from 'src/app/services/resources/resources.service';

import { MovilEditComponent } from '../moviles/movil-edit/movil-edit.component';
import { DateUtils } from 'src/app/utils/date-utils';
import * as xlsx from 'xlsx';
import { HeaderComponent } from '../../header/header.component';
import { ConjuntoVehiculo } from 'src/app/services/conjuntoVehiculo/models/conjunto-vehiculo.model';
import { ConjuntoVehiculoService } from 'src/app/services/conjuntoVehiculo/conjunto-vehiculo.service';


@Component({
  selector: 'app-moviles-catalog',
  templateUrl: './moviles-catalog.component.html',
  styleUrls: ['./moviles-catalog.component.css']
})
export class MovilesCatalogComponent extends CustomForms implements OnInit {
  @ViewChild('form') form: jqxWindowComponent;
  @ViewChild('grid') grid: jqxGridComponent;
  @ViewChild('formMovil', { read: ViewContainerRef }) editMovilComponent;
  @ViewChild('header') header: HeaderComponent;

  public static _this: MovilesCatalogComponent;
  private componentRef = null;
  public environment = environment;
  public canEdit = true;
  private select: any = [];
  private fromIAConfig = false;
  formMovil: any;
  public movilSelect: ConjuntoVehiculo;
  selectRow: any;

  // Variables grid
  public columngroups;
  public columns: any[] = [];
  public source;
  public dataAdapter;

  protected conjuntoVehiculos: ConjuntoVehiculo[] = [];

  mapWidth: number;
  mapHeight: number;

  intervalScroll: any;

  // Pongo por defecto los textos en los controles del grid en español
  public langGrid = JqWidgets.getLocalization('es');
  showLoader: boolean = true;

  constructor(private ssoService: SsoService,
    private configService: ConfigService,
    private resourcesService: ResourcesService,
    private conjuntoVehiculoService: ConjuntoVehiculoService) {
    super();
    MovilesCatalogComponent._this = this;
  }

  async ngOnInit() {
    this.mapHeight = document.getElementById('map-container').offsetHeight;
    this.mapWidth = document.getElementById('map-container').offsetWidth;
    this.canEdit = true; // TODO: por hacer...
    this.langGrid = JqWidgets.getLocalization(this.ssoService.getTicket().Usuario.Idioma.Codigo.substring(0, 2));
    setTimeout(async () => {
      this.initGrid();
      this.getMoviles();
    }, 1000);
  }

  async ngAfterViewInit(): Promise<void> {
    this.addCustomForm(this.form);

    if (!this.fromIAConfig) {
      this.form.setTitle(AppComponent.translate('Catalogo_flota'));
    } else {
      this.form.setTitle(AppComponent.translate('Catalogo_flota') + ' - ' + AppComponent.translate('IA'));
    }
    // Recupero el filtro guardado
    if (!this.fromIAConfig) {
      this.select = await this.configService.getUsuEmpApp('moviles-visibles', null);
    } else {
      this.select = await this.configService.getEmp('moviles-IA', null);
    }
    if (this.select) {
      this.select = JSON.parse(this.select);
    } else {
      this.select = [];
    }
  }

  // Este método es llamado por el creador del componente
  public init(componentRef: any, fromIAConfig: boolean) {
    this.componentRef = componentRef;
    this.fromIAConfig = fromIAConfig;
  }

  resizeColumns(grid: jqxGridComponent) {
    Utils.renderSizeGrid(grid, 500,
      [
        'acciones', 'unid', 'fabricacionChasis', 'personasChasis',
        'operariosChasis', 'cargaChasis', 'fabricacionCarrozado',
        'anchoMedidas', 'altoMedidas', 'largoMedidas', 'cargaMedidas',
        'volumenMedidas', 'kmCoCombustible', 'precioEconomia',
        'amortizacionEconomia', 'costeEconomia', 'modeloEconomia'
      ]
    );
  }

  // Cierro el formulario y destruyo el componente
  public async onClose() {
    // Destruyo el componente
    if (this.componentRef) {
      this.componentRef.destroy();
    }

    if(this.intervalScroll) {
      clearInterval(this.intervalScroll);
    }

    MovilesCatalogComponent._this = null;
  }

  // Guardo los filtros y destruyo el componente
  public async onGuardar() {
    // Guardo la configuración
    const modelos: any[] = [];
    const rowsSelec = this.grid.getselectedrowindexes();
    if (rowsSelec) {
      rowsSelec.forEach(i => {
        this.conjuntoVehiculos[i].vehiculos.forEach(vehiculo => {
          modelos.push({ id: vehiculo.idLicencia });
        });
        //modelos.push({ id: this.movilesList[i].Codigo });
      });
    }
    // Guardo la variable de configuración con los datos del filtro
    if (!this.fromIAConfig) {
      await this.configService.setUsuEmpApp('moviles-visibles', JSON.stringify(modelos));
    } else {
      await this.configService.setEmp('moviles-IA', JSON.stringify(modelos));
    }
    // Mando actualizar el filtro para que se repinten los móviles
    this.resourcesService.setFilterVisible();
  }

  // Para traducir los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  onBindingComplete() {
    const rows = this.grid.getrows();
    if (rows) {
      rows.forEach(row => {
        if (this.select.find(s => s.id === row.visible) !== undefined) {
          this.grid.selectrow(row.boundindex);
        }
      });
    }
  }

  closeWindow(){
    this.form.close();
  }

  initGrid() {
    this.columngroups = [
      { text: AppComponent.translate('Chasis'), align: 'center', name: 'chasisGroup' },
      { text: AppComponent.translate('Carrozado'), align: 'center', name: 'carrozadoGroup' },
      { text: AppComponent.translate('Pesos_medidas'), align: 'center', name: 'pesosMedidasGroup' },
      { text: AppComponent.translate('Combustibles_gases'), align: 'center', name: 'combustibleGroup' },
      { text: AppComponent.translate('Datos_economicos'), align: 'center', name: 'economiaGroup' },
    ]

    this.columns = [
      { text: '', columntype: 'textbox', filtertype: 'textbox', datafield: 'visible', hidden: true },
      { text: '', columntype: 'textbox', filtertype: 'textbox', datafield: 'selec', hidden: true },
      { text: 'Id', columntype: 'textbox', filtertype: 'textbox', datafield: 'id', hidden: true },
      {
        text: this.translate('Acciones'),
        width: 50,
        columntype: 'text',
        sortable: false,
        editable: false,
        datafield: 'acciones',
        groupable: false,
        menu: false,
        rendered: (columnHeaderElement) => {
          return columnHeaderElement[0];
        },
        createwidget: (
          row: any,
          column: any,
          value: string,
          htmlElement: HTMLElement
        ): void => {
          this.initBtnColumn(row, column, value, htmlElement);
        },
        initwidget: (
          row: any,
          column: any,
          value: string,
          htmlElement: HTMLElement
        ) => {
          this.initBtnColumn(row, column, value, htmlElement);
        }
      },
      { text: AppComponent.translate('Unidades'), columntype: 'textbox', filtertype: 'textbox', datafield: 'unid', cellsrenderer: this.numberrenderer, width: 40 },
      { text: '', columntype: 'image', datafield: 'icono', cellsrenderer: this.imagerenderer },
      {
        text: AppComponent.translate('Recurso'), columntype: 'textbox', filtertype: 'checkedlist', datafield: 'recurso',
        aggregates: [{
          'Total': function (aggregatedValue, currentValue: number) {
            return aggregatedValue + 1;
          },
        }],
        aggregatesrenderer: function (aggregates) {
          let renderstring = '';
          if (aggregates["Total"] !== undefined) {
            renderstring = '<div class="cellTotal" style="margin-left: 4px; position:fixed;">' + aggregates["Total"] + '</div>';
          }
          return renderstring;
        },
      },
      { text: AppComponent.translate('Clase'), columntype: 'textbox', filtertype: 'checkedlist', datafield: 'clase' },
      { text: AppComponent.translate('Marca'), columntype: 'textbox', filtertype: 'checkedlist', datafield: 'marcaChasis', columngroup: 'chasisGroup' },
      { text: AppComponent.translate('Modelo'), columntype: 'textbox', filtertype: 'textbox', datafield: 'modeloChasis', columngroup: 'chasisGroup' },
      { text: AppComponent.translate('Año_fabricacion'), columntype: 'textbox', filtertype: 'textbox', datafield: 'fabricacionChasis', columngroup: 'chasisGroup', cellsrenderer: this.numberrenderer, width: 60 },
      { text: AppComponent.translate('Num_personas'), columntype: 'textbox', filtertype: 'textbox', datafield: 'personasChasis', columngroup: 'chasisGroup', cellsrenderer: this.numberrenderer, width: 60 },
      { text: AppComponent.translate('Num_operarios'), columntype: 'textbox', filtertype: 'textbox', datafield: 'operariosChasis', columngroup: 'chasisGroup', cellsrenderer: this.numberrenderer, width: 60 },
      { text: AppComponent.translate('Carga_util'), columntype: 'textbox', filtertype: 'textbox', datafield: 'cargaChasis', columngroup: 'chasisGroup', cellsrenderer: this.numberrenderer, width: 60 },
      { text: AppComponent.translate('Tipo_canbus'), columntype: 'textbox', filtertype: 'checkedlist', datafield: 'canbusChasis', columngroup: 'chasisGroup' },

      { text: AppComponent.translate('Marca'), columntype: 'textbox', filtertype: 'checkedlist', datafield: 'marcaCarrozado', columngroup: 'carrozadoGroup' },
      { text: AppComponent.translate('Modelo'), columntype: 'textbox', filtertype: 'textbox', datafield: 'modeloCarrozado', columngroup: 'carrozadoGroup' },
      { text: AppComponent.translate('Tipo_de_residuo'), columntype: 'textbox', filtertype: 'checkedlist', datafield: 'residuoCarrozado', columngroup: 'carrozadoGroup' },
      { text: AppComponent.translate('Tipo_canbus'), columntype: 'textbox', filtertype: 'checkedlist', datafield: 'canbusCarrozado', columngroup: 'carrozadoGroup' },
      { text: AppComponent.translate('Año_fabricacion'), columntype: 'textbox', filtertype: 'textbox', datafield: 'fabricacionCarrozado', columngroup: 'carrozadoGroup', cellsrenderer: this.numberrenderer, width: 60 },

      { text: AppComponent.translate('Ancho'), columntype: 'textbox', filtertype: 'textbox', datafield: 'anchoMedidas', columngroup: 'pesosMedidasGroup', cellsrenderer: this.numberrenderer, width: 60 },
      { text: AppComponent.translate('Alto'), columntype: 'textbox', filtertype: 'textbox', datafield: 'altoMedidas', columngroup: 'pesosMedidasGroup', cellsrenderer: this.numberrenderer, width: 60 },
      { text: AppComponent.translate('Largo'), columntype: 'textbox', filtertype: 'textbox', datafield: 'largoMedidas', columngroup: 'pesosMedidasGroup', cellsrenderer: this.numberrenderer, width: 60 },
      { text: AppComponent.translate('Carga_maxima_neta'), columntype: 'textbox', filtertype: 'textbox', datafield: 'cargaMedidas', columngroup: 'pesosMedidasGroup', cellsrenderer: this.numberrenderer, width: 60 },
      { text: AppComponent.translate('Volumen'), columntype: 'textbox', filtertype: 'textbox', datafield: 'volumenMedidas', columngroup: 'pesosMedidasGroup', cellsrenderer: this.numberrenderer, width: 60 },

      { text: AppComponent.translate('Combustible'), columntype: 'textbox', filtertype: 'checkedlist', datafield: 'combustibleCombustible', columngroup: 'combustibleGroup' },
      { text: 'Km/Co²', columntype: 'textbox', filtertype: 'textbox', datafield: 'kmCoCombustible', columngroup: 'combustibleGroup', cellsrenderer: this.numberrenderer, width: 60 },

      { text: AppComponent.translate('Precio_compra'), columntype: 'textbox', filtertype: 'textbox', datafield: 'precioEconomia', columngroup: 'economiaGroup', cellsrenderer: this.numberrenderer, width: 60 },
      { text: AppComponent.translate('Años_amortizacion'), columntype: 'textbox', filtertype: 'textbox', datafield: 'amortizacionEconomia', columngroup: 'economiaGroup', cellsrenderer: this.numberrenderer, width: 60 },
      { text: AppComponent.translate('Coste_euro_km'), columntype: 'textbox', filtertype: 'textbox', datafield: 'costeEconomia', columngroup: 'economiaGroup', cellsrenderer: this.numberrenderer, width: 60 },
      { text: AppComponent.translate('Año_modelo'), columntype: 'textbox', filtertype: 'textbox', datafield: 'modeloEconomia', columngroup: 'economiaGroup', cellsrenderer: this.numberrenderer, width: 60 },
    ];

    this.source = {
      datatype: 'json',
      datafields: [
        { name: 'visible', map: 'visible' },
        { name: 'selec', map: 'selec' },

        { name: 'id', type: 'number', map: 'id' },
        { name: 'acciones', type: 'string' },
        { name: 'icono', type: 'image', map: 'icono' },
        { name: 'unid', type: 'number', map: 'vehiculos>length' },
        { name: 'clase', map: 'clase>nombre' },
        { name: 'recurso', map: 'recurso>nombre' },

        { name: 'marcaChasis', map: 'chasis>modelo>marca>nombre' },
        { name: 'modeloChasis', map: 'chasis>modelo>nombre' },
        { name: 'fabricacionChasis', map: 'chasis>anyoFabricacion' },
        { name: 'personasChasis', map: 'chasis>numPersonas' },
        { name: 'operariosChasis', map: 'chasis>numOperarios' },
        { name: 'cargaChasis', map: 'chasis>carga_maxima' },
        { name: 'canbusChasis', map: 'chasis>canBusMotor>tipoCombo' },

        { name: 'marcaCarrozado', map: 'carrozado>modelo>marca>nombre' },
        { name: 'modeloCarrozado', map: 'carrozado>modelo>nombre' },
        { name: 'residuoCarrozado', map: 'carrozado>residuo>nombre' },
        { name: 'canbusCarrozado', map: 'carrozado>chasisCarrozado>tipoCombo' },
        { name: 'fabricacionCarrozado', map: 'carrozado>anyoFabricacion' },

        { name: 'anchoMedidas', map: 'ancho' },
        { name: 'altoMedidas', map: 'alto' },
        { name: 'largoMedidas', map: 'largo' },
        { name: 'cargaMedidas', map: 'carga_maxima_neta' },
        { name: 'volumenMedidas', map: 'volumen' },

        { name: 'combustibleCombustible', map: 'chasis>combustible>nombre' },
        { name: 'kmCoCombustible', map: 'kmco2' },

        { name: 'precioEconomia', map: 'precio_compra' },
        { name: 'amortizacionEconomia', map: 'anyo_amortizacion' },
        { name: 'costeEconomia', map: 'coste' },
        { name: 'modeloEconomia', map: 'anyoMatriculacion' },
      ],
      localdata: this.conjuntoVehiculos,
      sortcolumn: 'nombre',
      sortdirection: 'asc'
    };

    this.columns.forEach(column => {
      column.rendered = (element) => { Utils.tooltiprenderer(element) };
    });

    this.dataAdapter = new jqx.dataAdapter(this.source);
    this.resizeColumns(this.grid);
  }

  updatefilterconditions = (type: string, defaultconditions: any): string[] => {
    return Utils.updatefilterconditions(type, defaultconditions);
  };

  public filter(cellValue?: any, rowData?: any, dataField?: string, filterGroup?: any, defaultFilterResult?: boolean): any {
    let filterColumns = [
      'modeloChasis',
      'modeloCarrozado',
    ]

    return Utils.filterRow(cellValue, dataField, filterGroup, defaultFilterResult, filterColumns);
  }

  // Incializa la columna de botones
  async initBtnColumn(
    row: any,
    column: any,
    value: string,
    htmlElement: HTMLElement
  ) {
    htmlElement.innerHTML = '';
    // Crea un contenedor para los botones
    const btnContainer = document.createElement('div');
    btnContainer.style.display = 'flex';
    btnContainer.style.justifyContent = 'space-around';
    btnContainer.style.gap = '2px';
    btnContainer.style.padding = '2px';

    let rowdata;
    if (isNaN(row)) {
      rowdata = row.bounddata;
    } else {
      rowdata = this.grid.getrowdata(row);
    }

    let conjuntoVehiculo = this.conjuntoVehiculos.find(conjVeh => conjVeh.id == rowdata.id);

    const btnEdit = document.createElement('div');
    btnEdit.innerHTML = `
        <button class="button" style="height: 23px; cursor: pointer;" title="`+ AppComponent.translate('Detalle') + `">
          <i class="fa-regular fa-eye"></i>
        </button>
      `;
    btnEdit.id = `buttonEdit_jqxButton`;
    btnContainer.appendChild(btnEdit);

    btnEdit.addEventListener('click', async (event: any) => {
      this.form.collapse();

      this.formMovil = this.editMovilComponent.createComponent(MovilEditComponent);
      this.formMovil.instance.init(this.formMovil, conjuntoVehiculo);
    });

    htmlElement.appendChild(btnContainer);
  }

  // Recupera los móviles de la empresa
  async getMoviles() {
    this.conjuntoVehiculos = await this.conjuntoVehiculoService.getConjuntosVehiculo();

    if (this.conjuntoVehiculos) {
      this.conjuntoVehiculos.forEach(conjVeh => {
        if (conjVeh.clases && conjVeh.clases.length != 0) {
          conjVeh.clase = conjVeh.clases[0];
        }

        if (conjVeh.carrozados && conjVeh.carrozados.length != 0) {
          conjVeh.carrozado = conjVeh.carrozados[0];
        }

        if (conjVeh.vehiculos && conjVeh.vehiculos.length != 0) {
          conjVeh['visible'] = conjVeh.vehiculos[0].idLicencia;
        }
      });

      this.source.localdata = this.conjuntoVehiculos;
      this.grid.updatebounddata();

      this.onBindingComplete();
      this.showLoader = false;
      this.resizeColumns(this.grid);
    }
  }

  imagerenderer(row: number, columnfield: string, value: any,
    defaulthtml: string, columnproperties: any, rowdata: any): string {
    return value ? '<img style="margin-left: 4px; margin-top: 6.5px;" height="16" width="16" src="data:image/jpg;base64,' + value + '"/>' :
      '<img style="margin-left: 4px; margin-top: 6.5px;" height="16" width="16" src="assets/images/car.png" />';
  }

  numberrenderer(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (value) {
      return (
        '<div class="jqx-grid-cell-right-align" style="margin-top: 6.5px">' +
        NumberUtils.format(value, 0) +
        '</div>'
      );
    } else if (value === 0) {
      return (
        '<div class="jqx-grid-cell-right-align" style="margin-top: 6.5px">' +
        NumberUtils.format(value, 2) +
        '</div>'
      );
    }
  }

  numberrendererDecimales(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (value) {
      return (
        '<div style="margin-right: 4px; margin-top: 5px; text-align: right;">' +
        NumberUtils.format(value, 2) +
        '</div>'
      );
    } else if (value === 0) {
      return (
        '<div style="margin-right: 4px; margin-top: 5px; text-align: right;">' +
        NumberUtils.format(value, 2) +
        '</div>'
      );
    }
  }

  public onEditar() {
    if (this.movilSelect) {
      this.form.collapse();

      this.formMovil = this.editMovilComponent.createComponent(MovilEditComponent);
      this.formMovil.instance.init(this.formMovil, this.movilSelect);
    }
  }

  public onRowDoubleClick(event: any) {
    if (this.selectRow) {
      this.selectRow.cells.forEach(cell => {
        cell.classList.remove('selectedCell');
      });
    }

    this.movilSelect = this.conjuntoVehiculos[event.args.rowindex];
    this.selectRow = event.args.row.owner.that.mousecaptureposition.clickedrow;
    this.selectRow.cells.forEach(cell => {
      cell.classList.add('selectedCell');
    });

    this.scrollController();

    setTimeout(() => {
      this.onEditar();
    }, 200);
  }

  scrollController() {
    let scrollTop = this.grid.scrollposition().top;
    if (this.intervalScroll) {
      clearInterval(this.intervalScroll);
    }

    /*
      En caso de que se tenga un row elegido se comprueba se inicializa
      un intervalo para comprobar si hay modificacion en el scroll
      en caso de que haya modificacion se deselecciona el movil
    */
    if (this.selectRow) {
      this.intervalScroll = setInterval(() => {
        if (scrollTop !== this.grid.scrollposition().top) {
          if (this.selectRow) {
            this.selectRow.cells.forEach(cell => {
              cell.classList.remove('selectedCell');
            });
            this.selectRow = null;
            this.movilSelect = null;
          }
        }
      }, 500);
    }
  }

  onExportar() {
    if (this.grid.getrows().length === 0) {
      return MainComponent.getInstance().showWarning('ATENCION', this.translate('No_existen_datos'), 2000);
    } else {
      this.grid.hidecolumn('imagen');
      const json = this.grid.exportdata('json');
      let datos = JSON.parse(json);
      let datosRow = this.grid.getrows();
      datos.forEach((element, index) => {
        element[this.translate('Subflota')] = datosRow.map((item) => item.subflota)[index];
      });
      const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(datos);
      this.generateAutofilterHeader(ws);
      const wb: xlsx.WorkBook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(wb, ws, 'Hoja1');
      xlsx.writeFile(wb, DateUtils.formatDateAMDhms(new Date()) + '_' + this.translate('Vehiculos') + '.xlsx');
      this.grid.showcolumn('imagen');
    }
  }

  eventFilter() {
    this.header.searchInput['nativeElement'].value = '';
    this.getMoviles();
  }

  eventResetFilter() {
    this.header.searchInput['nativeElement'].value = '';
    this.onBuscar();
  }

  onBuscar() {
    let filterValue = '';

    if (this.header.searchInput['nativeElement'].value.length >= 3) {
      filterValue = this.header.searchInput['nativeElement'].value.toUpperCase();
    } else {
      filterValue = '';
    }

    if (this.conjuntoVehiculos) {
      this.conjuntoVehiculos.forEach(mov => {
        if (
          mov.clase?.nombre?.compareWith(filterValue) ||
          mov.recurso?.nombre?.compareWith(filterValue) ||
          mov.chasis?.modelo?.marca?.nombre?.compareWith(filterValue) ||
          mov.chasis?.modelo?.nombre?.compareWith(filterValue) ||
          (mov.chasis?.anyoFabricacion + '').compareWith(filterValue) ||
          (mov.chasis?.numPersonas + '').compareWith(filterValue) ||
          (mov.chasis?.numOperarios + '').compareWith(filterValue) ||
          (mov.chasis?.carga_maxima + '').compareWith(filterValue) ||
          (mov.chasis?.canBusMotor + '').compareWith(filterValue) ||
          mov.carrozado?.modelo?.marca?.nombre?.compareWith(filterValue) ||
          mov.carrozado?.modelo?.nombre?.compareWith(filterValue) ||
          mov.carrozado?.residuo?.nombre?.compareWith(filterValue) ||
          (mov.carrozado?.chasisCarrozado + '').compareWith(filterValue) ||
          (mov.carrozado?.anyoFabricacion + '').compareWith(filterValue) ||
          (mov.ancho + '').compareWith(filterValue) ||
          (mov.alto + '').compareWith(filterValue) ||
          (mov.largo + '').compareWith(filterValue) ||
          (mov.carga_maxima_neta + '').compareWith(filterValue) ||
          (mov.volumen + '').compareWith(filterValue) ||
          mov.chasis?.combustible?.nombre?.compareWith(filterValue) ||
          (mov.kmco2 + '').compareWith(filterValue) ||
          (mov.precio_compra + '').compareWith(filterValue) ||
          (mov.anyo_amortizacion + '').compareWith(filterValue) ||
          (mov.coste + '').compareWith(filterValue) ||
          (mov.anyoMatriculacion + '').compareWith(filterValue)
        ) {
          mov['selec'] = 'selec';
        } else {
          mov['selec'] = '';
        }
      });
    }

    // Compruebo si ya he creado el filtro "selec" anteriormente
    const filters = this.grid.getfilterinformation();
    if (filters.find(s => s.datafield === 'selec') === undefined) {
      const filtergroup = new jqx.filter();
      filtergroup.operator = 'and';
      filtergroup.addfilter(0, filtergroup.createfilter('stringfilter', 'selec', 'equal'));
      this.grid.addfilter('selec', filtergroup);
    }
    this.grid.applyfilters();
    this.grid.updatebounddata('data');

    this.resizeColumns(this.grid);
  }

  generateAutofilterHeader(sheet) {
    // Añade filtro a todas las casillas.
    sheet['!autofilter'] = { ref: sheet['!ref'] };
  }

  // Boton para imprimir
  onPrint() {
    if (this.grid.getrows().length === 0) {
      return MainComponent.getInstance().showWarning('ATENCION', this.translate('No_existen_datos'), 2000);
    } else {
      this.grid.hidecolumn('imagen');
      let gridContent = this.grid.exportdata('html');
      let newWindow = window.open('', '', 'width=800, height=500'),
        document = newWindow.document.open(),
        pageContent =
          '<!DOCTYPE html>\n' +
          '<html>\n' +
          '<head>\n' +
          '<meta charset="utf-8" />\n' +
          '<title>jQWidgets Grid</title>\n' +
          '</head>\n' +
          '<body>\n' +
          gridContent +
          '\n</body>\n</html>';
      this.grid.showcolumn('imagen');
      document.write(pageContent);
      document.close();
      newWindow.onafterprint = function () {
        newWindow.close();
      };
      newWindow.print();
    }
  }
}
