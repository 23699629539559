import { Component, OnInit, ViewChild } from '@angular/core';

import { GeoUtils } from 'src/app/utils/geo-utils';
import { AppComponent } from 'src/app/app.component';
import { CustomForms } from '../../forms/custom-forms';
import { MainComponent } from '../../main/main.component';
import { environment } from 'src/environments/environment';

import { MapBounds, MapComponent, MapLatLng, MapPolygon } from 'movisat-maps';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';

import { SsoService } from 'src/app/services/sso/sso.service';
import { ZonesService } from 'src/app/services/zones/zones.service';
import { ConfigService } from 'src/app/services/config/config.service';
import { AuditoriaService } from 'src/app/services/auditoria/auditoria.service';

import { ZonaModel } from 'src/app/services/zones/models/zona.model';
import { Accion } from 'src/app/services/auditoria/models/accion.model';
import { AuditoriaModel } from 'src/app/services/auditoria/models/auditoria.model';
import { AmbitoActividadModel } from 'src/app/services/geographics/ambito-actividad.model';
import { Utils } from 'src/app/utils/utils';
import { MenuComponent } from '../../main/menu/menu.component';

@Component({
  selector: 'app-ambito',
  templateUrl: './ambito.component.html',
  styleUrls: ['./ambito.component.css']
})
export class AmbitoComponent extends CustomForms implements OnInit {
  @ViewChild('form') form: jqxWindowComponent;
  @ViewChild('grid') grid: jqxGridComponent;

  public static _this: AmbitoComponent;

  private componentRef = null;
  public theme = environment.tema;
  private zonas: ZonaModel[] = [];
  public selectedAreas = [];
  public marcoAmbitoPolygon: MapPolygon;
  public visible = false;
  private map: MapComponent;
  public arrPolygonsGeojson = [];
  public arrCircles = [];
  public mapBounds;
  // Variables para guardar el ámbito de actividad
  public ambitoActividad: AmbitoActividadModel;
  // Variables para el grid
  public dataSource: any = [];
  public dataAdapter = new jqx.dataAdapter(this.dataSource);
  public columns = [
    { text: 'Id', columntype: 'textbox', filtertype: 'textbox', datafield: 'id', width: 50, hidden: true },
    { text: AppComponent.translate('Nombre'), columntype: 'textbox', filtertype: 'textbox', datafield: 'nombre', width: 140, },
    { text: AppComponent.translate('Tipo'), columntype: 'textbox', filtertype: 'checkedlist', datafield: 'tipo', width: 60, },
    { text: 'select', datafield: 'select', width: 1, hidden: true }
  ];

  // Pongo por defecto los textos en los controles del grid en español
  public langGrid = JqWidgets.getLocalization('es');
  private usuario = this.ssoService.getTicket().Usuario.Email;
  private auditoria: AuditoriaModel = new AuditoriaModel(this.usuario, 0);
  visibleMarco: any;

  constructor(private zonesService: ZonesService, private configService: ConfigService, private auditoriaService: AuditoriaService, private ssoService: SsoService) {
    super();
    AmbitoComponent._this = this;
  }

  ngOnInit(): void {
  }

  //recuperar datos del ambito de actividad
  async loadAmbito() {
    MainComponent.getInstance().hideAmbito(false);
    const res = await this.configService.getEmpApp('ambito-actividad', 'default');
    this.visible = await this.configService.getEmpApp('ver-ambito-actividad', 'false') === 'true';
    const ambito: AmbitoActividadModel = JSON.parse(res);
    this.ambitoActividad = ambito;
    //filtrar filas que coinciden con los datos almacenados
    const result = this.grid.getrows().filter(e => {
      return ambito.zonas.indexOf(e.id) != -1;
    });
    //seleccionar filas
    result.forEach(row => {
      this.grid.selectedrowindex(row.uid)
      this.grid.setcellvalue(row.uid, 'select', true);
    })

    this.grid.sortby('select', 'asc');
    this.grid.sortby('nombre', 'asc');
  }

  // Obtengo las zonas de la empresa
  async getZones() {
    this.zonas = await this.zonesService.getZonas();
    if (this.zonas) {
      this.dataSource = {
        datatype: 'json',
        datafields: [
          { name: 'id', type: 'int', map: 'Id' },
          { name: 'nombre', type: 'string', map: 'Nombre' },
          { name: 'tipo', type: 'string', map: 'TipoNombre' },
          { name: 'select', type: 'bool', map: 'select' }
        ],
        localdata: this.zonas,
      }
      this.dataAdapter = new jqx.dataAdapter(this.dataSource);
      //Utils.renderSizeGrid(this.grid, 500);
    } else {
      this.zonas = [];
    }
  }

  updatefilterconditions = (type: string, defaultconditions: any): string[] => {
    return Utils.updatefilterconditions(type, defaultconditions);
  };

  public filter(cellValue?: any, rowData?: any, dataField?: string, filterGroup?: any, defaultFilterResult?: boolean): any {
    let filterColumns = [
      'nombre',
    ]

    return Utils.filterRow(cellValue, dataField, filterGroup, defaultFilterResult, filterColumns);
  }

  // Inicialización del componente
  init(componentRef: any) {
    this.componentRef = componentRef;
  }

  async ngAfterViewInit() {
    this.map = MainComponent.getActiveMap();
    this.addCustomForm(this.form);
    // cambio el título de la ventana
    this.form.setTitle(AppComponent.translate('Ambito_actividad'));
    // Posiciono el formulario
    const mapContainer = document.getElementById('center-container').getClientRects();
    this.form.position({
      x: mapContainer[0].left + 2,
      y: mapContainer[0].top + 60
    });
    await this.getZones();
    this.loadAmbito();
  }

  //cuando se cierra la ventana
  public onClose() {
    if (this.arrCircles.length > 0 || this.arrPolygonsGeojson.length > 0) {
      this.deletePolygons();
    }
    if (this.componentRef) {
      this.componentRef.destroy();
    }
    if (this.marcoAmbitoPolygon) {
      this.deleteMarcoAmbito();
    }
    MainComponent.getInstance().showAmbito();
    AmbitoComponent._this = null;
  }

  // Para traducir los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  onSelect(event) {
    this.selectedAreas = [];
    let rowSel = this.grid.getselectedrowindexes();
    if (rowSel.length > 0) {
      rowSel.forEach(idx => {
        this.selectedAreas.push(this.zonas[idx])
      })
      //se dibujan los polígonos seleccionados
      this.drawPolygon(this.selectedAreas);
      //se centra el mapa en los polígonos seleccionados
      this.centeredPolygons(this.selectedAreas);
      this.map.fitTo(this.mapBounds);
      //se dibuja el polígono del marco
      this.showMarcoAmbito();
    } else {
      this.deleteMarcoAmbito();
      this.deletePolygons();
    }
  }

  //se activa cuando se hace check en Ver
  onChangeVisible(event) {
    this.visible = event.currentTarget.checked;
    if (this.selectedAreas.length > 0) {
      this.drawPolygon(this.selectedAreas);
      this.centeredPolygons(this.selectedAreas);
      //funcion que centra el mapa a un area
      this.map.fitTo(this.mapBounds);
      this.showMarcoAmbito();
    } else {
      this.deleteMarcoAmbito();
      this.deletePolygons();
    }
  }

  onChangeMarco(event) {
    this.visibleMarco = event.currentTarget.checked;
    if (this.visibleMarco) {
      MainComponent.getInstance().showMarcoGeografico(true);
    } else {
      MainComponent.getInstance().hideMarcoCartografico(true);
    }
  }

  drawPolygon(zonas: ZonaModel[]) {
    this.deletePolygons();
    zonas.forEach(zona => {
      if (zona.Geometria != null) {
        let polygonGeoJson = this.map.addPolygonsFromGeoJson(zona.Geometria, false, {
          dataModel: zona,
          content: zona.Nombre,
          strokeColor: '#227481',
          strokeOpacity: 0.3,
          strokeWeight: 1,
          fillColor: '#F3FF00',
          fillOpacity: 0.3,
          zIndex: 100,
        })[0];
        this.arrPolygonsGeojson.push(polygonGeoJson);
      } else {
        let circle = this.map.addCircle({
          dataModel: zona,
          content: zona.Nombre,
          strokeColor: '#227481',
          strokeOpacity: 0.3,
          strokeWeight: 1,
          fillColor: '#F3FF00',
          fillOpacity: 0.1,
          position: new MapLatLng(zona.Lat, zona.Lng),
          radius: zona.Radio,
          draggable: false,
          editable: false
        });
        this.arrCircles.push(circle);
      }
    });
  }

  //borrar polígonos
  deletePolygons() {
    this.arrPolygonsGeojson.forEach(polygon => {
      this.map.removePolygon(polygon)
    });
    this.arrCircles.forEach(circle => {
      this.map.removeCircle(circle);
    })
    this.arrCircles = [];
    this.arrPolygonsGeojson = [];
  }

  centeredPolygons(zonas: ZonaModel[]) {
    let arrLat = [];
    let arrLng = [];
    zonas.forEach(zona => {
      if (zona.Geometria != null) {
        let arrayCoordenadas = zona.Geometria.geometry.coordinates;
        arrayCoordenadas.forEach(polygon => {
          polygon[0].forEach(coord => {
            arrLat.push(coord[1]);
            arrLng.push(coord[0]);
          })
        });
      } else {
        // En el caso de los círculos  hay que tener en cuenta el cuadro que lo contiene
        let newPos = GeoUtils.getNewLatLng(new MapLatLng(zona.Lat, zona.Lng), -zona.Radio, -zona.Radio);
        arrLat.push(newPos.lat);
        arrLng.push(newPos.lng);
        newPos = GeoUtils.getNewLatLng(new MapLatLng(zona.Lat, zona.Lng), zona.Radio, zona.Radio);
        arrLat.push(newPos.lat);
        arrLng.push(newPos.lng);
      }
    });
    //calcular máximos y minimos para centar ppolígono en el mapa
    let arrLatMax = Math.max(...arrLat);
    let arrLatMin = Math.min(...arrLat);
    let arrLngmax = Math.max(...arrLng);
    let arrLngMin = Math.min(...arrLng);
    let coordMax = new MapLatLng(arrLatMax, arrLngmax);
    let coordMin = new MapLatLng(arrLatMin, arrLngMin);
    let mapBounds = new MapBounds(coordMin, coordMax);
    this.mapBounds = mapBounds;
  }

  async onGuardar(event) {
    let marco = MainComponent.getInstance().marcoGeografico.marco
    // compruebo que el ambito de actividad no se salga del marco geográfico
    if (this.mapBounds.swCorner.lat < marco.swCorner.lat || this.mapBounds.swCorner.lng < marco.swCorner.lng ||
      this.mapBounds.neCorner.lat > marco.neCorner.lat || this.mapBounds.neCorner.lng > marco.neCorner.lng) {
      return MainComponent.showWarning('ATENCION', 'Error_ambito', 2000);
    } else {
      await this.saveModel();
      this.deleteMarcoAmbito();
      this.deletePolygons();
      this.form.close();
      MenuComponent.getInstance().loadMenu();
      window.location.reload();
      this.auditoria.AccionId = Accion.CONFIGURACION_AMBITO_DE_ACTIVIDAD;
      this.auditoriaService.addAuditoria(this.auditoria);
    }
  }

  async saveModel() {
    const arrIds = [];
    if (this.selectedAreas.length > 0) {
      this.selectedAreas.forEach(area => {
        arrIds.push(area.Id);
      })
    } else {
      // Si no hay zonas no hay marco
      this.mapBounds = new MapBounds(new MapLatLng(0, 0), new MapLatLng(0, 0));
    }
    const ambito = new AmbitoActividadModel(this.mapBounds, arrIds);
    await this.configService.setEmpApp('ambito-actividad', JSON.stringify(ambito));
    await this.configService.setEmpApp('ver-ambito-actividad', this.visible.toString());
  }

  //dibujar polígono(rectangulo) que contiene el ambito de actividad
  public showMarcoAmbito() {
    if (this.marcoAmbitoPolygon) {
      this.map.removePolygon(this.marcoAmbitoPolygon);
      this.marcoAmbitoPolygon = null;
    }
    this.marcoAmbitoPolygon = this.map.addPolygon({
      strokeColor: '#2500fb',
      strokeOpacity: 0.3,
      strokeWeight: 2,
      fillColor: '#000000',
      fillOpacity: 0
    });
    this.map.addPolygonPoint(this.marcoAmbitoPolygon, {
      dataModel: this,
      content: this.translate('Ambito_actividad'),
      position: this.mapBounds['swCorner']
    });
    this.map.addPolygonPoint(this.marcoAmbitoPolygon, {
      dataModel: this,
      content: this.translate('Ambito_actividad'),
      position: new MapLatLng(this.mapBounds.neCorner.lat, this.mapBounds.swCorner.lng)
    });
    this.map.addPolygonPoint(this.marcoAmbitoPolygon, {
      dataModel: this,
      content: this.translate('Ambito_actividad'),
      position: this.mapBounds.neCorner
    });
    this.map.addPolygonPoint(this.marcoAmbitoPolygon, {
      dataModel: this,
      content: this.translate('Ambito_actividad'),
      position: new MapLatLng(this.mapBounds.swCorner.lat, this.mapBounds.neCorner.lng)
    });
  }

  public deleteMarcoAmbito() {
    if (this.marcoAmbitoPolygon) {
      this.map.removePolygon(this.marcoAmbitoPolygon);
      this.marcoAmbitoPolygon = null;
    }
  }
}
