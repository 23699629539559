import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { PuComponent } from './pu.component';
import { PuEditComponent } from './pu-edit/pu-edit.component';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [
    PuComponent,
    PuEditComponent,
  ],
  imports: [
    FormsModule,
    CommonModule,
    BrowserModule,
    SharedModule
  ],
  exports: [
    PuComponent
  ]
})
export class PuModule { }
