<div style="display: flex; gap: 2px; height: calc(100% - 55px);">
  <div style="width: calc(40%);">
    <fieldset>
      <legend>{{ translate("Gestion") }}</legend>
      <div style="gap: 2px;display: flex; flex-direction: column;">
        <div>
          {{ translate("Marca_fabricante") }}
        </div>
        <jqxInput [width]="300" [height]="25" [disabled]="true">
        </jqxInput>
        <div>
          {{ translate("Modelo_fabricante") }}
        </div>
        <jqxInput [value]="elemEdit?.cerradura?.tag" [width]="300" [height]="25" [disabled]="true">
        </jqxInput>
        <div>
          {{ translate("Estado") }}
        </div>
        <jqxInput [value]="elemEdit?.cerradura?.estado" [width]="220" [height]="25" [disabled]="true">
        </jqxInput>
      </div>
      <div style="display: flex; gap: 4px; margin-top: 2px; justify-content: space-between;">
        <div>
          <div>
            {{ translate("Peso_maximo") }} (Kg)
          </div>
          <app-input-number [height] [width]="145" [render]="renderer()" [decimal]="2"
            [disabled]="true"></app-input-number>
        </div>
        <div>
          <div>
            {{ translate("Frecuencia_procesado") }} ({{ translate("Dias")}})
          </div>
          <app-input-number [height] [width]="180" [render]="renderer()" [decimal]="0"
            [disabled]="true"></app-input-number>
        </div>
      </div>
      <div style="margin-top: 2px;">
        {{ translate("Operaciones_mas_recientes") }}
      </div>
      <div style="width: 100%; height: calc(100% - 210px); margin-bottom: 5px;">
        <jqxGrid #gridOperacionesRecientes [theme]="environment.tema" [width]="'100%'" [height]="'100%'" [appCellClick]
          [autoheight]="false" [source]="dataAdapterOperRecientes" [columnsresize]="true" [selectionmode]="'singlerow'"
          [columns]="columOperRecientes" [sortable]="true" [altrows]="true" [showrowlines]="true"
          [enablebrowserselection]="true" [rowsheight]="25" [columnsheight]="20" [enabletooltips]="true"
          [localization]="langGrid">
        </jqxGrid>
      </div>
    </fieldset>
  </div>

  <div style="margin-left: 5px; width: calc(60% - 10px);">
    <fieldset>
      <legend>{{ translate("Historico_de_incidencias") }}</legend>
      <div>
        <app-periodo-select #periodoSelect [noTrimestre]="true" [noSemestre]="true" [noLibre]="true"
          [height]="24"></app-periodo-select>
        <div>
          <jqxButton style="float: left; margin-left: 6px" [imgPosition]="'left'"
            [textImageRelation]="'imageBeforeText'" [imgSrc]='"/assets/images/search.png"' [width]="100" [height]="26"
            [textPosition]="'left'" [value]="translate('Buscar')" (onClick)="onSearch()">
          </jqxButton>
        </div>
      </div>
      <jqxGrid #gridHistIncidencias [theme]="environment.tema" [width]="'100%'" [height]="'calc(100% - 60px)'"
        [appCellClick] [autoheight]="false" [source]="dataAdapterHistIncidencias" [columnsresize]="true"
        [selectionmode]="'singlerow'" [enablebrowserselection]="true" [columns]="columHistIncidencias" [sortable]="true"
        [altrows]="true" [showrowlines]="true" [rowsheight]="25" [columnsheight]="20" [enabletooltips]="true"
        [localization]="langGrid">
      </jqxGrid>
    </fieldset>
  </div>
</div>