<div #puContainer id="puGrid" style="overflow: hidden; height: 100%;">
    <jqxGrid #gridPU [theme]="environment.tema" [height]="'100%'" [width]="'100%'" [source]="dataAdapter" [appCellClick]
        [columnsresize]="true" [sortable]="true" [selectionmode]="'checkbox'" [showrowlines]="false"
        [rendertoolbar]="createToolBar" [showcolumnlines]="true" [showtoolbar]="true" [columns]="columns"
        [altrows]="true" [sortmode]="'many'" [enabletooltips]="false" [toolbarheight]="30" [filterable]="true"
        [filtermode]="'default'" [showaggregates]="true" [showstatusbar]="true" [statusbarheight]="20"
        [autoshowfiltericon]="true" [filterrowheight]="20" [rowsheight]="25" [columnsheight]="20"
        [enablebrowserselection]="true" [localization]="langGrid" [filter]="filter"
        [updatefilterconditions]="updatefilterconditions" (onRowclick)="onRowClick($event)"
        (onBindingcomplete)="onBindingComplete()" (onFilter)="onChangeFilter($event)"
        (onRowdoubleclick)="onRowdoubleclick($event)">
    </jqxGrid>
</div>
