import { MovilModel } from "../../resources/models/movil.model";

export class SubflotaModel {
    Id: number;
    EmpresaId: number;
    Nombre: string;
    Observaciones: string;
    MovilesId: number[];
    Moviles: MovilModel[];

    constructor() { }
}
