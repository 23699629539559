<jqxLoader #loader [imagePosition]="'top'" [width]="100" [height]="60" [text]="translate('Procesando')">
</jqxLoader>
<jqxWindow #formResumen [theme]="environment.tema" [width]="1110" [height]="562" [zIndex]="50"
    [position]="getFormPos(formResumen)" [autoOpen]="true" [zIndex]="90" [showCloseButton]="true"
    [showCollapseButton]="true" [resizable]="false" (onClose)="onClose()">
    <div class="formHeader">
        {{translate('Resumen_volumetricos')}}
    </div>
    <div class="form" style="float:left; overflow: hidden;">
        <div style="float: left; padding-top: 2px; clear: both;">
            {{translate('Periodo')}}: {{dateUtils.formatDateTime(fechaIni, true)}} -
            {{dateUtils.formatDateTime(fechaFin, true)}}
        </div>
        <div style="float: left; width: 100%; height: 470px; background-color: white; clear: both;">
            <div style="float: left; padding-top: 8px; width: 100%; clear: both;">
                <div style="float: left; width: 33%; padding-left: 4px; padding-top: 4px;">
                    <b>{{translate('Volumetricos').toUpperCase()}}</b>
                    <hr>
                    <div style="float: left; width: 180px;">
                        {{translate('Con_elemento')}}<br>
                        {{translate('Sin_elemento')}}<br>
                        {{translate('Total')}}
                    </div>
                    <div style="float: left; width: 100px; text-align: center;">
                        <b>{{numberUtils.format(volumConElemento,0)}}</b><br>
                        <b style="color: red;">{{numberUtils.format(volumSinElemento,0)}}</b><br>
                        <b>{{numberUtils.format(volumTotales,0)}}</b>
                    </div>
                    <div *ngIf="volumTotales>0" style="float: left; text-align: center;">
                        {{numberUtils.format(volumConElemento/volumTotales*100,2)}}%<br>
                        {{numberUtils.format(volumSinElemento/volumTotales*100,2)}}%
                    </div>
                </div>
                <div style="float: left; width: 33%; padding-left: 4px; padding-top: 4px;">
                    <b>{{translate('Bateria').toUpperCase()}}</b>
                    <hr>
                    <div style="float: left; width: 180px;">
                        {{translate('Baja')}} &lt; {{BAT_BAJA}}%<br>
                        {{translate('Muy_baja')}} &lt; {{BAT_MUY_BAJA}}% </div>
                    <div style="float: left; width: 100px; text-align: center;">
                        <b style="color: red;">{{numberUtils.format(bateriaBaja,0)}}</b><br>
                        <b style="color: red;">{{numberUtils.format(bateriaMuyBaja,0)}}</b>
                    </div>
                    <div *ngIf="volumTotales>0" style="float: left; text-align: center;">
                        {{numberUtils.format(bateriaBaja/volumTotales*100,2)}}%<br>
                        {{numberUtils.format(bateriaMuyBaja/volumTotales*100,2)}}%
                    </div>
                </div>
                <div style="float: left; width: 33%; padding-left: 4px; padding-top: 4px;">
                    <b>{{translate('Temperatura').toUpperCase()}}</b>
                    <hr>
                    <div style="float: left; width: 180px;">
                        {{translate('Alta')}} &gt; {{TEMP_ALTA}}°C<br>
                        {{translate('Muy_alta')}} &gt; {{TEMP_MUY_ALTA}}°C
                    </div>
                    <div style="float: left; width: 100px; text-align: center;">
                        <b style="color: red;">{{numberUtils.format(temperaturaAlta,0)}}</b><br>
                        <b style="color: red;">{{numberUtils.format(temperaturaMuyAlta,0)}}</b>
                    </div>
                    <div *ngIf="volumTotales>0" style="float: left; text-align: center;">
                        {{numberUtils.format(temperaturaAlta/volumTotales*100,2)}}%<br>
                        {{numberUtils.format(temperaturaMuyAlta/volumTotales*100,2)}}%<br>
                    </div>
                </div>
            </div>
            <div style="float: left; width: 100%; clear: both;">
                <div style="float: left; width:33%; padding-left: 4px; padding-top: 8px;">
                    <b>{{translate('Lecturas').toUpperCase()}}</b>
                    <hr>
                    <div style="float: left; width: 180px;">
                        {{translate('Lecturas_con_elemento')}}<br>
                        <div style="float: left; background-color: rgb(233, 233, 233); width: 100%;">
                            {{translate('En_hora')}} &lt; {{_15_MIN/60000}} min.<br>
                            {{translate('Atrasadas')}} &lt; {{_30_MIN/60000}} min<br>
                            {{translate('Fuera_hora')}} &gt;= {{_30_MIN/60000}} min<br>
                            {{translate('Lecturas_erroneas')}} (999)
                        </div>
                        {{translate('Lecturas_sin_elemento')}}<br>
                        {{translate('Lecturas_totales')}}
                    </div>
                    <div style="float: left; width: 100px; text-align: center;">
                        <b>{{numberUtils.format(lecturasConElemento,0)}}</b><br>
                        <div style="float: left; background-color: rgb(233, 233, 233); width: 100%;">
                            {{numberUtils.format(lecturasEnHora,0)}}<br>
                            {{numberUtils.format(lecturasAtrasadas,0)}}<br>
                            <div style="color: red;">
                                {{numberUtils.format(lecturasFueraHora,0)}}<br>
                                {{numberUtils.format(lecturasErroneas,0)}}
                            </div>
                        </div>
                        <b style="color: red;">{{numberUtils.format(lecturasSinElemento,0)}}</b><br>
                        <b>{{numberUtils.format(lecturasTotales,0)}}</b>
                    </div>
                    <div *ngIf="lecturasConElemento>0" style="float: left; text-align: center; width: 60px;">
                        {{numberUtils.format(lecturasConElemento/lecturasTotales*100,2)}}%<br>
                        <div
                            style="float: left; background-color: rgb(233, 233, 233); text-align: center; width: 100%;">
                            {{numberUtils.format(lecturasEnHora/lecturasConElemento*100,2)}}%<br>
                            {{numberUtils.format(lecturasAtrasadas/lecturasConElemento*100,2)}}%<br>
                            {{numberUtils.format(lecturasFueraHora/lecturasConElemento*100,2)}}%<br>
                            {{numberUtils.format(lecturasErroneas/lecturasConElemento*100,2)}}%<br>
                        </div>
                        {{numberUtils.format(lecturasSinElemento/lecturasTotales*100,2)}}%
                    </div>
                </div>
                <div style="float: left; width: 33%; padding-left: 4px; padding-top: 8px;">
                    <b>{{translate('Recepcion').toUpperCase()}}</b>
                    <hr>
                    <div style="float: left; width: 180px;">
                        {{translate('En_hora')}} &lt; {{_15_MIN/60000}} min.<br>
                        {{translate('Atrasadas')}} &lt; {{_30_MIN/60000}} min.<br>
                        {{translate('Fuera_hora')}} &gt;= {{_30_MIN/60000}} min.
                    </div>
                    <div style="float: left; width: 100px; text-align: center;">
                        <b>{{numberUtils.format(enviosEnHora,0)}}</b><br>
                        <b>{{numberUtils.format(enviosAtrasados,0)}}</b><br>
                        <b style="color: red;">{{numberUtils.format(enviosFueraHora,0)}}</b>
                    </div>
                    <div *ngIf="totalEnvios>0" style="float: left; text-align: center;">
                        {{numberUtils.format(enviosEnHora/totalEnvios*100,2)}}%<br>
                        {{numberUtils.format(enviosAtrasados/totalEnvios*100,2)}}%<br>
                        {{numberUtils.format(enviosFueraHora/totalEnvios*100,2)}}%
                    </div>
                </div>
            </div>
            <div style="float: left; width: 100%; height: 100px; clear: both;">
                <div style="float: left; width:100%; padding-left: 4px; padding-top: 8px;">
                    <div style="float: left; width: 66.5%;">
                        <b>{{translate('Faltan_lecturas').toUpperCase()}}</b>
                        <jqxGrid #grid [theme]="environment.tema" [width]="'100%'" [height]="180" [source]="dataAdapter"
                            [appCellClick] [columnsresize]="true" [sortable]="true" [showrowlines]="false"
                            [showcolumnlines]="true" [enablebrowserselection]="true" [columns]="columns"
                            [altrows]="true" [sortmode]="'single'" [enabletooltips]="true" [filterable]="false"
                            [showstatusbar]="true" [statusbarheight]="20" [showaggregates]="true" [rowsheight]="20"
                            [columnsheight]="20" [localization]="langGrid" (onRowclick)="onRowClick($event)">
                        </jqxGrid>
                    </div>
                    <div style="float: left; width: 33%; padding-left: 6px;">
                        <b>{{translate('No_han_enviado').toUpperCase()}}</b>
                        <jqxGrid #grid2 [theme]="environment.tema" [width]="'100%'" [height]="180"
                            [source]="dataAdapter2" [columnsresize]="true" [sortable]="true" [showrowlines]="false"
                            [appCellClick] [showcolumnlines]="true" [columns]="columns2" [altrows]="true"
                            [sortmode]="'single'" [enablebrowserselection]="true" [enabletooltips]="true"
                            [filterable]="false" [showstatusbar]="true" [statusbarheight]="20" [showaggregates]="true"
                            [rowsheight]="20" [columnsheight]="20" [localization]="langGrid"
                            (onRowclick)="onRowClick2($event)">
                        </jqxGrid>
                    </div>
                </div>
            </div>
        </div>
        <div style="float: left; margin-top: 8px; width: 100%;">
            <jqxButton style="float:left;" [width]='100' [height]='26' [textImageRelation]='"imageBeforeText"'
                [textPosition]='"left"' [imgSrc]='"/assets/images/center.png"' [value]="translate('Centrar')"
                (onClick)="onCentrar($event)">
            </jqxButton>
        </div>
    </div>
</jqxWindow>