<div class="loader-container" *ngIf="showLoader">
  <div class=" loader"></div>
</div>
<jqxWindow #form [jqxWindowAutoResize] [theme]="environment.tema" [width]="mapWidth" [height]="mapHeight - 24" [zIndex]="900"
  [position]="getFormPos(form, 24)" [autoOpen]="true" [showCloseButton]="true" [showCollapseButton]="true"
  [resizable]="true" (onClose)="onClose()">
  <div class="formHeader" style="overflow-x: hidden;">
    {{translate('Listado_alarmas_geo')}}
  </div>
  <div class="form" style="float:left; overflow: hidden;">
    <jqxSplitter #mainSplitter [panels]="[{ min: '15%', size: '15%' }]" [height]="'calc(100% - 40px)'" [width]="'100%'">
      <div>
        <div style="height: 100%">
          <app-moviles-select (passArray)="movilesSelected($event)"></app-moviles-select>
        </div>
      </div>
      <div>
        <div style="height: calc(100% - 40px);">
          <div>
            <app-header #header [buscador]="true" [exportar]="true" [periodo]="true" [filtro]="true"
              [noLibre]="true" [resetFiltro]="true" (eventExportar)="onExportar()" (eventFilter)="onAceptar()"
              (eventResetFilter)="onResetFilter()" (eventBuscador)="onBuscar()">
            </app-header>
          </div>
          <jqxGrid #grid [theme]="environment.tema" [width]="'100%'" [height]="'100%'" [source]="dataAdapter"
            [appCellClick] [columnsresize]="true" [sortable]="true" [showrowlines]="false" [showcolumnlines]="true"
            [columns]="columns" [altrows]="true" [filterable]="true" [showstatusbar]="true" [statusbarheight]="20"
            [showaggregates]="true" [enablebrowserselection]="true" [rowsheight]="22" [columnsheight]="20"
            [localization]="langGrid" [columngroups]="columngroups" [groupable]="true">
          </jqxGrid>
        </div>
      </div>
    </jqxSplitter>
    <div>
      <jqxButton style="float: left; margin-left: 5px;margin-top: 10px" [width]="100" [height]="26"
        [textImageRelation]="'imageBeforeText'" [textPosition]="'left'" (onClick)="closeWindow()"
        [value]="translate('Salir')">
      </jqxButton>
    </div>
  </div>
</jqxWindow>
